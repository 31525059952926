import React, { useState } from 'react';
import './TransportationModeSelector.scss';

interface TransportationMode {
  id: string;
  icon: string;
  label: string;
}

interface TransportationModeSelectorProps {
  onModeChange: (mode: string) => void;
  disabledModes: number[]; // Array to control the disabled state of each button
}

const transportationModes: TransportationMode[] = [
  { id: 'Air', icon: 'airplanemode_active', label: 'Air' },
  { id: 'Sea', icon: 'directions_boat_filled', label: 'Sea' },
  { id: 'Land', icon: 'local_shipping', label: 'Land' }
];

const TransportationModeSelector: React.FC<TransportationModeSelectorProps> = ({ onModeChange, disabledModes }) => {
  const [selectedMode, setSelectedMode] = useState(transportationModes[0].id);

  return (
    <div className="mode-selector-container">
      {transportationModes.map((mode, index) => (
        <button
          key={mode.id}
          className={`mode-button ${selectedMode === mode.id ? 'selected' : ''}`}
          onClick={() => {
            setSelectedMode(mode.id);
            onModeChange(mode.id);
          }}
          disabled={disabledModes[index] === 1} // Disable the button if the corresponding array element is 1
        >
          <span className="material-icons">{mode.icon}</span>
          <span className="mode-button-text">{mode.label}</span>
        </button>
      ))}
    </div>
  );
};

export default TransportationModeSelector;
