import React from 'react';
import Icon from 'components/Icon';
import { format as formatDate } from 'utils/date';
import { metersToPixels } from 'utils/maps/math';
import { MeasMarkerProps } from '../AbstractMarker';
import DotMarker from '../Dot';
import './styles.scss';

interface Props extends MeasMarkerProps {
  map: any;
}

export default class TowerMarker extends DotMarker<Props> {
  name = 'TowerMarker';

  renderPopupContent = () => {
    const meas = this.props.measurement;
    const accurancy = this.props.measurement.cell.accuracy;
    const triggers = this.getTriggers(meas);

    return <div className="marker-popup">
                <p className="marker-popup-field icon"><Icon className="marker-popup-icon" name="cell_tower" size={12}/><b>TOWER</b></p>
                <p className="marker-popup-field">{formatDate(meas.time)}</p>
                <p className="marker-popup-field"><b>Index:</b> {this.props.index}</p>
                <p className="marker-popup-field"><b>Temperature:</b> {meas.temperature} ºC</p>
                { meas.battery && <p className="marker-popup-field"><b>Battery:</b> {meas.battery}%</p> }
                <p className="marker-popup-field"><b>Tower Accuracy:</b> {accurancy}m</p>
                { triggers.length > 0 && <p className="marker-popup-field"><b>Triggered:</b> {triggers.join(', ')}</p> }
            </div>;
  }

  renderMarker() {
    const accurancy = this.props.measurement.cell.accuracy;

    if(this.props.open && this.props.map && accurancy) {
      const areaSize = metersToPixels(accurancy, this.props.lat, this.props.map.getZoom());

      return <span className="marker-tower">
                <span className="marker-tower-area" style={{ width: areaSize + "px", height: areaSize + "px" }}></span>
                { super.renderMarker() }
            </span>;
    }

    return super.renderMarker();
  }
}