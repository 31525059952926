import React, { Component, MouseEventHandler, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import { trackEvent } from 'utils/analytics';
import './styles.scss';

type ButtonProps = PropsWithChildren<{
    category: string;
    action: string;
    label?: string;
    disabled?: boolean;
    to?: string;
    download?: string;
    icon?: string;
    iconSize?: number;
    iconType?: string;
    onClick?: MouseEventHandler;
    styles?: 'solid' | 'outline' | 'alert' | 'none';
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    reference?: any;
  }>

export default class Button extends Component<ButtonProps, {}> {
    static defaultProps = { styles: 'solid', className: '' };

    onClickHandler = (args: any) => {
        args.stopPropagation();
        trackEvent(this.props.category, this.props.action, this.props.label);

        this.props.onClick && this.props.onClick(args);
    }

    renderChildren() {
        return(<>
            { this.props.icon && <Icon className="button-icon" name={this.props.icon} size={this.props.iconSize || 16} type={this.props.iconType} /> }
            { this.props.children }
        </>);
    }

    render() {
        let className = `shlk-button shlk-button__${this.props.styles} ${this.props.className}`;

        if(this.props.icon) className += ' icon';
        if(this.props.disabled) className += ' shlk-disabled';

        if (this.props.download) {
            return (
                <a
                    href={this.props.to}
                    className={className}
                    download={this.props.download}
                    onClick={this.onClickHandler}
                >
                    { this.renderChildren() }
                </a>
            );
        } else if (this.props.to) {
            return (
                <Link
                    to={this.props.to}
                    className={className}
                    onClick={this.onClickHandler}
                >
                    { this.renderChildren() }
                </Link>
            );
        }

        return (
            <button
                className={className}
                onClick={this.onClickHandler}
                type={this.props.type}
                disabled={this.props.disabled}
                ref={this.props.reference}
            >
                { this.renderChildren() }
            </button>
        );
    }
}
