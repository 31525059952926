import { shlkAPI, clean } from "services/api";

export const getTruckFuels = async () => {
  return shlkAPI("truck/fuels", {}, true, { method: "GET" });
};

export const getTruckMake = async () => {
  return shlkAPI("truck/makes", {}, true, { method: "GET" });
};
export const getTruckModels = async (data: {
  make: string;
  fuel: string;
  AxleConfiguration: string;
}) => {
  return shlkAPI("truck/models", data, true, { method: "POST" });
};

export const getPortList = async (data: { countryCode: string }) => {
  return shlkAPI("ports/list", data, true, { method: "POST" });
};

export const getPortNearest = async (data: {
  longitude: string;
  latitude: string;
}) => {
  return shlkAPI("ports/nearest", data, true, { method: "POST" });
};
