import React, { Component, MouseEventHandler, PropsWithChildren } from 'react';
import { trackEvent } from 'utils/analytics';
import './styles.scss';

type TrackableElementProps = PropsWithChildren<{
    onClick?: MouseEventHandler | null;
    className?: string;
    category: string;
    action: string;
    label?: string;
    dataset?: object;
    reference?: any;
}>

export default class TrackableElement extends Component<TrackableElementProps, {}> {
    static defaultProps = { className: '' };

    onClickHandler = (args: any) => {
        trackEvent(this.props.category, this.props.action, this.props.label);

        this.props.onClick && this.props.onClick(args);
    }

    render() {
        const classname = `trackable-element ${this.props.className} ${this.props.onClick ? '' : 'no-interaction'}`;

        return(
            <div { ...this.props.dataset }
                ref={this.props.reference}
                className={classname}
                onClick={this.onClickHandler}
            >
                { this.props.children }
            </div>
        );
    }
}
