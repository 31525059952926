import { RouteParams, Dimensions } from 'utils/analytics';

export default {
    /**
     * Initializes the logger.
     * @param {Object} options - The options for initialization.
     * @param {string | null} options.userId - The user ID for analytics.
     * @returns {void}
     */
    init({ userId }: { userId: string | null }) {
        this.setAnalyticsUserId(userId);
    },

    /**
     * Sets the user ID for analytics tracking.
     * 
     * @param userId - The user ID to set. Pass `null` to clear the user ID.
     */
    setAnalyticsUserId(userId: string | null) {
        console.info('Analytics | setAnalyticsUserId:', userId);
    },

    /**
     * Tracks a pageview event in the analytics system.
     * 
     * @param routeParams - The route parameters for the current page.
     * @param dimensions - The dimensions associated with the pageview event.
     */
    trackPageview(routeParams: RouteParams, dimensions: Dimensions) {
        console.info('Analytics | trackPageview:', { routeParams, dimensions });
    },

    /**
     * Tracks an event for analytics.
     * 
     * @param category - The category of the event.
     * @param action - The action associated with the event.
     * @param label - The label for the event.
     */
    trackEvent(category: string, action: string, label: string) {
        console.info('Analytics | trackEvent:', { category, action, label });
    },

    /**
     * Tracks an exception in the analytics system.
     * 
     * @param description - The description of the exception.
     * @param fatal - Indicates whether the exception is fatal or not.
     */
    trackException(description: string, fatal: boolean) {
        console.info('Analytics | trackException:', { description: description.toString(), fatal });
    }
};