import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Page from 'components/ControlBars/Page';
import ContentLoader from 'components/Loading/ContentLoader';
import Button from 'components/Inputs/Button';
import { getTagInfo, updateTag, deleteTag, getTagPermissions } from 'services/tags';
import InfoTables from './InfoTables';
import Configuration from './Configuration';
import { TagsContext } from '../context';
import './styles.scss';

interface MatchProps {
  id: string;
}

type Props = RouteComponentProps<MatchProps>

interface State {
    loading: boolean;
    view: "INFO" | "CONFIG";
    permissions: string[];
    data: {
        tag: any;
        shipments: any[];
        trackers: any[];
    };
    update: { name: string; styles: { color: string  } };
}

class Tag extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            view: "INFO",
            permissions: [],
            data: { tag: {}, shipments: [], trackers: [] },
            update: { name: '', styles: { color: '' } }
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        this.setState({ loading: true });

        Promise.all([
            getTagInfo({ id }),
            getTagPermissions({ id })
        ]).then(([data, permissions]) =>
            this.setState({
                data, permissions,
                loading: false,
                update: {
                    name: data.tag.name,
                    styles: {
                        color: data.tag.styles.color
                    }
                }
            })
        );
    }

    deleteTag = () => {
        if(window.confirm('Are you sure you want to remove this tag?\nAll his references will be removed.')) {
            this.setState({ loading: true });
            deleteTag({ id: this.state.data.tag._id }).then(() => {
                this.context.updateTags(this.context.tags.filter(({ _id }: { _id: string }) => _id !== this.state.data.tag._id));
                this.props.history.push('/tags');
            });
        }
    }

    updateTag = (update: any) => {
        this.setState({loading : true});

        updateTag({ id: this.state.data.tag._id, tag: update })
            .then(({ tag }) => {
                const tagIndex = this.context.tags.findIndex(({ _id }: { _id: string }) => tag._id === _id);

                if(tagIndex > -1) {
                    this.context.tags[tagIndex] = tag;
                }

                this.setState({ data: Object.assign(this.state.data, { tag }) });
            })
            .catch(error => {
                window.alert(error.response.data.msg);
                this.setState({ update: { ...this.state.data.tag } });
            }).finally(() => this.setState({ loading: false }));
    }

    renderContent = () => {
        const hasConfigPermission = this.state.permissions.includes('CONFIG_TAGS');

        return (<>
            <div className="tags-header">
                <h1
                    className="tags-title tag"
                    style={{
                        backgroundColor: this.state.data.tag.styles.color
                    }}
                >
                    {this.state.data.tag.name}
                </h1>
                { hasConfigPermission && this.state.view === "INFO" &&
                    <Button className="tags-toggle-btn"
                            category="Tags"
                            action="Click Tab"
                            label="Config"
                            icon="settings"
                            onClick={() => this.setState({ view : "CONFIG" })}>
                        Configuration
                    </Button>
                }
                { this.state.view === "CONFIG" &&
                    <Button className="tags-toggle-btn"
                            category="Tags"
                            action="Click Tab"
                            label="INFO"
                            icon="table_view"
                            onClick={() => this.setState({ view : "INFO" })}>
                        Information
                    </Button>
                }
            </div>

            { this.state.view === "INFO" && <InfoTables permissions={this.state.permissions} data={this.state.data}/> }
            { this.state.view === "CONFIG" && <Configuration tag={this.state.data.tag} update={this.updateTag} delete={this.deleteTag}/>}
        </>);
    }

    render() {
        return (
        <Page className="shlk-tags">
            <ContentLoader loading={this.state.loading} content={this.renderContent}/>
        </Page>
        );
    }
}

Tag.contextType = TagsContext;

export default withRouter(Tag);