export const setSessionData = (name: string, value: string) => {
    sessionStorage.setItem(name, value);
};

export const getSessionData = (name: string) => {
    return sessionStorage.getItem(name);
};

export const removeSessionData = (name: string) => {
    sessionStorage.removeItem(name);
};

export const removeAllSessionData = sessionStorage.clear;