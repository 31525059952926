import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import Page from 'components/ControlBars/Page';
import ContentLoader from 'components/Loading/ContentLoader';
import { getTrackerPublic } from 'services/trackers';
import { EXTERNAL_ESHELLS } from 'pages/EShell/utils';
import './styles.scss';

interface MatchProps {
  id: string;
}
type Props = RouteComponentProps<MatchProps>

type State = {
  loading: boolean;
  tracker: any;
  shipment: any;
};

const redirectOnExternal = (id: string) => {
  if(EXTERNAL_ESHELLS.identifiers.includes(id)) {
    return window.location.href = EXTERNAL_ESHELLS.site;
  }
};

export default class EShell extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      tracker: null,
      shipment: null
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    this.setState({ loading: true });

    if(!redirectOnExternal(id)) {
      getTrackerPublic({ id })
      .then((info) => this.setState({
        loading: false,
        tracker: info && info.tracker,
        shipment: info && info.shipment
      }));
    }
  }

  renderContent = () => {
    if(!this.state.tracker) {
      window.location.href = 'https://www.naxxar.uk';
    } else if(this.state.shipment) {
      this.props.history.push(`/shipments/${this.state.shipment}/public`);
    } else {
      this.props.history.push(`/trackers/${this.state.tracker}`);
    }

    return <></>;
  }

  render() {
    return (<Page className="shlk-eshell">
      <ContentLoader loading={this.state.loading} content={this.renderContent} />
    </Page>);
  }
}