import React, { Component } from 'react';

import { trackEvent } from 'utils/analytics';

import './styles.scss';

interface Props {
    update: any;
    unit: string;
    name: string;
    value: string;
    error?: string;
    disabled?: boolean;
    model?:any;
}

export default class InputSettings extends Component<Props> {
    static defaultProps = { value: '' };

    render() {

        return (<div className="settings-input">
            <label className="settings-input__name">{this.props.name} ({this.props.unit}):</label>
            <input
                className={`settings-input__field ${this.props.error ? 'invalid' : ''}`}
                type="number"
                value={this.props.value}
                disabled={this.props.disabled}
                onChange={({ currentTarget }) => {
                    const intValue = currentTarget.value.length ? parseInt(currentTarget.value) : undefined;

                    trackEvent('InputSettingsForm', this.props.name, currentTarget.value);
                    this.props.update(intValue);
                }}
            />
            {
                this.props.error &&
                    <div className="settings-input__validation">{this.props.error}</div>
            }
        </div>);
    }
}



// import React, { Component } from 'react';

// import { trackEvent } from 'utils/analytics';

// import './styles.scss';

// interface Props {
//     update: any;
//     unit: string;
//     name: string;
//     value: string;
//     error?: string;
//     disabled?: boolean;
//     model?: any;
// }

// interface State {
//     error: string | undefined;
//     inputValue: string; // To store the input value during typing
// }

// export default class InputSettings extends Component<Props, State> {
//     static defaultProps = { value: '' };

//     constructor(props: Props) {
//         super(props);
//         this.state = {
//             error: undefined,
//             inputValue: props.value || '', // Initialize with the value prop
//         };
//     }

//     // Validate the final value after typing is done
//     validateValue = (name: string, value: number | undefined) => {
//         const { model } = this.props;
//         let error = undefined;

//         // Apply validation rules based on model and field name
//         if (model === 'ESHELL_R') {
//             if (name === 'motion') {
//                 if (value === undefined || value < 5 || value > 1800) {
//                     error = 'Min: 5 / Max: 1800';
//                 }
//             } else if (name === 'static') {
//                 if (value === undefined || value < 300 || value > 43000) {
//                     error = 'Min: 300 / Max: 43000';
//                 }
//             }
//         }

//         return error;
//     };

//     // Handle value changes during typing
//     handleChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
//         this.setState({ inputValue: currentTarget.value });

//         // Track the event, but don't validate yet
//         trackEvent('InputSettingsForm', this.props.name, currentTarget.value);
//     };

//     // Validate when the user moves away from the field (onBlur event)
//     handleBlur = () => {
//         const intValue = this.state.inputValue.length ? parseInt(this.state.inputValue) : undefined;

//         // Perform validation on blur (after user has finished typing)
//         const error = this.validateValue(this.props.name, intValue);

//         // Update local state with validation result
//         this.setState({ error });

//         // If valid, propagate the value to the parent via the update prop
//         if (!error) {
//             this.props.update(intValue);
//         }
//     };

//     render() {
//         // Determine the final error message, either from the local state or passed via props
//         const errorMessage = this.state.error || this.props.error;

//         return (
//             <div className="settings-input">
//                 <label className="settings-input__name">{this.props.name} ({this.props.unit}):</label>
//                 <input
//                     className={`settings-input__field ${errorMessage ? 'invalid' : ''}`}
//                     type="number"
//                     value={this.state.inputValue} // Use the local state for the input value
//                     disabled={this.props.disabled}
//                     onChange={this.handleChange} // Update value onChange without validating
//                     onBlur={this.handleBlur} // Validate when the input loses focus (onBlur)
//                 />
//                 {
//                     errorMessage && (
//                         <div className="settings-input__validation">{errorMessage}</div>
//                     )
//                 }
//             </div>
//         );
//     }
// }
