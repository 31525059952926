import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import User from 'components/User';
import Page from 'components/ControlBars/Page';
import Company from 'components/Company';
import { ReactSVG } from 'react-svg';
import TrackableElement from 'components/Trackable/Element';
import { getCookieData } from 'utils/data/cookies';
import { trackException } from 'utils/analytics';

export default class UserPage extends Component<RouteComponentProps> {
  copyToClickBoard = () => {
    const token = getCookieData("authToken") || '';

    navigator.clipboard.writeText(token)
              .then(function () {
                 window.alert('The TOKEN has been copied to your clipboard');
              }, function (err: any) {
                  trackException(err.stack || err.message);
                  window.alert('Sorry, there was an error while copying');
              });
  }

  render() {
    return <Page className="shlk-user">
        <User/>

        <div className="user-field">
          <div className="user-token-wrapper">
            <b>API Token</b>
            <ReactSVG className="user-token-icon"
                      onClick={this.copyToClickBoard}
                      src="/assets/svg/copy-outline.svg" />
          </div>
          <span className="user-token">{getCookieData("authToken")}</span>
          <div className="user-docs-link">
            <TrackableElement
              category="User Page"
              action="Click API Docs"
            >
              Find more info at
              <a href="https://documenter.getpostman.com/view/25703270/2s935poNYX" target="_blank" rel="noopener noreferrer">
                <b> docs.naxxar.uk</b>
              </a>
            </TrackableElement>
          </div>
        </div>

        <Company />
    </Page>;
  }
}