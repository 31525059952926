// import React, { useState, FormEvent } from 'react';
// import './styles.scss';
// import { useHistory } from 'react-router-dom';

// const ForgotPassword: React.FC = () => {
//   const [email, setEmail] = useState<string>('');
//   const history = useHistory();

//   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     console.log("Email entered: ", email);
//     history.push(`/enter-code?email=${encodeURIComponent(email)}`);
//   };

//   const handleBackToLogin = () => {
//     history.push('/login');  // Navigates back to the login page
//   };

//   return (
//     <div className="shlk-login">
//       <div className="shlk-login-wrapper">
//         <img className="shlk-login-logo"
//              src="/assets/icon/Naxxar_Full.png"
//              alt="Naxxar" />
//         <div className='Forgot-Password'> <span>Forgot Password?</span></div>
//         <div className='Forgot-Password-text'><span>We will send you reset instructions.</span></div>
//         <form className="shlk-login-form" onSubmit={handleSubmit}>
//           <div className="shlk-form__container">
//             <input className="shlk-login-input shlk-login-email"
//                    name="email"
//                    type="email"
//                    placeholder="Email Address"
//                    value={email}
//                    onChange={(e) => setEmail(e.target.value)}
//                    required />
//           </div>
//           <input className="shlk-login-input shlk-login-btn"
//                  type="submit"
//                  value="Send" />
//           <div className="back-to-login" onClick={handleBackToLogin}>
//             <span>&larr; Back to Login</span>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ForgotPassword;

// import React, { useState, FormEvent } from 'react';
// import './styles.scss';
// import { useHistory } from 'react-router-dom';
// import { send_otp } from 'services/login';
//  // Import the send_otp function

// const ForgotPassword: React.FC = () => {
//   const [email, setEmail] = useState<string>('');
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const history = useHistory();

//   const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);

//     try {
//       // Trigger the send_otp API
//       await send_otp({ emails: email });

//       // Navigate to the next page with the entered email
//       history.push(`/enter-code?email=${encodeURIComponent(email)}`);
//     } catch (err) {
//       setError('Failed to send OTP. Please check your email and try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleBackToLogin = () => {
//     history.push('/login');  // Navigates back to the login page
//   };

//   return (
//     <div className="shlk-login">
//       <div className="shlk-login-wrapper">
//         <img className="shlk-login-logo"
//              src="/assets/icon/Naxxar_Full.png"
//              alt="Naxxar" />
//         <div className='Forgot-Password'> <span>Forgot Password?</span></div>
//         <div className='Forgot-Password-text'><span>We will send you reset instructions.</span></div>

//         <form className="shlk-login-form" onSubmit={handleSubmit}>
//           <div className="shlk-form__container">
//             <input className="shlk-login-input shlk-login-email"
//                    name="email"
//                    type="email"
//                    placeholder="Email Address"
//                    value={email}
//                    onChange={(e) => setEmail(e.target.value)}
//                    required />
//           </div>
//           <input className="shlk-login-input shlk-login-btn"
//                  type="submit"
//                  value={loading ? 'Sending...' : 'Send'}
//                  disabled={loading} />

//           {error && <div className="error-message">{error}</div>} {/* Display error message */}

//           <div className="back-to-login" onClick={handleBackToLogin}>
//             <span>&larr; Back to Login</span>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ForgotPassword;

// import React, { useState, FormEvent } from 'react';
// import './styles.scss';
// import { useHistory } from 'react-router-dom';
// import { send_otp } from 'services/login'; // Import the send_otp function

// const ForgotPassword: React.FC = () => {
//   const [email, setEmail] = useState<string>('');
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const history = useHistory();

//   const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);

//     try {
//       // Trigger the send_otp API
//       await send_otp({ emails: email });

//       // Store email in localStorage or sessionStorage
//       localStorage.setItem('resetEmail', email); // Optionally use sessionStorage

//       // Navigate to the next page without email in the URL
//       history.push('/enter-code');
//     } catch (err) {
//       setError('Failed to send OTP. Please check your email and try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleBackToLogin = () => {
//     history.push('/login');  // Navigates back to the login page
//   };

//   return (
//     <div className="shlk-login">
//       <div className="shlk-login-wrapper">
//         <img className="shlk-login-logo"
//              src="/assets/icon/Naxxar_Full.png"
//              alt="Naxxar" />
//         <div className='Forgot-Password'><span>Forgot Password?</span></div>
//         <div className='Forgot-Password-text'><span>We will send you reset instructions.</span></div>

//         <form className="shlk-login-form" onSubmit={handleSubmit}>
//           <div className="shlk-form__container">
//             <input className="shlk-login-input shlk-login-email"
//                    name="email"
//                    type="email"
//                    placeholder="Email Address"
//                    value={email}
//                    onChange={(e) => setEmail(e.target.value)}
//                    required />
//           </div>
//           <input className="shlk-login-input shlk-login-btn"
//                  type="submit"
//                  value={loading ? 'Sending...' : 'Send'}
//                  disabled={loading} />

//           {error && <div className="error-message">{error}</div>} {/* Display error message */}

//           <div className="back-to-login" onClick={handleBackToLogin}>
//             <span>&larr; Back to Login</span>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ForgotPassword;

import React, { useState, FormEvent } from "react";
import "./styles.scss";
import { useHistory, withRouter } from "react-router-dom";
import { send_otp } from "services/login"; // Import the send_otp function

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>(""); // Clear email on load
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Trigger the send_otp API
      const response = await send_otp({ emails: email });
      console.log(response, "response");
      if (response.success == true) {
        // Store email in localStorage
        localStorage.setItem("resetEmail", email);

        // Navigate to the next page without email in the URL
        history.push("/enter-code");
      } else {
        // window.location.reload();
        setError(response.message);
      }
    } catch (err) {
      setError("Failed to send OTP. Please check your email and try again.");
      // window.location.reload();
    } finally {
      setLoading(false);
    }
  };

  const handleBackToLogin = () => {
    sessionStorage.setItem('refreshLogin', 'true');
    history.push("/login"); // Navigates back to the login page
  };

  return (
    <>

        <div className="shlk-login">
          <div className="shlk-login-wrapper">
            <img
              className="shlk-login-logo"
              src="/assets/icon/Naxxar_Full.png"
              alt="Naxxar"
            />
            <div className="Forgot-Password">
              <span>Forgot Password?</span>
            </div>
            <div className="Forgot-Password-text">
              <span>We will send you reset code.</span>
            </div>

            <form className="shlk-login-form" onSubmit={handleSubmit}>
              <div className="shlk-form__container padding_adjustment">
                <input
                  className="shlk-login-input shlk-login-email"
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <input
                className="shlk-login-input shlk-login-btn padding_adjustment"
                type="submit"
                value={loading ? "Sending..." : "Send"}
                disabled={loading}
              />

              {error && <div className="error-message">{error}</div>}

              <div
                className="back-to-login padding_adjustment"
                onClick={handleBackToLogin}
              >
                <span>&larr; Back to Login</span>
              </div>
            </form>
          </div>
        </div>
  
    </>
  );
};

export default withRouter(ForgotPassword);
