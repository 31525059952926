import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import Tracker from 'components/Tracker';

interface Props {
  shipment: any;
  trackers: any;
  selected: any;
  onChange: any;
}

export default class ShipmentTrackers extends Component<Props> {
  selectTracker = (tracker: any) => {
    let selected = [];
    const isSelected = this.props.selected.includes(tracker);

    if (isSelected) {
      selected = this.props.selected.filter((st: any) => st !== tracker);
    } else {
      selected = [...this.props.selected, tracker];
    }

    this.props.onChange(selected);
  }

  renderTracker = (tracker: any) => {
    return <div className="new-tracker" key={`Tracker${tracker._id}`}
                onClick={() => this.selectTracker(tracker)} >
                  <Tracker tracker={tracker} />
                  <ReactSVG className="new-tracker-icon"
                  src={`/assets/svg/${this.props.selected.includes(tracker) ? "checkmark-square" : "square"}.svg`} />
            </div>;
  }

  render() {
    return (<>
      <h4 className="new-box-title">Assigned trackers*</h4>
      <div className="new-box-scroll">
        {
          this.props.trackers.length === 0 ?
            <p>You don&apos;t have any<br /> available tracker</p> :
            this.props.trackers.map(this.renderTracker)
        }
      </div>
    </>);
  }
}