import React, { Component } from "react";
import FormSettings from "components/Forms/Settings";
import { validateSettings } from "components/Forms/Settings/utils";
import ContentLoader from "components/Loading/ContentLoader";
import Button from "components/Inputs/Button";
import Table from "components/Table";
import SelectableList from "components/SelectableList";
import { getTagsList } from "services/tags";
import { updateTrackers } from "services/trackers";
import { format } from "utils/date";
import { getNamedEshell } from "utils/tracker";
import "./styles.scss";
import NewFormSettings from "components/Forms/Settings/indexx";

interface Props {
  trackers: any[];
  update: Function;
  close: Function;
}

interface State {
  loading: boolean;
  tags: any[];
  selectedTags: string[];
  settings: any;
}

const getSelectedTags = (trackers: any[]) => {
  return trackers.reduce((tags, tracker) => {
    for (const tag of tracker.tags) {
      if (!tags.includes(tag.name)) tags.push(tag.name);
    }

    return tags;
  }, []);
};

export default class ActionsTrackers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      tags: [],
      selectedTags: getSelectedTags(props.trackers),
      settings: null,
    };
  }

  componentDidMount() {
    getTagsList().then((tags: any) => this.setState({ tags, loading: false }));
  }

  updateTrackers = () => {
    this.setState({ loading: true });

    const tags = this.state.tags.filter(({ name }: any) =>
      this.state.selectedTags.includes(name)
    );
    const update = this.props.trackers.map(({ _id }: any) => {
      const update: any = { tags };

      this.state.settings && (update.settings = this.state.settings);

      return { id: _id, update };
    });

    updateTrackers(update)
      .then(() =>
        this.props.update(
          this.props.trackers.map((tracker: any) => {
            this.state.settings && (tracker.settings = this.state.settings);
            tracker.tags = tags;

            return tracker;
          })
        )
      )
      .finally(() => this.props.close());
  };

  render() {
    if (this.props.trackers.length === 0) return <></>;

    const trackers = this.props.trackers,
      validation = validateSettings(this.state.settings),
      formData = { settings: this.props.trackers[0].settings, validation };

    const activeShipment = this.props.trackers.some(
      (trk: any) => !!trk.transport
    );
console.log(this.props.trackers[0].settings,"t");
    return (
      <ContentLoader
        loading={this.state.loading}
        content={() => (
          <div className="actions-popup">
            <div className="actions-group actions-trackers">
              <div className="action-header">
                <h2 className="action-title">eShells</h2>
                <div className="action-options">
                  <Button
                    category="Trackers Actions"
                    action="Create"
                    disabled={activeShipment}
                    to={
                      "/new?tracker=" +
                      this.props.trackers.map((trk: any) => trk._id + ",")
                    }
                  >
                    New shipment
                  </Button>
                  <Button
                    category="Trackers Actions"
                    action="Save"
                    onClick={this.updateTrackers}
                  >
                    Save
                  </Button>
                </div>
              </div>
              <Table
                headings={[
                  "Name",
                  "Model",
                  "Battery",
                  "Last update",
                  "Country",
                  "On shipment",
                  "Tags",
                ]}
                rows={trackers.map(
                  ({
                    _id,
                    model,
                    name,
                    transport,
                    battery,
                    lastMeasurement,
                    tags,
                  }: any) => ({
                    id: _id,
                    cells: [
                      { value: name },
                      { value: getNamedEshell(model) },
                      { value: (lastMeasurement.battery || battery) + "%" },
                      { value: format(lastMeasurement.time) },
                      {
                        value:
                          lastMeasurement.location.country !== "Unknown"
                            ? lastMeasurement.location.country
                            : "",
                      },
                      { value: transport ? "Yes" : "" },
                      { value: tags.map(({ name }: any) => name).join(", ") },
                    ],
                  })
                )}
              />
            </div>
            <div className="actions-group">
              <h2 className="action-title">Tags</h2>
              <SelectableList
                name="tags"
                list={this.state.tags.map(({ name }) => name)}
                selected={this.state.selectedTags}
                onChange={(selectedTags: any[]) =>
                  this.setState({ selectedTags })
                }
              />
            </div>
            <div className="actions-group actions-options">
              <h2 className="action-title">Configuration</h2>
              <NewFormSettings
                data={formData}
                update={(settings: any) => this.setState({ settings })}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
