import Supercluster, { PointFeature } from 'supercluster';

export function getMarkers(map: any, route: any) {
  const markers = [].concat(...route.waypoints);

  if(!map) return markers;

  const sp = new Supercluster({
    radius: 40,
    maxZoom: 16
  });

  const points: PointFeature<any>[] = markers.map((meas: any) => ({
    type: 'Feature',
    properties: {
      lat_y: meas.location.lat,
      long_x: meas.location.lng
    },
    geometry: {
      type: 'Point',
      coordinates: [meas.location.lng, meas.location.lat],
      meas
    }
  }));
  sp.load(points);

  const clusters = sp.getClusters([-180, -90, 180, 90], map.getZoom());

  return clusters.map((cluster: any) => {
    if(!cluster.properties || !cluster.properties.cluster) return cluster.geometry.meas;

    const clusterLocation = {
      lng: cluster.geometry.coordinates[0],
      lat: cluster.geometry.coordinates[1]
    };

    const leaves: any = sp.getLeaves(cluster.id, Infinity, 0);

    return ({
      _id: leaves[0].geometry.meas._id,
      location: clusterLocation,
      source: 'CLUSTER',
      cluster: {
        amount: cluster.properties.point_count,
        measurements: leaves.map((leaf: any) => leaf.geometry.meas)
      }
    });
  });
}