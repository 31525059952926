/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export const TagsContext: any = React.createContext({
    tags: [],
    updateTags(tags: never[]) {
        this.tags = tags;
    }
});

