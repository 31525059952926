import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';

import './styles.scss';

type Props = {
    permission: string;
    status: string;
    callback: any;
}

export default class PermissionOption extends Component<Props> {
    renderIcon = () => {
        let icon = "radio-button-off-outline";
        if(this.props.status === "GRANTED") icon = "checkmark-circle-outline";
        else if(this.props.status === "DENIED") icon = "close-circle-outline";

        return <ReactSVG className="option-icon" src={`/assets/svg/${icon}.svg`} />;
    }

    render() {
        return <div className={`permissions-option permissions-option__${this.props.status}`}>
            <div className='option-box'>
                { this.renderIcon() }
            </div>
            <h4 className='option-caption'>
                {this.props.permission.replace("_", " ").toLowerCase()}
            </h4>
        </div>;
    }
}