import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import Icon from 'components/Icon';
import TrackableElement from 'components/Trackable/Element';

import './styles.scss';

interface Props extends RouteComponentProps {
    hash: string;
    content: any;
}

interface State {
    closing: boolean;
}

const getHashValue = (location: string, hash: string) => {
    const key = `#${hash}=`;
    return location.indexOf(key) === 0 ?
            location.replace(key, "") :
            null;
};

class ContentPopup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { closing: false };
    }

    closeHandler = (e: any) => {
        e.stopPropagation();
        this.close();
    }

    close = () => {
        this.setState({ closing : true });

        setTimeout(() => {
            this.props.history.push(this.props.location.pathname);
            this.setState({ closing : false });
        }, 1000);
    }

    render() {
        const location = this.props.location.hash,
                hash = this.props.hash;

        if(location.indexOf(`#${hash}`) !== 0) {
            return <></>;
        }

        return <div className={`shlk-content-popup ${this.state.closing ? "shlk-cp-closing" : ""}`}>
            <TrackableElement
                category="Card"
                action="CloseAll"
                className="shlk-cp-background"
                onClick={this.closeHandler}>

            </TrackableElement>

            <div className="shlk-cp-card">
                <div className="shlk-cp-header">
                    <TrackableElement
                        className="shlk-cp-close-wrapper"
                        category="Card"
                        action="Close"
                        onClick={this.closeHandler}
                    >
                        <Icon className="shlk-cp-close" size={40} name="expand_more"/>
                    </TrackableElement>
                </div>

                <div className="shlk-cp-content">
                    { this.props.content(this.close, getHashValue(location, hash)) }
                </div>
            </div>
        </div>;
    }
}

export default withRouter(ContentPopup);