import { intersectsWithMap } from "./math";

const processByViewport = (measurement: any, map: any) => {
  const processed: any[] = [];

  for(let i = 0; i < measurement.length; i++) {
    const prev = measurement[i - 1],
          current = measurement[i],
          next = measurement[i + 1];

    if(intersectsWithMap(map, prev, current) || intersectsWithMap(map, current, next)) {
      processed.push(current);
    }
  }

  return processed;
};

const renderLine = (map: any, maps: any, line: any) => {
  const path = new maps.Polyline({
    path: [line.origin, line.dest],
    geodesic: true,
    strokeColor: "#3D5FA0",
    strokeOpacity: 1.0,
    strokeWeight: 3
  });

  path.setMap(map);
  map.addRoute(path);

  return path;
};

const processDuplicated = (lines: any[]) => {
  const processed: any = {};

  [].concat(...lines).forEach(({origin, dest}: any) => {
    const key = `${origin.lat}x${origin.lng}_${dest.lat}x${dest.lng}`;
    if(!processed[key]) processed[key] = { origin, dest, amount: 0 };
    processed[key].amount++;
  });

  return Object.values(processed);
};

const getMarkerFromMeasurement = (waypoint: any, markers: any) => {
  return markers.find((marker: any) => {
    return marker === waypoint ||
            (!!marker.cluster && marker.cluster.measurements.includes(waypoint));
  });
};

const createLines = (waypoints: any, markers: any) => {
  const lines = [];

  for(let i = 1; i < waypoints.length; i++) {
    const origin = getMarkerFromMeasurement(waypoints[i - 1], markers).location,
          dest = getMarkerFromMeasurement(waypoints[i], markers).location;

    lines.push({ origin, dest });
  }

  return lines;
};

export function getRoute(map: any, route: any, settings: any) {
  const measurements = route.measurements.filter(({ source }: any) => settings.sources.includes(source));

  return {
      ...route,
      waypoints: processByViewport(measurements, map)
  };
}

export function getRoutes(map: any, settings: any, routes: any[]) {
  return routes
    .filter(({ tracker }: any) => settings.trackers.includes(tracker.identifier))
    .map((route: any) => getRoute(map, route, settings));
}

export function renderRoute(map: any, maps: any, route: any, markers: any) {
  if(!!maps && !!map) {
    if(typeof(maps.DirectionsRenderer) === 'function' && route.waypoints.length > 1) {
      const lines = createLines(route.waypoints, markers);
      const processed = processDuplicated(lines);

      processed.forEach((line: any) => renderLine(map, maps, line));
    }
  }
}