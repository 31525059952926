import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

export default class Welcome extends Component {
  render() {
    return (
      <div className="home-public-content">
        <h1>
          Hi! Welcome to <b>Naxxar</b>!
        </h1>

        <p>
          If you want to have better visibility and data for you assets, <br />
          send us an email to{" "}
          <a href="mailto:support@naxxar.uk">support@naxxar.uk</a>
        </p>

        <p>
          Know more about us at{" "}
          <Link to="https://www.naxxar.uk/">https://www.naxxar.uk/</Link>
        </p>

        <p>
          If you have been invited to perform an inspection on one or more
          shipments.
        </p>
        <p> Please log on to the inspection app. </p>
        <p>
          {" "}
          <Link to="http://localhost:5173">http://localhost:5173</Link>{" "}
        </p>
      </div>
    );
  }
}
