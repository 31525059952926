import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import Icon from "components/Icon";
import BoxTitle from "components/Box/Title";
import TrackableElement from "components/Trackable/Element";
import { ackEvent } from "services/event/";
import { getEventIcon } from "utils/events";
import { format as formatDate } from "utils/date";
import { ShipmentContext } from "../context";
import "./styles.scss";

// interface State {
//   events: Array<any>;
// }

// export default class Events extends Component<{}, State> {
//   constructor(props: {}) {
//     super(props);
//     this.state = { events: [] };
//   }

//   componentDidMount = this.updateEvents;

//   updateEvents() {
//     const shipment = this.context.shipment;

//     let events: Array<any> = [];

//     events = events.concat(shipment.events);

//     shipment.transports.forEach((trans: any) => {
//       events = events.concat(trans.events);
//     });

//     events.sort(
//       (a: any, b: any) =>
//         Date.parse(b.measurement.time) - Date.parse(a.measurement.time)
//     );

//     this.setState({ events });
//   }

//   show = (event: any) => {
//     const mapSettings = Object.assign({}, this.context.mapSettings);
//     mapSettings.popup.open = {
//       id: event._id,
//       type: "EVENT",
//       center: event.measurement && event.measurement.location,
//     };
//     this.context.updateMapSettings(mapSettings);

//     if (!event.ack) {
//       ackEvent(event._id).then(() => {
//         const shipment = this.context.shipment;

//         const markEvent = (events: Array<any>) => {
//           events.forEach((ev: any) => {
//             if (ev._id === event._id) ev.ack = true;
//           });
//         };

//         shipment.transports.forEach((trans: any) => markEvent(trans.events));
//         markEvent(shipment.events);

//         this.context.updateShipment(shipment);
//         this.updateEvents();
//       });
//     }
//   };

//   renderField = (event: any) => {
//     const shipment = this.context.shipment;

//     if (event.measurement && event.measurement.tracker) {
//       const tracker = shipment.transports.find(
//         (trans: any) => trans.tracker._id === event.measurement.tracker
//       ).tracker;

//       return (
//         <p className="event-field event-title">
//           <b>{tracker.name}</b>
//         </p>
//       );
//     } else {
//       return (
//         <p className="event-field event-title">
//           <b>Vessel</b>
//         </p>
//       );
//     }
//   };

//   renderEvent = (event: any, index: number) => {
//     return (
//       <TrackableElement
//         className={`event-box event-ack-${event.ack}`}
//         key={`Event_${index}`}
//         category="ShipmentEvents"
//         action="Open Event"
//         label={event.type}
//         onClick={() => this.show(event)}
//       >
//         <Icon
//           className="event-icon"
//           size={20}
//           name={getEventIcon(event.type)}
//         />

//         <div>
//           {this.renderField(event)}
//           <p className="event-field event-date">
//             {formatDate(event.measurement.time)}
//           </p>
//           <p className="event-field">{event.message}</p>
//         </div>

//         <div className="event-show">
//           <ReactSVG
//             className="event-show-icon"
//             src="/assets/svg/chevron-right.svg"
//           />
//         </div>
//       </TrackableElement>
//     );
//   };

//   render() {
//     return (
//       <div className="shipment-events">
//         <BoxTitle text="Events" />

//         <div className="events-wrapper">
//           {this.state.events.map(this.renderEvent)}
//         </div>
//       </div>
//     );
//   }
// }

// Events.contextType = ShipmentContext;

interface State {
  events: Array<any>;
  selectedEventId: string | null; // Add this line to track the selected event
}

export default class Events extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = { events: [], selectedEventId: null }; // Initialize selectedEventId as null
  }

  componentDidMount = this.updateEvents;

  updateEvents() {
    const shipment = this.context.shipment;

    let events: Array<any> = [];

    events = events.concat(shipment.events);

    shipment.transports.forEach((trans: any) => {
      events = events.concat(trans.events);
    });

    events.sort(
      (a: any, b: any) =>
        Date.parse(b.measurement.time) - Date.parse(a.measurement.time)
    );

    this.setState({ events });
  }

  show = (event: any) => {
    const mapSettings = Object.assign({}, this.context.mapSettings);
    mapSettings.popup.open = {
      id: event._id,
      type: "EVENT",
      center: event.measurement && event.measurement.location,
    };
    this.context.updateMapSettings(mapSettings);

    this.setState({ selectedEventId: event._id }); // Update the selected event ID here

    if (!event.ack) {
      ackEvent(event._id).then(() => {
        const shipment = this.context.shipment;

        const markEvent = (events: Array<any>) => {
          events.forEach((ev: any) => {
            if (ev._id === event._id) ev.ack = true;
          });
        };

        shipment.transports.forEach((trans: any) => markEvent(trans.events));
        markEvent(shipment.events);

        this.context.updateShipment(shipment);
        this.updateEvents();
      });
    }
  };

  renderField = (event: any) => {
    const shipment = this.context.shipment;

    if (event.measurement && event.measurement.tracker) {
      const tracker = shipment.transports.find(
        (trans: any) => trans.tracker._id === event.measurement.tracker
      ).tracker;

      return (
        <p className="event-field event-title">
          <b>{tracker.name}</b>
        </p>
      );
    } else {
      return (
        <p className="event-field event-title">
          <b>Vessel</b>
        </p>
      );
    }
  };

  renderEvent = (event: any, index: number) => {
    // Conditionally apply the 'selected' class based on the selectedEventId
    const isSelected = this.state.selectedEventId === event._id;

    return (
      <TrackableElement
        className={`event-box event-ack-${event.ack} ${
          isSelected ? "event-selected" : ""
        }`} // Add 'event-selected' class if the event is selected
        key={`Event_${index}`}
        category="ShipmentEvents"
        action="Open Event"
        label={event.type}
        onClick={() => this.show(event)}
      >
        <Icon
          className="event-icon"
          size={20}
          name={getEventIcon(event.type)}
        />

        <div>
          {this.renderField(event)}
          <p className="event-field event-date">
            {formatDate(event.measurement.time)}
          </p>
          <p className="event-field">{event.message}</p>
        </div>

        <div className="event-show">
          <ReactSVG
            className="event-show-icon"
            src="/assets/svg/chevron-right.svg"
          />
        </div>
      </TrackableElement>
    );
  };

  render() {
    return (
      <div className="shipment-events">
        <BoxTitle text="Events" />

        <div className="events-wrapper">
          {this.state.events.map(this.renderEvent)}
        </div>
      </div>
    );
  }
}

Events.contextType = ShipmentContext;
