import { shlkAPI, clean } from 'services/api';

export const getUnackEvents = async () => {
  return shlkAPI('event/unAck', {}, true, { method: 'GET' });
};

export const ackEvent = async (id: string) => {
  return shlkAPI('event/ack', { id }).then(clean);
};

