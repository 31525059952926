import React, { Component } from 'react';
import TrackableElement from 'components/Trackable/Element';
import Icon from 'components/Icon';
import './styles.scss';

interface Props {
    text: string | JSX.Element;
    name: string;
}

interface State {
    open: boolean;
}

export default class InfoTooltip extends Component<Props, State> {
    private timer: ReturnType<typeof setTimeout> | null = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            open: false
        };
    }

    click = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        if(this.timer) {
            this.setState({ open: false });
            clearTimeout(this.timer);
            this.timer = null;
        } else {
            this.setState({ open: true });
            this.timer = setTimeout(() => {
                this.setState({ open: false });
                this.timer = null;
            }, 10000);
        }
    };

    render() {
        return (
            <TrackableElement
                className="form-info"
                category="FormInfo"
                action={`${this.state.open ? 'Close' : 'Open'} formInfo`}
                label={this.props.name}
                onClick={this.click}
            >
                <Icon name="info" size={18} type="outlined" className="form-info__icon" />
                { this.state.open && <span
                        className="form-info__text"
                    > { this.props.text } </span>
                }
            </TrackableElement>
        );
    }
}