import React, { Component } from 'react';
import { round } from 'utils/math';
import './styles.scss';

interface Props {
  shipment: any;
  locations: Array<any>;
}

const calculateTime = (start: Date, end: Date) => {
  return round((end.getTime() - start.getTime()) / 1000 / 60 / 60);
};

export default class ReportHistory extends Component<Props> {
  renderGroup = (group: any, index: number) => {
    const start = new Date(group.start),
          end = new Date(group.end),
          time = calculateTime(start, end);

    return <tr className='report-history-group' key={`ReportHistoryGroup${index}`}>
      <td>{start.toLocaleDateString()}</td>
      <td>{group.name}</td>
      <td>{group.km > 0 ? `${round(group.km)} km` : '-'}</td>
      <td>{time > 0 ? `${time} h` : "-"}</td>
      <td>{group.velocity > 0 ? `${round(group.velocity)} km/h` : "-"}</td>
      <td>{group.times.idle > 0 ? `${round(group.times.idle)} h` : "-"}</td>
      <td>{end.toLocaleDateString()}</td>
    </tr>;
  }

  render() {
    return (
      <div className='report-history'>
        <table>
          <thead>
            <tr>
              <td><h4>Arrival</h4></td>
              <td><h4>Location</h4></td>
              <td><h4>Distance</h4></td>
              <td><h4>Time</h4></td>
              <td><h4>Avg Velocity on Movement</h4></td>
              <td><h4>Idle Time</h4></td>
              <td><h4>Departure</h4></td>
            </tr>
          </thead>
          <tbody>
            { this.props.locations.map(this.renderGroup)}
          </tbody>
        </table>
      </div>
    );
  }
}