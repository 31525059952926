import React, { Component } from 'react';
import Button from 'components/Inputs/Button';
import ContentLoader from 'components/Loading/ContentLoader';
import Page from 'components/ControlBars/Page';
import { getAllUsers, manageUser } from 'services/users';
import { setCookieData } from 'utils/data/cookies';
import "./styles.scss";

interface State {
  loading: boolean;
  companies: any;
}

export default class AdminUsers extends Component<{}, State> {
  constructor(props: {}){
    super(props);

    this.state = {
      loading : false,
      companies: []
    };
  }

  sortByCompanies = (users: any) => {
    const companies: any = {};

    users.forEach((user: any) => {
      const company = user.company || { _id: "other", name: "Other" };
      const id = company._id;

      if(!companies[id]) {
        companies[id] = {
          name: company.name,
          users: []
        };
      }

      companies[id].users.push(user);
    });

    return Object.values(companies);
  }

  async componentDidMount() {
    this.setState({ loading: true });

    getAllUsers()
        .then(this.sortByCompanies)
        .then((companies: any) => this.setState({ companies, loading: false }));
  }

  manage = (user: any) => {
    this.setState({ loading: true });

    manageUser(user.email)
        .then((data: any) => {
          setCookieData('authToken', data.token);
          window.alert(`You will be redirected to the homepage of user: ${user.email}`);
          window.location.href = "/";
        });
  }

  renderContent= () => {
    return this.state.companies.map((company: any, index: number) =>
      <div className="company-name" key={`AdminCompany${index}`}>
        <h3>COMPANY: {company.name}</h3>
        <ul className="users-list">

        {
          company.users.map((user: any, index: number) =>
            <li className="user-account" key={`AdminUser${index}`}>
              <Button category="AdminUsers"
                      action="Select user"
                      label={user.email}
                      onClick={() => this.manage(user)}
                      styles='solid'
                      className="">
                Select
              </Button>
              <div>
                <h4>{user.fullName}</h4>
                <p>{user.email}</p>
              </div>
            </li>
          )
        }
        </ul>
      </div>
    );
  };

  render() {
    return <Page className="shlk-account-management">
        <h1>Select User to manage</h1>
        <ContentLoader content={this.renderContent} loading={this.state.loading} />
    </Page>;
  }
}

