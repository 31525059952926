import { shlkAPI, clean, Options } from "services/api";

export const getShipmentsPagination = async (status = {}, page:any, limit:any, searchText:any, createdAt:any, updatedAt:any) => {
  return shlkAPI("shipments/list-pagination", {status, page, limit, searchText, createdAt, updatedAt});
};

export const getShipments = async (filter = {}) => {
  return shlkAPI("shipments/list", filter);
};

export const getAllShipments = async (id: string, filter: any) => {
  return shlkAPI("shipments/all", { id, filter });
};

export const getShipmentCarbon = async (id: string) => {
  return shlkAPI("carbon/shipment", { id });
};

export const getShipmentInfo = async (id: string) => {
  return shlkAPI("shipments/get", { id });
};

export const getShipmentReportData = async (data: {
  id: string;
  config: any;
}) => {
  return shlkAPI("shipments/report", data);
};

export const getPublicShipmentInfo = async (id: string) => {
  return shlkAPI("shipments/public", { id }, false);
};

export const createNewShipment = async (
  shipment: object,
  trackers: Array<string>
) => {
  return shlkAPI("shipments/create", { shipment, trackers }).then(clean);
};

export const validateShipment = async (
  shipment: object,
  trackers: Array<string>
) => {
  return shlkAPI("shipments/validate", { shipment, trackers }).then(clean);
};
export const completeShipment = async (id: string) => {
  return shlkAPI("shipments/complete", { id }).then(clean);
};

export const deleteShipment = async (id: string) => {
  return shlkAPI("shipments/delete", { id }).then(clean);
};

export const updateSettings = async (id: string, settings: any) => {
  return shlkAPI("shipments/settings", { id, settings }).then(clean);
};

export const updateTags = async (data: { id: string; tags: any[] }) => {
  return shlkAPI("shipments/tags", data).then(clean);
};

export const updateShipment = async (data: {
  id: string;
  update: any;
  trackers: Array<string>;
}) => {
  return shlkAPI("shipments/update", data).then(clean);
};

export const getShipmentPermissions = async (id: string) => {
  return shlkAPI("shipments/permissions", { id });
};

export const inviteMember = async (
  data: { name: string; email: string; roles: Array<string>; id: string },
  options?: Options
) => {
  return shlkAPI("shipments/member/invite", data, true, options).then(clean);
};

export const inviteInspections = async (
  data: { name: string; email: string; roles: Array<string>; id: string },
  options?: Options
) => {
  return shlkAPI("shipments/member/invitecamera", data, true, options).then(clean);
};

export const sendReport = async (
  data: { emails: string[]; name:any; report:any; shipment:any; company:any; },
  options?: Options
) => {
  return shlkAPI("shipments/member/sendreport", data, true, options).then(clean);
};
export const getMemberRoles = async (data: { id: string }) => {
  return shlkAPI("shipments/member/roles", data);
};

export const getCameraRoles = async (data: { id: string }) => {
  return shlkAPI("shipments/member/cameraroles", data);
};

export const removeMember = async (data: { user: string; id: string }) => {
  return shlkAPI("shipments/member/remove", data).then(clean);
};

export const getOnboardShipments = async () => {
  return shlkAPI("shipments/onboard", {}, true, { method: "GET" });
};

export const getEmissionsTimeLine = async (data: { id: string }) => {
  return shlkAPI("shipments/emissionLine", data, true, { method: "POST" });
};

export const getEmissionsStats = async (data: { id: string }) => {
  return shlkAPI("shipments/emissionsStats", data, true, { method: "POST" });
};

export const getEmissionhubs = async (data: { id: string }) => {
  return shlkAPI("shipments/hubs", data, true, { method: "POST" });
};

