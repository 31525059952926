let DEVICE = '';

const MIN_WIDTH = 700;

const isMobileVersion = () => {
    return window.innerWidth < MIN_WIDTH;
};

const MOBILE = 'mobile';
const DESKTOP = 'desktop';

export const calculateDevice = () => {
    DEVICE = isMobileVersion() ? MOBILE : DESKTOP;

    return DEVICE;
};

export function getDevice() {
    return DEVICE;
}

export function isDesktop() {
    return DEVICE === DESKTOP;
}

export function isMobile() {
    return DEVICE === MOBILE;
}