import React, { Component } from "react";
import ContentLoader from "components/Loading/ContentLoader";
import Page from "components/ControlBars/Page";
import "./styles.scss";
import { listCompanies } from "services/company";
import { getAllShipments, getShipmentCarbon } from "services/shipments";

import CompanyCard from "components/Company/CompanyCard";

import Shipmentv2 from "components/Cards/Shipmentv2";

import RouteMapV2 from "components/GoogleMap/RouteMapv2";


import MultimodalMetric from "components/Cards/MultiModalMetric";
import TransportationModeSelector from "components/Forms/Shipment/Mode/ModeSelectorVertical";
import MultiModalSplitupCard from "components/Cards/MultiModalSplitup";
import DrivingCard from "components/Cards/DrivingCard";







function getDisableModeArray(distance: any) {
    //If distance is a single value, then it is a single mode shipment
    if (typeof distance === "number") {
      return [1, 1, 0];
    } else {
      const disableModeArray = [0, 0, 0];
      if (distance.air === -1) {
        disableModeArray[0] = 1;
      }
      if (distance.sea === -1) {
        disableModeArray[1] = 1;
      }
      if (distance.driving === -1) {
        disableModeArray[2] = 1;
      }
      return disableModeArray;
    }

  }

  const ModeMap = ["Air", "Sea", "Land"];




interface State {
  loading: boolean;
  companies: any[];
  shipments: any[];
  selectedCompany: any;
  selectedShipment: any;
  waypoints: any[];
  origin: any;
  destination: any;
  distance: any;
  emissions: any;
  points:any[];
  distanceObject:any;
  seaport_driving:any[];
  airports: any;
  airport_driving: any[];
  air_legs: any[];
  sea_legs: any[];
  disableModeArray: number[];
  currentMode: string;
}

export default class AdminShipments extends Component<{}, State> {
  private completedFilter = { status: ["completed"] };
  scrollPositionCompany = 0;
  scrollPositionShipment = 0;
  scrollRefCompany: React.RefObject<HTMLUListElement>;
  scrollRefShipment: React.RefObject<HTMLUListElement>;

  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      companies: [],
      shipments: [],
      selectedCompany: null,
      selectedShipment: null,
      waypoints: [],
      origin: null,
      destination: null,
      distance: 0,
      emissions: 0,
      points:[],
      distanceObject:{},
      airports: {},
      seaport_driving: [],
      airport_driving: [],
      air_legs: [],
      sea_legs: [],
      disableModeArray: [1, 1, 1],
      currentMode: "Air"
    };
    this.scrollRefCompany = React.createRef();
    this.scrollRefShipment = React.createRef();
  }

  getSnapshotBeforeUpdate() {
    if (this.scrollRefCompany.current) {
      this.scrollPositionCompany = this.scrollRefCompany.current.scrollTop;
    }
    if (this.scrollRefShipment.current) {
      this.scrollPositionShipment = this.scrollRefShipment.current.scrollTop;
    }
    return null;
  }

  componentDidUpdate() {
    if (this.scrollRefCompany.current) {
      this.scrollRefCompany.current.scrollTop = this.scrollPositionCompany;
    }
    if (this.scrollRefShipment.current) {
      this.scrollRefShipment.current.scrollTop = this.scrollPositionShipment;
    }
  }




  async componentDidMount() {
    this.setState({ loading: true });
    const companies_data = await listCompanies();
    const companies = companies_data.map((company: any) => ({
      name: company.name,
      id: company._id
    }));
    this.setState({ companies, loading: false });
  }

  handleCompanyClick = async (companyId: string) => {
    this.setState({ loading: true, selectedCompany: companyId });

      console.log("companyId:", companyId);

      const shipments = await getAllShipments(companyId, this.completedFilter);

 
      this.setState({
        shipments: shipments,
        selectedShipment: null,
        waypoints: [],
        origin: shipments.length > 0 ? shipments[0].origin : null,
        destination: shipments.length > 0 ? shipments[0].destination : null,
        distance: 0,
        emissions: 0,
        distanceObject: shipments.length > 0 ? shipments[0].distance : null,
        points : [],
        loading: false
      }, () => {
        console.log("shipments after setting:", this.state.shipments); // Now this will log the updated state
      });
    
  };

  handleShipmentClick = async (shipmentIndex: number) => {
    this.setState({ loading: true });


      const selectedShipment = this.state.shipments[shipmentIndex];
      const shipmentId = selectedShipment._id;

      const shipment = await getShipmentCarbon(shipmentId);


      if (!shipment) {
        throw new Error("Shipment not found");
      }
      console.log("shipment:", shipment);

      //If searoute is present, then log sea route points
      if(shipment.distance.sea_route){
      console.log("shipment.points:", shipment.distance.sea_route);
      }
    

      this.setState({
        selectedShipment: shipmentIndex,
        waypoints: shipment.waypoints,
        origin: shipment.origin,
        destination: shipment.destination,
        distance: shipment.distance.distance,
        emissions: shipment.distance.emissions,
        distanceObject:shipment.distance,
        points: (shipment.distance.multi_modal )? shipment.distance.sea_route : [],
        airports: (shipment.distance.multi_modal )? shipment.distance.airports : {},
      
        seaport_driving: (shipment.distance.multi_modal )? shipment.distance.sea_driving_points : {},
        airport_driving: (shipment.distance.multi_modal )? shipment.distance.air_driving_points : {},
        air_legs: (shipment.distance.multi_modal )? shipment.distance.air_legs['legs'] : [],
        sea_legs: (shipment.distance.multi_modal )? shipment.distance.sea_legs['legs'] : [],
        disableModeArray: getDisableModeArray(shipment.distance.distance),
        currentMode: ModeMap[getDisableModeArray(shipment.distance.distance).findIndex((value: number) => value === 0)],

        loading: false
      }, () => {
        console.log("Air legs:", this.state.air_legs);
        console.log("Sea legs:", this.state.sea_legs);
      }
      
      );
    
  };

  renderCompaniesList = () => {
    return (
      <div className="raised-card cards">
        <h3>Companies</h3>
        <ul ref={this.scrollRefCompany} className="scrollable-list">
          {this.state.companies.map((company, index) => (
            <li key={index}>
              <CompanyCard
                company={company}
                onClick={(id) => this.handleCompanyClick(id)}
                isSelected={this.state.selectedCompany === company.id}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  renderShipmentsList = () => {
    return (
      <div className="raised-card cards">
        <h3>Shipment</h3>
        <ul ref={this.scrollRefShipment} className="scrollable-list">
          {this.state.shipments.map((shipment, index) => (
            <li key={index}>
              <Shipmentv2
                shipment={shipment}
                index={index}
                onClick={(id) => this.handleShipmentClick(id)}
                isSelected={this.state.selectedShipment === index}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  renderMapWithWaypoints() {
    const { loading, waypoints, origin, destination, distanceObject, points, airports, seaport_driving, airport_driving } = this.state;
  
    if (loading || !origin || !destination) {
      return <div>Loading...</div>;
    }
  
    let seaRoute: { lat: number; lng: number }[] = [];
    console.log("distanceObject:", distanceObject);


    let drivable: boolean; // Declare the 'drivable' variable

    if (distanceObject) {
      drivable = distanceObject.isDrivable;
    } else {
      drivable = false;
    }

    // Null check for distanceObject and sea_route
    if (distanceObject && points && points.length > 0) {
      seaRoute = points.map(([lng, lat]: [number, number]) => ({ lat, lng }));
      console.log("seaRoute:", seaRoute);
    } 
  
    return (
      <RouteMapV2
        waypoints={waypoints}
        origin={origin}
        destination={destination}
        sea_route={seaRoute}
        isDrivable={drivable}
        airports={airports}
        seaport_driving={seaport_driving}
        airport_driving={airport_driving}
      />
    );
  }

  renderModeDetails() {
    const { sea_legs, air_legs, origin, destination, emissions, currentMode, distance, distanceObject } = this.state;
    
    let drivingDistance = 0;
    let drivingEmissions = 0;

    // If distance of a single mode shipment is a number, then it is a single mode shipment, get distance from state
    if (typeof distance === "number") {
        drivingDistance = distance;
        drivingEmissions = distanceObject.emissions;

    } else {
      drivingDistance = distance.driving;
      drivingEmissions = emissions.driving;
    }
    
    console.log("Origin and destination address:", origin.address, destination.address);
   console.log("Current mode in renderModeDetails:", currentMode);
   if (currentMode === "Air") {
    return (<MultiModalSplitupCard
    mode="Air"
    nodeNames={[origin.address, air_legs[0].airportName , air_legs[2].airportName, destination.address]}
    distances={[air_legs[0].distance, air_legs[1].distance, air_legs[2].distance]}
    emissions={[air_legs[0].emission, air_legs[1].emission, air_legs[2].emission]}  
  />);
  } else if (currentMode === "Sea") {
    return(<MultiModalSplitupCard
    mode="Sea"
    nodeNames={[origin.address, sea_legs[0].portName , sea_legs[2].portName, destination.address]}
    distances={[sea_legs[0].distance, sea_legs[1].distance, sea_legs[2].distance]}
    emissions={[sea_legs[0].emission, sea_legs[1].emission, sea_legs[2].emission]}
    />);
} else {
  return (<DrivingCard
    origin={origin.address}
    destination={destination.address}
    distance={drivingDistance}
    emissions={drivingEmissions}
    />);

  }
  }

  

  render() {
    console.log("selectedShipment:", this.state.selectedShipment);


    return (
      <Page className="shlk-account-management" type="map">
        <ContentLoader
          content={() => (
            <div className="shlk-layout">
              {this.renderCompaniesList()}

              {this.renderShipmentsList()}

              <div className="left-column">
                <div className="raised-card map-holder">
                  {/**
                   * Routemap visible only if a shipment is selected
                   */}

                  {this.state.selectedShipment !== null && (
                    <div className="map">{this.renderMapWithWaypoints()}</div>
                  )}
                </div>
                {this.state.selectedShipment !== null && (
                  console.log("distance:", this.state.distance),
                  <div className="row">
                    <TransportationModeSelector
                      onModeChange={(mode) => {
                        this.setState({ currentMode: mode });
                        console.log("mode:", mode);
                      }}
                      disabledModes={this.state.disableModeArray}></TransportationModeSelector>
                    {this.renderModeDetails()}
                    <MultimodalMetric values={this.state.emissions} units={["kg", "kg"]} threshold={1000} title="Total CO2 emissions per route" />
 
                  </div>
                )}
              </div>
            </div>
          )}
          loading={this.state.loading}
        />
      </Page>
    );
  }
}
