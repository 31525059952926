export function decorateMaps(maps: any) {
  maps.Map.prototype.getRoutes = function() { return this.routes || []; };
  maps.Map.prototype.clearRoutes = function() {
    if(!this.routes) this.routes = [];
    else {
      this.routes.forEach(function(routes: any) { routes.setMap(null); });
      this.routes = [];
    }
  };
  maps.Map.prototype.addRoute = function(route: any) {
    if(!this.routes) this.routes = [];
    this.routes.push(route);
  };

  return maps;
}

export function onRefresh(map: any, maps: any, callback: any) {
  // ON ZOOM
  maps.event.addListener(map, 'zoom_changed', callback);

  // ON PAN
  let hasDragged = false;
  maps.event.addListener(map, "dragend", () => { hasDragged = true; callback(); });
  maps.event.addListener(map, "center_changed", () => {
    if(hasDragged) {
      hasDragged = false;
      callback();
    }
  });
}