import { shlkAPI, clean, Options } from "services/api";

export const removeUser = async (user: string) => {
  return shlkAPI("company/remove", { user }).then(clean);
};

export const inviteUser = async (
  data: { name: string; email: string; roles: Array<string> },
  options?: Options
) => {
  return shlkAPI("company/invite", data, true, options).then(clean);
};

export const createCompany = async (data: {
  userName: string;
  email: string;
  password: string;
  companyName: string;
  projects: string[];
}) => {
  return shlkAPI("company/create", data).then(clean);
};

export const listCompanies = async () => {
  return shlkAPI("company/list").then(clean);
};

export const getCompanyStats = async (data: {}) => {
  return shlkAPI("company/stats", data, true, { method: "POST" });
};
