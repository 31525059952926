import React, { Component } from 'react';
import Page from 'components/ControlBars/Page';
import ContentLoader from 'components/Loading/ContentLoader';
import { getFreeTrackers } from 'services/trackers';
import { getAllUsers } from 'services/users';
import { assignTracker } from 'services/trackers';
import Info from './Info';
import QR from './QR';
import './styles.scss';

interface State {
  loading: boolean;
  trackers: any;
  users: any;
  view: "INFO" | "QR";
}

export default class AdminFreeTrackers extends Component<{}, State> {
  constructor(props: {}){
    super(props);

    this.state = {
      loading : false,
      trackers: [],
      users: [],
      view: "INFO"
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    Promise.all([
      getFreeTrackers(),
      getAllUsers()
    ])
    .then(([trackers, users]) => this.setState({ trackers, users, loading: false }));
  }

  assign = (id: number, email: string) => {
    this.setState({ loading: true });

    assignTracker(id, email)
      .then(() => this.setState({
          loading: false,
          trackers : this.state.trackers.filter((tracker: any) => tracker.identifier !== id)
        }));
  }

  renderInfoTrackers = (tracker: any, index: number) =>
      <Info tracker={tracker}
            key={`Admin_Tracker_${index}`}
            users={this.state.users}
            callback={this.assign}/>;

  renderQRTrackers = (tracker: any, index: number) =>
      <QR tracker={tracker} key={`Admin_Tracker_${index}`}/>

  renderContent= () => {
    return (<>
      {(this.state.trackers.length === 0) && (
        <h1>There are no free trackers</h1>
      )}

      <div className="admin-trackers-change" onClick={() => this.setState({
          view : this.state.view === "INFO" ? "QR" : "INFO"
        })}>
        Change view
      </div>

      <p>
        {
          this.state.view === 'INFO' ?
            'Assign free trackers to users' :
            'View QRs for free trackers'
        }
      </p>

      {
        this.state.trackers.map((tracker: any, index: number) =>
          this.state.view === 'INFO' ?
            this.renderInfoTrackers(tracker, index) :
            this.renderQRTrackers(tracker, index)
        )
      }
    </>);
  }

  render() {
    return (
      <Page className="shlk-admin-trackers">
        <ContentLoader content={this.renderContent} loading={this.state.loading} />
      </Page>
  );}
}
