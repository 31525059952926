import React, { Component } from 'react';

import './styles.scss';

export default class Footer extends Component {
  render() {
    return (
        <footer>
            <div className="footer-hint" >
                <span>Do you need help? Let us know how can we assist you at &nbsp;<a className="footer-hint__link" href="mailto:support@naxxar.uk">support@naxxar.uk</a>.</span>
            </div>
        </footer>
    );
  }
}