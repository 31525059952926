import React, { Component } from 'react';
import ContentPopup from 'components/ContentPopup';
import SharePopup from 'components/Popups/Share';
import { inviteMember, getMemberRoles } from 'services/tags';
import Members from './Members';
import Shipments from './Shipments';
import Trackers from './Trackers';

interface Props {
    permissions: string[];
    data: {
        tag: any;
        shipments: any[];
        trackers: any[];
    };
}

export default class InfoTablesTag extends Component<Props> {
    render() {
        const hasSharePermission = this.props.permissions.includes('INVITE_MEMBERS');

        return (<div className='tag-info'>
            <div className="tags-box__container">
                { hasSharePermission && <Members tag={this.props.data.tag}/> }
                <Trackers trackers={this.props.data.trackers}/>
                <Shipments shipments={this.props.data.shipments} />
            </div>

            {hasSharePermission && <ContentPopup hash="share" content={(close: any) =>
                <SharePopup type='tag'
                            inviteMember={inviteMember}
                            getRoles={getMemberRoles}
                            document={this.props.data.tag}
                            close={close} />
            }/>}
        </div>);
    }
}