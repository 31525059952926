import React from 'react';
import './SelectableIcon.scss';

interface SelectableIconProps {
  selectedMode: string;
  onModeChange: (mode: string) => void;
}

export const transportationModes = [
  { id: 'Air', icon: 'airplanemode_active', label: 'Air' },
  { id: 'Sea', icon: 'directions_boat_filled', label: 'Sea' },
  { id: 'Land', icon: 'local_shipping', label: 'Land' },
  { id: 'Rail', icon: 'train', label: 'Rail' }
];

const SelectableIcon: React.FC<SelectableIconProps> = ({ selectedMode, onModeChange }) => {
  return (
    <div className="mode-icon-container">
      {transportationModes.map((mode) => (
        <div
          key={mode.id}
          className={`mode-icon ${selectedMode === mode.id ? 'selected' : ''}`}
          onClick={() => onModeChange(mode.id)}
        >
          <span className="material-icons">{mode.icon}</span>
          <span className="mode-icon-text">{mode.label}</span>
        </div>
      ))}
    </div>
  );
};

export default SelectableIcon;
