import React, { Component, PropsWithChildren } from 'react';
import { hasPermission } from 'utils/auth';

type Props = PropsWithChildren<{
    permission: string;
    granted?: Array<string>;
}>

export default class HasPermissions extends Component<Props> {
    /**
     * Checks if the user has the right permissions.
     * 
     * @returns {boolean} True if the user has the right permissions, false otherwise.
     */
    hasRightPermissions = () => {
        return this.props.granted ?
                this.props.granted.includes(this.props.permission) :
                hasPermission(this.props.permission);
    }

    render() {
        if(this.hasRightPermissions()) {
            return <>{this.props.children}</>;
        }

        return <></>;
    }
}