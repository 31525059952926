import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BoxTitle from 'components/Box/Title';
import BoxOption from 'components/Box/Option';
import Tracker from 'components/Tracker';
import TrackableElement from 'components/Trackable/Element';
import { ShipmentContext } from '../context';
import "./styles.scss";

interface Props extends RouteComponentProps {
    raw: boolean;
}

interface State {
    loading: boolean;
    trackers: Array<any>;
    trackerCentered: number;
}

class Trackers extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            trackers: [],
            trackerCentered: -1
        };
    }

    componentDidMount() {
        this.setState({
            trackers: this.context.shipment.transports.map(({ tracker }: any) => tracker)
        });
    }

    clickCheckBox = (tracker: any) => {
        let trackers = [...this.context.mapSettings.trackers];

        if (trackers.includes(tracker.identifier)) {
            trackers = trackers.filter((identifier: string) => identifier !== tracker.identifier);
        } else {
            trackers.push(tracker.identifier);
        }

        const mapSettings = Object.assign({},
            this.context.mapSettings,
            { trackers });

        this.context.updateMapSettings(mapSettings);
    }

    centerTracker = (tracker: any) => {
        let trackerCentered = -1;
        const mapSettings = Object.assign({}, this.context.mapSettings);

        if(tracker.identifier !== this.state.trackerCentered) {
            trackerCentered = tracker.identifier;
            !mapSettings.trackers.includes(tracker.identifier) && mapSettings.trackers.push(tracker.identifier);
            mapSettings.popup.open = { id: trackerCentered, type: "TRACKER", center: tracker.lastMeasurement.location };
        } else {
            mapSettings.popup.open = null;
        }

        this.context.updateMapSettings(mapSettings);
        this.setState({ trackerCentered });
    }

    renderCheckBox = (tracker: any) => {
        const display = this.context.mapSettings.trackers,
            shouldDisplay = display.indexOf(tracker.identifier) > -1;

        return <TrackableElement
            className="tracker-option tracker-checkbox"
            category="CheckboxShells"
            action={`${shouldDisplay ? 'Hide' : 'Display'} eShell on map`}
            label={tracker.name}
            onClick={(event: any) => {
                event.stopPropagation();
                this.clickCheckBox(tracker);
            }}
            >
            <p className="label">Display</p>
            <ReactSVG
                className="tracker-checkbox-icon"
                src={`/assets/svg/${shouldDisplay ? "checkmark-square" : "square"}.svg`} />
        </TrackableElement>;
    }

    renderRaw = (tracker: any) => {
        return this.props.raw && (
            <TrackableElement
                className="tracker-option"
                category="CheckboxShells"
                action="Open raw"
                label={tracker.identifier}
                onClick={(event: any) => {
                    event.stopPropagation();
                    this.props.history.push(`/shipments/${this.context.shipment._id}#raw=${tracker.identifier}`);
                }}
                >
                <div className="tracker-raw">
                    <p className="label">Data</p>
                    <ReactSVG className="tracker-raw-icon" src="/assets/svg/table.svg" />
                </div>
            </TrackableElement>
        );
    }

    renderGoToTracker = (tracker: any) => <TrackableElement
        className="tracker-option"
        category="CheckboxShells"
        action="Open Tracker Info"
        label={tracker.identifier}
        onClick={(event: any) => {
            event.stopPropagation();
            this.props.history.push(`/trackers/${tracker.identifier}`);
        }}
        >
        <div className="tracker-raw">
            <p className="label">Info</p>
            <ReactSVG className="tracker-raw-icon" src="/assets/svg/eShells.svg" />
        </div>
    </TrackableElement>

    renderTrackerOptions = (tracker: any) => {
        return <div className="tracker-options">
            { this.renderCheckBox(tracker) }
            { this.renderGoToTracker(tracker) }
            { this.renderRaw(tracker) }
        </div>;
    }

    render() {
        const trackersLength = this.state.trackers.length;
        const title = `${trackersLength} eShell${trackersLength > 1 ? 's' : '' }`;

        return (
            <div className="shipment-trackers">
                <BoxTitle text={title} />

                <div className="trackers-content">
                    {this.state.trackers.map(tracker => {
                        const isSelected = this.state.trackerCentered === tracker.identifier;

                        return  <TrackableElement key={`Tracker_${tracker.identifier}`}
                            category="CheckboxShells"
                            action="Click View eShell"
                            label={tracker.identifier}
                            onClick={() => this.centerTracker(tracker)}>
                                <BoxOption selected={isSelected}>
                                    <Tracker tracker={tracker} />
                                    { this.renderTrackerOptions(tracker) }
                                </BoxOption>
                            </TrackableElement>;
                    })}
                </div>
            </div>
        );
    }
}

Trackers.contextType = ShipmentContext;

export default withRouter(Trackers);