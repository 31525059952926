const EVENTS_ICONS: any = {
    'idle': 'watch_later',
    'temperature':'thermostat',
    'battery': 'battery_alert',
    'light':'lightbulb',
    'collision' : 'edgesensor_high'
};

export function getEventIcon(name: string) {
    return EVENTS_ICONS[name.toLowerCase()] || 'warning';
}