import React, { Component } from 'react';

import Page from 'components/ControlBars/Page';
import ContentLoader from 'components/Loading/ContentLoader';

import { getAdminData } from 'services/admin';

import "./styles.scss";

interface State {
  data: any;
}

export default class AdminCompany extends Component<{}, State> {
  constructor(props: {}){
    super(props);

    this.state = { data: null };
  }

  componentDidMount() {
    getAdminData()
      .then((data: any) => this.setState({ data }));
  }

  renderContent = () => {
    const { trackers, companies, shipments } = this.state.data;

    return <>
        <h1>Data</h1>
        <div className='data-group data-group-total'>
          <div>
            <h4>Trackers</h4>
            <p>Active: {trackers.active}</p>
            <p>Inactive: {trackers.inactive}</p>
          </div>
          <div>
            <h4>Shipments</h4>
            <p>Total: {shipments.ongoing + shipments.completed}</p>
            <p>OnGoing: {shipments.ongoing}</p>
            <p>Completed: {shipments.completed}</p>
          </div>
        </div>
        <div className='data-group'>
          <h3>Companies</h3>
          <div className='data-group-companies'>
            { companies.map((company: any, index: number) =>
              <div className='data-company' key={`AdminDataCompany${index}`}>
                <h4>{company.name} ({company.members})</h4>
                <p>Active: {company.shipments.ongoing}</p>
                <p>Completed: {company.shipments.completed}</p>
                <p className={company.shipments.recent === 0 ? "data-0" : "data-1"}>Recently Modified: {company.shipments.recent}</p>
              </div>
            )}
          </div>
        </div>
    </>;
  }

  render() {
    return (
      <Page className="shlk-admin-data">
        <ContentLoader content={this.renderContent} loading={!this.state.data} />
      </Page>
  );}
}
