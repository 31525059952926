// import React, { Component } from "react";

// import { ReactSVG } from "react-svg";
// import ShipmentCard from "components/Cards/Shipment";
// import Button from "components/Inputs/Button";
// import ShipmentsFilter from "components/Filter/Shipments";
// import Icon from "components/Icon";
// import ContentLoader from "components/Loading/ContentLoader";
// import TrackableElement from "components/Trackable/Element";
// import { getShipments } from "services/shipments";
// import { debounce } from "utils/functions";
// import { getEventIcon } from "utils/events";
// import "./styles.scss";
// import ShipmentCardV2 from "components/Cards/Shipment/indexx";
// import { isMobile } from "utils/device";

// interface State {
//   shipments: Array<any>;
//   filter: boolean;
//   loading: boolean;
//   isMobile: any;
// }

// export default class Shipments extends Component<{}, State> {
//   private defaultFilter = { status: ["ongoing"], tags: [] };

//   constructor(props: {}) {
//     super(props);
//     this.state = {
//       shipments: [],
//       filter: false,
//       loading: true,
//       isMobile: false,
//     };
//   }

//   componentDidMount() {
//     getShipments(this.defaultFilter).then((shipments) =>
//       this.setState({ shipments, loading: false })
//     );

//     window.addEventListener("resize", this.checkMobile);
//     this.checkMobile();
//   }
//   componentWillUnmount() {
//     window.removeEventListener("resize", this.checkMobile);
//   }

//   checkMobile = () => {
//     const isMobile = window.innerWidth < 768; // Adjust threshold as needed
//     this.setState({ isMobile });
//   };

//   filterCallback = (filter: any) => {
//     this.setState({ loading: true }, () => this.getFilteredShipments(filter));
//   };

//   getFilteredShipments = debounce((filter: any) => {
//     getShipments(filter).then((shipments) =>
//       this.setState({
//         shipments,
//         loading: false,
//       })
//     );
//   }, 1200);

//   getCards = (shipments: any) => {
//     if (shipments.length === 0) {
//       return (
//         <div className="shlk-card-group">
//           <h3 className="shlk-card-group-header">0 Shipments</h3>
//         </div>
//       );
//     }

//     return shipments.map((shipment: any) => (
//       <ShipmentCardV2
//         key={`Shipment_${shipment._id}`}
//         shipment={shipment}
//         lines={[]}
//         showBoxButton={false}
//         actions={false}
//       />
//     ));
//   };

//   render() {
//     const { isMobile } = this.state;
//     return (
//       <div className="shlk-shipments shlk-widget">
//         <div className="shlk-shipment__buttons">
//           <div className="shipment-buttons__group">
//             <Button
//               category="ShipmentWidget"
//               action="Click New Shipment"
//               to="/new"
//             >
//               + New Shipment
//             </Button>
//             <Button
//               category="ShipmentWidget"
//               action="Click Tags"
//               className="tags-manager"
//               to="/tags"
//             >
//               <ReactSVG
//                 className="tags-manager__icon"
//                 src="/assets/svg/pricetags-outline.svg"
//               />
//               <span>Tags</span>
//             </Button>
//           </div>
//           <Button
//             category="ShipmentWidget"
//             action="Click Shipments"
//             to="/shipments"
//           >
//             All shipments
//           </Button>
//         </div>
//         <h2 className="shlk-title">
//           <span>Ongoing shipments</span>
//           {/* <TrackableElement
//             category="ShipmentWidget"
//             action="Click Filter"
//             onClick={() => this.setState({ filter: !this.state.filter })}
//           >
//             <Icon className="shlk-title__icon" size={20} name="tune" />
//           </TrackableElement> */}
//         </h2>
//         {this.state.filter && (
//           <ShipmentsFilter
//             filter={this.defaultFilter}
//             filterCallback={(filter: any) => this.filterCallback(filter)}
//           />
//         )}
//         <ContentLoader
//           loading={this.state.loading}
//           content={() => (
//             <div
//               className="shlk-shipments__container"
//               style={{ width: isMobile ? "100%":"" , display:"contents" }}
//             >
//               {this.getCards(this.state.shipments)}
//             </div>
//           )}
//         />
//       </div>
//     );
//   }
// }

import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import ShipmentCard from "components/Cards/Shipment";
import Button from "components/Inputs/Button";
import ShipmentsFilter from "components/Filter/Shipments";
import Icon from "components/Icon";
import ContentLoader from "components/Loading/ContentLoader";
import TrackableElement from "components/Trackable/Element";
import { getShipments } from "services/shipments";
import { debounce } from "utils/functions";
import { getEventIcon } from "utils/events";
import "./styles.scss";
import ShipmentCardV2 from "components/Cards/Shipment/dashboardcard";
import { isMobile } from "utils/device";

interface State {
  shipments: Array<any>;
  filter: boolean;
  loading: boolean;
  isMobile: any;
  currentPage: number;
  cardsPerPage: number;
}

export default class Shipments extends Component<{}, State> {
  private defaultFilter = { status: ["ongoing"], tags: [] };

  constructor(props: {}) {
    super(props);
    this.state = {
      shipments: [],
      filter: false,
      loading: true,
      isMobile: false,
      currentPage: 0,
      cardsPerPage: 2, // Change this value as needed
    };
  }

  componentDidMount() {
    getShipments(this.defaultFilter).then((shipments) => {
      this.setState({ shipments, loading: false });
      console.log("List Loading", shipments.length);
    });

    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 768; // Adjust threshold as needed
    this.setState({ isMobile });
  };

  filterCallback = (filter: any) => {
    this.setState({ loading: true }, () => this.getFilteredShipments(filter));
  };

  getFilteredShipments = debounce((filter: any) => {
    getShipments(filter).then((shipments) =>
      this.setState({
        shipments,
        loading: false,
      })
    );
  }, 1200);

  handleNext = () => {
    const { currentPage, shipments, cardsPerPage } = this.state;
    const totalPages = Math.ceil(shipments.length / cardsPerPage);
    this.setState({ currentPage: (currentPage + 1) % totalPages });
  };

  handlePrevious = () => {
    const { currentPage, shipments, cardsPerPage } = this.state;
    const totalPages = Math.ceil(shipments.length / cardsPerPage);
    this.setState({ currentPage: (currentPage - 1 + totalPages) % totalPages });
  };

  renderDots = () => {
    const { shipments, cardsPerPage, currentPage } = this.state;
    const totalPages = Math.ceil(shipments.length / cardsPerPage);

    return Array.from({ length: totalPages }).map((_, index) => (
      <span
        key={index}
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: currentPage === index ? "#19575C" : "#C4C4C4",
          display: "inline-block",
          margin: "0 5px", // Spacing between dots
        }}
      />
    ));
  };

  getCardsForCurrentPage = () => {
    const { shipments, currentPage, cardsPerPage } = this.state;
    const startIndex = currentPage * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    return shipments.slice(startIndex, endIndex);
  };

  getCards = (shipments: any) => {
    if (shipments.length === 0) {
      return (
        <div className="shlk-card-group">
          <h3 className="shlk-card-group-header">0 Shipments</h3>
        </div>
      );
    }

    return shipments.map((shipment: any) => (
      <ShipmentCardV2
        key={`Shipment_${shipment._id}`}
        shipment={shipment}
        lines={[]}
        showBoxButton={false}
        actions={false}
      />
    ));
  };

  render() {
    const { isMobile, currentPage, cardsPerPage, shipments } = this.state;
    const totalPages = Math.ceil(shipments.length / cardsPerPage);

    return (
      <div className="shlk-shipments shlk-widget">
        <div className="shlk-shipment__buttons">
          <div className="shipment-buttons__group">
            <Button
              category="ShipmentWidget"
              action="Click New Shipment"
              to="/new"
            >
              + New Shipment
            </Button>
            <Button
              category="ShipmentWidget"
              action="Click Tags"
              className="tags-manager"
              to="/tags"
            >
              <ReactSVG
                className="tags-manager__icon"
                src="/assets/svg/pricetags-outline.svg"
              />
              <span>Tags</span>
            </Button>
            <Button
            category="ShipmentWidget"
            action="Click Shipments"
            to="/shipments"
          >
            All Shipments
          </Button>
          </div>
        
        </div>
        <h2
          className="shlk-title"
          style={{ marginBottom: isMobile ? "15px" : "" }}
        >
          <span>Ongoing Shipments</span>
          {/* <TrackableElement
            category="ShipmentWidget"
            action="Click Filter"
            onClick={() => this.setState({ filter: !this.state.filter })}
          >
            <Icon className="shlk-title__icon" size={20} name="tune" />
          </TrackableElement> */}
        </h2>
        {this.state.filter && (
          <ShipmentsFilter
            filter={this.defaultFilter}
            filterCallback={(filter: any) => this.filterCallback(filter)}
          />
        )}
        <ContentLoader
          loading={this.state.loading}
          content={() => (
            <>
              {isMobile && (
                <>
                  {" "}
                  <div
                    className="shlk-shipments__container"
                    style={{
                      width: isMobile ? "100%" : "",
                      display: "contents",
                    }}
                  >
                    {this.getCards(this.getCardsForCurrentPage())}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      background: "white",
                      boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                      borderRadius: "5px",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{ fontSize: "30px" }}
                      onClick={this.handlePrevious}
                    >
                      {"<"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {this.renderDots()}
                    </div>
                    <div style={{ fontSize: "30px" }} onClick={this.handleNext}>
                      {">"}
                    </div>
                  </div>
                </>
              )}
              {!isMobile && (
                <>
                  {" "}
                  <div
                    className="shlk-shipments__container"
                    style={{ width: isMobile ? "100%" : "" }}
                  >
                    {this.getCards(this.state.shipments)}
                  </div>
                </>
              )}
            </>
          )}
        />
      </div>
    );
  }
}
