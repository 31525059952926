import React, { Component } from 'react';
import TagsBox from 'components/Tags/Box';
import { updateTags } from 'services/shipments';
import { getTagsList } from 'services/tags';
import { ShipmentContext } from '../context';

interface Props {
    actions: boolean;
    resetTagTab?:any;
}

interface State {
    loading: boolean;
    tags: any[];
}

export default class Tags extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { loading: true, tags: [] };
    }

    componentDidMount() {
        getTagsList().then(tags => this.setState({ tags, loading: false }));
    }

    componentWillUnmount() {
        // Reset Share tab when leaving the page
        if (this.props.resetTagTab) {
          this.props.resetTagTab();
        }
      }

    updateTags = (tags: any[]) => {
        this.setState({ loading: true });
        updateTags({ id: this.context.shipment._id, tags })
            .then(() => {
                this.context.updateShipment(Object.assign({}, this.context.shipment, { tags: [...tags] }));
                this.setState({ loading: false});
            });
    }

    render() {
        console.log("Taggggg");
        return <TagsBox
                    all={this.state.tags}
                    selected={this.context.shipment.tags}
                    onUpdate={this.updateTags}
                    actions={this.props.actions}/>;
    }
}

Tags.contextType = ShipmentContext;