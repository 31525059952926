import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReactSVG } from 'react-svg';
import ContentLoader from 'components/Loading/ContentLoader';
import Button from 'components/Inputs/Button';
import Input from 'components/Inputs/Input/Default';
import Selectable from 'components/Inputs/Selectable';
import Title from 'components/Title';
import { getUser, updateUser } from 'services/users';
import Signature from './Signature';
import './styles.scss';

interface Props extends RouteComponentProps {
  signature?: boolean;
}

interface State {
  loading: boolean;
  user: any;
  update: any;
}

class User extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      user: {},
      update: {}
    };
  }

  updateUserData = (data: any) => {
    this.setState({
      loading: false,
      user: Object.assign({}, data, { 'new-password' : "", 'new-password-confirm': "" }),
      update: Object.assign({}, data, { 'new-password' : "", 'new-password-confirm': "" })
    });
  }

  componentDidMount() {
    this.setState({ loading: true });

    getUser()
      .then((user: any) => this.updateUserData(user));
  }

  updateUser = () => {
    this.setState({ loading: true });

    updateUser(this.state.update)
      .then(() => this.updateUserData(this.state.update));
  }

  updateField = (key: any, value: any) => {
    const update = this.state.update;
    update[key] = value;
    this.setState({ update });
  }

  renderInput = (user: any, name: string, label: string, type: string) => {
    return (<div className={`user-field ${!this.isValid(name) ? "user-field-invalid" : ""}`}>
      <Input type={type}
              name={name}
              label={label}
              value={user[name]}
              onChange={(event: any) => this.updateField(name, event.currentTarget.value)}/>
    </div>);
  }

  renderNotificationsCheckbox = (user: any, name: string) => {
    const channels = user.notifications.channels;

    return (
      <Selectable name={name}
                checked={channels.indexOf(name) >= 0}
                onClick={(checked: boolean) => {
                  const notifications = channels.filter((not: string) => not !== name);
                  if(checked) notifications.push(name);
                  this.updateField("notifications", { channels: notifications });
                }}/>
    );
  }

  renderButton = (name: string, icon: string, extraProps: any) => {
    const buttonProps = Object.assign({
      category: "User",
      action: name,
      className: `user-${name.toLowerCase()} user-button`
    }, extraProps);

    return (
      <Button {...buttonProps}>
        <ReactSVG className="user-button-icon" src={`/assets/svg/${icon}.svg`} />
        <span>{name}</span>
      </Button>
    );
  }

  isValid = (field: string) => {
    const update = this.state.update;

    if(field === 'new-password-confirm') {
      return update['new-password'] === update['new-password-confirm'];
    } else if(field === "email") {
      return /\S+@\S+\.\S+/.test(update.email);
    }

    return true;
  }

  hasUpdates = () => {
    return this.isValid("new-password-confirm") &&
            this.isValid("email") &&
            JSON.stringify(this.state.update) !== JSON.stringify(this.state.user);
  }

  render() {
    const hasUpdates = this.hasUpdates();

    return <>
      <Title text="User" icon="person">
        { this.renderButton("Update", "edit", { onClick: this.updateUser, disabled: !hasUpdates }) }
      </Title>
      <ContentLoader loading={this.state.loading} content={() => {
        const update = this.state.update;

        return (<>
          { this.renderInput(update, "fullName", "Name", 'text') }
          { this.renderInput(update, "email", "Email", 'text') }
          { this.renderInput(update, "new-password", "New password", 'password')}
          { this.renderInput(update, "new-password-confirm", "Confirm password", 'password') }

          <div className="user-checkboxes user-field">
            <p className="user-label">Notifications</p>
            { this.renderNotificationsCheckbox(update, "EMAIL") }
          </div>

          { this.props.signature &&
              <Signature initialValue={this.state.user.signature}
                          callback={(value: string) => this.updateField('signature', value)}/> }
        </>);
      }}/>
    </>;
  }
}

export default withRouter(User);