import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import BoxTitle from 'components/Box/Title';
import BoxButton from 'components/Box/Button';
import TrackableElement from 'components/Trackable/Element';
import { createDocument, deleteDocument, downloadDocument } from 'services/documents';
import { getCookieData } from 'utils/data/cookies';
import { ShipmentContext } from '../context';
import './styles.scss';

interface Props {
    remove: boolean;
    upload: boolean;
}

interface State {
    documents: Array<any>;
    pdfToUpload: any[];
}

export default class Documents extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            documents : [],
            pdfToUpload:  []
        };
    }

    componentDidMount() {
        this.setState({
            documents : this.context.shipment.documents.map((doc: any) => {
                doc.loading = false;
                return doc;
            })
        });
    }

    markDoc = (doc: any, mark: boolean) => {
        this.setState({
            documents: this.state.documents.map(stateDoc => {
                if(stateDoc === doc) stateDoc.loading = mark;
                return stateDoc;
            })
        });
    }

    onFileChange = (documents: any) => {
        this.setState({
            pdfToUpload : documents.map((doc: any) => doc.file)
        });
    }

    onSubmit = (e: any) => {
        e.preventDefault();

        const formData = new FormData();

        const notPDF = this.state.pdfToUpload.filter((doc: any) => doc.type !== "application/pdf");
        if(notPDF.length > 0) {
            window.alert("You can only upload PDFs");
            return;
        }

        const toUpload = this.state.pdfToUpload.filter((doc: any) => doc.type === "application/pdf");
        for (const pdf in toUpload) {
            formData.append('pdfCollection', toUpload[pdf]);
        }

        formData.append('shipment', this.context.shipment._id);
        formData.append('folder', "" + getCookieData('email'));

        this.setState({
            documents: [
                ...this.state.documents,
                ...toUpload.map(doc => { return {name: doc.name, loading: true};})
            ],
            pdfToUpload: []
        });

        createDocument(formData)
            .then((createdDocuments: any) => {
                const documents = this.state.documents.map((doc) => {
                    const created = createdDocuments.filter((created: any) => doc.name === created.name);

                    if(created.length > 0) {
                        created[0].loading = false;

                        return created[0];
                    } else {
                        return doc;
                    }
                });

                this.context.shipment.documents = documents;
                this.setState({ documents, pdfToUpload: [] });
            });
    }

    download = (doc:  any) => {
        this.markDoc(doc, true);

        downloadDocument(doc._id, doc.name)
                .then(() => this.markDoc(doc, false));
    }

    delete = (doc: any) => {
        if(window.confirm("Are you sure you want to delete this document?")) {
            this.markDoc(doc, true);

            deleteDocument(this.context.shipment._id, doc._id)
                    .then(() => {
                        const newDocs = this.state.documents.filter(stateDoc => stateDoc !== doc);

                        this.context.shipment.documents = newDocs;
                        this.setState({ documents: newDocs });
                    });
        }
    }

    renderDoc = (doc: any, index: number) => {
        return (<div className="docs-file" key={"document_" + index}>
            <div className="docs-file-header">
                <h4 className="docs-file-title">{doc.name.replace(".pdf", "")}</h4>
            </div>

            {!doc.loading && (<>
                {
                    (this.props.remove) &&
                        <TrackableElement
                            className="docs-button"
                            category="ShipmentDocs"
                            action="Click Remove Document"
                            label={doc.name}
                            onClick={() => this.delete(doc)}>
                            <ReactSVG className="docs-icon docs-close" src="/assets/svg/trash-outline.svg"/>
                        </TrackableElement>
                }
                <TrackableElement
                    className="docs-button"
                    category="ShipmentDocs"
                    action="Click Download Document"
                    label={doc.name}
                    onClick={() => this.download(doc)}>
                    <ReactSVG className="docs-icon" src="/assets/svg/cloud-download-outline.svg"/>
                </TrackableElement>
            </>)}

            {doc.loading && (<div className="docs-button">
                <ReactSVG className="docs-icon docs-loading" src="/assets/svg/refresh.svg"/>
            </div>)}
        </div>);
    }

    render() {
        return (
            <div className="shipment-docs bc-wrapper">
                <BoxTitle text="Documentation" />

                <div className="docs-saved bc-stretch">
                    {this.state.documents.map(this.renderDoc)}
                </div>

                {
                    (this.props.upload) && (
                        <form className="documents-form bc-wrapper" onSubmit={this.onSubmit}>
                            <div className="filepond-wrapper">
                                <FilePond files={this.state.pdfToUpload}
                                            allowMultiple={true}
                                            server={null}
                                            instantUpload={false}
                                            onupdatefiles={this.onFileChange}>
                                </FilePond>
                            </div>

                            <BoxButton
                                category="Shipment"
                                action="Add document"
                                label={this.state.pdfToUpload.map(doc => doc.name).join(',')}
                                disabled={this.state.pdfToUpload.length === 0}
                                type="submit"
                                icon="save"
                                text="Save"
                            />
                        </form>
                    )
                }
            </div>
        );
    }
}

Documents.contextType = ShipmentContext;