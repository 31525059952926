import React, { Component } from "react";

import Icon from "components/Icon";

import "./styles.scss";
import { ReactSVG } from "react-svg";

interface EventProps {
  name: string;
  icon: string;
  count: number;
}

interface state {
  isMobile: any;
}

export default class Event extends Component<EventProps, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }
  componentDidMount() {
    // Check if window width is less than a certain threshold to determine mobile view
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 800; // Adjust threshold as needed
    this.setState({ isMobile });
  };
  render() {
    const { isMobile } = this.state;
    console.log(this.props.icon, "iconname");
    const fillColor = this.props.count >= 1 ? "#C11C1C" : "#3d9aa1";
    const svgWidth = isMobile ? "49" : "66";
    const svgHeight = isMobile ? "49" : "66";
    return (
      <div
        className={`shlk-status__event`}
        style={{ minWidth: isMobile && "20%", minHeight: isMobile && "0%" }}
      >
        <div className="event-icon__container">
          {this.props.icon == "lightbulb" ? (
            <ReactSVG
              src={"/assets/svg/doorsecurity.svg"}
              beforeInjection={(svg) => {
                const circle = svg.querySelector("circle");
                if (circle) {
                  circle.setAttribute("fill", fillColor);
                }
                // Set the width and height attributes of the SVG
                svg.setAttribute("width", svgWidth);
                svg.setAttribute("height", svgHeight);
              }}
            />
          ) : (
            <Icon
              className={` ${
                this.props.count >= 1 ? "red-icon" : "event-icon"
              }`}
              size={isMobile ? 30 : 50}
              name={this.props.icon}
            />
          )}
        </div>
        <div className={`event-info `}>
          <span
            style={{
              fontSize: isMobile && "15px",
              fontWeight: isMobile && "bold",
            }}
          >
            {this.props.name === "light"
    ? "Security"
    : isMobile && this.props.name === "temperature"
    ? "temp"
    : this.props.name}
          </span>
          <span
            className="event-count"
            style={{ fontSize: isMobile && "23px" }}
          >
            {this.props.count}
          </span>
        </div>
      </div>
    );
  }
}
