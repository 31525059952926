import React, { Component, CSSProperties } from 'react';
import { ReactSVG } from 'react-svg';

import './styles.scss';

interface Props {
    style? : CSSProperties;
}

export default class Spinner extends Component<Props> {
    render() {
       return (
        <div className="loader-spinner" style={this.props.style}>
            <div className="loader-round">
                <ReactSVG className="loader-icon" src="/assets/svg/vessel.svg" />
                <ReactSVG className="loader-icon" src="/assets/svg/pin.svg" />
                <ReactSVG className="loader-icon" src="/assets/svg/eShells.svg" />
            </div>
        </div>
       );
    }
}