import React from 'react';
import { ReactSVG } from 'react-svg';
import VesselPopup from 'components/GoogleMap/Popups/Fleet';
import Marker, { MarkerProps } from '../AbstractMarker';
import './styles.scss';

interface VesselProps extends MarkerProps {
  text: any;
  vessel: any;
  active: boolean;
}

const isMoving = (lastMeasurement: any) => {
  return lastMeasurement.sog !== 0;
};

const calculateRotation = ({ lastMeasurement }: any) => {
  return isMoving(lastMeasurement) ? lastMeasurement.cog : lastMeasurement.heading;
};

export default class LiveVesselMarker extends Marker<VesselProps> {
  static defaultProps = { text: '' };
  name = 'LiveVesselMarker';

  hasPopup = true;
  renderPopupContent = () => {
    return <VesselPopup vessel={this.props.vessel} />;
  }

  renderMarker() {
    const vessel = this.props.vessel || {};

    if(!vessel.lastMeasurement) {
      return <></>;
    }

    return <div className={`marker-live-vessel-container ${this.props.active ? 'active' : ''}`}>
      <div className="marker-live-vessel" style={{ transform: `rotate(${calculateRotation(vessel)}deg)`}}>
        <div className="marker-live-vessel-text" style={{ transform: `rotate(-${calculateRotation(vessel)}deg)`}}>{ this.props.text }</div>
        <ReactSVG className="marker-live-vessel-icon" src={`/assets/svg/navigation-outline.svg`} />
      </div>
    </div>;
  }
}