import Box from "components/Box";
import BoxTitle from "components/Box/Title";
import ContentPopup from "components/ContentPopup";
import Page from "components/ControlBars/Page";
import TrackersMap from "components/GoogleMap/TrackersMap";
import Button from "components/Inputs/Button";
import Dropdown from "components/Inputs/Dropdown";
import ContentLoader from "components/Loading/ContentLoader";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { getTrackers } from "services/trackers";
import Actions from "./Actions";
import Tracker from "./BoxTracker";
import Filter from "./Filter";
import "./styles.scss";

type Props = RouteComponentProps;

type State = {
  loading: boolean;
  selected: Array<any>;
  trackers: Array<any>;
  countries: Array<any>;
  open: Number | null;
  filter: any;
};

export default class Trackers extends Component<Props, State> {
  private selected: any = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      trackers: [],
      selected: [],
      open: null,
      countries: [],
      filter: {
        open: false,
        batteryMin: 0,
        batteryMax: 100,
        active: true,
        inactive: true,
        onboard: true,
        tags: [],
        countries: [],
      },
    };
  }

  // getData = () => {
  //   this.setState({ loading: true });

  //   return getTrackers(this.state.filter).then((trackers) => {
  //     const selected = trackers.filter(({ identifier }: any) =>
  //       this.state.selected.some(
  //         (tracker: any) => tracker.identifier === identifier
  //       )
  //     );

  //     this.setState({ trackers, selected, loading: false });
  //   });
  // };
  // getData = () => {
  //   this.setState({ loading: true });

  //   return getTrackers(this.state.filter).then((trackers) => {
  //     // Ensure trackers are filtered based on batteryMin and batteryMax
  //     const filteredTrackers = trackers.filter((tracker: any) => {
  //       const battery = tracker.lastMeasurement && tracker.lastMeasurement.battery || 0; // Assuming battery is part of tracker object
  //       return (
  //         battery >= this.state.filter.batteryMin &&
  //         battery <= this.state.filter.batteryMax
  //       );
  //     });

  //     const selected = filteredTrackers.filter(({ identifier }: any) =>
  //       this.state.selected.some(
  //         (tracker: any) => tracker.identifier === identifier
  //       )
  //     );
  //     console.log(filteredTrackers, "filtered trackers");
  //     this.setState({ trackers: filteredTrackers, selected, loading: false });
  //   });
  // };
  getData = () => {
    this.setState({ loading: true });

    // Clone the filter object to avoid direct mutation
    const filterToSend = { ...this.state.filter };

    // If 'Unknown' is in the countries array, remove it before sending to the API
    if (filterToSend.countries.includes("Unknown")) {
      filterToSend.countries = filterToSend.countries.filter(
        (country: string) => country !== "Unknown"
      );
    }

    return getTrackers(filterToSend).then((trackers) => {
      // Ensure trackers are filtered based on batteryMin and batteryMax
      const filteredTrackers = trackers.filter((tracker: any) => {
        const battery = tracker.lastMeasurement&& tracker.lastMeasurement.battery || 0; // Assuming battery is part of tracker object
        return (
          battery >= this.state.filter.batteryMin &&
          battery <= this.state.filter.batteryMax
        );
      });

      const selected = filteredTrackers.filter(({ identifier }: any) =>
        this.state.selected.some(
          (tracker: any) => tracker.identifier === identifier
        )
      );

      console.log(filteredTrackers, "filtered trackers");
      this.setState({ trackers: filteredTrackers, selected, loading: false });
    });
};

  componentDidMount() {
    this.getData().then(() => {
      const countries = this.state.trackers.reduce(
        (acc: any[], tracker: any) => {
          const country =
            tracker.lastMeasurement && tracker.lastMeasurement.location.country;

          if (country && !acc.includes(country)) acc.push(country);

          return acc;
        },
        []
      );

      this.setState({ countries });
    });
  }

  onFilterUpdate = (filter: any) => {
    
    this.setState({ filter }, this.getData);
  };

  boxClick = (open: any, callback?: any) => {
    this.setState({ open }, callback);
  };

  onSelect = (tracker: any) => {
    const index = this.state.selected.findIndex(
      ({ identifier }) => identifier === tracker.identifier
    );
    const selected = [...this.state.selected];

    if (index >= 0) {
      selected.splice(index, 1);
    } else {
      selected.push(tracker);
    }

    this.setState({ selected });
  };

  mapClickCallback = (identifier: any, status: string) => {
    const open = status === "close" ? null : identifier;

    this.boxClick(open, this.scrollToOption);
  };

  renderBoxContent = () => {
    const selectedTrackers = this.state.trackers.filter(
      (tracker) => tracker.transport || tracker.lastMeasurement
    );

    const selectedLength = this.state.selected.length;
    const trackersLength = selectedTrackers.length;
    const allSelected = selectedLength === trackersLength;
    const action = `${allSelected ? "Unselect" : "Select"} all`;
console.log(selectedLength);
    return (
      <div className="trackers-content">
        <div className="trackers-header">
          <BoxTitle
            text={`${trackersLength} ${
              trackersLength === 1 ? "Device" : "Devices"
            }`}
          />
        </div>

        <div className="trackers-actions">
          <Dropdown title="Search filters">
            <Filter
              onChange={this.onFilterUpdate}
              filter={this.state.filter}
              countries={this.state.countries}
            />
          </Dropdown>
          <div className="actions-select">
            <Button
              category="eShells"
              action="Manage eShells"
              styles="none"
              to="#actions"
              disabled={ selectedLength !== 1}
            >
              Manage Settings
            </Button>
            {/* <Button
              category="eShells"
              action={action}
              styles="none"
              onClick={() => {
                let selected: Array<any> = [];

                if (!allSelected) {
                  selected = [...this.state.trackers];
                }

                this.setState({ selected });
              }}
            >
              {action}
            </Button> */}
            {/* <Button
              category="eShells"
              action={action}
              styles="none"
              onClick={() => {
                let selected: Array<any> = [];

                if (!allSelected) {
                  selected = this.state.trackers.filter(
                    (tracker) => tracker.transport || tracker.lastMeasurement // Select if either transport or lastMeasurement is present
                  );
                }

                this.setState({ selected });
              }}
            >
              {action}
            </Button> */}
          </div>
        </div>

        <ContentLoader
          loading={this.state.loading}
          content={() => (
            <div className="trackers-wrapper">
              {trackersLength === 0 && (
                <p className="trackers-warning">No matches</p>
              )}

              {this.state.trackers.map((tracker: any, index: number) => (
                <Tracker
                  tracker={tracker}
                  onSelect={this.onSelect}
                  selected={this.state.selected.some(
                    ({ identifier }) => tracker.identifier === identifier
                  )}
                  opened={this.state.open === tracker.identifier}
                  openedRef={this.selected}
                  click={(open: any) => this.boxClick(open)}
                  key={`Trackers_${index}`}
                />
              ))}
            </div>
          )}
        />
      </div>
    );
  };

  scrollToOption = () =>
    this.selected.current &&
    this.selected.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

  render() {
    return (
      <Page className="shlk-trackers" type="map">
        <Box
          menu={[
            {
              type: "trackers",
              render: this.renderBoxContent,
              icon: "eShells",
            },
          ]}
          onBoxOpen={this.scrollToOption}
        />
        <TrackersMap
          trackers={this.state.trackers}
          click={this.mapClickCallback}
          open={this.state.open || undefined}
        />

        {this.state.selected.length > 0 && (
          <ContentPopup
            hash="actions"
            content={(close: any) => (
              <Actions
                close={close}
                trackers={this.state.selected}
                update={(updated: Array<any>) => {
                  const trackers = this.state.trackers.map(
                    (trk: any) =>
                      updated.find((upd: any) => upd._id === trk._id) || trk
                  );
                  this.setState({ trackers });
                }}
              />
            )}
          />
        )}
      </Page>
    );
  }
}
