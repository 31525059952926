import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";

import Icon from "components/Icon";
import Tags from "components/Tags/List";
import { getSharingCompany } from "utils/shipment";
import { calculatePercentageDone } from "utils/location";
import "./styles.scss";
import ship_icon from "../../../assets/icon/ship_icon.png";
import { eventNames } from "process";
import BoxButton from "components/Box/Button";
import {
  completeShipment,
  deleteShipment,
  getEmissionhubs,
  getEmissionsStats,
  getShipmentInfo,
} from "services/shipments";
import shipmentdata from "./mock_shipment.json";
import Button from "components/Inputs/Button";
interface State {
  hoveredLineIndex: number | null;
  loading: boolean;
  expanded: boolean;
  isMobile: boolean;
  emissionStats: any;
  ShipmentsData: any;
  hubsEmission: any;
}

interface Props {
  shipment: any;
  lines: number[];
  showBoxButton: boolean;
  actions: boolean;
  onHover?: (origin: any, destination: any) => void;
  onClick?: (origin: any, destination: any) => void;
  allShipmentPage?: boolean;
  id?: any;
}

interface AlarmEvents {
  [key: string]: {
    ack: boolean;
    events: any[];
  };
}

export default class ShipmentCardV3 extends Component<Props, State> {
  private alarmEvents: AlarmEvents = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      hoveredLineIndex: null,
      loading: true,
      expanded: false,
      isMobile: false,
      emissionStats: {},
      ShipmentsData: {},
      hubsEmission: {},
    };
    // Bind event handlers
    this.handleLineHover = this.handleLineHover.bind(this);
    this.handleLineLeave = this.handleLineLeave.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  handleLineHover(index: number) {
    this.setState({ hoveredLineIndex: index });
  }

  handleLineLeave() {
    this.setState({ hoveredLineIndex: null });
  }
  handleLineClick(index: number): void {
    this.setState({ hoveredLineIndex: null });
  }
  toggleExpanded() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  async componentDidMount() {
    // Check if window width is less than a certain threshold to determine mobile view
    const id = this.props.shipment._id;
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();
    // try {
    //   const response = await getEmissionsStats({
    //     id,
    //   });
    //   // Assuming `nearestNames` comes from the response.
    //   const emissionStatsData = response; // Adjust this line based on actual response structure
    //   this.setState({ emissionStats: emissionStatsData });
    // } catch (error) {
    //   console.error("Error fetching port list:", error);
    // }

    try {
      const response = await getShipmentInfo(id);
      // Assuming `nearestNames` comes from the response.
      const shipmentsData = await response; // Adjust this line based on actual response structure
      this.setState({ ShipmentsData: shipmentsData });
    } catch (error) {
      console.error("Error fetching port list:", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 768; // Adjust threshold as needed
    this.setState({ isMobile });
  };

  showAlarmIcons() {
    const alarms: any = [];
    const { ShipmentsData } = this.state;

    if (
      ShipmentsData &&
      ShipmentsData.transports &&
      ShipmentsData.transports[0] &&
      ShipmentsData.transports[0].events
    ) {
      // Check if there's an unacknowledged delay event
      const delayEvent = ShipmentsData.transports[0].events.find(
        (event: any) => event.ack === false && event.type === "DELAY"
      );
      const idleEvent = ShipmentsData.transports[0].events.find(
        (event: any) => event.ack === false && event.type === "IDLE"
      );
      const temperatureEvent = ShipmentsData.transports[0].events.find(
        (event: any) => event.ack === false && event.type === "TEMPERATURE"
      );
      const batteryEvent = ShipmentsData.transports[0].events.find(
        (event: any) => event.ack === false && event.type === "BATTERY"
      );
      const lightEvent = ShipmentsData.transports[0].events.find(
        (event: any) => event.ack === false && event.type === "LIGHT"
      );

      // Render delay icon if there's an unacknowledged delay event
      if (delayEvent) {
        alarms.push(
          <div style={{}}>
            <div style={{ width: "20px", height: "20px" }}>
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/delay.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                }}
              />
            </div>
          </div>
        );
      }
      if (temperatureEvent) {
        alarms.push(
          <div
            style={{
              background: "rgb(193, 28, 28)",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden", // Ensure the icon doesn't overflow the container
              marginTop: "5px",
            }}
          >
            <div style={{ width: "15px", height: "15px" }}>
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/temperature.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                }}
              />
            </div>
          </div>
        );
      }
      if (batteryEvent) {
        alarms.push(
          <div
            style={{
              background: "rgb(193, 28, 28)",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden", // Ensure the icon doesn't overflow the container
              marginTop: "5px",
            }}
          >
            <div style={{ width: "15px", height: "15px" }}>
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/battery.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                }}
              />
            </div>
          </div>
        );
      }
      if (lightEvent) {
        alarms.push(
          <div style={{}}>
            <div style={{ width: "20px", height: "20px" }}>
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/LIGHT.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                }}
              />
            </div>
          </div>
        );
      }
      if (!delayEvent && !temperatureEvent && !batteryEvent && !lightEvent) {
        alarms.push(
          <div style={{}}>
            <div
              style={{
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/GREEN.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                  marginRight: "10px",
                  marginBottom: "2px",
                }}
              />
              <div style={{ fontSize: "15px", color: "green" }}>
                No Alerts Detected
              </div>
            </div>
          </div>
        );
      }
    }

    return alarms;
  }

  updateShipment = (serviceFunction: any, status: string) => {
    this.setState({ loading: true });

    serviceFunction(this.props.shipment._id).then(() => {
      const shipment = this.props.shipment;
      shipment.status = status;
      this.context.updateShipment(shipment);
      this.setState({ loading: false });
    });
  };
  complete = () => {
    if (
      window.confirm(
        "Are you sure you want to complete the shipment? \nThis action is not reversible"
      )
    ) {
      this.updateShipment(completeShipment, "completed");
    }
  };

  delete = () => {
    if (
      window.confirm(
        "Are you sure you want to cancel the shipment? \nThis action is not reversible"
      )
    ) {
      this.updateShipment(deleteShipment, "canceled");
    }
  };

  trimText = (text: string, maxLength: number) => {
    const trimmedText =
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    console.log(trimmedText, "trimmedText");
    return trimmedText;
  };

  render() {
    const { isMobile } = this.state;
    const { allShipmentPage } = this.props;
    const { emissionStats } = this.state;
    const { ShipmentsData, loading } = this.state;
    // const { hubsEmission } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    let modes: any = [];
    if (ShipmentsData && ShipmentsData.modes) {
      modes = ShipmentsData.modes;
    }

    let hubs: any = [];
    if (ShipmentsData && ShipmentsData.transports[0]) {
      hubs =
        ShipmentsData.transports[0].hubs && ShipmentsData.transports[0].hubs;
    }

    const { _id, name, origin, destination, containerNumber, status } =
      this.props.shipment;

    const isOngoingShipment =
      ShipmentsData.status && ShipmentsData.status === "ongoing";
    const sharingCompany = getSharingCompany(this.props.shipment);
    const tags = [...this.props.shipment.tags];

    sharingCompany &&
      tags.unshift({ name: sharingCompany, styles: { color: "#7e3da0" } });

    const { hoveredLineIndex } = this.state;
    const { expanded } = this.state;
    const shipmentDate = new Date(ShipmentsData.createdAt);
    const createdDate = `${shipmentDate.getDate()}.${
      shipmentDate.getMonth() + 1
    }.${shipmentDate.getFullYear()}`;

    const transports = ShipmentsData.transports;

    let estimatedDate: string | null = null;

    if (transports && transports[0] && transports[0].hubs) {
      const hubs = transports[0].hubs;
      const lastArrayIndex = hubs.length - 1; // Get the index of the last element
      const etaTimestamp = hubs[lastArrayIndex]
        ? hubs[lastArrayIndex].arrivalEst
        : "NA"; // Get the ETA timestamp from the last array element

      if (etaTimestamp) {
        const etaDate = new Date(etaTimestamp); // Convert the timestamp to a Date object
        estimatedDate = `${etaDate.getDate()}.${
          etaDate.getMonth() + 1
        }.${etaDate.getFullYear()}`; // Format the date as dd.mm.yyyy
      }
    }
    console.log(
      ShipmentsData,
      hubs,
      emissionStats,
      "I am shipment data from postman"
    );

    const firstWaypoints =
      (ShipmentsData &&
        ShipmentsData.transports &&
        ShipmentsData.transports[0] &&
        ShipmentsData.transports[0].measurements) ||
      [];

    // Sort the measurements based on the time property
    firstWaypoints.sort(
      (a: any, b: any) =>
        new Date(a.time).getTime() - new Date(b.time).getTime()
    );
    const currentLocation =
      firstWaypoints.length > 0
        ? firstWaypoints[firstWaypoints.length - 1].location
        : null;

    console.log(currentLocation, "card");

    return (
      <>
        {loading ? (
          <>loading</>
        ) : (
          <>
            <Link
              key={_id}
              to={`/shipments/${_id}`}
              style={{ cursor: "pointer" }}
            >
              <div
                className="shipment-card__container"
                style={{
                  width: isMobile ? "" : "380px",
                  margin: isMobile ? "0px" : "",
                  marginBottom: isMobile ? "20px" : "",
                }}
                onMouseEnter={() =>
                  this.props.onHover && this.props.onHover(origin, destination)
                }
              >
                <div className="shlk-card shipment-card__box">
                  <div
                    className="shipment-card__top"
                    style={{ padding: "15px" }}
                  >
                    <div className="shipment-card__info">
                      <div
                        className="shipment-card__bar full_width"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="shipment-card__field left name"
                          style={{ width: "50%" }}
                        >
                          {isMobile ? (
                            <>
                              {" "}
                              {ShipmentsData.name
                                ? ShipmentsData.name.length > 15
                                  ? `${ShipmentsData.name.slice(0, 15)}...`
                                  : ShipmentsData.name.toUpperCase()
                                : ""}
                            </>
                          ) : (
                            <>
                              {" "}
                              {ShipmentsData.name
                                ? ShipmentsData.name.length > 30
                                  ? `${ShipmentsData.name.slice(0, 30)}...`
                                  : ShipmentsData.name.toUpperCase()
                                : ""}
                            </>
                          )}
                        </div>
                        <div
                          className="shipment-card__field right name"
                          style={{ width: "50%", display: "contents" }}
                        >
                          <span
                            className={` ${
                              this.props.showBoxButton &&
                              this.props.actions &&
                              "container-number"
                            }`}
                          >
                            {ShipmentsData.containerNumber
                              ? ShipmentsData.containerNumber.length < 10
                                ? ShipmentsData.containerNumber
                                : `${ShipmentsData.containerNumber.slice(
                                    0,
                                    9
                                  )}...`
                              : "NA"}
                          </span>
                        </div>
                      </div>
                      <div
                        className="shipment-card__bar full_width"
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          marginBottom: "15px",
                        }}
                      >
                        <div
                          className="shipment-card__field left name"
                          style={{ width: "50%" }}
                        >
                          {ShipmentsData.origin.label
                            ? ShipmentsData.origin.label.length > 20
                              ? `${ShipmentsData.origin.label.slice(0, 20)}...`
                              : ShipmentsData.origin.label
                            : ShipmentsData.origin.address.length > 20
                            ? `${ShipmentsData.origin.address.slice(0, 20)}...`
                            : ShipmentsData.origin.address}
                        </div>
                        <div
                          className="shipment-card__field right name"
                          style={{ width: "50%", display: "contents" }}
                        >
                          <span>
                            {ShipmentsData.destination.label
                              ? ShipmentsData.destination.label.length > 15
                                ? `${ShipmentsData.destination.label.slice(
                                    0,
                                    15
                                  )}...`
                                : ShipmentsData.destination.label
                              : ShipmentsData.destination.address.length > 15
                              ? `${ShipmentsData.destination.address.slice(
                                  0,
                                  15
                                )}...`
                              : ShipmentsData.destination.address}
                          </span>
                        </div>
                      </div>

                      <div className="shipment-card__bar full_width">
                        <div
                          className="shipment-bar"
                          style={{
                            marginTop: "10px",
                            marginBottom: "15px",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          {hubs.map((hub: any, index: any) => (
                            <React.Fragment key={hub._id}>
                              {/* Render point */}
                              <div
                                className={`shipment-bar__point expanded ${
                                  hub.arrivalAct && "done"
                                }`}
                                style={{
                                  left: `${(index / (hubs.length - 1)) * 100}%`,
                                  zIndex: "2",
                                }}
                              ></div>

                              {/* Render line if there is a next mode */}
                              {index < modes.length && (
                                <div
                                  className={`shipment-bar__line expanded ${
                                    modes[index].status === "COMPLETED"
                                      ? "done"
                                      : ""
                                  } ${
                                    modes[index].status === "ONGOING"
                                      ? "ongoing"
                                      : ""
                                  }`}
                                  style={{
                                    position: "absolute",
                                    left: `${
                                      (index / (hubs.length - 1)) * 100
                                    }%`,
                                    top: "1px",
                                    width: `${(1 / (hubs.length - 1)) * 100}%`,
                                  }}
                                >
                                  {/* Render progress for the ongoing segment */}
                                  {modes[index].status === "ONGOING" && (
                                    <div
                                      className="shipment-bar__progress"
                                      style={{
                                        position: "absolute",
                                        width: `${
                                          hubs[index] && hubs[index + 1]
                                            ? calculatePercentageDone(
                                                hubs[index].address.location
                                                  .lat, // originLat
                                                hubs[index].address.location
                                                  .lng, // originLng
                                                hubs[index + 1].address.location
                                                  .lat, // destLat
                                                hubs[index + 1].address.location
                                                  .lng, // destLng (should be lng)
                                                currentLocation.lat, // actualLat
                                                currentLocation.lng // actualLng
                                              )
                                            : "0"
                                        }%`,
                                        zIndex: "1",
                                        height: "3px",
                                        // top: "1px",
                                        backgroundColor: "#19575C",
                                      }}
                                    >
                                      {modes[index].modeType === "Land" &&
                                        isOngoingShipment && (
                                          <div className="shipment-bar__icon expanded">
                                            <ReactSVG
                                              src={`/assets/svg/truck.svg`}
                                            />
                                          </div>
                                        )}
                                      {modes[index].modeType === "Air" &&
                                        isOngoingShipment && (
                                          <div className="shipment-bar__icon expanded">
                                            <ReactSVG
                                              src={`/assets/svg/flight.svg`}
                                            />
                                          </div>
                                        )}
                                      {modes[index].modeType === "Sea" &&
                                        isOngoingShipment && (
                                          <div className="shipment-bar__icon expanded">
                                            <ReactSVG
                                              src={`/assets/svg/ship.svg`}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  )}
                                  {modes[index].status === "COMPLETED" &&
                                    index === modes.length - 1 && (
                                      <div
                                        className="shipment-bar__progress"
                                        style={{
                                          position: "absolute",
                                          width: `${
                                            hubs[index] && hubs[index + 1]
                                              ? calculatePercentageDone(
                                                  hubs[index].address.location
                                                    .lat, // originLat
                                                  hubs[index].address.location
                                                    .lng, // originLng
                                                  hubs[index + 1].address
                                                    .location.lat, // destLat
                                                  hubs[index + 1].address
                                                    .location.lng, // destLng (should be lng)
                                                  currentLocation.lat, // actualLat
                                                  currentLocation.lng // actualLng
                                                )
                                              : "0"
                                          }%`,
                                          zIndex: "5",
                                          height: "3px",
                                          // top: "1px",
                                          backgroundColor: "#19575C",
                                        }}
                                      >
                                        {modes[index].modeType === "Land" && (
                                          <div
                                            className="shipment-bar__icon expanded"
                                            style={{ left: "98%" }}
                                          >
                                            <ReactSVG
                                              src={`/assets/svg/truck.svg`}
                                            />
                                          </div>
                                        )}
                                        {modes[index].modeType === "Air" && (
                                          <div
                                            className="shipment-bar__icon expanded"
                                            style={{ left: "98%" }}
                                          >
                                            <ReactSVG
                                              src={`/assets/svg/flight.svg`}
                                            />
                                          </div>
                                        )}
                                        {modes[index].modeType === "Sea" && (
                                          <div
                                            className="shipment-bar__icon expanded"
                                            style={{ left: "98%" }}
                                          >
                                            <ReactSVG
                                              src={`/assets/svg/ship.svg`}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  {!isOngoingShipment &&
                                    index === modes.length - 1 && (
                                      <div
                                        style={{
                                          zIndex: "3",
                                          position: "relative",
                                        }}
                                      >
                                        {modes[index].modeType === "Land" &&
                                          index === modes.length - 1 && (
                                            <div
                                              className="shipment-bar__icon expanded"
                                              style={{
                                                position: "absolute",
                                                left: "105%", // Move to the end of the bar
                                                transform: "translateX(-100%)", // Prevent overflow
                                                backgroundColor: "#8b8b8b",
                                              }}
                                            >
                                              <ReactSVG
                                                src={`/assets/svg/truck.svg`}
                                              />
                                            </div>
                                          )}
                                        {modes[index].modeType === "Air" &&
                                          index === modes.length - 1 && (
                                            <div
                                              className="shipment-bar__icon expanded"
                                              style={{
                                                position: "absolute",
                                                left: "105%", // Move to the end of the bar
                                                transform: "translateX(-100%)", // Prevent overflow
                                                backgroundColor: "#8b8b8b",
                                              }}
                                            >
                                              <ReactSVG
                                                src={`/assets/svg/flight.svg`}
                                              />
                                            </div>
                                          )}
                                        {modes[index].modeType === "Sea" &&
                                          index === modes.length - 1 && (
                                            <div
                                              className="shipment-bar__icon expanded"
                                              style={{
                                                position: "absolute",
                                                left: "105%", // Move to the end of the bar
                                                transform: "translateX(-100%)", // Prevent overflow
                                                backgroundColor: "#8b8b8b",
                                              }}
                                            >
                                              <ReactSVG
                                                src={`/assets/svg/ship.svg`}
                                              />
                                            </div>
                                          )}
                                      </div>
                                    )}
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                          <div
                            className="shipment-bar__point expanded"
                            style={{ left: "100%", zIndex: "2" }}
                          ></div>
                        </div>
                      </div>

                      <div
                        className="shipment-card__bar full_width"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="shipment-card__field left name"
                          style={{ width: "50%" }}
                        >
                          {createdDate}
                        </div>
                        <div
                          className="shipment-card__field right name"
                          style={{ width: "50%", display: "contents" }}
                        >
                          <span>ETA {estimatedDate}</span>
                        </div>
                      </div>

                      <div
                        className="shipment-card__bar full_width"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="shipment-card__field left name"
                          style={{ width: "50%" }}
                        >
                          {this.showAlarmIcons()}
                        </div>
                        <div
                          className="shipment-card__field right name"
                          style={{ width: "50%", display: "contents" }}
                        >
                          <div
                            className="shipment-card__carbon"
                            style={{ width: "100px" }}
                          >
                            <span>CO2</span>
                            <span>
                              {ShipmentsData &&
                                (ShipmentsData.emissionsTillNow > 1000
                                  ? `${(
                                      ShipmentsData.emissionsTillNow / 1000
                                    ).toFixed(2)} t`
                                  : `${Math.round(
                                      ShipmentsData.emissionsTillNow
                                    )} kg`)}
                            </span>
                          </div>
                        </div>
                      </div>

                      {this.props.showBoxButton &&
                      isOngoingShipment &&
                      this.props.actions ? (
                        <div
                          className="shipment-card__bar full_width info-btn-wrapper bc-wrapper"
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <BoxButton
                            category="ShipmentInfo"
                            action="Click Cancel"
                            label={_id}
                            onClick={this.delete}
                            icon="close"
                            text="Cancel"
                          />
                          <BoxButton
                            category="ShipmentInfo"
                            action="Click Finish"
                            label={_id}
                            onClick={this.complete}
                            icon="flag"
                            text="Finish"
                          />
                          <BoxButton
                            category="ShipmentInfo"
                            action="Click Edit"
                            label={_id}
                            to={`/shipments/${this.props.shipment._id}#edit`}
                            icon="edit"
                            text="Edit"
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      {tags.length ? (
                        <div
                          className=" shipment-card__bar_details_bar full_width"
                          style={{ marginTop: "0px", marginBottom: isMobile?"15px":"0px" }}
                        >
                          <Tags noLink={true} tags={tags} />
                        </div>
                      ) : null}
                      {isMobile && allShipmentPage && (
                        <div
                          className="shipment-card__field left address"
                          style={{ margin: "0px" }}
                        >
                          <div
                            style={{
                              marginBottom: "0px",
                              marginLeft: "0px",
                              fontSize: "15px",
                            }}
                            className=""
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.onClick &&
                                this.props.onClick(origin, destination);
                            }}
                          >
                            Show on Map
                          </div>
                        </div>
                      )}
                      {!isOngoingShipment && (
                        <div
                          className="shipment-card__field left address"
                          style={{
                            flexDirection: "row",
                            color: "green",
                            textAlign: isMobile ? "end" : "start",
                            marginBottom: "0px",
                            marginLeft: "0px",
                            fontSize: "15px",
                          }}
                        >
                          Completed
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </>
        )}
      </>
    );
  }
}
