import React from 'react';
import AbstractMarker, { MarkerProps } from '../AbstractMarker'; // Adjust the import path as needed
import './styles.scss';

interface WaypointMarkerProps extends MarkerProps {
  index: number;
}

export default class WaypointMarker extends AbstractMarker<WaypointMarkerProps> {
  readonly name = 'WaypointMarker';

  renderMarker() {
    const { index } = this.props;
    // Implement your waypoint marker rendering logic here
    return (
      <div className="waypoint-marker">
        {index}
      </div>
    );
  }

}
