// Import necessary modules and functions
import { Location as RouterLocation, History } from 'history';
import { getSessionData, setSessionData } from 'utils/data/sessionStorage';

// Define a constant for the session key
export const PATH_STACK_SESSION_KEY = 'shlk-path-stack';

// Define a constant object for custom redirects
const CUSTOM_REDIRECTS: { [key: string]: string } = {
    '/shipments': '/'
};

// Function to get the full path name from a location object
export const getFullPathname = (location: Location | RouterLocation) => `${location.pathname}${location.search}${location.hash}`;

// Function to get the path name from a location object
export const getPathname = (location: Location | RouterLocation) => `${location.pathname}${location.search}`;

// Function to initialize the navigation stack in the session storage
export const initializeNavigationStack = () => {
    setSessionData(PATH_STACK_SESSION_KEY, '');
};

// Function to set the navigation stack in the session storage
export const setNavigationStack = (prevLocation?: RouterLocation, currentLocation?: RouterLocation) => {
    const currentState = currentLocation && currentLocation.state as { [backButton: string] :boolean };

    // If there's no previous or current location, or if the back button was pressed, return
    if(!prevLocation || !currentLocation || (currentState && currentState.backButton)) return;

    const currentPathname = getPathname(currentLocation);
    const prevPathname = getPathname(prevLocation);

    // If the current or previous path is the root, initialize the navigation stack
    // if([currentPathname, prevPathname].includes('/')) {
    //     initializeNavigationStack();
    // } else {
    //     // Otherwise, update the navigation stack in the session storage
    //     const stack = getSessionData(PATH_STACK_SESSION_KEY)!;

    //     if(currentPathname !== prevPathname) {
    //         const newStack = stack ? `${stack},${prevPathname}` : prevPathname;

    //         !stack!.endsWith(prevPathname) && setSessionData(PATH_STACK_SESSION_KEY, newStack);
    //     }
    // }
    if ([currentPathname, prevPathname].includes('/')) {
        initializeNavigationStack();
    } else {
        // Otherwise, update the navigation stack in the session storage
        const stack = getSessionData(PATH_STACK_SESSION_KEY);  // Don't use ! here, handle null/undefined
    
        if (currentPathname !== prevPathname) {
            const newStack = stack ? `${stack},${prevPathname}` : prevPathname;
    
            // Ensure stack exists and is a string before calling .endsWith()
            if (stack && typeof stack === 'string' && !stack.endsWith(prevPathname)) {
                setSessionData(PATH_STACK_SESSION_KEY, newStack);
            }
        }
    }
};

// Function to go back in the navigation stack
export const goBack = (history: History) => {
    const stack = getSessionData(PATH_STACK_SESSION_KEY)!.split(',');
    let backUrl = stack.pop();

    // If there's a URL to go back to, update the session storage
    if(backUrl) {
        setSessionData(PATH_STACK_SESSION_KEY, stack.join(','));
    } else {
        // Otherwise, calculate the back URL and check for custom redirects
        const path = history.location.pathname;

        backUrl = path.substring(0, path.lastIndexOf('/')) || '/';

        if(CUSTOM_REDIRECTS[backUrl]) {
            backUrl = CUSTOM_REDIRECTS[backUrl];
        }
    }

    // Push the back URL to the history
    history.push({ pathname: backUrl, state: { backButton: true }});
};

/**
 * Retrieves the value of a specified parameter from a search string.
 * 
 * @param search - The search string containing the parameters.
 * @param key - The key of the parameter to retrieve.
 * @returns The value of the specified parameter, or null if the parameter is not found.
 */
export const getParam = (search: string, key: string) => {
    const searchParams: any = new URLSearchParams(search);
    return searchParams.get(key);
};

// Function to generate an absolute path from a relative path
export const generateAbsoultePath = (path: string) => {
    const protocol = window.location.protocol,
          hostname = window.location.hostname,
          port = window.location.port ? ":" + window.location.port : '',
          site = `${protocol}//${hostname}${port}`;

    return `${site}${path}`;
};