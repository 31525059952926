//PAGES
import Home from './Home';

import Login from 'pages/Login';
import Public from './Public';
import EShell from './EShell';

import Shipments from './Shipments';
import Fleet from './Fleet';
import New from './New';
import Tracker from './Tracker';
import Trackers from './Trackers';
import User from './User';

import Shipment from './Shipment';
import Report from './Shipment/Report';

import Tags from './Tags';
import Tag from './Tags/Tag';

import Admin from './Admin';
import AdminCompany from './Admin/Company';
import AdminUsers from './Admin/Users';
import AdminData from './Admin/Data';
import AdminTrackers from './Admin/Trackers';
import AdminTrackersAll from './Admin/Trackers/All';
import AdminTrackersFree from './Admin/Trackers/Free';
import AdminDataShipments from './Admin/Shipments';

import NotFound from 'pages/NotFound';
import NotAllowed from 'pages/NotAllowed';
import ForgotPassword from './Login/requestReset';
import EnterResetCode from './Login/enterCode';
import ResetPassword from './Login/resetPassword';

export const PAGES = {
    Public,
    Login,
    NotFound,
    Home,
    EShell,
    Shipments,
    Fleet,
    New,
    Shipment,
    Report,
    Tags,
    Tag,
    Tracker,
    Trackers,
    User,
    NotAllowed,
ForgotPassword,
EnterResetCode,
ResetPassword,

    Admin,
    AdminCompany,
    AdminData,
    AdminUsers,
    AdminTrackers,
    AdminTrackersAll,
    AdminTrackersFree,
    AdminDataShipments
};