import React, { Component } from 'react';
import Icon from 'components/Icon';
import { ReportConfig } from 'pages/Shipment/Report';
import { format as formatDate } from 'utils/date';
import { getEventIcon } from 'utils/events';
import './styles.scss';

interface Props {
  shipment: any;
  config: ReportConfig;
}

const getName = (meas: any, group: string) => {
  return group === "country" ?
            meas.location.country :
            `${meas.location.city}, ${meas.location.country}`;
};

export default class ReportEvents extends Component<Props> {
  renderEvent = (event: any, index: number) => {
    return <tr className='report-event' key={`ReportEvent${index}`}>
      <td className='report-event-icon'>
        <Icon className={`report-event__${event.class.toLowerCase()}`} size={20} name={getEventIcon(event.type)} />
      </td>
      <td>
        <p className="report-event-field report-event-date">{formatDate(event.measurement.time)}</p>
      </td>
      <td>
        <p className="report-event-field report-event-location">{getName(event.measurement, this.props.config.group)}</p>
      </td>
      <td>
        <p className="report-event-field" style={{textAlign:"left"}}>{event.message}</p>
      </td>
    </tr>;
  }

  render() {
    const transport = this.props.shipment.transports.find(({ tracker }: any) => `${tracker.identifier}` === `${this.props.config.identifier}`);

    if(transport.events.length === 0) return <></>;

    return (
      <table className='report-events'>
        <thead>
          <tr>
            <td><h4 className='report-events-title'>Events</h4></td>
          </tr>
        </thead>
        <tbody>
          {transport.events.map(this.renderEvent)}
        </tbody>
      </table>
    );
  }
}