import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import HasPermission from 'components/Permissions/Has';
import Title from 'components/Title';
import { CompanyContext } from '../context';
import Member from './Member';
import './styles.scss';

interface State {
  loading: boolean;
}

export default class Add extends Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    return (<div className='company-view'>
      <Title text={this.context.company.name} icon="people">
        <HasPermission permission='INVITE_MEMBERS'>
          <div className="company-add-button" onClick={() => this.context.changeLayout("ADD")}>
            <span className="company-add-plus">+</span>
            <ReactSVG className="company-add-icon" src="/assets/svg/person.svg" />
          </div>
        </HasPermission>
      </Title>

      <div className="company-members">
        { this.context.company.members.map((member: any, index: number) =>
          <Member key={`Member${index}`} member={member}/>
        )}
      </div>
    </div>
    );
  }
}

Add.contextType = CompanyContext;