import React, { Component } from 'react';

import VesselMap from 'components/GoogleMap/VesselMap';

import { getFleet } from 'services/fleet/';

import Map from '../Map';

interface State {
  vessels: Array<any>;
}

export default class FleetWidget extends Component<{}, State> {
  constructor(props: {}) {
      super(props);
      this.state = { vessels: [] };
  }

  componentDidMount() {
    getFleet().then((_ref) => {
      const { vessels = [] } = _ref || {}; // Provide a default empty object
      this.setState({ vessels });
    });
  }

  render() {
      return (
        <Map title="Fleet" link="/fleet">
          <VesselMap className="shlk-location-map" vessels={this.state.vessels} />
        </Map>
      );
  }
}