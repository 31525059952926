import React, { Component } from 'react';
import AbstractMap from 'components/GoogleMap/Abstract';
import { getRoutes, renderRoute } from 'utils/maps/routes';
import { decorateMaps, onRefresh } from 'utils/maps/google';
import { getMarkers } from 'utils/maps/waypoints';
import Spinner from 'components/Loading/Spinner';
import DotMarker from 'components/GoogleMap/Markers/Dot';
import TextMarker from 'components/GoogleMap/Markers/Text';
import TowerMarker from 'components/GoogleMap/Markers/Tower';
import EventMarker from 'components/GoogleMap/Markers/Event';
import LiveVesselMarker from 'components/GoogleMap/Markers/LiveVessel';
import LiveTrackerMarker from 'components/GoogleMap/Markers/LiveTracker';
import ClusterMarker from 'components/GoogleMap/Markers/Cluster';
import WifiMarker from 'components/GoogleMap/Markers/Wifi';
import VesselMarker from 'components/GoogleMap/Markers/Vessel';
import TrackerMarker from 'components/GoogleMap/Markers/Tracker';

interface Route {
  shipment?: any;
  vessel?: any;
  tracker: any,
  measurements: any[],
  events: any[]
}

interface ProcessedRoute extends Route {
  waypoints: any[];
}

interface MapViewProps {
  updating?: boolean;
  routes: Route[];
  settings: {
    trackers: Array<string>;
    colors: boolean;
    events?: boolean;
    routes: boolean;
    sources: string[];
    popup?: {
      click: any;
      open?: {
        type: string;
        id: string;
        center?: any;
      };
    };
  };
}

interface State {
  map: any;
  maps: any;
  routes: ProcessedRoute[];
}

const getSelectedRoutes = (routes: Array<any>, selected: string[]) => {
  return routes.filter(({ tracker }: any) => selected.includes(tracker.identifier));
};

export default class RoutesMap3 extends Component<MapViewProps, State> {
  private popupSettings: any;
  private openPopupSettings: any;

  constructor(props: MapViewProps) {
    super(props);

    this.state = {
      map: null,
      maps: null,
      routes: []
    };
  }

  componentDidUpdate(prevProps: Readonly<MapViewProps>) {
    const selectedRoutes = getSelectedRoutes(this.props.routes, this.props.settings.trackers);

    const shouldUpdate =
      (selectedRoutes.length !== this.state.routes.length) ||
      (prevProps.settings.sources.length !== this.props.settings.sources.length) ||
      selectedRoutes.some(({ tracker, measurements, events }: any) => {
        if(!this.props.settings.trackers.includes(tracker.identifier)) return false;

        const selected = this.state.routes.find((route: any) => tracker.identifier === route.tracker.identifier);

        return !selected || selected.measurements.length !== measurements.length || selected.events.length !== events.length;
      });

    if(shouldUpdate) {
      this.setMapZoom(selectedRoutes);

      this.setState({
        routes: this.state.map ? getRoutes(this.state.map, this.props.settings, this.props.routes) : []
      });
    }

    if(this.popupSettings && this.openPopupSettings.center && this.state.map) {
      this.popupSettings.click("open", this.openPopupSettings.id, this.openPopupSettings.type);
      this.state.map.setCenter(this.openPopupSettings.center);
      this.setState({ routes: getRoutes(this.state.map, this.props.settings, this.props.routes) });
    }
  }

  setMapZoom = (routes: any[]) =>   {
    if(routes.length) {
      const bounds = new this.state.maps.LatLngBounds();

      routes.forEach((route: any) => route.measurements.forEach(({ location }: any) => bounds.extend(location)));

      this.state.map.fitBounds(bounds);
      this.state.map.setZoom(Math.min(this.state.map.getZoom(), 12));
    } else {
      this.state.map.setZoom(0);
    }
  }

  renderEvent = (event: any, index: number) => {
    if(event.measurement) {
      let popup = {};

      if(this.popupSettings) {
        popup = { open: this.openPopupSettings.id === event._id, click: (state: string) => this.popupSettings.click(state, event, 'EVENT') };
      }

      const eventProps: any = {
        key: `EventMarker_${index}`,
        event, ...popup,
        lat: event.measurement.location.lat,
        lng: event.measurement.location.lng
      };

      return <EventMarker {...eventProps}/>;
    }

    return <></>;
  }

  renderWaypoint = (meas: any, index: number) => {
    let popup = {};

    if(this.popupSettings) {
      let open = this.openPopupSettings.id === meas._id;

      if(meas.source === "CLUSTER") {
        open = meas.cluster.measurements.some(({ _id }: { _id: string }) => this.openPopupSettings.id === _id);
      }

      popup = { open, click: (state: string) => this.popupSettings.click(state, meas, 'WAYPOINT') };
    }

    const dotProps: any = {
      index,
      key: meas._id,
      measurement: meas, ...popup,
      lat: meas.location.lat,
      lng: meas.location.lng,
      source: this.props.settings.colors ? meas.source.toLowerCase() : 'waypoint'
    };

    if(meas.source === "CLUSTER") {
      return (<ClusterMarker {...dotProps}/>);
    } else if(meas.source === "TOWER") {
      return (<TowerMarker {...dotProps} map={this.state.map}/>);
    } else if(meas.source === "WIFI") {
      return (<WifiMarker {...dotProps} map={this.state.map}/>);
    } else if(meas.source === "VESSEL") {
      return (<VesselMarker {...dotProps} vessel={meas.vessel}/>);
    } else if(meas.source === "TRACKER") {
      return (<TrackerMarker {...dotProps}/>);
    } else {
      return (<DotMarker {...dotProps}/>);
    }
  }

  getShipmentMarkers = ({ shipment }: any) => {
    return [
      this.renderEndpoint("Origin", shipment.origin),
      this.renderEndpoint("Destination", shipment.destination)
    ];
  }

  getVesselMarkers = (route: any) => {
    let popup = {};
    const vessel = route.vessel;

    if(this.popupSettings) {
      popup = { open: this.openPopupSettings.id === vessel._id, click: (state: string) => this.popupSettings!.click(state, vessel, 'VESSEL') };
    }

    if(vessel.lastMeasurement) {
      const vesselProps: any = {
        vessel, ...popup,
        active: vessel.active,
        lat: vessel.lastMeasurement.location.lat,
        lng: vessel.lastMeasurement.location.lng
      };

      vessel.active = route.transport && route.transport.status === 'ONBOARD';

      return <LiveVesselMarker {...vesselProps}/>;
    }

    return <></>;
  }

  renderTracker = ({ tracker, shipment }: any) => {
    let popup = {};

    if(this.popupSettings) {
      popup = {
        open: this.openPopupSettings.type === 'TRACKER' && tracker._id === this.openPopupSettings.id,
        click: (state: string) => this.popupSettings.click(state, tracker, 'TRACKER')
      };
    }

    const trackerProps: any = {
      key: tracker._id,
      lat: tracker.lastMeasurement.location.lat,
      lng: tracker.lastMeasurement.location.lng,
      active: shipment && shipment.status === 'ongoing',
      tracker,
      ...popup
    };

    return <LiveTrackerMarker {...trackerProps} />;
  }

  renderEndpoint = (text: any, endpoint: any) => {
    return <TextMarker text={endpoint.label || text} lat={endpoint.location.lat} lng={endpoint.location.lng} />;
  }

  onGoogleApiLoaded = (map: any, maps: any) => {
    const onMapUpdate = () => this.setState({ routes: getRoutes(map, this.props.settings, this.props.routes) });

    this.setState({
      map,
      maps: decorateMaps(maps)
    }, () => {
      this.setMapZoom(this.props.routes);
      onMapUpdate();
    });

    onRefresh(map, maps, onMapUpdate);
  }

  render() {
    let openEvent = null;
    const { map, maps } = this.state;

    this.popupSettings = this.props.settings.popup || {};
    this.openPopupSettings = this.popupSettings.open || {};

    map && map.clearRoutes();

    return (
      <div className={`shlk-map shlk-route-map shlk-route-map__multiple ${this.props.updating ? 'updating' : ''}`}>
        { (!this.state.map || this.props.updating) && <Spinner style={{ marginTop: 0 }}/> }

        <AbstractMap
          onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
          defaultCenter={{lat : 0, lng : 0}}
          defaultZoom={10}
        >
          { this.state.routes.map((route: any) => {
            const markers = getMarkers(map, route);
            const shipment = route.shipment ? this.getShipmentMarkers(route) : [];
            const vessel = route.vessel ? this.getVesselMarkers(route) : [];
            const events = this.props.settings.events ? route.events.map(this.renderEvent) : [];
            this.props.settings.routes ? renderRoute(map, maps, route, markers) : (map && map.clearRoutes());

            if(this.popupSettings) {
              openEvent = !this.props.settings.events && this.openPopupSettings.type === 'EVENT' &&
                                 events.find((_id: any) => _id === this.openPopupSettings.id);
            }

            return [
              ...shipment,
              ...markers.map(this.renderWaypoint),
              ...events,
              vessel,
              this.renderTracker(route)
            ];
            })
          }

          { openEvent && this.renderEvent(openEvent, 0) }

        </AbstractMap>
      </div>
    );
  }
}