export const calculateDistanceBetween = (
  m1: { lat: any; lng: any },
  m2: { lat: any; lng: any }
) => {
  if (m1.lat === m2.lat && m1.lng === m2.lng) return 0;
  const φ1 = (m1.lat * Math.PI) / 180,
    φ2 = (m2.lat * Math.PI) / 180,
    Δλ = ((m2.lng - m1.lng) * Math.PI) / 180,
    R = 6371e3;
  return (
    Math.acos(
      Math.sin(φ1) * Math.sin(φ2) + Math.cos(φ1) * Math.cos(φ2) * Math.cos(Δλ)
    ) * R
  );
};

export const calculatePercentageDone = (
  originLat: number,
  originLng: number,
  destLat: number,
  destLng: number,
  actualLat: number,
  actualLng: number
) => {
  const done = calculateDistanceBetween(
    { lat: originLat, lng: originLng },
    { lat: actualLat, lng: actualLng }
  );
  const pending = calculateDistanceBetween(
    { lat: actualLat, lng: actualLng },
    { lat: destLat, lng: destLng }
  );
  console.log(
    done,"done",
    pending,"pending",
    done / (done + pending),"forumal value",
    originLat,"originLat",
    originLng,"originLng",
    destLat,"destLat",
    destLng,"destLng",
    actualLat,"actualLat",
    actualLng,"actualLng",
    "percentage"
  );
  return ((done / (done + pending)) * 100).toFixed(2);
};
