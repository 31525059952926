import { ADDRGETNETWORKPARAMS } from 'dns';
import React, { Component, createRef } from 'react';

interface Props {
  shipment: any;
  field: string;
  label?: string;
  sublevel?: string;
  type?: 'textarea' | 'text';
  onChange: any;
  validator?: Function;
}

class ShipmentInput extends Component<Props> {
  private inputRef = createRef<HTMLInputElement>();
  private autoComplete: any;

  componentDidMount() {
    if (window.google && this.inputRef.current) {
      this.initializeAutocomplete();
    }
  }

  initializeAutocomplete() {
    if (this.inputRef && this.inputRef.current) {
      const options = {
        types: ['train_station', 'transit_station'] // Restrict the search to cities only
      };
  
      this.autoComplete = new window.google.maps.places.Autocomplete(
        this.inputRef.current,
        options
      );
  
      this.autoComplete.addListener('place_changed', this.onPlaceSelected);
    } else {
      console.error('Input ref is not available.');
    }
  }
  
  
  onPlaceSelected = () => {
    const place = this.autoComplete.getPlace();
    if (!place.geometry) {
      // User entered the name of a place that was not suggested and pressed the Enter key,
      // or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }
   
    // You can now use placeName for your requirements
    // For example, updating the state or props, or calling an onChange handler
    this.props.onChange(this.props.field, this.inputRef.current!.value, "address");
    this.props.onChange(this.props.field, place.geometry.location.toJSON(), "location");
  };
  

  render() {
    const { shipment, field, label, type, validator } = this.props;
    const sublevel = "address";
    const value = (sublevel ? shipment[field][sublevel] : shipment[field]) || "";
    console.log("sublevel", sublevel);
    console.log("field", field);
    let validation = { isValid: true, error: null };

    if (validator) {
      validation = validator(value);
    }

    const inputId = `input-${field}-${sublevel || 'main'}`;


    return (
      <div className={`new-input new-input--${field} ${sublevel || ''} ${validation.isValid ? '' : 'error'}`}>
        <label className="new-input-label" htmlFor={inputId}>{label || field}</label>

          <input id={inputId} ref={this.inputRef} className="new-input-value" type="text" value={value}
            onChange={(event) => {
              this.props.onChange(field, event.currentTarget.value, "address");
            }} />
        {validation.error && <div className="new-input-validation">{validation.error}</div>}
      </div>
    );
  }
}

export default ShipmentInput;
