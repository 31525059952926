import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import Page from 'components/ControlBars/Page';
import ContentLoader from 'components/Loading/ContentLoader';
import ShipmentContent from 'components/Shipment';
import { getShipmentInfo, getShipmentPermissions } from 'services/shipments';
import { clean } from 'services/api';
import './styles.scss';

interface MatchProps {
  id: string;
}
type Props = RouteComponentProps<MatchProps>

type State = {
  loading: boolean;
  shipment: any;
  permissions: Array<string>;
};


export default class Shipment extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      shipment: null,
      permissions: []
    };
  }

  getShipment= () => {
    const id = this.props.match.params.id;

    this.setState({ loading: true });

    Promise.all([
      getShipmentInfo(id),
      getShipmentPermissions(id)
    ])
    .then(([shipment, permissions]) => {
      this.setState({ loading: false, shipment, permissions });
    });
  }

  componentDidMount() {
    this.getShipment();
  }

  hardRefresh = () => {
    clean();
    this.getShipment();
  }

  renderContent = () => {
    return <ShipmentContent shipment={this.state.shipment}
                            permissions={this.state.permissions}
                            hardRefresh={this.hardRefresh}/>;
  }

  render() {
    return (
      <Page className="shlk-shipment" type="map">
        <ContentLoader loading={this.state.loading} content={this.renderContent} />
      </Page>
    );
  }
}