// Import the React library for creating React components
import React from 'react';

// Import the ReactDOM library for rendering React components to the DOM
import ReactDOM from 'react-dom';

// Import the main App component from the file located in the components directory
import App from './components/App';


// Log a message to the console indicating the start of the app
console.log("Starting app...");

// Use ReactDOM to render the App component into a DOM element with the id 'root'
ReactDOM.render(<App />, document.getElementById('root'));