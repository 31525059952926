import React, { Component } from 'react';
import { trackEvent } from 'utils/analytics';
import Icon from 'components/Icon';
import FormInfo from '../Info';
import './styles.scss';

interface Props {
    single?: boolean;
    onClick?: any;
    info?: string;
    name: string;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
    withoutTitle?: boolean;
}

const ICON_PER_TYPE = {
    radio: { checked: 'check_circle', unchecked: 'radio_button_unchecked' },
    checkbox: { checked: 'check_box', unchecked: 'check_box_outline_blank' }
};

export default class SelectableInput extends Component<Props> {
    static defaultProps = { className: '' };

    onClickHandler = () => {
        const isChecked = !this.props.checked;

        trackEvent('SelectableInputForm', `${isChecked ? 'Check' : 'Uncheck'} ${this.props.name}`, this.props.name);
        this.props.onClick(isChecked);
    }

    renderCheck = () => {
        const type = this.props.single ? 'radio' : 'checkbox';
        const icon = this.props.checked ? ICON_PER_TYPE[type].checked : ICON_PER_TYPE[type].unchecked;

        return (<>
            <input
                type={type}
                className="form-selectable-check"
                name={this.props.name}
                checked={this.props.checked}
                onChange={() => this.props.disabled ? null : this.onClickHandler()}
            />
            <Icon type="outlined" className="form-selectable-icon" name={icon} size={20} />
        </>);
    }

    render() {
        return (
            <label
                className={`form-selectable form-selectable__${this.props.disabled ? 'disabled' : 'active'} ${this.props.className}`}
            >
                { (this.props.onClick) && this.renderCheck() }

                { !this.props.withoutTitle && <span className="form-selectable-title">{this.props.name}</span> }

                {(this.props.info) && <FormInfo name={this.props.name} text={this.props.info} />}
            </label>
        );
    }
}
