import React, { PureComponent } from "react";
import { validateSettings } from "components/Forms/Settings/utils";
import { mergeDeep } from "utils/functions";
import Input from "./Input";
import Tags from "./Tags";
import Settings from "./Settings";
import Trackers from "./Trackers";
import Actions from "./Actions";
import Location from "./Location";

import Dropdown from "./Dropdown";
import "./styles.scss";

import { ModeObject, AnyMode } from "./Mode";
import Mode from "./Mode";

import { transportationModes } from "./Mode/ModeSelector";
import { hasPermission } from "utils/auth";
import _ from "lodash";
/**
 * Props for the Shipment component.
 */
interface Props {
  //The shipment to be displayed, is null when creating a new shipment.
  shipment: any;
  trackers: any;
  tags: any;
  callback: any;
  selectedTrackers: any;
  edit?: boolean;
  validationErrors?: any; // Pass validationErrors as a prop
  create? :any;
}

/**
 * Represents the state of the Shipment component.
 */
type State = {
  update: any;
  selectedTrackers: Array<any>;
  loading: boolean;
  selectedModeIndex: number | null;
  beta: boolean;
};

/**
 * Represents the Shipment component.
 * TODO: Refactor this component for delete mode
 */

export default class FormShipment extends PureComponent<Props, State> {
  /**
   * Constructs a new instance of the Shipment component.
   * @param {Props} props - The props for the Shipment component.
   */
  constructor(props: Props) {
    super(props);
    // If the shipment is being edited, the vessel field must be a string.
    const update = props.shipment || {}; // Fallback to an empty object if shipment is null
    // If the vessel is an object, it must be a vessel object, so we get the mmsi.
    update.vessel && (update.vessel = update.vessel.mmsi);

    let beta = true;
    // if Shipment does not have a Schema version, set it
    // if (!update.schemaVersion) {
    //   if (hasPermission("APP_DEV")) {
    //     update.schemaVersion = "CROSS_MODAL_V1";
    //     update.modes = update.modes || []; // Initialize modes if not present
    //     beta = true;
    //   } else {
    //     update.schemaVersion = "LEGACY";
    //     beta = false;
    //   }
    // }

    if (!update.schemaVersion) {
      update.schemaVersion = "CROSS_MODAL_V1";
      update.modes = update.modes || []; // Initialize modes if not present
      beta = true;
    }

    // If the shipment is being edited, the vessel field must be a string.
    this.state = {
      loading: true,
      update,
      selectedTrackers: props.selectedTrackers,
      //If there is any mode, select the first one
      selectedModeIndex: update.modes && update.modes.length > 0 ? 0 : null,
      beta: beta,
    };
  }

  // Function to map modeType to icon and label
  getIconDetails(modeType: string) {
    return transportationModes.find((mode) => mode.id === modeType);
  }

  handleChainElementClick = (index: number) => {
    this.setState({ selectedModeIndex: index });
  };

  /**
   * Updates the field value in the shipment state.
   * @param field - The field to be updated.
   * @param value - The new value for the field.
   * @param sublevel - Optional sublevel for nested fields.
   */
  updateField = (field: string, value: any, sublevel?: string | number) => {
    console.log("updateField", field, value, sublevel);
    console.log("updateField", this.state.update);

    const shipment = mergeDeep({}, this.state.update);

    if (sublevel !== undefined) {
      // Check if field is an array
      if (Array.isArray(shipment[field])) {
        // Convert sublevel to number and use it as an index
        const index = Number(sublevel);
        if (!isNaN(index)) {
          shipment[field][index] = value;
        } else {
          console.error(`Invalid array index: ${sublevel}`);
        }
      } else {
        // Field is an object, use sublevel as a key
        shipment[field][sublevel] = value;
      }
    } else {
      shipment[field] = value;
    }

    this.setState({ update: shipment });
  };

  addMode = () => {
    const newMode = { modeType: "Land" };
    const newModes = [...this.state.update.modes, newMode];
    this.updateField("modes", newModes);
    this.setState({ selectedModeIndex: newModes.length - 1 });
  };

  onModeChange = (mode: ModeObject, key: number) => {
    // Convert key to string because updateField expects a string or undefined for sublevel
    this.updateField("modes", mode, String(key));
  };

  onModeDelete = (key: number) => {
    const modes = [...this.state.update.modes];
    modes.splice(key, 1);
    this.updateField("modes", modes);
    if (this.state.update.modes.length > 1) {
      this.setState({ selectedModeIndex: this.state.selectedModeIndex! - 1 });
    } else {
      this.setState({ selectedModeIndex: null });
    }
  };

  onModeFieldChange = (
    field: string,
    value: any,
    key: number,
    sublevel?: string
  ) => {
    const modes: AnyMode[] = [...this.state.update.modes];

    if (key in modes) {
      const mode = modes[key];

      // TypeScript doesn't allow indexed access on union types directly
      // Using 'any' type to bypass this restriction
      if (sublevel) {
        (mode as any)[field][sublevel] = value;
      } else {
        (mode as any)[field] = value;
      }
    } else {
      console.error(`Invalid mode index: ${key}`);
    }

    this.updateField("modes", modes);
  };

  render() {
    const validation = validateSettings(this.state.update.settings,  this.state.selectedTrackers &&
      this.state.selectedTrackers[0] &&
      this.state.selectedTrackers[0].model);

    return (
      <div
        className={`form-shipment ${
          this.props.edit ? "form-shipment-edit" : ""
        }`}
      >
        <div className="new-header">
          <h1 className="new-title">Shipment Receipt</h1>
          <h2 className="new-subtitle">Fields marked with * are mandatory</h2>
        </div>

        <div className="new-wrapper">
          <div className="new-box new-box-double new-box-general">
            <div className="new-box-half">
              <h4 className="new-box-title">General Information</h4>
              <Input
                shipment={this.state.update}
                onChange={this.updateField}
                field="name"
              />
              <Input
                shipment={this.state.update}
                onChange={this.updateField}
                field="description"
                type="textarea"
              />
            </div>
            <div className="new-box-half shipment-reference">
              <h4 className="new-box-title">Shipment Reference</h4>
              <Input
                shipment={this.state.update}
                onChange={this.updateField}
                label="Shipping Document or Bill of Lading Number"
                field="booking"
              />
            </div>
          </div>

          <div className="new-box new-box-double new-box-general">
            <div className="new-box-half">
              <h4 className="new-box-title">Container Information</h4>

              <Input
                shipment={this.state.update}
                onChange={this.updateField}
                label="Weight in tonnes/ VGM(Verified Gross Mass)"
                field="weight"
                validator={(value: string) => {
                  const isValid = !value || /^[0-9]*\.?[0-9]+$/.test(value);
                  return {
                    isValid,
                    error: isValid ? null : "Weight must be a number in tonnes",
                  };
                }}
                disabled={this.props.edit}
              />
              <div style={{ pointerEvents: this.props.edit ? "none" : "auto" }}>
                <Dropdown
                  parentObject={this.state.update}
                  field="cargoType"
                  options={["DRY", "REEFER", "EMPTY"]}
                  onChange={this.updateField}
                  label="Type of Container"
                />

                <Dropdown
                  parentObject={this.state.update}
                  field="containerType"
                  options={["20'", "40'", "40' HC", "Other"]}
                  onChange={this.updateField}
                  label="Size of container"
                />
              </div>
            </div>

            <div className="new-box-half">
              <h4 className="new-box-title empty">
                {" "}
                <br></br>
              </h4>
              <Input
                shipment={this.state.update}
                onChange={this.updateField}
                field="containerNumber"
                label="Container Number"
                disabled={this.props.edit}
              />
              <Input
                shipment={this.state.update}
                onChange={this.updateField}
                field="containerSeal"
                label="Container Seal Number"
                disabled={this.props.edit}
              />

              <Input
                shipment={this.state.update}
                onChange={this.updateField}
                field="vessel"
                label="MMSI Number"
                validator={(value: string) => {
                  const isValid = !value || /^\d{9}$/.test(value);
                  return {
                    isValid,
                    error: isValid ? null : "MMSI must be 9 digits number",
                  };
                }}
                disabled={this.props.edit}
              />
            </div>
          </div>

          <div className="new-box new-box-location new-box-double">
            <Location
              field="origin"
              shipment={this.state.update}
              onChange={this.updateField}
            />
          </div>

          <div className="new-box new-box-location new-box-double">
            <Location
              field="destination"
              shipment={this.state.update}
              onChange={this.updateField}
            />
          </div>

          {this.state.beta ? (
            <div className="new-box new-box-double new-box-general">
              <div className="new-box-half">
                <h4 className="new-box-title">Transport Mode</h4>

                {/* Render Mode Icons */}
                <div className="transport-chain">
                  {this.state.update.modes.map(
                    (mode: ModeObject, index: number) => {
                      const iconDetails = this.getIconDetails(mode.modeType);
                      const icon = iconDetails ? iconDetails.icon : "";
                      const label = iconDetails ? iconDetails.label : "";

                      return (
                        <div
                          key={index}
                          className={`mode-icon ${
                            this.state.selectedModeIndex === index
                              ? "selected"
                              : ""
                          }`}
                          style={{
                            cursor: this.props.edit ? "not-allowed" : "",
                          }}
                          onClick={() => {
                            if (!this.props.edit) {
                              // Prevent onClick if in edit mode
                              this.handleChainElementClick(index);
                            }
                          }}
                        >
                          <span className="material-icons">{icon}</span>
                          <span>{label}</span>
                        </div>
                      );
                    }
                  )}
                  {/* Render Add Mode Icon */}
                  <div
                    className="mode-icon"
                    style={{ cursor: this.props.edit ? "not-allowed" : "" }}
                    onClick={() => {
                      if (!this.props.edit) {
                        // Prevent adding mode if in edit mode
                        this.addMode();
                      }
                    }}
                  >
                    <span className="material-icons">add</span>
                  </div>
                </div>

                {/* Render Selected Mode Component */}
                {this.state.selectedModeIndex !== null && (
                  <div
                    style={{ pointerEvents: this.props.edit ? "none" : "auto" }}
                  >
                    <Mode
                      mode={
                        this.state.update.modes[this.state.selectedModeIndex]
                      }
                      id={this.state.selectedModeIndex}
                      key={this.state.selectedModeIndex}
                      onModeChange={(updatedMode: ModeObject) =>
                        this.onModeChange(
                          updatedMode,
                          this.state.selectedModeIndex!
                        )
                      }
                      onModeDelete={() =>
                        this.onModeDelete(this.state.selectedModeIndex!)
                      }
                      onModeFieldChange={this.onModeFieldChange}
                      update={this.state.update}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null}

          <div className="new-box-group">
            <div className="new-box new-box-trackers">
              <Trackers
                shipment={this.state.update}
                trackers={this.props.trackers}
                selected={this.state.selectedTrackers}
                onChange={(selectedTrackers: any) =>
                  this.setState({ selectedTrackers })
                }
              />
            </div>

            {!this.props.edit && (
              <div className="new-box new-box-settings">
                <Settings
                  trackers={this.state.selectedTrackers}
                  shipment={this.state.update}
                  validation={validation}
                  onChange={this.updateField}
                />
              </div>
            )}
          </div>

          {!this.props.edit && (
            <div className="new-box new-box-tags">
              <Tags
                shipment={this.state.update}
                tags={this.props.tags}
                onChange={this.updateField}
              />
            </div>
          )}
          <p style={{ fontSize: "18px", color: "rgb(193, 28, 28)" }}>
            {this.props.validationErrors ? this.props.validationErrors : ""}
          </p>
          <Actions
            shipment={this.state.update}
            callback={this.props.callback}
            trackers={this.state.selectedTrackers}
            edit={!!this.props.edit}
            validation={validation}
            create= {this.props.create}
          />
        </div>
      </div>
    );
  }
}
