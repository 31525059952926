import GoogleAnalytics from 'utils/analytics/googleAnalytics';
import Logger from 'utils/analytics/logger';
import { getCookieData } from 'utils/data/cookies';

/**
 * Represents the parameters for a route in analytics.
 */
export interface RouteParams {
    /**
     * The title of the page.
     */
    page_title: string;
    
    /**
     * The path of the page.
     */
    page_path: string;
    
    /**
     * The location of the page.
     */
    page_location: string;
}

/**
 * Represents the dimensions for analytics tracking.
 */
export interface Dimensions {
    shipment_status?: string;
}

/**
 * Represents an analytics object.
 */
interface Analytic {
    init: Function;
    setAnalyticsUserId: Function;
    trackPageview: Function;
    trackEvent: Function;
    trackException: Function;
}

/**
 * Mapping of property names to analytics providers.
 * @type {Object}
 */
const ANALYTICS_PER_PROPERTY: any = {
    'app-preprod-v1-1.ew.r.appspot.com': [Logger],
    'app.naxxar.uk': [GoogleAnalytics],
    'localhost': [Logger]
};

console.log('ANALYTICS_PER_PROPERTY', window.location.hostname);

// If the current environment is 'development', use the Logger for analytics.
// Otherwise, use the analytics provider specified for the current hostname in the ANALYTICS_PER_PROPERTY object.
const ANALYTICS = process.env.NODE_ENV === 'development' ? [Logger] : ANALYTICS_PER_PROPERTY[window.location.hostname];

/**
 * Loads the analytics by initializing each analytic with the user ID.
 */
function loadAnalytics() {
    const userId = getCookieData('userId');

    ANALYTICS.forEach((analytic: Analytic) => analytic.init({ userId }));
}

/**
 * Sets up global listeners for handling errors and unhandled rejections.
 */
function setGlobalListeners() {
    window.onerror = function (message: string | Event, filename?: string, line?: number, column?: number, error?: Error) {
        let stack = '';

        if (error && error.stack) {
          stack = error.stack;
        } else {
          stack = `${message}. at ${filename}, line ${line} column ${column}`;
        }

        trackException(stack);

        return false;
    };

    window.onunhandledrejection = function ({ reason }: PromiseRejectionEvent) {
        trackException(reason);
    };
}

/**
 * Sets the user ID for analytics.
 * @param userId The user ID to set.
 */
export function setAnalyticsUserId(userId: string) {
    ANALYTICS.forEach((analytic: Analytic) => analytic.setAnalyticsUserId(userId));
}

/**
 * Tracks a pageview event with the given route parameters and dimensions.
 * @param routeParams - The route parameters for the pageview.
 * @param dimensions - The dimensions for the pageview.
 */
export function trackPageview(routeParams: RouteParams, dimensions: Dimensions) {
    ANALYTICS.forEach((analytic: Analytic) => analytic.trackPageview(routeParams, dimensions));
}

/**
 * Tracks an event using the specified category, action, and label.
 * @param category - The category of the event.
 * @param action - The action of the event.
 * @param label - The optional label of the event.
 */
export function trackEvent(category: string, action: string, label = '') {
    ANALYTICS.forEach((analytic: Analytic) => analytic.trackEvent(category, action, label));
}

/**
 * Tracks an exception in the analytics system.
 * @param description - The description of the exception.
 * @param fatal - Indicates whether the exception is fatal. Default is true.
 */
export function trackException(description: string, fatal = true) {
    ANALYTICS.forEach((analytic: Analytic) => analytic.trackException(description, fatal));
}

/**
 * Initializes the analytics module.
 * This function sets global listeners and loads analytics.
 */
export default {
    init() {
        setGlobalListeners();
        loadAnalytics();
    }
};