import React, { Component, PropsWithChildren } from 'react';
import { ReactSVG } from 'react-svg';
import { trackEvent } from 'utils/analytics';
import './styles.scss';

type Props = PropsWithChildren<{
    title: string | JSX.Element;
}>

interface State {
    open: boolean;
}

export default class Dropdown extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { open: false };
    }

    onClickHandler = () => {
        const isOpen = !this.state.open;

        trackEvent('DropDownForm', `${isOpen ? 'Open' : 'Close'} Dropdown`);
        this.setState({ open : isOpen });
    }

    render() {
        const icon = this.state.open ? "chevron-up" : "chevron-down";
// console.log(this.props.title,this.props.children, "modes");
        return (
            <div className="dropdown-container">
                <div className="dropdown-title">
                    { this.props.title }
                    <ReactSVG
                        onClick={this.onClickHandler}
                        className="dropdown-icon"
                        src={`/assets/svg/${icon}.svg`}
                    />
                </div>
                {   this.state.open && <div className="dropdown-children">
                        {this.props.children}
                    </div>
                }
            </div>
        );
    }
}
