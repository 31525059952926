const isObject = (item: any) =>
  item && typeof item === 'object' && !Array.isArray(item);

export const debounce = (callback: Function, timeout = 500) => {
    let timer: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), timeout);
    };
};

export const toggle = (array: (string | boolean)[], value: string | boolean) => {
  const index = array.indexOf(value);

  if(index < 0) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }

  return [...array];
};

export const reducer = (array: any[], condition: Function) => {
  return array.reduce(([group1, group2]: [any[], any[]], value: any) =>
  condition(value) ? [[...group1, value], group2] : [group1, [...group2, value]]
, [[], []]);
};

export const appendScript = (src: string, { id = '' }: { id?: string } = {}) => {
  const scriptElement = document.createElement('script');

  scriptElement.id = id;
  scriptElement.async = true;
  scriptElement.src = src;

  document.head.appendChild(scriptElement);
};

export const isEmail = (value: string) => {
  return /\S+@\S+\.\S+/.test(value);
};

export const mergeDeep = (target: any, ...sources: any): any => {
  if (!sources.length) return target;

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

export const sortAlphabetically = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};