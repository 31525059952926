import React, { Component } from 'react';
import Button from 'components/Inputs/Button';
import Input from 'components/Inputs/Input/Default';
import PermissionsTable from 'components/Permissions/Table';
import { isEmail } from 'utils/functions';
import './styles.scss';

interface Props {
  roles: Array<{
    name: string;
    permissions: Array<string>;
  }>;
  mandatory?: Array<string>;
  cancel: any;
  update: any;
}

interface State {
  name: string;
  email: string;
  selectedRoles: Array<string>;
}

export default class AddPermissions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedRoles: [...props.mandatory || []],
      name: '',
      email: ''
    };
  }

  updateSelectedRoles = (role: any, value: any) => {
    let selectedRoles = this.state.selectedRoles;

    if (value) {
      selectedRoles.push(role);
    } else {
      selectedRoles = selectedRoles.filter(element => element !== role);
    }

    this.setState({ selectedRoles });
  }

  isValidData = () => {
    return !!this.state.name &&
            !!this.state.email && isEmail(this.state.email) &&
            this.state.selectedRoles.length !== 0;
  }

  render() {
    return <div className='permissions-add'>
          <div className='add-inputs'>
            <Input type="text" name="name" label="Name: "
                    onChange={(event: any) => this.setState({ name: event.currentTarget.value })}
                    value={this.state.name} />
            <Input type="email" name="email" label="Email: "
                    className={`add-input-${isEmail(this.state.email) ? "valid" : "invalid"}`}
                    onChange={(event: any) => this.setState({ email: event.currentTarget.value })}
                    value={this.state.email} />
          </div>

          <PermissionsTable roles={this.props.roles}
                            mandatory={this.props.mandatory}
                            selected={this.state.selectedRoles}
                            callback={this.updateSelectedRoles} />

          <div className="add-buttons">
            <Button category="Invite"
                    action="Cancel"
                    onClick={this.props.cancel}>
              Cancel
            </Button>

            <Button category="Invite"
                    action="Send"
                    disabled={!this.isValidData()}
                    onClick={() => this.props.update(this.state.name, this.state.email, this.state.selectedRoles)}>
              Send Invite
            </Button>
          </div>
    </div>;
  }
}