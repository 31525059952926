// import React, { Component } from "react";
// import { RouteComponentProps, withRouter } from "react-router";
// import RoutesMap from "components/GoogleMap/RoutesMap";
// import TrackableElement from "components/Trackable/Element";
// import Box from "components/Box";
// import ContentPopup from "components/ContentPopup";
// import Icon from "components/Icon";
// import Documents from "./Documents";
// import Info from "./Info";
// import Tags from "./Tags";
// import Share from "./Share";
// import Settings from "./Settings";
// import Trackers from "./Trackers";
// import MapSettings from "./MapSettings";
// import Events from "./Events";
// import EditPopup from "./Popups/Edit";
// import SharePopup from "../Popups/Share";
// import RawPopup from "components/Raw";
// import { isLoggedIn } from "utils/auth";
// import { inviteMember, getMemberRoles } from "services/shipments";
// import { ShipmentContext } from "./context";
// import "./styles.scss";
// import Emissions from "./Emissions";
// import ScanBox from "./ScanBox";

// interface Props extends RouteComponentProps {
//   shipment: any;
//   permissions: Array<string>;
//   hardRefresh: any;
// }

// interface State {
//   content: string;
//   shipment: any;
//   updateShipment: any;
//   mapSettings: any;
//   updateMapSettings: any;
//   hardRefresh: any;
//   trackerCentered: number;
// }

// const countEvents = (shipment: any) => {
//   let count = 0;

//   count += shipment.events.filter((event: any) => !event.ack).length;

//   shipment.transports.forEach((trans: any) => {
//     count += trans.events.filter((event: any) => !event.ack).length;
//   });

//   return count;
// };

// class Shipment extends Component<Props, State> {
//   constructor(props: Props) {
//     super(props);

//     this.state = {
//       content: this.props.permissions[0],
//       shipment: props.shipment,
//       mapSettings: {
//         trackers: props.shipment.transports.map(
//           (trans: any) => trans.tracker.identifier
//         ),
//         tower: false,
//         routes: true,
//         events: props.permissions.includes("VIEW_EVENTS"),
//         sources: ["TRACKER", "VESSEL", "TOWER", "WIFI", "N/A"],
//         popup: {
//           open: null,
//           click: this.openPopup.bind(this),
//         },
//       },
//       updateMapSettings: this.updateMapSettings.bind(this),
//       updateShipment: this.updateShipment.bind(this),
//       hardRefresh: this.props.hardRefresh,
//       trackerCentered: -1,
//     };
//   }

//   getOpen = (value: string) => {
//     const measurement: any = []
//       .concat(
//         ...this.props.shipment.transports.map(
//           (trans: any) => trans.measurements
//         )
//       )
//       .find((meas: any) => meas._id === value);

//     if (measurement) {
//       this.openPopup("open", value, measurement.source, measurement.location);
//     }
//   };

//   updateMapSettings = (mapSettings: any) => {
//     this.setState({ mapSettings });
//   };

//   updateShipment = (shipment: any) => {
//     this.setState({ shipment });
//   };

//   openPopup = (state: string, element: any, type: string, center?: any) => {
//     const mapSettings = Object.assign({}, this.state.mapSettings);

//     if (state === "open") {
//       mapSettings.popup.open = { type, id: element._id || element, center };
//     } else {
//       mapSettings.popup.open = null;
//     }

//     this.setState({ mapSettings });
//   };

//   renderBox = () => {
//     const shipment = this.context.shipment;
//     const permissions = this.props.permissions;
//     const boxProps: any = { menu: [] };
//     permissions.includes("VIEW_SHIPMENT") &&
//       boxProps.menu.push({
//         type: "location",
//         render: () => (
//           <></>
//         ),
//         icon: "location",
//       });

//     permissions.includes("VIEW_SHIPMENT") &&
//       boxProps.menu.push({
//         type: "info",
//         render: () => (
//           <Info actions={permissions.includes("ACTIONS_SHIPMENT")} />
//         ),
//         icon: "info-outline",
//       });

//     permissions.includes("VIEW_EVENTS") &&
//       boxProps.menu.push({
//         type: "events",
//         render: () => <Events />,
//         icon: "bell",
//         notification: countEvents(this.state.shipment) > 0,
//       });
//     permissions.includes("VIEW_SHIPMENT") &&
//       boxProps.menu.push({
//         type: "scanbox",
//         render: () => (
//           <ScanBox
//             actions={permissions.includes("ACTIONS_SHIPMENT")}
//             shipment={shipment}
//             lines={[]}
//             showBoxButton={true}
//           />
//         ),
//         icon: "cam",
//       });
//     permissions.includes("DOWNLOAD_DOCS") &&
//       boxProps.menu.push({
//         type: "docs",
//         render: () => (
//           <Documents
//             remove={permissions.includes("DELETE_DOCS")}
//             upload={permissions.includes("UPLOAD_DOCS")}
//           />
//         ),
//         icon: "documentation",
//       });
//     permissions.includes("VIEW_SHIPMENT") &&
//       boxProps.menu.push({
//         type: "emissions",
//         render: () => (
//           <Emissions
//             actions={permissions.includes("ACTIONS_SHIPMENT")}
//             shipment={shipment}
//             lines={[]}
//             showBoxButton={true}
//           />
//         ),
//         icon: "emissions",
//       });
//     permissions.includes("VIEW_SHIPMENT") &&
//       boxProps.menu.push({
//         type: "trackers",
//         render: () => <Trackers raw={permissions.includes("VIEW_RAW")} />,
//         icon: "eShells",
//       });
//     permissions.includes("CONFIG_SETTINGS") &&
//       boxProps.menu.push({
//         type: "settings",
//         render: () => <Settings />,
//         icon: "settings",
//       });
//     permissions.includes("VIEW_SHIPMENT") &&
//       this.state.shipment.status === "completed" &&
//       isLoggedIn() &&
//       boxProps.menu.push({
//         type: "report",
//         to: `/shipments/${this.state.shipment._id}/report`,
//         icon: "report",
//       });
//     permissions.includes("VIEW_EVENTS") &&
//       boxProps.menu.push({
//         type: "tags",
//         render: () => (
//           <Tags actions={permissions.includes("ACTIONS_SHIPMENT")} />
//         ),
//         icon: "pricetags-outline",
//       });

//     permissions.includes("VIEW_SHIPMENT") &&
//       boxProps.menu.push({
//         type: "map",
//         render: () => (
//           <MapSettings
//             events={permissions.includes("VIEW_EVENTS")}
//             settings={this.state.mapSettings}
//           />
//         ),
//         icon: "map",
//       });

//     permissions.includes("CREATE_PUBLIC") &&
//       boxProps.menu.push({
//         type: "share",
//         render: () => <Share />,
//         icon: "share",
//       });

//     return <Box {...boxProps} />;
//   };

//   centerMapButton = () => {
//     const trackers = this.state.shipment.transports.map(
//       (trans: any) => trans.tracker
//     );

//     const trackerCentered = (this.state.trackerCentered + 1) % trackers.length;
//     const tracker = trackers[trackerCentered];

//     this.openPopup(
//       "open",
//       tracker._id,
//       "TRACKER",
//       tracker.lastMeasurement.location
//     );
//     this.setState({ trackerCentered });
//   };

//   render() {
//     return (
//       <div className="shipment-page">
//         <ShipmentContext.Provider value={this.state}>
//           {this.renderBox()}

//           <TrackableElement
//             className="map-btn refresh-btn"
//             category="Shipment"
//             action="Click Refresh Map"
//             onClick={this.props.hardRefresh}
//           >
//             <Icon name="refresh" size={22} />
//           </TrackableElement>

//           <TrackableElement
//             className="map-btn center-btn"
//             category="Shipment"
//             action="Click Center Map"
//             onClick={this.centerMapButton}
//           >
//             <Icon name="my_location" size={20} />
//           </TrackableElement>

//           <RoutesMap
//             settings={this.state.mapSettings}
//             routes={this.state.shipment.transports.map((transport: any) => ({
//               measurements: transport.measurements,
//               events: transport.events,
//               tracker: transport.tracker,
//               shipment: this.state.shipment,
//               transport: transport,
//               vessel: this.state.shipment.vessel,
//             }))}
//           />

//           <ContentPopup
//             hash="edit"
//             content={(close: any) => (
//               <EditPopup
//                 shipment={this.state.shipment}
//                 close={(shipment: any) => {
//                   if (shipment) {
//                     this.setState({
//                       shipment,
//                       mapSettings: Object.assign(this.state.mapSettings, {
//                         trackers: shipment.transports.map(
//                           ({ tracker }: any) => tracker.identifier
//                         ),
//                       }),
//                     });
//                   }

//                   close();
//                 }}
//               />
//             )}
//           />

//           <ContentPopup
//             hash="share"
//             content={(close: any) => (
//               <SharePopup
//                 type="shipment"
//                 inviteMember={inviteMember}
//                 getRoles={getMemberRoles}
//                 document={this.state.shipment}
//                 close={close}
//               />
//             )}
//           />

//           <ContentPopup
//             hash="raw"
//             content={(close: any, identifier: string) => {
//               const transport = this.props.shipment.transports.find(
//                 ({ tracker }: any) => identifier === tracker.identifier
//               );
//               const info = transport
//                 ? {
//                     tracker: transport.tracker,
//                     measurements: transport.measurements,
//                   }
//                 : null;
//               return (
//                 <RawPopup
//                   info={info}
//                   close={(meas: any) => {
//                     meas && this.getOpen(meas._id);
//                     close();
//                   }}
//                 />
//               );
//             }}
//           />
//         </ShipmentContext.Provider>
//       </div>
//     );
//   }
// }

// export default withRouter(Shipment);

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import RoutesMap from "components/GoogleMap/RoutesMap";
import TrackableElement from "components/Trackable/Element";
import Box from "components/Box";
import ContentPopup from "components/ContentPopup";
import Icon from "components/Icon";
import Documents from "./Documents";
import Info from "./Info";
import Tags from "./Tags";
import Share from "./Share";
import Settings from "./Settings";
import Trackers from "./Trackers";
import MapSettings from "./MapSettings";
import Events from "./Events";
import EditPopup from "./Popups/Edit";
import SharePopup from "../Popups/Share";
import RawPopup from "components/Raw";
import { isLoggedIn } from "utils/auth";
import {
  inviteMember,
  getMemberRoles,
  getCameraRoles,
  inviteInspections,
} from "services/shipments";
import { ShipmentContext } from "./context";
import "./styles.scss";
import Emissions from "./Emissions";
import ScanBox from "./ScanBox";

interface Props extends RouteComponentProps {
  shipment: any;
  permissions: Array<string>;
  hardRefresh: any;
  isPublic?: any;
}

interface State {
  type: any;
  content: string;
  shipment: any;
  updateShipment: any;
  mapSettings: any;
  updateMapSettings: any;
  hardRefresh: any;
  trackerCentered: number;
  isFromScanBoxOpenShareTab: any;
  isFromScanBoxOpenTagTab: any, // Add a similar flag for tags
}

const countEvents = (shipment: any) => {
  let count = 0;

  count += shipment.events.filter((event: any) => !event.ack).length;

  shipment.transports.forEach((trans: any) => {
    count += trans.events.filter((event: any) => !event.ack).length;
  });

  return count;
};

class Shipment extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      content: this.props.permissions[0],
      type: "info",
      shipment: props.shipment,
      mapSettings: {
        trackers: props.shipment.transports.map(
          (trans: any) => trans.tracker.identifier
        ),
        tower: false,
        routes: true,
        events: props.permissions.includes("VIEW_EVENTS"),
        sources: ["TRACKER", "VESSEL", "TOWER", "WIFI", "N/A"],
        popup: {
          open: null,
          click: this.openPopup.bind(this),
        },
      },
      updateMapSettings: this.updateMapSettings.bind(this),
      updateShipment: this.updateShipment.bind(this),
      hardRefresh: this.props.hardRefresh,
      trackerCentered: -1,
      isFromScanBoxOpenShareTab: false,
      isFromScanBoxOpenTagTab: false, // Add a similar flag for tags
    };
  }

  getOpen = (value: string) => {
    const measurement: any = []
      .concat(
        ...this.props.shipment.transports.map(
          (trans: any) => trans.measurements
        )
      )
      .find((meas: any) => meas._id === value);

    if (measurement) {
      this.openPopup("open", value, measurement.source, measurement.location);
    }
  };

  updateMapSettings = (mapSettings: any) => {
    this.setState({ mapSettings });
  };

  updateShipment = (shipment: any) => {
    this.setState({ shipment });
  };

  openPopup = (state: string, element: any, type: string, center?: any) => {
    const mapSettings = Object.assign({}, this.state.mapSettings);

    if (state === "open") {
      mapSettings.popup.open = { type, id: element._id || element, center };
    } else {
      mapSettings.popup.open = null;
    }

    this.setState({ mapSettings });
  };

  openTagTab = () => {
    console.log("Opening Tag Tab");
    this.setState(
      { content: "tags", type: "tags", isFromScanBoxOpenTagTab: true },
      () => {
        console.log("Updated State in openTagTab:", this.state);
      }
    );
  };
  openShareTab = () => {
    console.log("Opening Share Tab");
    this.setState(
      { content: "share", type: "share", isFromScanBoxOpenShareTab: true },
      () => {
        console.log("Updated State in openShareTab:", this.state);
      }
    );
  };

  resetTagTab = () => {
    console.log("Resetting Tag Tab");
    this.setState(
      { content: "info", type: "info", isFromScanBoxOpenTagTab: false },
      () => {
        console.log("Updated State in resetTagTab:", this.state);
      }
    );
  };

  resetShareTab = () => {
    console.log("Resetting Share Tab");
    this.setState(
      { content: "info", type: "info", isFromScanBoxOpenShareTab: false },
      () => {
        console.log("Updated State in resetShareTab:", this.state);
      }
    );
  };

  renderBox = () => {
    const shipment = this.context.shipment;
    const permissions = this.props.permissions;
    const boxProps: any = { menu: [] };

    permissions.includes("VIEW_SHIPMENT") &&
      boxProps.menu.push({
        type: "location",
        render: () => <></>,
        icon: "location",
      });

    permissions.includes("VIEW_SHIPMENT") &&
      boxProps.menu.push({
        type: "info",
        render: () => (
          <Info actions={permissions.includes("ACTIONS_SHIPMENT")} isPublic={this.props.isPublic} openTagTab={this.openTagTab}/>
        ),
        icon: "info-outline",
      });

    permissions.includes("VIEW_EVENTS") &&
      boxProps.menu.push({
        type: "events",
        render: () => <Events />,
        icon: "bell",
        notification: countEvents(this.state.shipment) > 0,
      });

    permissions.includes("VIEW_SHIPMENT") &&
      boxProps.menu.push({
        type: "scanbox",
        render: () => (
          <>
            <ShipmentContext.Provider value={this.state}>
              <ScanBox
                actions={permissions.includes("ACTIONS_SHIPMENT")}
                shipment={this.state.shipment}
                lines={[]}
                showBoxButton={true}
                openShareTab={this.openShareTab} // Pass the openShareTab function to ScanBox
                isPublic ={this.props.isPublic}
              />
            </ShipmentContext.Provider>
          </>
        ),
        icon: "cam",
      });

    permissions.includes("DOWNLOAD_DOCS") &&
      boxProps.menu.push({
        type: "docs",
        render: () => (
          <Documents
            remove={permissions.includes("DELETE_DOCS")}
            upload={permissions.includes("UPLOAD_DOCS")}
          />
        ),
        icon: "documentation",
      });

    permissions.includes("VIEW_SHIPMENT") &&
      boxProps.menu.push({
        type: "emissions",
        render: () => (
          <>
            <ShipmentContext.Provider value={this.state}>
              <Emissions
                actions={permissions.includes("ACTIONS_SHIPMENT")}
                shipment={this.state.shipment}
                lines={[]}
                showBoxButton={true}
                
              />
            </ShipmentContext.Provider>
          </>
        ),
        icon: "emissions",
      });

    permissions.includes("VIEW_SHIPMENT") &&
      boxProps.menu.push({
        type: "trackers",
        render: () => <Trackers raw={permissions.includes("VIEW_RAW")} />,
        icon: "eShells",
      });

    permissions.includes("CONFIG_SETTINGS") &&
      boxProps.menu.push({
        type: "settings",
        render: () => <Settings />,
        icon: "settings",
      });

    permissions.includes("VIEW_SHIPMENT") &&
      this.state.shipment.status === "completed" &&
      isLoggedIn() &&
      boxProps.menu.push({
        type: "report",
        to: `/shipments/${this.state.shipment._id}/report`,
        icon: "report",
      });

    permissions.includes("VIEW_EVENTS") &&
      boxProps.menu.push({
        type: "tags",
        render: () => (
          <Tags actions={permissions.includes("ACTIONS_SHIPMENT")}  resetTagTab={this.resetShareTab}/>
        ),
        icon: "pricetags-outline",
      });

    permissions.includes("VIEW_SHIPMENT") &&
      boxProps.menu.push({
        type: "map",
        render: () => (
          <MapSettings
            events={permissions.includes("VIEW_EVENTS")}
            settings={this.state.mapSettings}
          />
        ),
        icon: "map",
      });

    permissions.includes("CREATE_PUBLIC") &&
      boxProps.menu.push({
        type: "share",
        render: () => <Share resetShareTab={this.resetShareTab} />,
        icon: "share",
      });

    return (
      <Box
        {...boxProps}
        type={this.state.type} // Pass the type here if needed
        content={this.state.content}
      />
    );
  };

  centerMapButton = () => {
    const trackers = this.state.shipment.transports.map(
      (trans: any) => trans.tracker
    );

    const trackerCentered = (this.state.trackerCentered + 1) % trackers.length;
    const tracker = trackers[trackerCentered];

    this.openPopup(
      "open",
      tracker._id,
      "TRACKER",
      tracker.lastMeasurement.location
    );
    this.setState({ trackerCentered });
  };

  render() {
    return (
      <div className="shipment-page">
        <ShipmentContext.Provider value={this.state}>
          {this.renderBox()}

          <TrackableElement
            className="map-btn refresh-btn"
            category="Shipment"
            action="Click Refresh Map"
            onClick={this.props.hardRefresh}
          >
            <Icon name="refresh" size={22} />
          </TrackableElement>

          <TrackableElement
            className="map-btn center-btn"
            category="Shipment"
            action="Click Center Map"
            onClick={this.centerMapButton}
          >
            <Icon name="my_location" size={20} />
          </TrackableElement>

          <RoutesMap
            settings={this.state.mapSettings}
            routes={this.state.shipment.transports.map((transport: any) => ({
              measurements: transport.measurements,
              events: transport.events,
              tracker: transport.tracker,
              shipment: this.state.shipment,
              transport: transport,
              vessel: this.state.shipment.vessel,
            }))}
          />

          <ContentPopup
            hash="edit"
            content={(close: any) => (
              <EditPopup
                shipment={this.state.shipment}
                close={(shipment: any) => {
                  if (shipment) {
                    this.setState({
                      shipment,
                      mapSettings: Object.assign(this.state.mapSettings, {
                        trackers: shipment.transports.map(
                          ({ tracker }: any) => tracker.identifier
                        ),
                      }),
                    });
                  }

                  close();
                }}
              />
            )}
          />

          <ContentPopup
            hash="share"
            content={(close: any) => (
              <SharePopup
                type={     this.state.isFromScanBoxOpenShareTab
                  ? "the Inspections App"
                  : "this Shipment"}
                inviteMember={this.state.isFromScanBoxOpenShareTab ? inviteInspections: inviteMember}
                getRoles={
                  this.state.isFromScanBoxOpenShareTab
                    ? getCameraRoles
                    : getMemberRoles
                }
                document={this.state.shipment}
                close={close}
              />
            )}
          />

          <ContentPopup
            hash="raw"
            content={(close: any, identifier: string) => {
              const transport = this.props.shipment.transports.find(
                ({ tracker }: any) => identifier === tracker.identifier
              );
              const info = transport
                ? {
                    tracker: transport.tracker,
                    measurements: transport.measurements,
                  }
                : null;
              return (
                <RawPopup
                  info={info}
                  close={(meas: any) => {
                    meas && this.getOpen(meas._id);
                    close();
                  }}
                />
              );
            }}
          />
        </ShipmentContext.Provider>
      </div>
    );
  }
}

export default withRouter(Shipment);
