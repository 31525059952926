/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export const TrackerContext: any = React.createContext({
    tracker: null,
    events: [],
    measurements: [],
    updateEvents: () => {},
    updateTracker: () => {},
    updateMeasurements: () => {}
});

