import { shlkAPI, Options, clean } from 'services/api';

export const getTagsList = async () => {
  return shlkAPI('tags/list');
};

export const getTagsInfoList = async () => {
  return shlkAPI('tags/info');
};

export const getTagInfo = async (data: { id: string }) => {
  return shlkAPI('tags/get', data);
};

export const createTag = async (data: { name: string, color: string }) => {
  return shlkAPI('tags/create', data, true, { handledException: true }).then(clean);
};

export const updateTag = async (data: { id: string; tag: { name: string; styles: { color: string } } }) => {
  return shlkAPI('tags/update', data, true, { handledException: true }).then(clean);
};

export const deleteTag = async (data: { id: string }) => {
  return shlkAPI('tags/delete', data).then(clean);
};

export const inviteMember = async (data: { name: string; email: string; roles: Array<string>; id: string }, options?: Options) => {
  return shlkAPI('tags/member/invite', data, true, options).then(clean);
};

export const getMemberRoles = async (data: { id: string }) => {
  return shlkAPI('tags/member/roles', data);
};

export const removeMember = async (data: { user: string; id: string }) => {
  return shlkAPI('tags/member/remove', data).then(clean);
};

export const getTagPermissions = async (data: { id: string }) => {
  return shlkAPI('tags/permissions', data);
};