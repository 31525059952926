import React from 'react';
import './IntermodalCard.scss';

interface MultiModalSplitupCardProps {
  mode: 'Air' | 'Sea';
  nodeNames: string[]; // Array of 4 node names
  distances: number[]; // Array of 3 distances
  emissions: number[]; // Emissions produced by the journey
}

const MultiModalSplitupCard: React.FC<MultiModalSplitupCardProps> = ({ mode, nodeNames, distances, emissions }) => {
  if (nodeNames.length !== 4 || distances.length !== 3) {
    return <div>Invalid data provided</div>;
  }

  const transportMode = mode === 'Air' ? 'Airport' : 'Seaport';
  const icons = {
    driving: '🚗',
    mode: mode === 'Air' ? '✈️' : '⛵'
  };

  return (
    <div className="intermodal-card">
        <h2>Intermodal Splitup</h2>
      <div className="node-row bold-text">Origin: {nodeNames[0]}</div>
      <div className="distance-row">Driving to {transportMode}: <span className="bold-text">{distances[0].toFixed(2)} km, {emissions[0].toFixed(2)} kg CO<sub>2</sub></span> </div>
      <div className="node-row bold-text">{transportMode}: {nodeNames[1]}</div>
      <div className="distance-row">{mode.charAt(0).toUpperCase() + mode.slice(1)} distance: <span className="bold-text">{distances[1].toFixed(2)} km, {emissions[1].toFixed(2)} kg CO<sub>2</sub></span> </div>
      <div className="node-row bold-text">{transportMode}: {nodeNames[2]}</div>
      <div className="distance-row">Driving from {transportMode}: <span className="bold-text">{distances[2].toFixed(2)} km, {emissions[2].toFixed(2)} kg CO<sub>2</sub></span></div>
      <div className="node-row bold-text">Destination: {nodeNames[3]}</div>
    </div>
  );
};

export default MultiModalSplitupCard;
