import React, { Component } from 'react';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { fixGoogleImages } from 'utils/frame';
import './styles.scss';

interface Props {
  initialValue: string;
  callback: Function;
}

interface State {
  height: number;
}

export default class Signature extends Component<Props, State> {
  private signatureContent: any;

  constructor(props: Props) {
    super(props);
    this.state = { height : 50 };
  }

  onChangeHandler = () => {
    const content = this.signatureContent;
    fixGoogleImages(content);
    this.props.callback(content.innerHTML);
    this.setState({ height : content.clientHeight });
  };

  componentWillUnmount() {
      this.signatureContent && this.signatureContent.removeEventListener('input', this.onChangeHandler);
  }

  addListeners = (signatureContent: any) => {
      this.signatureContent = signatureContent;
      this.signatureContent.addEventListener('input', this.onChangeHandler);
  }

  render() {
    const content = `
    <html style="margin: 0; height: 100%; width: 100%;">
      <body style="margin: 0; height: 100%; width: 100%; box-sizing: border-box; background-color: #FAFAFA;">
          <div id="shlk-signature-content" contenteditable style="border: 0; outline: none; padding: 15px; box-sizing: border-box;">
            ${this.props.initialValue ? this.props.initialValue : ''}
          </div>
          <div id="shlk-email-selector" style="display: none;"></div>
      </body>
    </html>`;

    return <div className='user-field'>
      <p className='user-label'>Email signature</p>
      <div className="shlk-signature">
        <Frame className="shlk-signature-frame" mountTarget='#shlk-email-selector' initialContent={content} height={ this.state.height }>
            <FrameContextConsumer>
                {
                ({document, window}: any) => {
                    const signatureContent = document.getElementById("shlk-signature-content");
                    fixGoogleImages(signatureContent);
                    window.frameElement.height = signatureContent.clientHeight;
                    this.addListeners(signatureContent);
                    return <></>;
                }
                }
            </FrameContextConsumer>
        </Frame>
      </div>
    </div>;
  }
}