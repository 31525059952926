import React, { Component } from 'react';

import './styles.scss';

interface Props {
    text: string;
}

export default class BoxTitle extends Component<Props> {
    render() {
        return <h2 className="box-title">{ this.props.text }</h2>;
    }
}
