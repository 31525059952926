import React, { Component } from "react";
import { getUnackEvents } from "services/event/";
import { getEventIcon } from "utils/events";
import Event from "./Event";
import "./styles.scss";
import ChartWrapper from "components/Shipment/Emissions/Charts/Chart";
import {
  donughnutChartOptions1,
  donughnutChartOptions2,
} from "components/Shipment/Emissions/Charts/data";
import { getCompanyStats } from "services/company";

interface State {
  events: Array<any>;
  isMobile: any;
  currentSlide: any;
  companyStats: any;
  isLoading: any;
}

const EVENTS = ["delay", "idle", "temperature", "battery", "light"];
const OPTIONAL_EVENTS = ["collision"];

const STATUS_TITLES = {
  ok: "Everything is in order",
  warn: "You have some events to check",
};

export default class Status extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      events: [],
      isMobile: false,
      currentSlide: 0,
      companyStats: {},
      isLoading: true,
    };
  }

  async componentDidMount() {
    getUnackEvents().then((events) => this.setState({ events }));
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();
    try {
      const response = await getCompanyStats({});
      // Assuming `nearestNames` comes from the response.
      const stats = await response; // Adjust this line based on actual response structure
      this.setState({ companyStats: stats });
    } catch (error) {
      console.error("Error fetching port list:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 768; // Adjust threshold as needed
    this.setState({ isMobile });
  };
  nextSlide = () => {
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide + 1) % 2,
    }));
  };

  prevSlide = () => {
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide - 1 + 2) % 2,
    }));
  };

  sortEvents = () => {
    const events = [...EVENTS, ...OPTIONAL_EVENTS];
    const eventsByType: any = EVENTS.reduce(
      (events, event: string) => ({
        ...events,
        [event]: 0,
      }),
      {}
    );

    this.state.events.forEach(({ type }: { type: string }) => {
      const lowerCaseType: any = type.toLowerCase();

      if (events.includes(lowerCaseType)) {
        eventsByType[lowerCaseType] = ++eventsByType[lowerCaseType] || 1;
      }
    });

    return eventsByType;
  };

  render() {
    const { isMobile } = this.state;
    const { currentSlide } = this.state;
    const { companyStats, isLoading } = this.state;
    const { events } = this.state;
    console.log(companyStats, "cs");
    const totalEmissions = companyStats.emissionStats
      ? companyStats.emissionStats.totalEmissions
      : "";
    if (isLoading) {
      return <div>Loading...</div>;
    }

    const eventsByType = this.sortEvents();
    const isSomeEventUnAck = Object.values(eventsByType).some(
      (value) => !!value
    );
    const statusClass = isSomeEventUnAck ? "status-warn" : "status-ok";

    // const doughnutChartData1 = {
    //   labels: ["Air", "Water", "Land", "Rail"],
    //   datasets: [
    //     {
    //       label: "Dynamic",
    //       data: [
    //         companyStats.emissionStats
    //           ? companyStats.emissionStats.emissionsByMode.Air
    //           : "",
    //         companyStats.emissionStats
    //           ? companyStats.emissionStats.emissionsByMode.Sea
    //           : "",
    //         companyStats.emissionStats
    //           ? companyStats.emissionStats.emissionsByMode.Land
    //           : "",
    //         companyStats.emissionStats
    //           ? companyStats.emissionStats.emissionsByMode.Rail
    //           : "",
    //       ],
    //       backgroundColor: ["#B563F7", "#8AB4F8", "#FAC21B", "#FA7B57"],
    //       borderColor: ["#B563F7", "#8AB4F8", "#FAC21B", "#FA7B57"],
    //     },
    //   ],
    // };

    const isAllZero = companyStats.emissionStats
      ? Object.values(companyStats.emissionStats.emissionsByMode).every(
          (val) => val === 0
        )
      : true;

    const doughnutChartData1 = {
      labels: isAllZero ? ["No Emissions"] : ["Air", "Water", "Land", "Rail"],
      datasets: [
        {
          label: "Dynamic",
          data: isAllZero
            ? [1] // This will create a grey circle
            : [
                companyStats.emissionStats
                  ? companyStats.emissionStats.emissionsByMode.Air
                  : 0,
                companyStats.emissionStats
                  ? companyStats.emissionStats.emissionsByMode.Sea
                  : 0,
                companyStats.emissionStats
                  ? companyStats.emissionStats.emissionsByMode.Land
                  : 0,
                companyStats.emissionStats
                  ? companyStats.emissionStats.emissionsByMode.Rail
                  : 0,
              ],
          backgroundColor: isAllZero
            ? ["#D3D3D3"] // Grey color for the plain circle
            : ["#B563F7", "#8AB4F8", "#FAC21B", "#FA7B57"],
          borderColor: isAllZero
            ? ["#D3D3D3"] // Grey color for the border
            : ["#B563F7", "#8AB4F8", "#FAC21B", "#FA7B57"],
        },
      ],
    };

    const doughtnutLabel1 = {
      id: "doughnutLabel",
      afterDatasetsDraw(chart: any, args: any, plugins: any) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.font = "bold 25px Roboto Condensed";
        ctx.fillStyle = "#19575C";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          totalEmissions &&
            (totalEmissions < 1000
              ? Math.round(totalEmissions) + " kg"
              : Math.round(totalEmissions / 1000) + " t"),

          centerX,
          centerY
        );
        ctx.font = "bold 15px Roboto Condensed";
        ctx.fillText("of CO2e ", centerX, centerY + 30); // Adjust the y position as needed
        ctx.fillText("Total ", centerX, centerY - 30);
      },
    };

    const emissionValue = companyStats.emissionStats
      ? companyStats.emissionStats.totalEmissions
      : 0;
    const displayValue =
      emissionValue < 1000
        ? Math.round(emissionValue)
        : Math.round(emissionValue / 1000);

    const unit = emissionValue < 1000 ? "kg" : "tonne";

    return (
      <>
        {isLoading ? (
          <>loading</>
        ) : (
          <>
            {" "}
            {!isMobile && (
              <>
                {" "}
                <div
                  className="shlk-status shlk-widget"
                  style={{
                    display: "none",
                    flexDirection: "row",
                    width: "100%",
                    fontFamily: "  Roboto Condensed",
                    fontSize: "22px",
                    fontWeight: "bold",

                    marginBottom: "10px",
                  }}
                ></div>
                <div
                  className="shlk-status shlk-widget"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "49.1%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "  Roboto Condensed",
                        fontSize: "22px",
                        fontWeight: "bold",

                        // marginBottom: "10px",
                        height: "10%",
                      }}
                    >
                      Visibility
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "90%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="shlk-card"
                        style={{
                          height: "49%",
                          borderRadius: "10px",
                          width: "100%",
                          // marginRight: "10px",
                          // marginTop: "10px",
                          marginBottom: "0px",
                        }}
                      >
                        <div
                          className="shlk-status__body"
                          style={{ alignContent: "flex-start" }}
                        >
                          {Object.keys(eventsByType).map(
                            (eventName: string) => (
                              <Event
                                key={eventName}
                                name={eventName}
                                icon={getEventIcon(eventName)}
                                count={eventsByType[eventName]}
                              />
                            )
                          )}
                        </div>
                      </div>
                      <div
                        className="shlk-card"
                        style={{
                          height: "45%",
                          borderRadius: "10px",
                          width: "100%",
                          // marginRight: "10px",
                          background: "none",
                          // boxShadow: "none",
                          padding: "0px",
                          fontFamily: "Roboto Condensed",
                          marginBottom: "0px",
                        }}
                      >
                        <div
                          className="shlk-status__body"
                          style={{
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                        >
                          <div className="left-bottom-div">
                            <span className="label-font">
                              Completed Shipments This Month
                            </span>
                            {/* <br></br> */}
                            <span className="value-font">
                              {companyStats.visibilityStats
                                ? companyStats.visibilityStats
                                    .shipmentsCompleted > 1000
                                  ? (
                                      companyStats.visibilityStats
                                        .shipmentsCompleted / 1000
                                    ).toFixed(1) + "k"
                                  : companyStats.visibilityStats
                                      .shipmentsCompleted
                                : ""}

                              <br></br>
                              <span className="unit-font">shipments</span>
                            </span>
                          </div>
                          <div className="left-bottom-div">
                            <span className="label-font">
                              Total Ongoing Shipments
                            </span>
                            {/* <br></br> */}
                            <span className="value-font">
                              {companyStats.visibilityStats
                                ? companyStats.visibilityStats.activeShipments >
                                  1000
                                  ? (
                                      companyStats.visibilityStats
                                        .activeShipments / 1000
                                    ).toFixed(1) + "k"
                                  : companyStats.visibilityStats.activeShipments
                                : ""}
                              <br></br>
                              <span className="unit-font">shipments</span>
                            </span>
                          </div>
                          <div className="left-bottom-div">
                            <span className="label-font">
                              Total Goods Transported
                            </span>
                            {/* <br></br> */}
                            <span className="value-font">
                              {companyStats.visibilityStats
                                ? companyStats.visibilityStats.totalWeight <
                                  1000
                                  ? Math.round(
                                      companyStats.visibilityStats.totalWeight
                                    )
                                  : (
                                      companyStats.visibilityStats.totalWeight /
                                      1000
                                    ).toFixed(1)
                                : ""}

                              <br></br>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "#3D9AA1",
                                  fontWeight: "normal",
                                }}
                              >
                                {companyStats.visibilityStats.totalWeight < 1000
                                  ? "tonne"
                                  : "kilotonnes"}
                              </span>
                            </span>
                          </div>

                          <div className="left-bottom-div">
                            <span className="label-font">
                              Total Distance Travelled This Month
                            </span>
                            {/* <br></br> */}
                            <span className="value-font">
                              {companyStats.visibilityStats
                                ? (
                                    companyStats.visibilityStats.totalDistance /
                                    1000
                                  ).toFixed(0)
                                : ""}
                              <br></br>
                              <span className="unit-font">km</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "29.1%" }}>
                    <span
                      style={{
                        fontFamily: "  Roboto Condensed",
                        fontSize: "22px",
                        fontWeight: "bold",

                        marginBottom: "10px",
                      }}
                    >
                      Emissions
                    </span>
                    <div
                      style={{
                        backgroundColor: "white",
                        boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                        width: "100%",
                        // padding: "15px",
                        height: "330px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                        borderRadius: "10px",
                        // marginLeft:"3px"
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        className="responsive-width"
                        style={{
                          borderRadius: "0px",
                          // width: "80%",
                          marginRight: "20px",
                          marginLeft: "20px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          className="shlk-status__body"
                          style={{ alignContent: "flex-start" }}
                        >
                          {" "}
                          <ChartWrapper
                            type={"doughnut"}
                            data={doughnutChartData1}
                            options={donughnutChartOptions2}
                            plugins={[doughtnutLabel1]}
                          />
                        </div>
                      </div>
                      <div style={{ width: "30%" }}>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginRight: "5px",
                            // marginLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#FAC21B",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              fontFamily: "Roboto Condensed",
                              // Adjust as needed for spacing
                            }}
                          ></div>
                          <span style={{ fontFamily: "Roboto Condensed" }}>
                            LAND
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#8AB4F8",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span style={{ fontFamily: "Roboto Condensed" }}>
                            SEA
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#B563F7",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                              fontFamily: "Roboto CondeNDnsed",
                            }}
                          ></div>
                          <span style={{ fontFamily: "Roboto Condensed" }}>
                            AIR
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#FA7B57",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                              fontFamily: "Roboto Condensed",
                            }}
                          ></div>
                          <span style={{ fontFamily: "Roboto Condensed" }}>
                            RAIL
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "19.1%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ height: "10%" }}></span>
                    <div
                      style={{
                        height: "90%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                          height: "49%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          borderRadius: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "10px",
                            textAlign: "center",
                            fontFamily: "Roboto Condensed",
                          }}
                        >
                          Total CO2 Emissions This Month
                        </span>
                        {/* <br></br> */}
                        <span
                          style={{
                            fontSize: "35px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "10px",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            fontFamily: "Roboto Condensed",
                          }}
                        >
                          {displayValue}
                          <br></br>
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: "normal",
                              color: "#3D9AA1",
                              fontFamily: "Roboto Condensed",
                            }}
                          >
                            {unit}
                          </span>
                        </span>
                      </div>

                      <div
                        style={{
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                          height: "45%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          // marginTop: "15px",
                          borderRadius: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "10px",
                            textAlign: "center",
                            fontFamily: "Roboto Condensed",
                          }}
                        >
                          Average Carbon Intensity
                        </span>
                        {/* <br></br> */}
                        <span
                          style={{
                            fontSize: "35px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "10px",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            fontFamily: "Roboto Condensed",
                          }}
                        >
                          {companyStats.emissionStats
                            ? companyStats.emissionStats.emissionsByTonneKm.toFixed(
                                2
                              )
                            : ""}
                          <br></br>
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: "normal",
                              color: "#3D9AA1",
                              fontFamily: "Roboto Condensed",
                            }}
                          >
                            g CO2 / tonne km
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {isMobile && (
              <>
                {currentSlide === 0 && (
                  <>
                    {" "}
                    <div
                      style={{
                        fontFamily: "Roboto Condensed",
                        fontSize: "22px",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                    >
                      <span style={{ width: "50%" }}>Visibility</span>
                    </div>
                    <div
                      className="shlk-card"
                      style={{
                        // height: "170px",
                        borderRadius: "5px",
                        width: "100%",
                        marginRight: "10px",
                        marginTop: "10px",
                        fontFamily: "Roboto Condensed",
                      }}
                    >
                      <div
                        className="shlk-status__body"
                        style={{ alignContent: "flex-start", display: "flex" }}
                      >
                        {Object.keys(eventsByType).map((eventName: string) => (
                          <Event
                            key={eventName}
                            name={eventName}
                            icon={getEventIcon(eventName)}
                            count={eventsByType[eventName]}
                          />
                        ))}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                        fontFamily: "Roboto Condensed",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                          width: "48%",
                          // marginBottom: "15px",
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "5px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "5px",
                            textAlign: "center",
                            height: "40%",
                          }}
                        >
                          Completed Shipments This Month
                        </span>
                        {/* <br></br> */}
                        <span
                          style={{
                            fontSize: "23px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "5px",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          {companyStats.visibilityStats
                            ? companyStats.visibilityStats.shipmentsCompleted >
                              1000
                              ? (
                                  companyStats.visibilityStats
                                    .shipmentsCompleted / 1000
                                ).toFixed(1) + "k"
                              : companyStats.visibilityStats.shipmentsCompleted
                            : ""}

                          <br></br>
                          <span style={{ fontSize: "15px", color: "#3D9AA1" }}>
                            shipments
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                          width: "48%",
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "5px",
                          gridTemplateColumns: "1fr 1fr",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          // textAlign: "center"
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "5px",
                            textAlign: "center",
                            height: "40%",
                            padding:"0 5px",
                          }}
                        >
                          Total Ongoing Shipments
                        </span>
                        {/* <br></br> */}
                        <span
                          style={{
                            fontSize: "23px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "5px",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          {companyStats.visibilityStats
                            ? companyStats.visibilityStats.activeShipments >
                              1000
                              ? (
                                  companyStats.visibilityStats.activeShipments /
                                  1000
                                ).toFixed(1) + "k"
                              : companyStats.visibilityStats.activeShipments
                            : ""}
                          <br></br>
                          <span style={{ fontSize: "15px", color: "#3D9AA1" }}>
                            shipments
                          </span>
                        </span>
                      </div>{" "}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                        fontFamily: "Roboto Condensed",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                          width: "48%",
                          // marginBottom: "15px",
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "5px",
                          gridTemplateColumns: "1fr 1fr",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          // textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "5px",
                            textAlign: "center",
                            height: "40%",
                          }}
                        >
                          Total Goods Transported
                        </span>
                        {/* <br></br> */}
                        <span
                          style={{
                            fontSize: "23px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "5px",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          {companyStats.visibilityStats
                            ? companyStats.visibilityStats.totalWeight < 1000
                              ? Math.round(
                                  companyStats.visibilityStats.totalWeight
                                )
                              : (
                                  companyStats.visibilityStats.totalWeight /
                                  1000
                                ).toFixed(1)
                            : ""}

                          <br></br>
                          <span style={{ fontSize: "15px", color: "#3D9AA1" }}>
                            {companyStats.visibilityStats.totalWeight < 1000
                              ? "tonne"
                              : "kilotonnes"}
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                          width: "48%",
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "5px",
                          gridTemplateColumns: "1fr 1fr",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          // textAlign: "center"
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#19575C",
                            whiteSpace: "normal",
                            // paddingLeft: "5px",
                            textAlign: "center",
                            height: "40%",
                          }}
                        >
                          Total Distance Travelled This Month
                        </span>
                        {/* <br></br> */}
                        <span
                          style={{
                            fontSize: "23px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "5px",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          {companyStats.visibilityStats
                            ? (
                                companyStats.visibilityStats.totalDistance /
                                1000
                              ).toFixed(0)
                            : ""}
                          <br></br>
                          <span style={{ fontSize: "15px", color: "#3D9AA1" }}>
                            km
                          </span>
                        </span>
                      </div>{" "}
                    </div>
                  </>
                )}
                {currentSlide === 1 && (
                  <>
                    <div
                      style={{
                        fontFamily: "  Roboto Condensed",
                        fontSize: "22px",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                    >
                      <span style={{ width: "50%", marginBottom: "10px" }}>
                        Emissions
                      </span>
                    </div>
                    <div
                      style={
                        {
                          // width: "100%",
                          // // padding: "15px",
                          // height: "300px",
                          // borderRadius: "5px",
                        }
                      }
                    >
                      <div
                        className=""
                        style={{
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                          borderRadius: "0px",
                          width: "89",
                          // marginRight: "20px",
                          // marginLeft: "20px",
                          // marginTop: "10px",
                          // marginBottom: "20px",
                        }}
                      >
                        <div
                          className="shlk-status__body"
                          style={{
                            alignContent: "flex-start",
                            padding: "20px",
                          }}
                        >
                          {" "}
                          <ChartWrapper
                            type={"doughnut"}
                            data={doughnutChartData1}
                            options={donughnutChartOptions1}
                            plugins={[doughtnutLabel1]}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",

                          paddingTop: "10px",
                          paddingBottom: "10px",
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "5px",
                          width: "48%",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#19575C",
                            whiteSpace: "normal",
                            // paddingLeft: "5px",
                            fontFamily: "Roboto Condensed",
                            textAlign: "center",
                            padding: "5px",
                            height: "40%",
                          }}
                        >
                          Total CO2 Emissions This Month
                        </span>
                        <span
                          style={{
                            fontSize: "23px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "5px",
                            display: "flex",
                            flexDirection: "column",
                            fontFamily: "Roboto Condensed",
                            textAlign: "center",
                          }}
                        >
                          {displayValue}
                          <br></br>
                          <span style={{ fontSize: "15px", color: "#3D9AA1" }}>
                            {unit}
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",

                          paddingTop: "10px",
                          paddingBottom: "10px",
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "5px",
                          width: "48%",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#19575C",
                            whiteSpace: "normal",
                            // paddingLeft: "5px",
                            fontFamily: "Roboto Condensed",
                            textAlign: "center",
                            padding: "5px",
                            height: "40%",
                          }}
                        >
                          Average Carbon Intensity
                        </span>
                        <span
                          style={{
                            fontSize: "23px",
                            fontWeight: "bold",
                            color: "#19575C",
                            // paddingLeft: "5px",
                            display: "flex",
                            flexDirection: "column",
                            fontFamily: "Roboto Condensed",
                            textAlign: "center",
                          }}
                        >
                          {companyStats.emissionStats
                            ? companyStats.emissionStats.emissionsByTonneKm.toFixed(
                                2
                              )
                            : ""}
                          <br></br>
                          <span style={{ fontSize: "15px", color: "#3D9AA1" }}>
                            g CO2 / tonne km
                          </span>
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    background: "white",
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                    borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    onClick={this.prevSlide}
                    style={{
                      fontSize: "30px",
                    }}
                  >
                    {"<"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px", // Space between dots
                    }}
                  >
                    <span
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor:
                          currentSlide === 0 ? "#19575C" : "#C4C4C4",
                        display: "inline-block",
                      }}
                    />
                    <span
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor:
                          currentSlide === 1 ? "#19575C" : "#C4C4C4",
                        display: "inline-block",
                      }}
                    />
                  </div>

                  <div
                    onClick={this.nextSlide}
                    style={{
                      fontSize: "30px",
                    }}
                  >
                    {">"}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
