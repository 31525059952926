import React, { Component } from "react";

import Page from "components/ControlBars/Page";
import FleetWidget from "components/Widgets/Fleet";
import TrackersWidget from "components/Widgets/Trackers";
import Shipments from "components/Widgets/Shipments";
import Status from "components/Widgets/Status";
import HasPermissions from "components/Permissions/Has";
import Footer from "components/ControlBars/Footer";
import "./styles.scss";
interface AuthHomeState {
  currentSlide: any;
  isMobile: any;
}

export default class AuthHome extends Component<any, AuthHomeState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentSlide: 0,
      isMobile: false,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 768; // Adjust threshold as needed
    this.setState({ isMobile });
  };
  nextSlide = () => {
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide + 1) % 2,
    }));
  };

  prevSlide = () => {
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide - 1 + 2) % 2,
    }));
  };
  render() {
    const { currentSlide } = this.state;
    const { isMobile } = this.state;
    return (
      <Page className="shlk-home-auth">
        <HasPermissions permission="VIEW_EVENTS">
          <Status />
        </HasPermissions>

        <HasPermissions permission="VIEW_HOME">
          <Shipments />
        </HasPermissions>

        {isMobile && (
          <div
            className="shlk-widget-double"
            style={{
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
              borderRadius: isMobile ? "10px" : "",
              padding: "5px",
              width: isMobile ? "98%" : "100%",
            }}
          >
            {currentSlide === 0 && (
              <HasPermissions permission="VIEW_TRACKERS">
                <TrackersWidget />
              </HasPermissions>
            )}
            {currentSlide === 1 && (
              <HasPermissions permission="VIEW_FLEET">
                <FleetWidget />
              </HasPermissions>
            )}
          </div>
        )}
        {isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              background: "white",
              boxShadow: " rgba(0, 0, 0, 0.12) 0px 4px 16px",
              borderRadius: " 5px",
              marginBottom: " 15px",
            }}
          >
            <div
              onClick={this.prevSlide}
              style={{
                fontSize: "30px",
              }}
            >
              {"<"}
            </div>
            <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px", // Space between dots
    }}
  >
    <span
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: currentSlide === 0 ? "#19575C" : "#C4C4C4",
        display: "inline-block",
      }}
    />
    <span
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: currentSlide === 1 ? "#19575C" : "#C4C4C4",
        display: "inline-block",
      }}
    />
  </div>

            <div
              onClick={this.nextSlide}
              style={{
                fontSize: "30px",
              }}
            >
              {">"}
            </div>
          </div>
        )}

        {!isMobile && (
          <div className="shlk-widget-double" style={{marginBottom:"-15px"}}>
            <HasPermissions permission="VIEW_TRACKERS">
              <TrackersWidget />
            </HasPermissions>

            <HasPermissions permission="VIEW_FLEET">
              <FleetWidget />
            </HasPermissions>
          </div>
        )}

        <Footer />
      </Page>
    );
  }
}
