import React, { Component, PropsWithChildren } from 'react';
import { getCookieData } from 'utils/data/cookies';

type Props = PropsWithChildren<{}>

export default class Logged extends Component<Props> {
    render() {
        const token = getCookieData('authToken');

        if(!token) return <></>;
        return this.props.children;
    }
}