import React, { ChangeEvent, Component, createRef } from 'react';
import { getDatetimeLocal, getUTCFormat } from 'utils/date';
import './styles.scss';

type HTMLInputDatetimeElement = HTMLInputElement & {
    showPicker: Function;

}

interface Props {
    name: string;
    onChange: Function;
    value?: Date | null;
    label?: string;
    className?: string;
    min?: Date | null;
    max?: Date | null;
}

export default class Calendar extends Component<Props> {
    private calendarRef = createRef<HTMLInputDatetimeElement>();

    onFocusCalendar = () => {
        if(this.calendarRef && this.calendarRef.current) {
            this.calendarRef.current.showPicker();
        }
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputDatetimeElement>) => {
        const date = event.target.value;

        this.props.onChange(date ? new Date(date) : null);
    }

    renderInput() {
        const { label } = this.props;

        return <div className="input-calendar-container" onClick={this.onFocusCalendar}>
            { label &&
                <label className="input-label" htmlFor={this.props.name}>{label}</label>
            }
            <input type="datetime-local"
                    ref={this.calendarRef}
                    className='input-calendar'
                    onChange={this.onChangeHandler}
                    min={this.props.min ? getUTCFormat(this.props.min) : undefined}
                    max={this.props.max ? getUTCFormat(this.props.max) : undefined}
                    name={this.props.name} value={this.props.value ? getDatetimeLocal(this.props.value) : ''}/>
        </div>;
    }
    render() {
        return <div className={`form-input-calendar ${this.props.className || ''}`}>
            { this.renderInput() }
        </div>;
    }
}