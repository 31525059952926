// import React, { Component } from "react";
// import { RouteComponentProps, withRouter } from "react-router-dom";
// import BoxTitle from "components/Box/Title";
// import Button from "components/Inputs/Button";
// import HasPermissions from "components/Permissions/Has";
// import ContentLoader from "components/Loading/ContentLoader";
// import BoxButton from "components/Box/Button";
// import Icon from "components/Icon";
// import { format } from "utils/roles";
// import { generateAbsoultePath } from "utils/navigation";
// import { removeMember } from "services/shipments";
// import { trackException } from "utils/analytics";
// import { ShipmentContext } from "../context";
// import "./styles.scss";

// interface State {
//   loading: any;
// }
// interface Props {
//   resetShareTab: () => void; // New prop for opening the Share tab
// }
// class Share extends Component<RouteComponentProps, State, Props> {
//   constructor(props: RouteComponentProps) {
//     super(props);

//     this.state = {
//       loading: false,
//     };
//   }

//   add = () => {
//     const unlisten = this.props.history.listen((location: any, action: any) => {
//       if (action === "POP") {
//         unlisten();
//         this.setState({ loading: true });
//         this.context.hardRefresh();
//       }
//     });
//   };

//   copy = (link: string) => {
//     navigator.clipboard
//       .writeText(link)
//       .then(() => {
//         window.alert("The URL has been copied to your clipboard");
//       })
//       .catch((error) => {
//         trackException(error.stack || error.message);
//         window.alert("Sorry, there was an error while copying");
//       });
//   };

//   removeMember = (member: any) => {
//     if (
//       window.confirm(
//         `Are you sure you want to remove ${member.user.email} from this shipment?`
//       )
//     ) {
//       this.setState({ loading: true });

//       removeMember({
//         user: member.user._id,
//         id: this.context.shipment._id,
//       }).then(() => {
//         const shipment = this.context.shipment;
//         shipment.members = shipment.members.filter((m: any) => m !== member);

//         this.context.updateShipment(shipment);
//         this.setState({ loading: false });
//       });
//     }
//   };

//   render() {
//     console.log("share tab ");
//     const resetShareTab = this.props;
//     const shipment = this.context.shipment;
//     const link = generateAbsoultePath(
//       `/shipments/${this.context.shipment._id}/public`
//     );

//     return (
//       <div className="shipment-share bc-wrapper">
//         <BoxTitle text="Share" />

//         <ContentLoader
//           loading={this.state.loading}
//           content={() => (
//             <>
//               <div className="share-company share-group">
//                 <h4 className="share-public-title">
//                   Company: {shipment.company.name}
//                 </h4>
//               </div>
//               <div className="share-members share-group bc-stretch">
//                 <div className="share-members-header">
//                   <h4 className="share-public-title">Users</h4>

//                   <HasPermissions
//                     permission="INVITE_MEMBERS"
//                     granted={this.context.permissions}
//                   >
//                     <Button
//                       className="share-add"
//                       category="ShipmentShare"
//                       action="Add user"
//                       onClick={this.add}
//                       to={`/shipments/${this.context.shipment._id}#share`}
//                     >
//                       <Icon size={20} name="person_add" />
//                     </Button>
//                   </HasPermissions>
//                 </div>
//                 <div className="share-members-wrapper">
//                   {shipment.members.map((member: any, index: number) => {
//                     return (
//                       <div
//                         className="share-member"
//                         key={`ShipmentMember_${index}`}
//                       >
//                         <p className="share-member-field">
//                           <b>Email:</b>
//                           <br />
//                           {member.user.email}
//                         </p>
//                         <p className="share-member-field">
//                           <b>Roles:</b>
//                           <br />
//                           {member.permissions.roles.map(
//                             (role: string, index: number) =>
//                               `${index > 0 ? ", " : ""}${format(role)}`
//                           )}
//                         </p>
//                         <HasPermissions
//                           permission="INVITE_MEMBERS"
//                           granted={this.context.permissions}
//                         >
//                           <Button
//                             className="share-remove"
//                             category="ShipmentShare"
//                             action="Remove user"
//                             onClick={() => this.removeMember(member)}
//                           >
//                             <span>Remove</span>
//                           </Button>
//                         </HasPermissions>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>

//               <div className="share-public share-group">
//                 <h4 className="share-public-title">Public Tracking</h4>
//                 <div className="info-btn-wrapper bc-wrapper">
//                   <BoxButton
//                     category="ShipmentShare"
//                     action="Click Go to Public Tracking"
//                     label={this.context.shipment._id}
//                     to={`/shipments/${this.context.shipment._id}/public`}
//                     text="Go to Public Tracking"
//                   />
//                   <BoxButton
//                     category="ShipmentShare"
//                     action="Click Copy Link"
//                     label={this.context.shipment._id}
//                     onClick={() => this.copy(link)}
//                     icon="content_copy"
//                     text="Copy Link"
//                   />
//                 </div>
//               </div>
//             </>
//           )}
//         />
//       </div>
//     );
//   }
// }

// Share.contextType = ShipmentContext;

// export default withRouter(Share);

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BoxTitle from "components/Box/Title";
import Button from "components/Inputs/Button";
import HasPermissions from "components/Permissions/Has";
import ContentLoader from "components/Loading/ContentLoader";
import BoxButton from "components/Box/Button";
import Icon from "components/Icon";
import { format } from "utils/roles";
import { generateAbsoultePath } from "utils/navigation";
import { removeMember } from "services/shipments";
import { trackException } from "utils/analytics";
import { ShipmentContext } from "../context";
import "./styles.scss";

interface State {
  loading: boolean;
}
interface Props {
  resetShareTab: () => void; // New prop for opening the Share tab
}

class Share extends Component<RouteComponentProps & Props, State> {
  constructor(props: RouteComponentProps & Props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentWillUnmount() {
    // Reset Share tab when leaving the page
    if (this.props.resetShareTab) {
      this.props.resetShareTab();
    }
  }

  add = () => {
    const unlisten = this.props.history.listen((location: any, action: any) => {
      if (action === "POP") {
        unlisten();
        this.setState({ loading: true });
        this.context.hardRefresh();
      }
    });
  };

  copy = (link: string) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        window.alert("The URL has been copied to your clipboard");
      })
      .catch((error) => {
        trackException(error.stack || error.message);
        window.alert("Sorry, there was an error while copying");
      });
  };

  removeMember = (member: any) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${member.user.email} from this shipment?`
      )
    ) {
      this.setState({ loading: true });

      removeMember({
        user: member.user._id,
        id: this.context.shipment._id,
      }).then(() => {
        const shipment = this.context.shipment;
        shipment.members = shipment.members.filter((m: any) => m !== member);

        this.context.updateShipment(shipment);
        this.setState({ loading: false });
      });
    }
  };

  render() {
    console.log("share tab ");
    const shipment = this.context.shipment;
    const link = generateAbsoultePath(
      `/shipments/${this.context.shipment._id}/public`
    );

    return (
      <div className="shipment-share bc-wrapper">
        <BoxTitle text="Share" />

        <ContentLoader
          loading={this.state.loading}
          content={() => (
            <>
              <div className="share-company share-group" style={{paddingTop:"15px"}}>
                <h4 className="share-public-title">
                  Company: {shipment.company.name}
                </h4>
              </div>
              <div className="share-members share-group bc-stretch" style={{paddingTop:"10px"}}>
                <div className="share-members-header">
                  <h4 className="share-public-title">Users</h4>

                  <HasPermissions
                    permission="INVITE_MEMBERS"
                    granted={this.context.permissions}
                  >
                    <Button
                      className="share-add"
                      category="ShipmentShare"
                      action="Add user"
                      onClick={this.add}
                      to={`/shipments/${this.context.shipment._id}#share`}
                    >
                      <Icon size={20} name="person_add" />
                    </Button>
                  </HasPermissions>
                </div>
                <div className="share-members-wrapper">
                  {shipment.members.map((member: any, index: number) => {
                    return (
                      <div
                        className="share-member"
                        key={`ShipmentMember_${index}`}
                      >
                        <p className="share-member-field">
                          <b>Email:</b>
                          <br />
                          {member.user.email}
                        </p>
                        <p className="share-member-field">
                          <b>Roles:</b>
                          <br />
                          <div style={{display:"flex", width:"100%", alignItems:"flex-end"}}>
                            <p style={{width:"80%", margin:"0px"}}>
                          {member.permissions.roles.map(
                            (role: string, index: number) =>
                              `${index > 0 ? ", " : ""}${format(role)}`
                          )}</p>
                          <p style={{width:"20%", margin:"0px"}}>
                           <Button
                            className="share-remove"
                            category="ShipmentShare"
                            action="Remove user"
                            onClick={() => this.removeMember(member)}
                          >
                            <span>Remove</span>
                          </Button></p>
                          </div>
                     
                        </p>
                        <HasPermissions
                          permission="INVITE_MEMBERS"
                          granted={this.context.permissions}
                        >
                         
                        </HasPermissions>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="share-public share-group">
                <h4 className="share-public-title">Public Tracking</h4>
                <div className="info-btn-wrapper bc-wrapper">
                  <BoxButton
                    category="ShipmentShare"
                    action="Click Go to Public Tracking"
                    label={this.context.shipment._id}
                    to={`/shipments/${this.context.shipment._id}/public`}
                    text="Go to Public Tracking"
                  />
                  <BoxButton
                    category="ShipmentShare"
                    action="Click Copy Link"
                    label={this.context.shipment._id}
                    onClick={() => this.copy(link)}
                    icon="content_copy"
                    text="Copy Link"
                  />
                </div>
              </div>
            </>
          )}
        />
      </div>
    );
  }
}

Share.contextType = ShipmentContext;

export default withRouter(Share);
