import React, { Component } from 'react';

import Box from 'components/Box';
import Page from 'components/ControlBars/Page';
import VesselMap, { MapSettings } from 'components/GoogleMap/VesselMap';
import AllFleet from './All';
import LoadedVessels from './Loaded';

import './styles.scss';

type State = {
  mapSettings: MapSettings;
};

export default class Fleet extends Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      mapSettings: {
        vessels: [],
        popup: null,
        open: null
      }
    };
  }

  updateMapSettings = (mapSettings: MapSettings) => {
    this.setState({ mapSettings });
  }

  mapClickCallback = (key: string, status: string) => {
    this.setState({ mapSettings: {
      ...this.state.mapSettings,
      open: (status === "close" ? null : key)
    }});
  }

  render() {
    return (
      <Page className="shlk-fleet" type="map">
        <Box menu={[
          {
            type : "all",
            render: () => <AllFleet
                updateMapSettings={this.updateMapSettings}
                mapSettings={this.state.mapSettings}
              ></AllFleet>,
            icon: "vessel"
          },
          {
            type : "loaded",
            render: () => <LoadedVessels
                updateMapSettings={this.updateMapSettings}
                mapSettings={this.state.mapSettings}
              ></LoadedVessels>,
            icon: "route"
          }
        ]}/>

        <VesselMap
          vessels={this.state.mapSettings.vessels}
          open={this.state.mapSettings.open}
          popup={this.state.mapSettings.popup}
          click={this.mapClickCallback}
        />
      </Page>
    );
  }
}

