import React, { PropsWithChildren } from 'react';
import { AxiosError } from 'axios';
import { withRouter, RouteComponentProps } from 'react-router';
import Button from 'components/Inputs/Button';
import Page from 'components/ControlBars/Page';
import { logOut } from 'services/logout';
import { trackException } from 'utils/analytics';
import './styles.scss';

interface Message {
  [key: string]: string;
}

const DEFAULT_MESSAGES: Message = {
  type: 'Something went wrong.',
  info: 'Please, try again in a few minutes.'
};

const MESSAGE_PER_STATUS: Message = {
  403: 'You do not have permission to see this. Please contact your admin.',
  404: 'We could not find this page.'
};

interface State {
  hasError: boolean;
  error: Error | AxiosError | null;
}

type Props = RouteComponentProps & PropsWithChildren<{}>;

const getErrorData = (error: State['error']) => {
  let isUnexpected = true;
  let errorType = DEFAULT_MESSAGES.type;
  let errorInfo = DEFAULT_MESSAGES.info;

  if (error) {
    if('response' in error) {
      error as AxiosError;
      const response = error.response || error.request;

      if(response.data instanceof String) {
        errorInfo = response.data;
      } else if(response.data instanceof Object) {
        errorInfo = response.data.msg;
      } else {
        errorInfo = MESSAGE_PER_STATUS[response.status];
      }

      isUnexpected = response.status >= 500;
      errorType = `${response.status} ${response.statusText}`;
    } else {
      errorType = `${error.name}`;
      errorInfo = `${error.message}`;
    }
  }

  return { errorType, errorInfo, isUnexpected };
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any) {
    trackException(error.stack || error.message);
  }

  globalErrorHandler(event: any) {
    this.setState({ hasError: true, error: event.detail  });
  }

  componentDidMount() {
    window.addEventListener('global-error', this.globalErrorHandler.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('global-error', this.globalErrorHandler.bind(this));
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    const { errorType, errorInfo, isUnexpected } = getErrorData(this.state.error);

    return (
      <Page className="shlk-error-boundary" type="error">
        <div className="error-boundary-wrapper">
          <div className="box-info">
            <div className="top-info">
              <h1><span>Oooops: </span>{ errorType }</h1>
              <h3>{ errorInfo }</h3>
              { isUnexpected && <h4>We are working to fix the issue as soon as possible.</h4> }
            </div>
            <div className="bottom-info">
              <h5>If you need support, please reach us at <a href="mailto:support@naxxar.uk">support@naxxar.uk</a></h5>
            </div>
            <div className="error-boundary-buttons">
              <Button
                category="ErrorBoundary"
                action="Click"
                label="Go to the Homepage"
                onClick={() => {
                  this.setState({ hasError: false });
                  this.props.history.push('/');
                }}
              >
                Go to the Homepage
              </Button>
              <Button
                category="ErrorBoundary"
                action="Click"
                label="Logout"
                onClick={() => {
                  this.setState({ hasError: false });
                  logOut();
                }}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </Page>
      );
    }
}

export default withRouter(ErrorBoundary);