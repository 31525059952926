import { appendScript } from 'utils/functions';
import { RouteParams, Dimensions } from 'utils/analytics';

const SRC = 'https://www.googletagmanager.com/gtag/js';


//Specify google analaytics identifier
const CODE_PER_PROPERTY: any = {
    'app.naxxar.uk': ['G-DB5J8YBFSH']
};

/**
 * Default configuration for Google Analytics.
 */
const DEFAULT_CONFIG = {
    send_page_view: false,
    custom_map: {
        dimension1: 'shipment_status'
    }
};

export default {
    /**
     * Initializes the Google Analytics tracking.
     * @param {Object} options - The options for initialization.
     * @param {string | null} options.userId - The user ID.
     */
    init({ userId }: { userId: string | null }) {
        const code = CODE_PER_PROPERTY[window.location.hostname];

        if((!window.dataLayer || !window.gtag) && code) {
            const codes = CODE_PER_PROPERTY[window.location.hostname];
            const src = `${SRC}?id=${codes.join(',')}`;

            const config = Object.assign(DEFAULT_CONFIG, { user_id: userId });

            window.dataLayer = window.dataLayer || [];
            window.gtag = window.gtag || function(){ window.dataLayer.push(arguments); }; // eslint-disable-line prefer-rest-params

            window.gtag('js', new Date());

            codes.forEach((code: string) => window.gtag('config', code, config));

            appendScript(src);
        }
    },

    /**
     * Sets the user ID for Google Analytics.
     * @param userId The user ID to set.
     */
    setAnalyticsUserId(userId: string) {
        window.gtag('set', { user_id: userId });
    },

    /**
     * Tracks a page view in Google Analytics.
     * 
     * @param routeParams - The route parameters.
     * @param dimensions - The dimensions for the page view event.
     */
    trackPageview(routeParams: RouteParams, dimensions: Dimensions) {
        window.gtag('set', routeParams);
        window.gtag('event', 'page_view', dimensions);
    },

    /**
     * Tracks an event using Google Analytics.
     * 
     * @param category - The category of the event.
     * @param action - The action of the event.
     * @param label - The label of the event.
     */
    trackEvent(category: string, action: string, label: string) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label
        });
    },

    /**
     * Tracks an exception in Google Analytics.
     * 
     * @param description - The description of the exception.
     * @param fatal - Indicates whether the exception is fatal or not.
     */
    trackException(description: string, fatal: boolean) {
        window.gtag('event', 'exception', { description, fatal });
    }
};