import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import './styles.scss';

interface Props {
  shipment: any;
}

export default class ReportFooter extends Component<Props> {
  render() {
    return (
      <tfoot className='report-tfoot'>
        <tr>
          <td>
            <div className='report-footer-spacer'></div>
            <div className='report-footer'>
              <ReactSVG className="footer-logo" src="/assets/svg/logo.svg" />
              <div>
                <p>Naxxar Technology Ltd</p>
                <p><a target='_blank' rel="noopener noreferrer" href="https://www.naxxar.uk">www.naxxar.uk</a></p>
                <p><a target='_blank' rel="noopener noreferrer" href="mailto:support@naxxar.uk">support@naxxar.uk</a></p>
                <p>+44 7506 028073</p>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    );
  }
}