import React, { Component } from 'react';
import QRCode from 'qrcode';
import ContentLoader from 'components/Loading/ContentLoader';
import { generateAbsoultePath } from 'utils/navigation';
import './styles.scss';

interface Props {
  shipment: any;
}

type State = {
  qr: any;
};


export default class ReportQR extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { qr: null };
  }

  componentDidMount() {
    QRCode.toDataURL(generateAbsoultePath(`/shipments/${this.props.shipment._id}/public`), { color: { dark: '#19255A', light: '#0000' }})
          .then((url: any) => this.setState({ qr: url }));
  }

  render() {
    const link = generateAbsoultePath(`/shipments/${this.props.shipment._id}/public`);

    return (
      <div className='report-qr'>
        <ContentLoader loading={!this.state.qr} content={() =>
          <img className="report-qr-img" alt="report_qr" src={this.state.qr} />
        }/>
        <p className='report-qr-caption'><a target="_blank" rel="noreferrer" href={link}>More info</a></p>
      </div>
    );
  }
}