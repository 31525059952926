import React, { Component } from 'react';

interface Props {
    vessel: any;
    children?: JSX.Element;
}

export default class VesselPopup extends Component<Props> {
  render() {
    const measurement = this.props.vessel.lastMeasurement;
    const date = new Date(Date.parse(measurement.time));

    return (<div className="shlk-vessel__popup">
      <h4 className="vessel-popup__title">{this.props.vessel.name} {this.props.vessel.active ? '- Tracker onboard' : ''}</h4>
      <div className="vessel-popup__info">
        <div className="info-field"><b>MMSI:</b> {this.props.vessel.mmsi}</div>
        <div className="info-field"><b>Destination:</b> {measurement.dest}</div>
        <div className="info-field"><b>ETA:</b> {measurement.eta}</div>
        <p><b>Last update:</b> {date.toLocaleString()}</p>
      </div>
      { this.props.children }
    </div>);
  }
}
