import { shlkAPI, clean } from "services/api";

export const getAvailableTrackers = async () => {
  return shlkAPI("trackers/available");
};

export const getTrackers = async (data?: any) => {
  return shlkAPI("trackers/list", data);
};

export const getFreeTrackers = async () => {
  return shlkAPI("trackers/free");
};

export const assignTracker = async (id: number, email: string) => {
  return shlkAPI("trackers/assign", { id, email }).then(clean);
};

export const getTracker = async (data: { identifier: string }) => {
  return shlkAPI("trackers/get", data);
};

export const getTrackerHistory = async (data: {
  identifier: string;
  limit?: number;
  start?: string;
  end?: string;
}) => {
  return shlkAPI("trackers/history", data, true, { method: "GET" });
};

export const getTransports = async (data: { id: string }) => {
  return shlkAPI("trackers/transports", data);
};

export const getTrackerPublic = async (data: { id: string }) => {
  return shlkAPI("trackers/public", data);
};

export const getAllTrackers = async () => {
  return shlkAPI("trackers/all");
};

export const getAllMeasurements = async (data: { identifier: string }) => {
  return shlkAPI("trackers/measurements", data);
};

export const updateTags = async (data: { id: string; tags: any[] }) => {
  return shlkAPI("trackers/tags", data).then(clean);
};

export const updateTracker = async (data: { id: string; update: any }) => {
  return shlkAPI("trackers/update", [data]).then(clean);
};

export const updateTrackers = async (data: { id: string; update: any }[]) => {
  return shlkAPI("trackers/update", data).then(clean);
};
