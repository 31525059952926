import React, { Component, PropsWithChildren } from 'react';
import Icon from 'components/Icon';
import './styles.scss';

type Props = PropsWithChildren<{
    className?: string;
    selected?: boolean;
    disabled?: boolean;
    icon?: string;
}>

export default class BoxOption extends Component<Props> {
    static defaultProps = { className: '' };
    render() {
        let className = this.props.className + ' box-option';

        if(this.props.selected) className += ' box-option__selected';
        if(this.props.disabled) className += ' box-option__disabled';

        return (
            <div className={className}>
                <div className="option-content">
                    { this.props.children }
                </div>
                <Icon className="option-icon" name={this.props.icon || 'arrow_forward_ios'} size={20} type='outlined'/>
            </div>
        );
    }
}
