import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import Selectable from "components/Inputs/Selectable";
import { format } from "utils/roles";
import PermissionOption from "./Option";
import "./styles.scss";

type Role = {
  name: string;
  permissions: Array<string>;
};

interface Props {
  mandatory: Array<string>;
  selected: Array<string>;
  roles: Array<Role>;
  callback: any;
}

interface State {
  open: boolean;
}

const getAllPermissions = (roles: Array<Role>) => {
  const all: Set<string> = new Set();

  roles
    .map((role) => role.permissions)
    .forEach((permissions) =>
      permissions.forEach((permission) => all.add(permission))
    );

  return Array.from(all).sort((a, b) => a.localeCompare(b));
};

const getSelectedPermissions = (
  roles: Array<Role>,
  selected: Array<string>
) => {
  const selectedPermissions: Set<string> = new Set();

  roles
    .filter((role) => selected.includes(role.name))
    .map((role) => role.permissions)
    .forEach((permissions) =>
      permissions.forEach((permission) => selectedPermissions.add(permission))
    );

  return selectedPermissions;
};

export default class PermissionsTable extends Component<Props, State> {
  static defaultProps = { mandatory: [] };

  constructor(props: Props) {
    super(props);

    this.state = { open: false };
  }

  renderRole = (name: string) => {
    return (
      <Selectable
        key={`RoleCheckBox${name}`}
        onClick={(value: any) => this.props.callback(name, value)}
        name={format(name).toLowerCase()}
        disabled={this.props.mandatory.includes(name)}
        checked={this.props.selected.includes(name)}
      />
    );
  };

  render() {
    const allPermissions = getAllPermissions(this.props.roles);
    const selectedPermissions = getSelectedPermissions(
      this.props.roles,
      this.props.selected
    );
    console.log(this.props.roles, "roles");
    return (
      <div className="permissions-table">
        <div className="table-roles">
          <p className="table-roles-title">ROLES:</p>

          <div className="table-roles-content">
            {this.props.roles.map((role) => this.renderRole(role.name))}
          </div>
        </div>

        {/* <div className="table-roles">
          <p className="table-roles-title">ROLES:</p>

          <div className="table-roles-content">
            {this.props.roles

              .slice(-3)
              .map((role) => this.renderRole(role.name.toLocaleUpperCase()))}
          </div>
        </div> */}

        <div
          className="table-dropdown"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <p>{this.state.open ? "Hide" : "Check"} permissions</p>
          <ReactSVG
            className="table-dropdown-icon"
            src={`/assets/svg/${
              this.state.open ? "chevron-up" : "chevron-down"
            }.svg`}
          />
        </div>
        {this.state.open && (
          <div className="table-content">
            {allPermissions.map((option, index) => (
              <PermissionOption
                key={`POption${index}`}
                permission={option}
                status={
                  selectedPermissions.has(option) ? "GRANTED" : "DISABLED"
                }
                callback={(opt: any) => this.props.callback(opt)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}
