import React, { Component } from 'react';

import './styles.scss';

interface Props {
  users: any;
  tracker: any;
  callback: any;
}

export default class AdminInfoTracker extends Component<Props> {
  assign = (event: any, id: number) => {
    event.preventDefault();

    const email = event.currentTarget.elements[0].value;
    this.props.callback(id, email);
  }
  render() {
    const tracker = this.props.tracker;

    return (
      <div className="shlk-free-tracker">
        <h1>{ tracker.name }</h1>
        <p>Identifier : { tracker.identifier }</p>
        <form onSubmit={(e) => this.assign(e, tracker.identifier)}>
          <select>
            {this.props.users.map((user: any, index: number) =>
              <option key={`User_${index}`} value={user.email}>{user.fullName} - {user.email}</option>)}
          </select>
          <input type="submit" value="Assign" />
        </form>
      </div>
  );}
}
