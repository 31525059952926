import React, { Component } from 'react';
import TrackersMap from 'components/GoogleMap/TrackersMap';
import { getTrackers } from 'services/trackers';
import Map from '../Map';

interface State {
  trackers: Array<any>;
}

export default class TrackersWidget extends Component<{}, State> {
  constructor(props: {}) {
      super(props);
      this.state = { trackers: [] };
  }

  componentDidMount() {
    getTrackers()
      .then(trackers => this.setState({ trackers }));
  }

  render() {
      return (
        <Map title="Device management" link="/trackers">
          <TrackersMap className="shlk-location-map"
                          trackers={this.state.trackers}/>
        </Map>
      );
  }
}