import Box from "components/Box";
import BoxOption from "components/Box/Option";
import BoxTitle from "components/Box/Title";
import Page from "components/ControlBars/Page";
import TrackersMap from "components/GoogleMap/TrackersMap";
import ContentLoader from "components/Loading/ContentLoader";
import Tracker from "components/Tracker";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { getAllTrackers } from "services/trackers";
import "./styles.scss";

type Props = RouteComponentProps;

type State = {
  loading: boolean;
  trackers: Array<any>;
  open: any;
};

export default class AdminAllTrackers extends Component<Props, State> {
  private selected: any = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      trackers: [],
      open: null,
    };
  }

  updateTrackers = () => {
    this.setState({ loading: true });

    getAllTrackers().then((trackers: any) => {
      this.setState({ trackers, loading: false });
    });
  };

  async componentDidMount() {
    this.updateTrackers();
  }

  trackerPopup = (tracker: any) => {
    return (
      <div className="tracker-popup">
        <h4 className="tracker-popup-title">{`eShell ${tracker.identifier}`}</h4>
        <div className="tracker-popup-field">
          <b>Company</b>{" "}
          <span>{tracker.company ? tracker.company.name : "Unassigned"}</span>
        </div>
      </div>
    );
  };

  renderBoxContent = () => {
    const title = this.state.trackers.length > 0 ? "Devices" : "Device";

    return (
      <div className="trackers-content">
        <BoxTitle text={`${this.state.trackers.length} ${title}`} />

        <ContentLoader
          loading={this.state.loading}
          content={() => (
            <div className="trackers-wrapper">
              {this.state.trackers.map((tracker: any, index: number) => {
                const openTracker = tracker.identifier === this.state.open;

                return (
                  <div
                    key={`Trackers_${index}`}
                    className="tracker-option"
                    onClick={() =>
                      this.setState({
                        open: openTracker ? null : tracker.identifier,
                      })
                    }
                  >
                    <BoxOption selected={openTracker}>
                      <Tracker tracker={tracker} />
                    </BoxOption>
                  </div>
                );
              })}
            </div>
          )}
        />
      </div>
    );
  };

  mapClickCallback = (tracker: any, status: string) => {
    this.setState(
      { open: status === "close" ? -1 : tracker },
      this.scrollToOption
    );
  };

  scrollToOption = () =>
    this.selected.current &&
    this.selected.current.scrollIntoView({ behavior: "smooth" });

  render() {
    return (
      <Page className="shlk-admin-all" type="map">
        <Box
          menu={[
            {
              type: "trackers",
              render: this.renderBoxContent,
              icon: "eShells",
            },
          ]}
          onBoxOpen={this.scrollToOption}
        />
        <TrackersMap
          trackers={this.state.trackers}
          click={this.mapClickCallback}
          open={this.state.open}
          popup={this.trackerPopup}
        />
      </Page>
    );
  }
}
