import Icon from 'components/Icon';
import React from 'react';

import Marker, { MarkerProps } from '../AbstractMarker';

import './styles.scss';

interface LocationMarkerProps extends MarkerProps {
  type?: 'origin' | 'destination';
}

export default class LocationMarker extends Marker<LocationMarkerProps> {
  name = 'LocationMarker';

  renderMarker() {
    const markerClass = `marker-location shlk-marker ${this.props.type || ''}`;
    const spanText = this.props.type === 'origin' ? 'Origin' : 'Destination';

    return (
      <div className={markerClass}>
        <span className='span-text'>{spanText}</span>
        <Icon size={30} name="location_on" />
      </div>
    );
  }
}
