import React from 'react';
import { ReactSVG } from 'react-svg';
import { format as formatDate } from 'utils/date';
import Marker, { MarkerProps } from '../AbstractMarker';
import './styles.scss';

interface BasicMarker extends MarkerProps {
  active: boolean;
}

interface MarkerWithTracker extends BasicMarker {
  tracker: any;
  popup?: never;
}

interface MarkerWithPopup extends BasicMarker {
  popup: any;
  tracker?: never;
}

type Props = MarkerWithPopup | MarkerWithTracker;

export default class LiveTrackerMarker extends Marker<Props> {
  name = 'LiveTrackerMarker';

  hasPopup = true;
  renderPopupContent = () => {
    return <div className="marker-popup">
                <div className="marker-popup-field icon"><ReactSVG className="marker-popup-icon" src="/assets/svg/eShells.svg"/><b>{this.props.tracker.name}</b></div>
                <p className="marker-popup-field">{formatDate(this.props.tracker.lastMeasurement.time)}</p>
                { this.props.tracker.lastMeasurement.battery && <p className="marker-popup-field"><b>Battery:</b> {this.props.tracker.lastMeasurement.battery}%</p> }
            </div>;
  }

  renderMarker() {
    return (
      <ReactSVG className={`marker-live-tracker ${this.props.active ? "marker-live-tracker__active" : ""}`}
                  src="/assets/svg/eShells.svg"/>
    );
  }
}