import React, { Component, PropsWithChildren } from 'react';
import { ReactSVG } from 'react-svg';
import './styles.scss';

type Props = PropsWithChildren<{
    text: string;
    icon: string;
}>

export default class ContentCardTitle extends Component<Props> {
    render() {
        return (
            <div className="cc-header">
              <ReactSVG className="cc-header-icon" src={`/assets/svg/${this.props.icon}.svg`} />
              <h1>{this.props.text}</h1>
              { this.props.children }
            </div>
        );
    }
}
