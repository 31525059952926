// Import necessary modules
import jwt from 'jsonwebtoken';
import { getCookieData } from 'utils/data/cookies';

// Define a function to check if the user has a specific permission
/**
 * Checks if the user has the specified permission.
 * @param permission - The permission to check.
 * @returns True if the user has the permission, false otherwise.
 */
export const hasPermission = (permission: string) => {
    // Get the auth token from the cookies
    const token = getCookieData('authToken');

    // If there's no token, return false
    if(!token) return false;

    // Decode the token
    const decoded: any = jwt.decode(`${token}`);

    // Check if the decoded token includes the specified permission
    // If the token is decoded successfully and the permissions include the specified permission, return true
    // Otherwise, return false
    return decoded && decoded.permissions.includes(permission);
};

// Define a function to check if the user is logged in
/**
 * Checks if the user is logged in by verifying the presence of an auth token in the cookies.
 * @returns {boolean} True if the user is logged in, false otherwise.
 */
export const isLoggedIn = () => {
    // Check if there's an auth token in the cookies
    // If there is, return true (the user is logged in)
    // Otherwise, return false (the user is not logged in)
    return !!getCookieData('authToken');
};

// Define a function to get the user's permissions
/**
 * Retrieves the permissions from the authentication token.
 * @returns An array of permissions.
 */
export const getPermissions = () => {
    // Get the auth token from the cookies
    const token = getCookieData('authToken');

    // If there's no token, return an empty array
    if(!token) return [];

    // Decode the token
    const decoded: any = jwt.decode(`${token}`);

    // Get the permissions from the decoded token
    const permissions = decoded.permissions;

    // Return the permissions
    return permissions;
};