import React, { Component } from "react";
import "./styles.scss";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ShipmentContext } from "../context";

import shipmentdata from "../../Cards/Shipment/mock_shipment.json";
import Icon from "components/Icon";
import { calculatePercentageDone } from "utils/location";
import { ReactSVG } from "react-svg";
// import LineChart from "./Charts/LineChart";
// import { UserData } from "./Data";
import App from "./Charts/Chart";
import emissionsdata from "./Charts/Emissions_tab_mock.json";
import ChartWrapper from "./Charts/Chart";
import {
  barChartOptionsKg,
  barChartOptionsKgMobile,
  barChartOptionsTeu,
  barChartOptionsTeuMobile,
  barChartOptionsTonne,
  barChartOptionsTonneMobile,
  donughnutChartOptions,
  lineChartOptions,
  lineChartOptionsMobile,
} from "./Charts/data";
import {
  getEmissionsStats,
  getEmissionsTimeLine,
  getShipmentInfo,
} from "services/shipments";

interface MatchProps {
  id: string;
}

interface Props extends RouteComponentProps<MatchProps> {
  actions: boolean;
  shipment: any;
  lines: number[];
  showBoxButton: boolean;
}

interface State {
  loading: boolean;
  hoveredLineIndex: number | null;
  expanded: boolean;
  isMobile: boolean;
  currentSlide: number;
  emissionsTimeLine: any;
  ShipmentsData: any;
  emissionStats: any;
  activePoint:any;
}

class Emissions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hoveredLineIndex: null,
      loading: true,
      expanded: false,
      isMobile: false,
      currentSlide: 0,
      emissionsTimeLine: {},
      ShipmentsData: {},
      emissionStats: {},
      activePoint: null,
    };
    // Bind event handlers
    this.handleLineHover = this.handleLineHover.bind(this);
    this.handleLineLeave = this.handleLineLeave.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  handleLineHover(index: number) {
    this.setState({ hoveredLineIndex: index });
  }

  handleLineLeave() {
    this.setState({ hoveredLineIndex: null });
  }

  toggleExpanded() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }
  nextSlide = () => {
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide + 1) % 3,
    }));
  };

  prevSlide = () => {
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide - 1 + 3) % 3,
    }));
  };

  async componentDidMount() {
    const id = this.context.shipment._id;
    // Check if window width is less than a certain threshold to determine mobile view
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();
    try {
      const response = await getEmissionsTimeLine({
        id: this.context.shipment._id,
      });
      // Assuming `nearestNames` comes from the response.
      const emissionsTimeLineData = response; // Adjust this line based on actual response structure
      this.setState({ emissionsTimeLine: emissionsTimeLineData });
    } catch (error) {
      console.error("Error fetching port list:", error);
    }
    try {
      const response = await getEmissionsStats({
        id,
      });
      // Assuming `nearestNames` comes from the response.
      const emissionStatsData = response; // Adjust this line based on actual response structure
      this.setState({ emissionStats: emissionStatsData });
    } catch (error) {
      console.error("Error fetching port list:", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 768; // Adjust threshold as needed
    this.setState({ isMobile });
  };

  handlePointClick = (index:any) => {
    // Toggle active state for the clicked point
    this.setState((prevState) => ({
      activePoint: prevState.activePoint === index ? null : index,
    }));
  };

  render() {
    const { isMobile } = this.state;
    const { activePoint } = this.state;
    const isOngoingShipment = shipmentdata.status === "ongoing";
    const { emissionStats } = this.state;
    const { loading } = this.state;
    const ShipmentsData = this.props.shipment;
    console.log(this.props.shipment, "shipmentpropsemission");
    // const { hubsEmission } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    let modes: any = [];
    if (ShipmentsData && ShipmentsData.modes) {
      modes = ShipmentsData && ShipmentsData.modes;
    }

    let hubs: any = [];
    if (ShipmentsData && ShipmentsData.transports[0]) {
      hubs =
        ShipmentsData &&
        ShipmentsData.transports[0].hubs &&
        ShipmentsData.transports[0].hubs;
    }
    console.log(emissionStats.emissions, "em");
    const firstWaypoints =
      (ShipmentsData &&
        ShipmentsData.transports &&
        ShipmentsData.transports[0] &&
        ShipmentsData.transports[0].measurements) ||
      [];

    // Sort the measurements based on the time property
    firstWaypoints.sort(
      (a: any, b: any) =>
        new Date(a.time).getTime() - new Date(b.time).getTime()
    );
    const currentLocation =
      firstWaypoints.length > 0
        ? firstWaypoints[firstWaypoints.length - 1].location
        : null;
    // const currentLocation =
    //   ShipmentsData.transports &&
    //   ShipmentsData.transports[0] &&
    //   ShipmentsData.transports[0].tracker &&
    //   ShipmentsData.transports[0].tracker.lastMeasurement &&
    //   ShipmentsData.transports[0].tracker.lastMeasurement.location;
    // Calculate the positions of the points and lines dynamically based on the number of segments
    const numSegments = shipmentdata.modes.length; // Number of segments = number of arrays
    const segmentWidth = 100 / numSegments;
    const points: any = [];
    const { lines } = this.props;
    const { hoveredLineIndex } = this.state;
    const { expanded } = this.state;

    // Clear the lines array
    lines.length = 0;
    let doneSegmentIndex = -1; // Index of the ongoing segment

    let ongoingSegmentIndex = -1; // Index of the ongoing segment

    let CO2: any = 0;
    let TEU: any = 0;
    let TKM: any = 0;
    let distance: any = 0;
    let dw: any = 0;
    let kg: any = 0;
    let staticCO2: any = 0;
    const ATA: any = [];
    // Calculate the positions of the points and lines dynamically based on the number of segments
    for (let i = 0; i < numSegments; i++) {
      const startPoint = i * segmentWidth;
      const endPoint = (i + 1) * segmentWidth; // Calculate the endpoint correctly

      points.push(startPoint);
      lines.push(startPoint + segmentWidth / 2);
      if (shipmentdata.modes[i].status === "Completed") {
        doneSegmentIndex = i;
      }
      // Check if the current segment is ongoing
      if (shipmentdata.modes[i].status === "Ongoing") {
        ongoingSegmentIndex = i;
      }

      if (typeof shipmentdata.modes[i].dynamicEmissions === "number") {
        CO2 += shipmentdata.modes[i].dynamicEmissions;
        staticCO2 += shipmentdata.modes[i].staticEmissions;

        if (shipmentdata.containerType === "20") {
          TEU = CO2;
        } else {
          TEU = CO2 / 2;
        }
        if (typeof shipmentdata.modes[i].distance === "number") {
          distance += shipmentdata.modes[i].distance;
        }

        dw = shipmentdata.weight * distance;
        TKM = CO2 / dw;
        kg = CO2 / shipmentdata.weight;

        if (shipmentdata.modes[i].status === "Completed") {
          ATA.push(shipmentdata.modes[i].ATA);
        }
      }
    }
    const roundedCO2 = CO2.toFixed(2);
    const roundedTKM = TKM.toFixed(2);
    const roundedTEU = TEU.toFixed(2);
    const roundedkg = kg.toFixed(2);

    const shipmentDate = new Date(shipmentdata.createdAt.$date);
    const createdDate = `${shipmentDate.getDate()}.${
      shipmentDate.getMonth() + 1
    }.${shipmentDate.getFullYear()}`;

    const lastArrayIndex = shipmentdata.modes.length - 1; // Get the index of the last array
    const etaTimestamp = shipmentdata.modes[lastArrayIndex].ETA; // Get the ETA timestamp from the last array
    const etaDate = new Date(etaTimestamp); // Convert the timestamp to a Date object
    const estimatedDate = `${etaDate.getDate()}.${
      etaDate.getMonth() + 1
    }.${etaDate.getFullYear()}`; // Format the date as dd.mm.yyyy

    const { currentSlide } = this.state;

    const shipment = this.context.shipment;

    // const modes = shipment.modes;

    const { emissionsTimeLine } = this.state;

    const dynamicEmissions = emissionsTimeLine.dynamicEmissions;
    // Map dynamicEmissions to arrays of emissions and createdAt
    const emissionsStaticArray = dynamicEmissions
      ? dynamicEmissions.map((item: any) => item.static / 1000)
      : "";
    const emissionsDynamicArray = dynamicEmissions
      ? dynamicEmissions.map((item: any) => item.dynamic)
      : "";
    const formatTime = (seconds: any) => {
      const totalMinutes = Math.floor(seconds / 60);
      const hours = (Math.floor(totalMinutes / 60) % 24)
        .toString()
        .padStart(2, "0");
      const minutes = (totalMinutes % 60).toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    const createdAtArray = dynamicEmissions
      ? dynamicEmissions.map((item: any) => formatTime(item.cumulativeDistance))
      : "";
    console.log(
      createdAtArray,

      "emissionsTimeLine"
    );

    const lineChartData = {
      labels: createdAtArray,
      datasets: [
        {
          label: "GLEC DYNAMIC",
          data: emissionsDynamicArray,
          borderColor: "#59f9dc",
          backgroundColor: "#59f9dc",
          fill: false,
        },
        {
          label: "GLEC STATIC",
          data: emissionsStaticArray,
          borderColor: "#4C3BCF",
          backgroundColor: "#4C3BCF",
          fill: false,
        },
      ],
    };

    const doughnutChartData = {
      labels: ["Air", "Water", "Land", "Rail"],
      datasets: [
        {
          label: "Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerMode.Air
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerMode.Sea
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerMode.Land
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerMode.Rail
              : "",
          ],
          backgroundColor: ["#B563F7", "#8AB4F8", "#FAC21B", "#FA7B57"],
          borderColor: ["#B563F7", "#8AB4F8", "#FAC21B", "#FA7B57"],
        },
        {
          label: "Static ",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerMode.Air
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerMode.Sea
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerMode.Land
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerMode.Rail
              : "",
          ],
          backgroundColor: ["#DCBAF7", "#ADC9F5", "#F6D87E", "#FAC9AF"],
          borderColor: ["#DCBAF7", "#ADC9F5", "#F6D87E", "#FAC9AF"],
        },
      ],
    };
    const totalDynamic = emissionStats.emissions
      ? emissionStats.emissions.totalDynamic
      : "";
    const totalStatic = emissionStats.emissions
      ? emissionStats.emissions.totalStatic
      : "";

    const difference =
      totalDynamic && totalStatic && totalDynamic - totalStatic;

    // const doughtnutLabel = {
    //   id: "doughnutLabel",
    //   afterDatasetsDraw(chart: any, args: any, plugins: any) {
    //     const { ctx, data } = chart;
    //     const centerX = chart.getDatasetMeta(0).data[0].x;
    //     const centerY = chart.getDatasetMeta(0).data[0].y;
    //     ctx.save();
    //     ctx.font = "bold 30px sans-serif";
    //     ctx.fillStyle = "#19575C"; // Default color for positive differences
    //     ctx.textAlign = "center";
    //     ctx.textBaseline = "middle";

    //     if (totalDynamic > totalStatic) {
    //       ctx.fillStyle = "#C11C1C"; // Change color to red for positive differences
    //       ctx.fillText("+" + Math.round(difference) + "kg", centerX, centerY);
    //     } else if (totalDynamic < totalStatic) {
    //       ctx.fillStyle = "#06D001"; // Change color to green for negative differences
    //       ctx.fillText(
    //         "-" + Math.round(-difference) + "kg", // Display absolute value with minus sign
    //         centerX,
    //         centerY
    //       );
    //     } else {
    //       // Difference is 0 or not valid
    //       ctx.fillText("0 kg", centerX, centerY);
    //     }
    //     ctx.font = "bold 9px sans-serif";
    //     ctx.fillStyle = "#19575C"; // Default color for positive differences
    //     ctx.fillText("of CO2e compared ", centerX, centerY + 30); // Adjust the y position as needed
    //     ctx.font = "bold 9px sans-serif";
    //     ctx.fillText("to GLEC default ", centerX, centerY + 40); // Adjust the y position as needed
    //   },
    // };
    const doughtnutLabel = {
      id: "doughtnutLabel",
      afterDatasetsDraw(chart: any, args: any, plugins: any) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;

        // Conversion logic to handle units from kg to Tt
        const convertToReadableUnit = (value: number) => {
          if (value >= 1e15) {
            return { value: (value / 1e15).toFixed(2), unit: "Tt" }; // Convert to teratonnes
          } else if (value >= 1e12) {
            return { value: (value / 1e12).toFixed(2), unit: "Gt" }; // Convert to gigatonnes
          } else if (value >= 1e9) {
            return { value: (value / 1e9).toFixed(2), unit: "Mt" }; // Convert to megatonnes
          } else if (value >= 1e6) {
            return { value: (value / 1e6).toFixed(2), unit: "kt" }; // Convert to kilotonnes
          } else if (value >= 1000) {
            return { value: (value / 1000).toFixed(1), unit: "t" }; // Convert to tonnes
          } else {
            return { value: Math.round(value), unit: "kg" }; // Display in kilograms
          }
        };

        const differenceValue = convertToReadableUnit(Math.abs(difference));

        ctx.save();
        ctx.font = "bold 30px sans-serif";
        ctx.fillStyle = "#19575C"; // Default color for positive differences
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        if (totalDynamic > totalStatic) {
          ctx.fillStyle = "#C11C1C"; // Change color to red for positive differences
          ctx.fillText(
            "+" + differenceValue.value + differenceValue.unit,
            centerX,
            centerY
          );
        } else if (totalDynamic < totalStatic) {
          ctx.fillStyle = "#06D001"; // Change color to green for negative differences
          ctx.fillText(
            "-" + differenceValue.value + differenceValue.unit, // Display the value with the correct unit
            centerX,
            centerY
          );
        } else {
          // Difference is 0 or not valid
          ctx.fillText("0 kg", centerX, centerY);
        }

        ctx.font = "bold 9px sans-serif";
        ctx.fillStyle = "#19575C";
        ctx.fillText("of CO2e compared ", centerX, centerY + 30); // Adjust the y position as needed
        ctx.fillText("to GLEC default ", centerX, centerY + 40); // Adjust the y position as needed
      },
    };

    const barChartDataTonne = {
      labels: [""],

      datasets: [
        {
          label: "Air - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTKM.Air
              : "0",
          ],
          backgroundColor: "#B563F7",
          stack: "kg",
        },
        {
          label: "Water - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTKM.Sea
              : "0",
          ],
          backgroundColor: "#8AB4F8",
          stack: "kg",
        },
        {
          label: "Air - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTKM.Air
              : "0",
          ],
          backgroundColor: "#DCBAF7",
          stack: "tonne",
        },
        {
          label: "Land - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTKM.Land
              : "0",
          ],
          backgroundColor: "#FAC21B",
          stack: "kg",
        },
        {
          label: "Water - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTKM.Sea
              : "0",
          ],
          backgroundColor: "#ADC9F5",
          stack: "tonne",
        },
        {
          label: "Rail - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTKM.Rail
              : "0",
          ],
          backgroundColor: "#FA7B57",
          stack: "kg",
        },
        {
          label: "Land - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTKM.Land
              : "0",
          ],
          backgroundColor: "#F6D87E",
          stack: "tonne",
        },
        {
          label: "Rail - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTKM.Rail
              : "0",
          ],
          backgroundColor: "#FAC9AF",
          stack: "tonne",
        },
      ],
    };
    const barChartDataTeu = {
      labels: [""],

      datasets: [
        {
          label: "Air - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTEU.Air
              : "0",
          ],
          backgroundColor: "#B563F7",
          stack: "kg",
        },
        {
          label: "Water - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTEU.Sea
              : "0",
          ],
          backgroundColor: "#8AB4F8",
          stack: "kg",
        },
        {
          label: "Air - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTEU.Air
              : "0",
          ],
          backgroundColor: "#DCBAF7",
          stack: "tonne",
        },
        {
          label: "Land - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTEU.Land
              : "0",
          ],
          backgroundColor: "#FAC21B",
          stack: "kg",
        },
        {
          label: "Water - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTEU.Sea
              : "0",
          ],
          backgroundColor: "#ADC9F5",
          stack: "tonne",
        },
        {
          label: "Rail - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTEU.Rail
              : "0",
          ],
          backgroundColor: "#FA7B57",
          stack: "kg",
        },
        {
          label: "Land - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTEU.Land
              : "0",
          ],
          backgroundColor: "#F6D87E",
          stack: "tonne",
        },
        {
          label: "Rail - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTEU.Rail
              : "0",
          ],
          backgroundColor: "#FAC9AF",
          stack: "tonne",
        },
      ],
    };
    const barChartDataKg = {
      labels: [""],

      datasets: [
        {
          label: "Air - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerKg.Air
              : "0",
          ],
          backgroundColor: "#B563F7",
          stack: "kg",
        },
        {
          label: "Water - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerKg.Sea
              : "0",
          ],
          backgroundColor: "#8AB4F8",
          stack: "kg",
        },
        {
          label: "Air - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerKg.Air
              : "0",
          ],
          backgroundColor: "#DCBAF7",
          stack: "tonne",
        },
        {
          label: "Land - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerKg.Land
              : "0",
          ],
          backgroundColor: "#FAC21B",
          stack: "kg",
        },
        {
          label: "Water - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerKg.Sea
              : "0",
          ],
          backgroundColor: "#ADC9F5",
          stack: "tonne",
        },
        {
          label: "Rail - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerKg.Rail
              : "0",
          ],
          backgroundColor: "#FA7B57",
          stack: "kg",
        },
        {
          label: "Land - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerKg.Land
              : "0",
          ],
          backgroundColor: "#F6D87E",
          stack: "tonne",
        },
        {
          label: "Rail - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerKg.Rail
              : "0",
          ],
          backgroundColor: "#FAC9AF",
          stack: "tonne",
        },
      ],
    };

    return (
      <div>
        <div
          className="scroll-bar"
          style={{
            height: isMobile ? "90vh" : "90vh",
            overflowY: isMobile ? "auto" : "unset",
          }}
        >
          <div
            className="shipment-card__container expanded"
            style={{
              height: isMobile ? "" : "",
              margin: "0px",
              width: "920px",
            }}
          >
            <div className="shlk-card shipment-card__box">
              <div className="shipment-card__top" style={{ padding: "15px" }}>
                <div className="shipment-card__info">
                <div
                      className="shipment-card__bar full_width"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="shipment-card__field left name"
                        style={{ width: "50%" }}
                      >
                        {isMobile ? (
                          <>
                            {" "}
                            {ShipmentsData.name
                              ? ShipmentsData.name.length > 15
                                ? `${ShipmentsData.name.slice(0, 15)}...`
                                : ShipmentsData.name.toUpperCase()
                              : ""}
                          </>
                        ) : (
                          <>
                            {" "}
                            {ShipmentsData.name
                              ? ShipmentsData.name.length > 30
                                ? `${ShipmentsData.name.slice(0, 30)}...`
                                : ShipmentsData.name.toUpperCase()
                              : ""}
                          </>
                        )}
                      </div>
                      <div
                        className="shipment-card__field right name"
                        style={{ width: "50%" }}
                      >
                        <span
                          className={` ${
                            this.props.showBoxButton &&
                            this.props.actions &&
                            "container-number"
                          }`}
                        >
                          {ShipmentsData.containerNumber
                            ? ShipmentsData.containerNumber.length < 10
                              ? ShipmentsData.containerNumber
                              : `${ShipmentsData.containerNumber.slice(
                                  0,
                                  9
                                )}...`
                            : "NA"}
                        </span>
                      </div>
                    </div>

                  {!isMobile && (
                    <>
                      <div
                        className="shipment-card__bar_details_bar full_width"
                        style={{
                          marginTop: "30px",
                          marginBottom: "45px",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        <div className="shipment-bar">
                          {hubs.map((hub: any, index: any) => (
                            <React.Fragment key={hub._id}>
                              {/* Render point */}
                              <div
                                className={`shipment-bar__point expanded ${
                                  hub.arrivalAct && "done"
                                } `}
                                style={{
                                  left: `${(index / (hubs.length - 1)) * 100}%`,
                                  zIndex: "2",
                                }}
                              >
                                <div className="shipment-container">
                                  <div
                                    className="shipment-type"
                                    style={{
                                      position:
                                        hub.type !== "SHIPMENT_DESTINATION"
                                          ? "relative"
                                          : undefined,
                                      transform: "translateX(-100%)",
                                    }}
                                  >
                                    <span style={{ marginLeft: "5px" }}>
                                    {hub.address.address &&
                                      hub.address.address.length > 15
                                        ? hub.address.address.substring(0, 15) +
                                          "..."
                                        : hub.address.address}
                                    </span>
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "240%",
                                        marginTop: "15px",
                                        marginLeft: "5px",
                                        left:
                                          hub.type === "SHIPMENT_DESTINATION"
                                            ? undefined
                                            : "0%",
                                        right:
                                          hub.type === "SHIPMENT_DESTINATION"
                                            ? "0%"
                                            : undefined,
                                      }}
                                    >
                                      {modes[index] && modes[index].carbonStatic
                                        ? Math.round(
                                            modes[index].carbonStatic
                                          ) + "   kgCO2e"
                                        : ""}
                                    </span>
                                  </div>
                                  <div className="shipment-point"></div>
                                </div>
                              </div>

                              {/* Render line if there is a next mode */}
                              {index < modes.length && (
                                <div
                                  className={`shipment-bar__line expanded ${
                                    modes[index].status === "COMPLETED"
                                      ? "done"
                                      : ""
                                  } ${
                                    modes[index].status === "ONGOING"
                                      ? "ongoing"
                                      : ""
                                  }`}
                                  style={{
                                    position: "absolute",
                                    left: `${
                                      (index / (hubs.length - 1)) * 100
                                    }%`,
                                    top: "1px",
                                    width: `${(1 / (hubs.length - 1)) * 100}%`,
                                  }}
                                >
                                  {/* Render progress for the ongoing segment */}
                                  {modes[index].status === "ONGOING" && (
                                    <div
                                      className="shipment-bar__progress"
                                      style={{
                                        position: "absolute",
                                        width: `${
                                          hubs[index] && hubs[index + 1]
                                            ? calculatePercentageDone(
                                                hubs[index].address.location
                                                  .lat, // originLat
                                                hubs[index].address.location
                                                  .lng, // originLng
                                                hubs[index + 1].address.location
                                                  .lat, // destLat
                                                hubs[index + 1].address.location
                                                  .lng, // destLng (should be lng)
                                                currentLocation.lat, // actualLat
                                                currentLocation.lng // actualLng
                                              )
                                            : "0"
                                        }%`,
                                        zIndex: "1",
                                        height: "3px",
                                        // top: "1px",
                                        backgroundColor: "#19575C",
                                      }}
                                    >
                                      {modes[index].modeType === "Land" &&
                                        isOngoingShipment && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/truck.svg`}
                                            />
                                          </div>
                                        )}
                                      {modes[index].modeType === "Air" &&
                                        isOngoingShipment && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/flight.svg`}
                                            />
                                          </div>
                                        )}
                                      {modes[index].modeType === "Sea" &&
                                        isOngoingShipment && ( 
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/ship.svg`}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  )}
                                  {modes[index].status === "COMPLETED" &&
                                    index === modes.length - 1 && (
                                      <div
                                        className="shipment-bar__progress"
                                        style={{
                                          position: "absolute",
                                          width: `${
                                            hubs[index] && hubs[index + 1]
                                              ? calculatePercentageDone(
                                                  hubs[index].address.location
                                                    .lat, // originLat
                                                  hubs[index].address.location
                                                    .lng, // originLng
                                                  hubs[index + 1].address
                                                    .location.lat, // destLat
                                                  hubs[index + 1].address
                                                    .location.lng, // destLng (should be lng)
                                                  currentLocation.lat, // actualLat
                                                  currentLocation.lng // actualLng
                                                )
                                              : "0"
                                          }%`,
                                          zIndex: "5",
                                          height: "3px",
                                          // top: "1px",
                                          backgroundColor: "#19575C",
                                        }}
                                      >
                                        {modes[index].modeType === "Land" && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/truck.svg`}
                                            />
                                          </div>
                                        )}
                                        {modes[index].modeType === "Air" && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/flight.svg`}
                                            />
                                          </div>
                                        )}
                                        {modes[index].modeType === "Sea" && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/ship.svg`}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  {!isOngoingShipment &&
                                    index === modes.length - 1 && (
                                      <div
                                        style={{
                                          zIndex: "6",
                                          position: "relative",
                                        }}
                                      >
                                        {modes[index].modeType === "Land" &&
                                          index === modes.length - 1 && (
                                            <div
                                              className="shipment-bar__icon expanded"
                                              style={{
                                                position: "absolute",
                                                left: "101%", // Move to the end of the bar
                                                transform: "translateX(-100%)", // Prevent overflow
                                                backgroundColor: "#8b8b8b",
                                              }}
                                            >
                                              <ReactSVG
                                                src={`/assets/svg/truck.svg`}
                                              />
                                            </div>
                                          )}
                                        {modes[index].modeType === "Air" &&
                                          index === modes.length - 1 && (
                                            <div
                                              className="shipment-bar__icon expanded"
                                              style={{
                                                position: "absolute",
                                                left: "101%", // Move to the end of the bar
                                                transform: "translateX(-100%)", // Prevent overflow
                                                backgroundColor: "#8b8b8b",
                                              }}
                                            >
                                              <ReactSVG
                                                src={`/assets/svg/flight.svg`}
                                              />
                                            </div>
                                          )}
                                        {modes[index].modeType === "Sea" &&
                                          index === modes.length - 1 && (
                                            <div
                                              className="shipment-bar__icon expanded"
                                              style={{
                                                position: "absolute",
                                                left: "101%", // Move to the end of the bar
                                                transform: "translateX(-100%)", // Prevent overflow
                                                backgroundColor: "#8b8b8b",
                                              }}
                                            >
                                              <ReactSVG
                                                src={`/assets/svg/ship.svg`}
                                              />
                                            </div>
                                          )}
                                      </div>
                                    )}
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                          <div
                            className="shipment-bar__point expanded"
                            style={{ left: "100%" }}
                          ></div>
                        </div>
                      </div>
                    </>
                  )}
                 {isMobile && (
                    <>
                      <div
                        className="shipment-card__bar_details_bar full_width"
                        style={{
                          marginTop: "30px",
                          marginBottom: "45px",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        <div className="shipment-bar">
                          {hubs.map((hub: any, index: any) => (
                            <React.Fragment key={hub._id}>
                              {/* Render point */}
                              <div
            key={index}
            className={`shipment-bar__point expanded ${hub.arrivalAct && "done"}`}
            style={{
              left: `${(index / (hubs.length - 1)) * 100}%`,
              zIndex: "2",
            }}
            onClick={() => this.handlePointClick(index)} // Add onClick handler
          >
            <div className="shipment-container">
              <div
                className="shipment-type"
                style={{
                  position: hub.type !== "SHIPMENT_DESTINATION" ? "relative" : undefined,
                  transform: "translateX(-100%)",
                }}
              >
                {/* Display address and time for first and last points only */}
                {(index === 0 || index === hubs.length - 1) && (
                  <>
                    <span style={{ marginLeft: "10px" }}>
                      {hub.address.address
                        ? hub.address.address.length <= 10
                          ? hub.address.address
                          : `${hub.address.address.slice(0, 10)}...`
                        : "NA"}
                    </span>
                    <span
                      style={{
                        position: "absolute",
                        top: "240%",
                        marginTop: "15px",
                        marginLeft: "10px",
                        left: hub.type === "SHIPMENT_DESTINATION" ? undefined : "0%",
                        right: hub.type === "SHIPMENT_DESTINATION" ? "0%" : undefined,
                      }}
                    >
                      {modes[index] && modes[index].carbonStatic
                                        ? Math.round(
                                            modes[index].carbonStatic
                                          ) + "   kgCO2e"
                                        : ""}
                    </span>
                  </>
                )}
                <div className="shipment-point"></div>
              </div>
            </div>

            {/* Render the popup card only for middle points */}
            {index !== 0 && index !== hubs.length - 1 &&  activePoint === index && (
              <div
                className="popup-card"
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  transform: 'translateX(-80%)',
                  marginTop: '10px',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  zIndex:"999",
                  fontWeight:"bold"
                }}
              >
                <span style={{ display: 'block', marginBottom: '5px' }}>
                  {hub.address.address
                    ? hub.address.address.length <= 10
                      ? hub.address.address
                      : `${hub.address.address.slice(0, 10)}...`
                    : "NA"}
                </span>
                <span style={{ display: 'block' }}>
                {modes[index] && modes[index].carbonStatic
                                        ? Math.round(
                                            modes[index].carbonStatic
                                          ) + "   kgCO2e"
                                        : ""}
                </span>
              </div>
            )}
          </div>

                              {/* Render line if there is a next mode */}
                              {index < modes.length && (
                                <div
                                  className={`shipment-bar__line expanded ${
                                    modes[index].status === "COMPLETED"
                                      ? "done"
                                      : ""
                                  } ${
                                    modes[index].status === "ONGOING"
                                      ? "ongoing"
                                      : ""
                                  }`}
                                  style={{
                                    position: "absolute",
                                    left: `${
                                      (index / (hubs.length - 1)) * 100
                                    }%`,
                                    top: "1px",
                                    width: `${(1 / (hubs.length - 1)) * 100}%`,
                                  }}
                                >
                                  {/* Render progress for the ongoing segment */}
                                  {modes[index].status === "ONGOING" && (
                                    <div
                                      className="shipment-bar__progress"
                                      style={{
                                        position: "absolute",
                                        width: `${
                                          hubs[index] && hubs[index + 1]
                                            ? calculatePercentageDone(
                                                hubs[index].address.location
                                                  .lat, // originLat
                                                hubs[index].address.location
                                                  .lng, // originLng
                                                hubs[index + 1].address.location
                                                  .lat, // destLat
                                                hubs[index + 1].address.location
                                                  .lng, // destLng (should be lng)
                                                currentLocation.lat, // actualLat
                                                currentLocation.lng // actualLng
                                              )
                                            : "0"
                                        }%`,
                                        zIndex: "1",
                                        height: "3px",
                                        // top: "1px",
                                        backgroundColor: "#19575C",
                                      }}
                                    >
                                      {modes[index].modeType === "Land" &&
                                        isOngoingShipment && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/truck.svg`}
                                            />
                                          </div>
                                        )}
                                      {modes[index].modeType === "Air" &&
                                        isOngoingShipment && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/flight.svg`}
                                            />
                                          </div>
                                        )}
                                      {modes[index].modeType === "Sea" &&
                                        isOngoingShipment && ( 
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/ship.svg`}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  )}
                                  {modes[index].status === "COMPLETED" &&
                                    index === modes.length - 1 && (
                                      <div
                                        className="shipment-bar__progress"
                                        style={{
                                          position: "absolute",
                                          width: `${
                                            hubs[index] && hubs[index + 1]
                                              ? calculatePercentageDone(
                                                  hubs[index].address.location
                                                    .lat, // originLat
                                                  hubs[index].address.location
                                                    .lng, // originLng
                                                  hubs[index + 1].address
                                                    .location.lat, // destLat
                                                  hubs[index + 1].address
                                                    .location.lng, // destLng (should be lng)
                                                  currentLocation.lat, // actualLat
                                                  currentLocation.lng // actualLng
                                                )
                                              : "0"
                                          }%`,
                                          zIndex: "5",
                                          height: "3px",
                                          // top: "1px",
                                          backgroundColor: "#19575C",
                                        }}
                                      >
                                        {modes[index].modeType === "Land" && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/truck.svg`}
                                            />
                                          </div>
                                        )}
                                        {modes[index].modeType === "Air" && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/flight.svg`}
                                            />
                                          </div>
                                        )}
                                        {modes[index].modeType === "Sea" && (
                                          <div className="shipment-bar__icon expanded" style={{left:"99%"}}>
                                            <ReactSVG
                                              src={`/assets/svg/ship.svg`}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  {!isOngoingShipment &&
                                    index === modes.length - 1 && (
                                      <div
                                        style={{
                                          zIndex: "6",
                                          position: "relative",
                                        }}
                                      >
                                        {modes[index].modeType === "Land" &&
                                          index === modes.length - 1 && (
                                            <div
                                              className="shipment-bar__icon expanded"
                                              style={{
                                                position: "absolute",
                                                left: "101%", // Move to the end of the bar
                                                transform: "translateX(-100%)", // Prevent overflow
                                                backgroundColor: "#8b8b8b",
                                              }}
                                            >
                                              <ReactSVG
                                                src={`/assets/svg/truck.svg`}
                                              />
                                            </div>
                                          )}
                                        {modes[index].modeType === "Air" &&
                                          index === modes.length - 1 && (
                                            <div
                                              className="shipment-bar__icon expanded"
                                              style={{
                                                position: "absolute",
                                                left: "101%", // Move to the end of the bar
                                                transform: "translateX(-100%)", // Prevent overflow
                                                backgroundColor: "#8b8b8b",
                                              }}
                                            >
                                              <ReactSVG
                                                src={`/assets/svg/flight.svg`}
                                              />
                                            </div>
                                          )}
                                        {modes[index].modeType === "Sea" &&
                                          index === modes.length - 1 && (
                                            <div
                                              className="shipment-bar__icon expanded"
                                              style={{
                                                position: "absolute",
                                                left: "101%", // Move to the end of the bar
                                                transform: "translateX(-100%)", // Prevent overflow
                                                backgroundColor: "#8b8b8b",
                                              }}
                                            >
                                              <ReactSVG
                                                src={`/assets/svg/ship.svg`}
                                              />
                                            </div>
                                          )}
                                      </div>
                                    )}
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                          <div
                            className="shipment-bar__point expanded"
                            style={{ left: "100%" }}
                          ></div>
                        </div>
                      </div>
                    </>
                  )}

                  {!isMobile && (
                    <div
                      className="scrollable-content expanded-line-chart shipment-card__bar full_width"
                      style={{
                        display: "grid",
                        height: "60vh",
                        marginBottom: "10px",
                        marginTop: "5px",
                      }}
                    >
                      {!isMobile && (
                        <div
                          className=" expanded-line-chart shipment-card__bar_details_bar full_width"
                          style={{
                            backgroundColor: "#e8eeef",
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            justifyContent: "space-evenly",
                            borderRadius: "5px",
                            marginBottom:"60px"
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#e8eeef",
                              marginRight: "20px",
                            }}
                          >
                            {" "}
                            <ChartWrapper
                              type={"bar"}
                              data={barChartDataTonne}
                              options={barChartOptionsTonne}
                              plugins={undefined}
                            />
                          </div>
                          <div
                            style={{
                              backgroundColor: "#e8eeef",
                              marginRight: "20px",
                            }}
                          >
                            {" "}
                            <ChartWrapper
                              type={"bar"}
                              data={barChartDataTeu}
                              options={barChartOptionsTeu}
                              plugins={undefined}
                            />
                          </div>
                          <div
                            style={{
                              backgroundColor: "#e8eeef",
                              marginRight: "10px",
                            }}
                          >
                            {" "}
                            <ChartWrapper
                              type={"bar"}
                              data={barChartDataKg}
                              options={barChartOptionsKg}
                              plugins={undefined}
                            />
                          </div>
                        </div>
                      )}
                      {!isMobile && (
                        <>
                          <div
                            className=" expanded-line-chart shipment-card__bar full_width"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginTop: "20px",
                              alignItems: "flex-start",
                            }}
                          >
                            <div>
                            
                              <div
                                style={{
                
                                  backgroundColor: "#e8eeef",
                                  marginBottom: "15px",
                                  marginRight: "15px",
                                  height: "130px",
                                  width:"94%",
                                  color: "#2B8E8F",
                                  fontSize: "20px",
                                  display: "grid",
                                  alignContent: "center",
                                  justifyItems: "center",
                                  borderRadius: "5px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    // paddingLeft: "10px",
                                    textAlign: "center",
                                    fontFamily: "Roboto Condensed",
                                  }}
                                >
                                  Shipment total emissions
                                </span>
                                {/* <br></br> */}
                                <span
                                  style={{
                                    fontSize: "35px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    fontFamily: "Roboto Condensed",
                                  }}
                                >
                                  {emissionStats.emissions
                                    ? emissionStats.emissions.totalStatic < 1000
                                      ? `${Math.round(
                                          emissionStats.emissions.totalStatic
                                        )}`
                                      : `${(
                                          emissionStats.emissions.totalStatic /
                                          1000
                                        ).toFixed(1)}`
                                    : ""}
                                  <br></br>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                      color: "#3D9AA1",
                                      fontFamily: "Roboto Condensed",
                                    }}
                                  >
                                    {emissionStats.emissions.totalStatic < 1000
                                      ? "kg"
                                      : "t"}
                                  </span>
                                </span>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#e8eeef",
                                  marginRight: "20px",
                                  padding: "10px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div>
                                  <ChartWrapper
                                    type={"doughnut"}
                                    data={doughnutChartData}
                                    options={donughnutChartOptions}
                                    plugins={[doughtnutLabel]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#e8eeef",
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                justifyContent: "space-evenly",
                                borderRadius: "5px",
                                padding: "10px",
                                marginLeft:"-5px"
                              }}
                            >
                              {" "}
                              <ChartWrapper
                                type={"line"}
                                data={lineChartData}
                                options={lineChartOptions}
                                plugins={undefined}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {!isMobile && (
                    <div
                      style={{
                        // backgroundColor: "white",
                        height: "50px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        borderRadius: "5px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                      className="shipment-card__bar_details_bar full_width"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            fontSize: "14px",
                            color: "#3D9AA1",
                            fontWeight: "bold",
                          }}
                        >
                          GLEC DYNAMIC
                        </span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#FAC21B",
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "5px",
                                // Adjust as needed for spacing
                              }}
                            ></div>
                            <span>LAND</span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#8AB4F8",
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "5px", // Adjust as needed for spacing
                              }}
                            ></div>
                            <span>SEA</span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#B563F7",
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "5px", // Adjust as needed for spacing
                              }}
                            ></div>
                            <span>AIR</span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#FA7B57",
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "5px", // Adjust as needed for spacing
                              }}
                            ></div>
                            <span>RAIL</span>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            fontSize: "14px",
                            color: "#8B8B8B",
                            fontWeight: "bold",
                          }}
                        >
                          GLEC STATIC
                        </span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#F6D87E",
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "5px",
                                // Adjust as needed for spacing
                              }}
                            ></div>
                            <span>LAND</span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#ADC9F5",
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "5px", // Adjust as needed for spacing
                              }}
                            ></div>
                            <span>SEA</span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#DCBAF7",
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "5px", // Adjust as needed for spacing
                              }}
                            ></div>
                            <span>AIR</span>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#FAC9AF",
                                width: "10px",
                                height: "10px",
                                marginRight: "5px",
                                marginLeft: "5px", // Adjust as needed for spacing
                              }}
                            ></div>
                            <span>RAIL</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isMobile && (
                    <div
                      className="scrollable-content expanded-line-chart shipment-card__bar_details_bar full_width"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "60vh",
                        marginBottom: "0px",
                        marginTop: "15px",
                        width: "100%",
                      }}
                    >
                      <>
                        {isMobile && (
                          <div
                            style={{
                              position: "relative",
                              gridColumn: "1/-1",
                              marginBottom: "0px",
                              backgroundColor: "#e8eeef",
                              marginTop: "0px",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                          >
                            <div style={{ display: "flex", width: "100%" }}>
                              <div
                                style={{
                                  transition: "transform 0.3s ease",
                                  width: "300%",
                                  padding: "20px",
                                }}
                              >
                                {currentSlide === 0 && (
                                  <ChartWrapper
                                    type={"bar"}
                                    data={barChartDataTonne}
                                    options={barChartOptionsTonneMobile}
                                    plugins={undefined}
                                  />
                                )}
                                {currentSlide === 1 && (
                                  <ChartWrapper
                                    type={"bar"}
                                    data={barChartDataTeu}
                                    options={barChartOptionsTeuMobile}
                                    plugins={undefined}
                                  />
                                )}
                                {currentSlide === 2 && (
                                  <ChartWrapper
                                    type={"bar"}
                                    data={barChartDataKg}
                                    options={barChartOptionsKgMobile}
                                    plugins={undefined}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "100%",
                              }}
                            >
                              <div
                                onClick={this.prevSlide}
                                style={{
                                  fontSize: "30px",
                                }}
                              >
                                {"<"}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px", // Space between dots
                                }}
                              >
                                <span
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                      currentSlide === 0
                                        ? "#19575C"
                                        : "#C4C4C4",
                                    display: "inline-block",
                                  }}
                                />
                                <span
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                      currentSlide === 1
                                        ? "#19575C"
                                        : "#C4C4C4",
                                    display: "inline-block",
                                  }}
                                />
                                <span
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                      currentSlide === 2
                                        ? "#19575C"
                                        : "#C4C4C4",
                                    display: "inline-block",
                                  }}
                                />
                              </div>

                              <div
                                onClick={this.nextSlide}
                                style={{
                                  fontSize: "30px",
                                }}
                              >
                                {">"}
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className="shipment-card__bar full_width"
                          style={{
                            backgroundColor: "#e8eeef",
                            marginBottom: "10px",
                            marginRight: "",
                            height: "130px",
                            color: "#2B8E8F",
                            fontSize: "20px",
                            display: "flex",
                            flexDirection: "column",
                            width: "95%",
                            alignContent: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <span
                            style={{
                              color: "#19575c",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            {" "}
                            Shipment total emissions
                          </span>

                          <span
                            style={{ fontSize: "32px", fontWeight: "bold" }}
                          >
                            {emissionStats.emissions
                              ? emissionStats.emissions.totalStatic < 1000
                                ? Math.round(
                                    emissionStats.emissions.totalStatic
                                  )
                                : (
                                    emissionStats.emissions.totalStatic / 1000
                                  ).toFixed(1)
                              : ""}
                          </span>

                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "normal",
                              color: "#3D9AA1",
                              fontFamily: "Roboto Condensed",
                            }}
                          >
                            {emissionStats.emissions.totalStatic < 1000
                              ? "kg"
                              : "t"}
                          </span>
                        </div>
                        <div
                          className=" expanded-line-chart shipment-card__bar full_width"
                          style={{
                            backgroundColor: "#e8eeef",
                            borderRadius: "5px",
                            marginBottom: "0px",
                            padding: "20px",
                            width: "90%",
                          }}
                        >
                          <ChartWrapper
                            type={"doughnut"}
                            data={doughnutChartData}
                            options={donughnutChartOptions}
                            plugins={[doughtnutLabel]}
                          />
                        </div>
                        <div
                          className=" expanded-line-chart shipment-card__bar_details_bar full_width"
                          style={{
                            backgroundColor: "#e8eeef",
                            borderRadius: "5px",
                            marginTop: "0px",
                            marginBottom: "0px",
                            width: "100%",
                          }}
                        >
                          <ChartWrapper
                            type={"line"}
                            data={lineChartData}
                            options={lineChartOptionsMobile}
                            plugins={undefined}
                          />
                        </div>
                      </>
                    </div>
                  )}
                  {isMobile && (
                    <>
                      <div
                        style={{
                         
                          height: "50px",
                          display: "block",
                          alignContent: "center",
                          borderRadius: "5px",
                       
                          width: "100%",
                      
                          marginTop:"15px",
                          marginBottom:"0px"
                        }}
                        className="shipment-card__bar_details_bar full_width"
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#3D9AA1",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                          >
                            GLEC DYNAMIC
                          </span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "5px",
                                marginLeft: "5px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#FAC21B",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: "5px",
                                  marginLeft: "5px",
                                  // Adjust as needed for spacing
                                }}
                              ></div>
                              <span>LAND</span>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#8AB4F8",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: "5px",
                                  marginLeft: "5px", // Adjust as needed for spacing
                                }}
                              ></div>
                              <span>SEA</span>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#B563F7",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: "5px",
                                  marginLeft: "5px", // Adjust as needed for spacing
                                }}
                              ></div>
                              <span>AIR</span>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#FA7B57",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: "5px",
                                  marginLeft: "5px", // Adjust as needed for spacing
                                }}
                              ></div>
                              <span>RAIL</span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#8B8B8B",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                          >
                            GLEC STATIC
                          </span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "5px",
                                marginLeft: "5px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#F6D87E",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: "5px",
                                  marginLeft: "5px",
                                  // Adjust as needed for spacing
                                }}
                              ></div>
                              <span>LAND</span>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ADC9F5",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: "5px",
                                  marginLeft: "5px", // Adjust as needed for spacing
                                }}
                              ></div>
                              <span>SEA</span>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#DCBAF7",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: "5px",
                                  marginLeft: "5px", // Adjust as needed for spacing
                                }}
                              ></div>
                              <span>AIR</span>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#FAC9AF",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: "5px",
                                  marginLeft: "5px", // Adjust as needed for spacing
                                }}
                              ></div>
                              <span>RAIL</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Emissions.contextType = ShipmentContext;

export default withRouter(Emissions);
// // #9400FFF
