import React, { Component } from 'react';
import { sortAlphabetically } from 'utils/functions';
import './styles.scss';

type Props = {
    name: string;
    list: string[];
    selected: string[];
    onChange: Function;
    disabled?: boolean;
}

interface State {
    active: boolean;
}

export default class SelectableList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            active: false
        };
    }

    onChangeHandler = (event: any) => {
        let selected = [];
        const { checked, value } = event.target;

        if(checked) {
            selected = [...this.props.selected, value];
        } else {
            selected = this.props.selected.filter(item => item !== value);
        }

        this.props.onChange(selected);
    }

    renderItem = (item: string) => {
        return <li key={item}>
            <label>
            <input
                type="checkbox"
                value={item}
                name={this.props.name}
                checked={this.props.selected.includes(item)}
                onChange={this.onChangeHandler}
                disabled={this.props.disabled}
            />
                {item}
            </label>
        </li>;
    }

    renderList = () => {
        return <div
            className={`shlk-selectable-list ${this.state.active ? 'active' : ''}`}
            onClick={() => this.setState({ active: !this.state.active })}
            >
            {this.props.selected.length} selected
            <div className="list-container" onClick={(event: any) => event.stopPropagation()}>
                <ul>
                    { this.props.list
                        .sort(sortAlphabetically)
                        .map(this.renderItem) }
                </ul>
            </div>
      </div>;
    }

    render() {
        return this.props.list.length ?
            <div className="list-container">{ this.renderList() }</div> :
            <></>;
    }
}