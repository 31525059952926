// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { ReactSVG } from 'react-svg';

// import Page from 'components/ControlBars/Page';

// import './styles.scss';

// export default class Admin extends Component {
//   renderWrapper = (title: string, desc: string, link: string) => {
//     return (
//         <Link className="admin-option" to={link}>
//           <div className="admin-option-text">
//             <h2>{title}</h2>
//             <p>{desc}</p>
//           </div>
//           <ReactSVG className="admin-go-icon" src="/assets/svg/chevron-right.svg" />
//         </Link>
//     );
//   }

//   render() {
//     return (
//       <Page className="shlk-admin">
//         <div className="admin-wrapper">
//           <h1>Admin Pages</h1>

//           <div className="admin-options">
//             { this.renderWrapper("Free Trackers", "Assign free trackers to users", "/admin/trackers/free") }
//             { this.renderWrapper("All Trackers", "Check the location of all the trackers", "/admin/trackers/all") }
//             { this.renderWrapper("Users", "Manage Naxxar Tech users", "/admin/users") }
//             { this.renderWrapper("New Company", "Create a new company", "/admin/company") }
//             { this.renderWrapper("Data", "Check Naxxar Tech data", "/admin/data") }
//             { this.renderWrapper("All Shipments", "Check all shipments", "/admin/shipments") }

//           </div>
//         </div>
//       </Page>
//     );
//   }
// }

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Page from 'components/ControlBars/Page';

import './styles.scss';

interface AdminState {
  selectedFile: File | null;
  version: string;
  uploadMessage: string;
}
export default class Admin extends Component<{}, AdminState> {
  constructor(props:any) {
    super(props);
    this.state = {
      selectedFile: null,
      version: '',
      uploadMessage: '',
    };
  }

  renderWrapper = (title: string, desc: string, link: string) => {
    return (
      <Link className="admin-option" to={link}>
        <div className="admin-option-text">
          <h2>{title}</h2>
          <p>{desc}</p>
        </div>
        <ReactSVG className="admin-go-icon" src="/assets/svg/chevron-right.svg" />
      </Link>
    );
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedFile: event.target.files && event.target.files[0] || null });
  };

  handleVersionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ version: event.target.value });
  };

  handleUpload = async () => {
    const { selectedFile, version } = this.state;
    if (!selectedFile || !version) {
      this.setState({ uploadMessage: 'Please select a file and provide a version.' });
      return;
    }

    const formData = new FormData();
    formData.append('docxFile', selectedFile);
    formData.append('version', version);

    try {
      const response = await fetch('/api/terms/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        this.setState({ uploadMessage: 'File uploaded successfully!' });
      } else {
        this.setState({ uploadMessage: 'File upload failed.' });
      }
    } catch (error) {
      this.setState({ uploadMessage: 'An error occurred during upload.' });
    }
  };

  render() {
    return (
      <Page className="shlk-admin">
        <div className="admin-wrapper">
          <h1>Admin Pages</h1>

          <div className="admin-options">
            {this.renderWrapper('Free Trackers', 'Assign free trackers to users', '/admin/trackers/free')}
            {this.renderWrapper('All Trackers', 'Check the location of all the trackers', '/admin/trackers/all')}
            {this.renderWrapper('Users', 'Manage Naxxar Tech users', '/admin/users')}
            {this.renderWrapper('New Company', 'Create a new company', '/admin/company')}
            {this.renderWrapper('Data', 'Check Naxxar Tech data', '/admin/data')}
            {this.renderWrapper('All Shipments', 'Check all shipments', '/admin/shipments')}

            {/* Add Upload Section */}
            <div className="admin-option" style={{justifyContent:"space-between"}}>
              <h2>Upload Terms and Conditions</h2>
         
              <input
                type="file"
                accept=".docx"
                onChange={this.handleFileChange}
              />
              <input
                type="text"
                placeholder="Enter version"
                value={this.state.version}
                onChange={this.handleVersionChange}
              />
              <button onClick={this.handleUpload}>Upload</button>
              {this.state.uploadMessage &&    <p>{this.state.uploadMessage}</p>}
           
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
