// import React, { useState, useEffect } from "react";
// import ContentLoader from "components/Loading/ContentLoader";
// import { getTrackerSettings } from "services/settings";
// import { updateSettingValue } from "./utils";
// import Field from "./Field";
// import "./styles.scss";

// interface Props {
//   update: any;
//   data: any;
//   className?: string;
//   model?: any;
// }

// const FormSettings: React.FC<Props> = ({ update, data, className = "" }) => {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (data.settings) {
//       setLoading(false);
//       return;
//     }

//     getTrackerSettings().then((settings: any) => {
//       update(settings);
//       setLoading(false);
//     });
//   }, [data.settings, update]);

//   const handleUpdate = (path: string, setting: string, value: any) => {
//     const updatedSettings = { ...data.settings };

//     updateSettingValue(path, setting, value, updatedSettings);

//     update(updatedSettings);
//   };

//   const renderForm = () => {
//     const hasCollisionSetting = data.settings.tracking.collision;

//     return (
//       <div className={`form-settings ${className}`}>
//         {data.settings.tracking && (
//           <div>
//             <div className="settings-title">Tracking</div>

//             <Field
//               data={data}
//               update={handleUpdate}
//               path="tracking.params"
//               name="mode"
//               info="Changes in tracking frequency will affect battery lifespan"
//             />
//           </div>
//         )}
//         <div>
//           <div className="settings-title">Notifications</div>

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="notifications.emails"
//             name="emails"
//             info="Receive an email for every alarm triggered"
//           />
//         </div>
//         <div>
//           <div className="settings-title">Triggers</div>

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.temperature"
//             name="temperature"
//             info="An alarm will trigger when the temperature of drops lower than MIN (ºC) or higher than MAX (ºC)"
//           />

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.idle"
//             name="idle"
//             info="An alarm will trigger when the eShell hasn't moved more than DISTANCE (meters) in the last TIME (minutes)"
//           />

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.battery"
//             name="battery"
//             info="An alarm will trigger when the battery drops more than PERCENTAGE (%)"
//           />

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.arrival"
//             name="arrival"
//             info="An alarm will trigger when the eShell gets closer than METERS from the destination"
//           />

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.light"
//             name="light"
//             info="An alarm will trigger when the light sensor has been triggered after being in the dark"
//           />

//           {hasCollisionSetting && (
//             <Field
//               update={handleUpdate}
//               data={data}
//               path="triggers.collision"
//               name="collision"
//               info="An alarm will trigger when the acceleration sensor gets above the threshold"
//             />
//           )}

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.vessel"
//             name="vessel"
//             info="Enable alarms for vessel departure and arrival"
//           />
//         </div>
//       </div>
//     );
//   };

//   return (
//     <ContentLoader
//       loading={loading}
//       content={renderForm}
//     />
//   );
// };

// export default FormSettings;

// import React, { useState, useEffect } from "react";
// import ContentLoader from "components/Loading/ContentLoader";
// import { getDefault, getTrackerSettings } from "services/settings";
// import { updateSettingValue } from "./utils";
// import Field from "./Field";
// import "./styles.scss";

// interface Props {
//   update: any;
//   data: any;
//   className?: string;
//   model?: any;
//   trackerSettings?: any;
// }

// const FormSettings: React.FC<Props> = ({
//   update,
//   data,
//   className = "",
//   model,
//   trackerSettings,
// }) => {
//   const [loading, setLoading] = useState(true);
//   console.log(data.trackers.model, "data");
//   // Handle changes in data.trackers
//   useEffect(() => {
//     if (data.trackers.length === 0) {
//       // No tracker selected, set loading state
//       setLoading(true);
//       return;
//     }

//     setLoading(true);
//     if (trackerSettings) {
//       const model = data.trackers.model;
//       if (model === "MT07C") {
//         getTrackerSettings().then((settings: any) => {
//           update(settings);
//           setLoading(false);
//         });
//       } else {
//         getDefault().then((defaultSettings: any) => {
//           update(defaultSettings);
//           setLoading(false);
//         });
//       }
//     } else {
//       if (data.trackers && data.trackers.length > 0) {
//         const model = data.trackers[0].model;
//         if (model === "MT07C") {
//           getTrackerSettings().then((settings: any) => {
//             update(settings);
//             setLoading(false);
//           });
//         } else {
//           getDefault().then((defaultSettings: any) => {
//             update(defaultSettings);
//             setLoading(false);
//           });
//         }
//       }
//     }
//   }, [data.trackers, update]);

//   const handleUpdate = (path: string, setting: string, value: any) => {
//     const updatedSettings = { ...data.settings };
//     updateSettingValue(path, setting, value, updatedSettings);
//     update(updatedSettings);
//   };

//   const renderForm = () => {
//     const hasCollisionSetting = data.settings.tracking.collision;

//     return (
//       <div className={`form-settings ${className}`}>
//         {data.settings.tracking && (
//           <div>
//             <div className="settings-title">Tracking</div>

//             <Field
//               data={data}
//               update={handleUpdate}
//               path="tracking.params"
//               name="mode"
//               info="Changes in tracking frequency will affect battery lifespan"
//             />
//           </div>
//         )}
//         <div>
//           <div className="settings-title">Notifications</div>

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="notifications.emails"
//             name="emails"
//             info="Receive an email for every alarm triggered"
//           />
//         </div>
//         <div>
//           <div className="settings-title">Triggers</div>

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.temperature"
//             name="temperature"
//             info="An alarm will trigger when the temperature of drops lower than MIN (ºC) or higher than MAX (ºC)"
//           />

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.idle"
//             name="idle"
//             info="An alarm will trigger when the eShell hasn't moved more than DISTANCE (meters) in the last TIME (minutes)"
//           />

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.battery"
//             name="battery"
//             info="An alarm will trigger when the battery drops more than PERCENTAGE (%)"
//           />

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.arrival"
//             name="arrival"
//             info="An alarm will trigger when the eShell gets closer than METERS from the destination"
//           />

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.light"
//             name="light"
//             info="An alarm will trigger when the light sensor has been triggered after being in the dark"
//           />

//           {hasCollisionSetting && (
//             <Field
//               update={handleUpdate}
//               data={data}
//               path="triggers.collision"
//               name="collision"
//               info="An alarm will trigger when the acceleration sensor gets above the threshold"
//             />
//           )}

//           <Field
//             update={handleUpdate}
//             data={data}
//             path="triggers.vessel"
//             name="vessel"
//             info="Enable alarms for vessel departure and arrival"
//           />
//         </div>
//       </div>
//     );
//   };

//   return <ContentLoader loading={loading} content={renderForm} />;
// };

// export default FormSettings;

import React, { useState, useEffect } from "react";
import ContentLoader from "components/Loading/ContentLoader";
import { getDefault, getTrackerSettings } from "services/settings";
import { updateSettingValue } from "./utils";
import Field from "./Field";
import { ReactSVG } from "react-svg"; // Import the ReactSVG component
import "./styles.scss";

interface Props {
  update: any;
  data: any;
  className?: string;
  model?: any;
  trackerSettings?: any;
}

const FormSettings: React.FC<Props> = ({
  update,
  data,
  className = "",
  model,
  trackerSettings,
}) => {
  const [loading, setLoading] = useState(true);
  const [openTooltip, setOpenTooltip] = useState<string | null>(null);

  // Handle changes in data.trackers
  useEffect(() => {
    if (data.trackers.length === 0) {
      setLoading(true);
      return;
    }

    setLoading(true);
    if (trackerSettings) {
      const model = data.trackers.model;
      if (model) {
        getTrackerSettings(model).then((settings: any) => {
          update(settings);
          setLoading(false);
        });
      } else {
        getDefault().then((defaultSettings: any) => {
          update(defaultSettings);
          setLoading(false);
        });
      }
    } else {
      if (data.trackers && data.trackers.length > 0) {
        const model = data.trackers[0].model;
        if (model) {
          getTrackerSettings(model).then((settings: any) => {
            update(settings);
            setLoading(false);
          });
        } else {
          getDefault().then((defaultSettings: any) => {
            update(defaultSettings);
            setLoading(false);
          });
        }
      }
    }
  }, [data.trackers, update]);

  const handleUpdate = (path: string, setting: string, value: any) => {
    const updatedSettings = { ...data.settings };
    updateSettingValue(path, setting, value, updatedSettings);
    update(updatedSettings);
  };

  const toggleTooltip = (name: string) => {
    if (openTooltip === name) {
      setOpenTooltip(null);
    } else {
      setOpenTooltip(name);
    }
  };

  const renderTooltip = (name: string, text: string) =>
    openTooltip === name && (
      <div
        className="tooltip-content"
        style={{ marginBottom: "5px", marginTop: "-15px", width:"200px" }}
      >
        {text}
      </div>
    );

  const renderForm = () => {
    const hasCollisionSetting = data.settings.tracking.collision;
    console.log(data, "data");
    return (
      <div className={`form-settings ${className}`}>
        {data.settings.tracking && (
          <div>
            <div className="settings-title">Tracking</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Field
                data={data}
                update={handleUpdate}
                path="tracking.params"
                name="mode"
              />
              <ReactSVG
                style={{
                  color: "black",
                  cursor: "pointer",
                  marginLeft: "3px",
                  marginTop: "2px",
                }}
                src={"/assets/svg/info.svg"}
                onClick={() => toggleTooltip("mode")}
              />
            </div>
            {renderTooltip(
              "mode",
              "Changes in tracking frequency will affect battery lifespan"
            )}
          </div>
        )}
        <div>
          <div className="settings-title">Notifications</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={handleUpdate}
              data={data}
              path="notifications.emails"
              name="emails"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginBottom: "5px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => toggleTooltip("emails")}
            />
          </div>
          {renderTooltip(
            "emails",
            "Receive an email for every alarm triggered"
          )}
        </div>

        <div>
          <div className="settings-title">Triggers</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={handleUpdate}
              data={data}
              path="triggers.temperature"
              name="temperature"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginTop: "5px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => toggleTooltip("temperature")}
            />
          </div>
          {renderTooltip(
            "temperature",
            "An alarm will trigger when the temperature drops lower than MIN or higher than MAX (ºC)"
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={handleUpdate}
              data={data}
              path="triggers.idle"
              name="idle"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginTop: "3px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => toggleTooltip("idle")}
            />
          </div>
          {renderTooltip(
            "idle",
            "An alarm will trigger when the device hasn’t moved more than a certain distance in a set amount of time"
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={handleUpdate}
              data={data}
              path="triggers.battery"
              name="battery"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginTop: "5px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => toggleTooltip("battery")}
            />
          </div>
          {renderTooltip(
            "battery",
            "An alarm will trigger when the battery drops below a certain percentage"
          )}
            { data.settings &&
            data.settings.triggers &&
            data.settings.triggers.arrival && <>         <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={handleUpdate}
              data={data}
              path="triggers.arrival"
              name="arrival"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginTop: "5px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => toggleTooltip("arrival")}
            />
          </div>
          {renderTooltip(
            "arrival",
            "An alarm will trigger when the device gets closer than a certain distance to its destination"
          )}
       </>}
 
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={handleUpdate}
              data={data}
              path="triggers.light"
              name="light"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginBottom: "5px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => toggleTooltip("light")}
            />
          </div>
          {renderTooltip(
            "light",
            "An alarm will trigger when the light sensor detects a change from dark to light"
          )}

          {hasCollisionSetting && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Field
                  update={handleUpdate}
                  data={data}
                  path="triggers.collision"
                  name="Shock"
                />
                <ReactSVG
                  style={{
                    color: "black",
                    cursor: "pointer",
                    marginLeft: "3px",
                    marginBottom: "5px",
                  }}
                  src={"/assets/svg/info.svg"}
                  onClick={() => toggleTooltip("collision")}
                />
              </div>
              {renderTooltip(
                "collision",
                "Alarm will be triggered when the sensor detects impact or shock."
              )}
            </>
          )}
          {data.settings &&
            data.settings.triggers &&
            data.settings.triggers.vessel && (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Field
                    update={handleUpdate}
                    data={data}
                    path="triggers.vessel"
                    name="vessel"
                  />
                  <ReactSVG
                    style={{
                      color: "black",
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginBottom: "5px",
                    }}
                    src={"/assets/svg/info.svg"}
                    onClick={() => toggleTooltip("vessel")}
                  />
                </div>
                {renderTooltip(
                  "vessel",
                  "Enable alarms for vessel departure and arrival"
                )}
              </>
            )}
        </div>
      </div>
    );
  };

  return <ContentLoader loading={loading} content={renderForm} />;
};

export default FormSettings;
