import { Tag } from 'components/Tags/List';

export const addTagHandler = (event: any, tagsToUse: Tag[], tags: Tag[], callback: Function) => {
    const tag = tagsToUse.find(({ name }: { name: string }) => event.target.value === name);

    if(tag) {
        tags.push(tag);
        event.target.value = '';

        callback(tags);
      }
};