// import React, { useState, useEffect, FormEvent } from 'react';
// import './styles.scss';
// import { useHistory, useLocation } from 'react-router-dom';

// const EnterResetCode: React.FC = () => {
//   const [code, setCode] = useState<string>('');
//   const [email, setEmail] = useState<string>('');
//   const [resendMessage, setResendMessage] = useState<boolean>(false); // Use a boolean for conditional rendering
//   const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
//   const [timer, setTimer] = useState<number>(0);

//   const history = useHistory();
//   const location = useLocation();

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const emailParam = queryParams.get('email');
//     if (emailParam) {
//       setEmail(emailParam);
//     }
//   }, [location]);

//   useEffect(() => {
//     if (timer > 0) {
//       const intervalId = setInterval(() => {
//         setTimer(timer - 1);
//       }, 1000);
//       return () => clearInterval(intervalId);
//     } else if (timer === 0) {
//       setIsResendDisabled(false); // Enable the resend button when the timer ends
//     }
//   }, [timer]);

//   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     console.log("Code entered: ", code);
//     history.push('/reset-password');
//   };

//   const handleBackToLogin = () => {
//     history.push('/login');  // Navigates back to the login page
//   };

//   const handleResendCode = () => {
//     setResendMessage(true); // Update to show the new message
//     setIsResendDisabled(true);
//     setTimer(30); // Set the timer to 30 seconds
//   };

//   return (
//     <div className="shlk-login">
//       <div className="shlk-login-wrapper">
//         <img className="shlk-login-logo"
//              src="/assets/icon/Naxxar_Full.png"
//              alt="Naxxar" />
//         <div className='Password-Reset Forgot-Password '> <span>Password Reset</span></div>

//         {/* Conditional text rendering */}
//         <div className='Forgot-Password-text '>
//           {resendMessage ? (
//             <span>A new code has been sent to {email}.</span>
//           ) : (
//             <span>We sent a code to {email}. </span>
//           )}
//         </div>

//         <form className="shlk-login-form" onSubmit={handleSubmit}>
//           <div className="shlk-form__container">
//             <input className="shlk-login-input shlk-login-email"
//                    name="code"
//                    type="text"
//                    placeholder="Enter 6-digit code"
//                    value={code}
//                    onChange={(e) => setCode(e.target.value)}
//                    required />
//           </div>
//           <input className="shlk-login-input shlk-login-btn"
//                  type="submit"
//                  value="Verify" />
//         </form>

//         {/* Back to Login and Resend Code Section */}
//         <div className="action-buttons">
//           <div className="back-to-login" onClick={handleBackToLogin}>
//             <span>&larr; Back to Login</span>
//           </div>
//           <div className="resend-code">
//             <button
//               className="resend-code-btn"
//               onClick={handleResendCode}
//               disabled={isResendDisabled}
//             >
//               {isResendDisabled ? `Try again in ${timer} seconds` : 'Resend Code'}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EnterResetCode;

// import React, { useState, useEffect, FormEvent } from 'react';
// import './styles.scss';
// import { useHistory, useLocation } from 'react-router-dom';
// import { send_otp } from 'services/login';

// const EnterResetCode: React.FC = () => {
//   const [code, setCode] = useState<string>('');
//   const [email, setEmail] = useState<string>('');
//   const [resendMessage, setResendMessage] = useState<boolean>(false); // Use a boolean for conditional rendering
//   const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
//   const [timer, setTimer] = useState<number>(0);

//   const history = useHistory();
//   const location = useLocation();

//   // Extract email from the query parameter when the component mounts
//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const emailParam = queryParams.get('email');
//     if (emailParam) {
//       setEmail(emailParam);
//     }
//   }, [location]);

//   // Timer countdown for resend button
//   useEffect(() => {
//     if (timer > 0) {
//       const intervalId = setInterval(() => {
//         setTimer(timer - 1);
//       }, 1000);
//       return () => clearInterval(intervalId);
//     } else if (timer === 0) {
//       setIsResendDisabled(false); // Enable the resend button when the timer ends
//     }
//   }, [timer]);

//   // Handle the form submission to verify the code
//   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     console.log("Code entered: ", code);
//     history.push('/reset-password');
//   };

//   // Navigate back to the login page
//   const handleBackToLogin = () => {
//     history.push('/login');
//   };

//   // Handle resending the OTP code
//   const handleResendCode = async () => {
//     setResendMessage(true); // Show message that the code is being resent
//     setIsResendDisabled(true);
//     setTimer(30); // Set the timer to 30 seconds

//     try {
//       // Call the send_otp API with the email
//       await send_otp({ emails: email });
//     } catch (err) {
//       console.error('Failed to resend OTP:', err);
//       setResendMessage(false); // Reset the message if the resend fails
//     }
//   };

//   return (
//     <div className="shlk-login">
//       <div className="shlk-login-wrapper">
//         <img className="shlk-login-logo"
//              src="/assets/icon/Naxxar_Full.png"
//              alt="Naxxar" />
//         <div className='Password-Reset Forgot-Password '> <span>Password Reset</span></div>

//         {/* Conditional text rendering */}
//         <div className='Forgot-Password-text '>
//           {resendMessage ? (
//             <span>A new code has been sent to {email}.</span>
//           ) : (
//             <span>We sent a code to {email}. </span>
//           )}
//         </div>

//         <form className="shlk-login-form" onSubmit={handleSubmit}>
//           <div className="shlk-form__container">
//             <input className="shlk-login-input shlk-login-email"
//                    name="code"
//                    type="text"
//                    placeholder="Enter 6-digit code"
//                    value={code}
//                    onChange={(e) => setCode(e.target.value)}
//                    required />
//           </div>
//           <input className="shlk-login-input shlk-login-btn"
//                  type="submit"
//                  value="Verify" />
//         </form>

//         {/* Back to Login and Resend Code Section */}
//         <div className="action-buttons">
//           <div className="back-to-login" onClick={handleBackToLogin}>
//             <span>&larr; Back to Login</span>
//           </div>
//           <div className="resend-code">
//             <button
//               className="resend-code-btn"
//               onClick={handleResendCode}
//               disabled={isResendDisabled}
//             >
//               {isResendDisabled ? `Try again in ${timer} seconds` : 'Resend Code'}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EnterResetCode;

// import React, { useState, useEffect, FormEvent } from 'react';
// import './styles.scss';
// import { useHistory } from 'react-router-dom';
// import { send_otp } from 'services/login';  // Import the send_otp function

// const EnterResetCode: React.FC = () => {
//   const [code, setCode] = useState<string>('');
//   const [email, setEmail] = useState<string>('');  // Initialize email state
//   const [resendMessage, setResendMessage] = useState<boolean>(false);
//   const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
//   const [timer, setTimer] = useState<number>(0);

//   const history = useHistory();

//   // Retrieve the email from localStorage when the component mounts
//   useEffect(() => {
//     const storedEmail = localStorage.getItem('resetEmail');  // Optionally use sessionStorage
//     if (storedEmail) {
//       setEmail(storedEmail);
//     } else {
//       // Handle case where email is not found (e.g., navigate to another page)
//       history.push('/forgot-password');
//     }
//   }, [history]);

//   // Timer countdown for resend button
//   useEffect(() => {
//     if (timer > 0) {
//       const intervalId = setInterval(() => {
//         setTimer(timer - 1);
//       }, 1000);
//       return () => clearInterval(intervalId);
//     } else if (timer === 0) {
//       setIsResendDisabled(false);
//     }
//   }, [timer]);

//   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     console.log("Code entered: ", code);
//     history.push('/reset-password');
//   };

//   const handleBackToLogin = () => {
//     history.push('/login');
//   };

//   const handleResendCode = async () => {
//     setResendMessage(true);
//     setIsResendDisabled(true);
//     setTimer(30);  // Set the timer to 30 seconds

//     try {
//       // Resend the OTP using the stored email
//       await send_otp({ emails: email });
//     } catch (err) {
//       console.error('Failed to resend OTP:', err);
//       setResendMessage(false);
//     }
//   };

//   return (
//     <div className="shlk-login">
//       <div className="shlk-login-wrapper">
//         <img className="shlk-login-logo"
//              src="/assets/icon/Naxxar_Full.png"
//              alt="Naxxar" />
//         <div className='Password-Reset Forgot-Password '> <span>Password Reset</span></div>

//         {/* Conditional text rendering */}
//         <div className='Forgot-Password-text '>
//           {resendMessage ? (
//             <span>A new code has been sent to {email}.</span>
//           ) : (
//             <span>We sent a code to {email}. </span>
//           )}
//         </div>

//         <form className="shlk-login-form" onSubmit={handleSubmit}>
//           <div className="shlk-form__container">
//             <input className="shlk-login-input shlk-login-email"
//                    name="code"
//                    type="text"
//                    placeholder="Enter 6-digit code"
//                    value={code}
//                    onChange={(e) => setCode(e.target.value)}
//                    required />
//           </div>
//           <input className="shlk-login-input shlk-login-btn"
//                  type="submit"
//                  value="Verify" />
//         </form>

//         {/* Back to Login and Resend Code Section */}
//         <div className="action-buttons">
//           <div className="back-to-login" onClick={handleBackToLogin}>
//             <span>&larr; Back to Login</span>
//           </div>
//           <div className="resend-code">
//             <button
//               className="resend-code-btn"
//               onClick={handleResendCode}
//               disabled={isResendDisabled}
//             >
//               {isResendDisabled ? `Try again in ${timer} seconds` : 'Resend Code'}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EnterResetCode;

// import React, { useState, useEffect, FormEvent } from "react";
// import "./styles.scss";
// import { useHistory, withRouter } from "react-router-dom";
// import { send_otp, verify_otp } from "services/login"; // Import the send_otp function

// const EnterResetCode: React.FC = () => {
//   const [code, setCode] = useState<string>("");
//   const [email, setEmail] = useState<string>(""); // Store email here
//   const [resendMessage, setResendMessage] = useState<boolean>(false);
//   const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
//   const [timer, setTimer] = useState<number>(0); // Start with 0
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const [token, setToken] = useState<string>("");
//   const history = useHistory();

//   // Retrieve the email from localStorage when the component mounts
//   useEffect(() => {
//     const storedEmail = localStorage.getItem("resetEmail"); // Or sessionStorage
//     if (storedEmail) {
//       setEmail(storedEmail);
//     } else {
//       // If no email is found, redirect to forgot-password page
//       history.push("/forgot-password");
//     }
//   }, [history]);

//   // Timer countdown for resend button
//   useEffect(() => {
//     if (timer > 0) {
//       const intervalId = setInterval(() => {
//         setTimer(timer - 1);
//       }, 1000);
//       return () => clearInterval(intervalId);
//     } else if (timer === 0) {
//       setIsResendDisabled(false);
//     }
//   }, [timer]);

//   const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);

//     try {
//       // Trigger the send_otp API
//       const response = await verify_otp({ emails: email, otp: code });

//       if (response.success == true) {
//         // If successful, proceed with storing email and navigation

//         localStorage.setItem("resetEmail", email);
//         localStorage.setItem("token", response.token);
//         history.push("/reset-password");
//       } else {
//         // Prevent any further action if response contains an error
//         setError(response.message);
//       }
//     } catch (err) {
//       setError("Failed to send OTP. Please check your email and try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleBackToLogin = () => {
//     history.push("/login");
//   };

//   const handleResendCode = async () => {
//     setResendMessage(true);
//     setIsResendDisabled(true);
//     setTimer(30); // Set the timer to 30 seconds

//     try {
//       // Resend the OTP using the stored email
//       await send_otp({ emails: email });
//     } catch (err) {
//       console.error("Failed to resend OTP:", err);
//       setResendMessage(false);
//     }
//   };

//   return (
//     <div className="shlk-login">
//       <div className="shlk-login-wrapper">
//         <img
//           className="shlk-login-logo"
//           src="/assets/icon/Naxxar_Full.png"
//           alt="Naxxar"
//         />
//         <div className="Password-Reset Forgot-Password ">
//           {" "}
//           <span>Password Reset</span>
//         </div>

//         <div className="Forgot-Password-text ">
//           {resendMessage ? (
//             <span>A new code has been sent to {email}.</span>
//           ) : (
//             <span>We sent a code to {email}. </span>
//           )}
//         </div>

//         <form className="shlk-login-form" onSubmit={handleSubmit}>
//           <div className="shlk-form__container padding_adjustment">
//             <input
//               className="shlk-login-input shlk-login-email"
//               name="code"
//               type="text"
//               placeholder="Enter 6-digit code"
//               value={code}
//               onChange={(e) => setCode(e.target.value)}
//               required
//             />
//           </div>
//           <input
//             className="shlk-login-input shlk-login-btn padding_adjustment"
//             type="submit"
//             value={loading ? "Verifying..." : "Verify"}
//           />
//         </form>
//         {error && <div className="error-message">{error}</div>}
//         {/* Back to Login and Resend Code Section */}
//         <div className="action-buttons">
//           <div
//             className="back-to-login padding_adjustment"
//             onClick={handleBackToLogin}
//           >
//             <span>&larr; Back to Login</span>
//           </div>
//           <div className="resend-code">
//             <button
//               className="resend-code-btn"
//               style={{ cursor: isResendDisabled ? "not-allowed" : "pointer" }}
//               onClick={handleResendCode}
//               disabled={isResendDisabled}
//             >
//               {isResendDisabled
//                 ? `Try again in ${timer} seconds`
//                 : "Resend Code"}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default withRouter(EnterResetCode);

// import React, { useState, useEffect, FormEvent } from "react";
// import "./styles.scss";
// import { useHistory, withRouter } from "react-router-dom";
// import { send_otp, verify_otp } from "services/login"; // Import the send_otp function

// const EnterResetCode: React.FC = () => {
//   const [code, setCode] = useState<string>("");
//   const [email, setEmail] = useState<string>("");
//   const [resendMessage, setResendMessage] = useState<boolean>(false);
//   const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
//   const [timer, setTimer] = useState<number>(0);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const [token, setToken] = useState<string>("");
//   const history = useHistory();

//   // Retrieve the email from localStorage when the component mounts
//   useEffect(() => {
//     const storedEmail = localStorage.getItem("resetEmail");
//     if (storedEmail) {
//       setEmail(storedEmail);
//     } else {
//       history.push("/forgot-password");
//     }
//   }, [history]);

//   // Timer countdown for resend button
//   useEffect(() => {
//     if (timer > 0) {
//       const intervalId = setInterval(() => {
//         setTimer((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(intervalId);
//     } else if (timer === 0) {
//       setIsResendDisabled(false);
//     }
//   }, [timer]);

//   const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await verify_otp({ emails: email, otp: code });

//       if (response.success === true) {
//         localStorage.setItem("resetEmail", email);
//         localStorage.setItem("token", response.token);
//         history.push("/reset-password");
//       } else {
//         setError(response.message);
//       }
//     } catch (err) {
//       setError("Failed to send OTP. Please check your email and try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleResendCode = async () => {
 
//     console.log("Resend button clicked");
//     setResendMessage(false);
//     setIsResendDisabled(true);
//     setTimer(30); // Reset the timer to 30 seconds for each resend

//     try {
//       await send_otp({ emails: email });
//       setResendMessage(true);
//     } catch (err) {
//       console.error("Failed to resend OTP:", err);
//       setResendMessage(false);
//       setIsResendDisabled(false); // Allow user to attempt resend again
//     }
//   };
//   const handleBackToLogin = () => {
//         history.push("/login");
//       };

//   return (
//     <div className="shlk-login">
//       <div className="shlk-login-wrapper">
//         <img
//           className="shlk-login-logo"
//           src="/assets/icon/Naxxar_Full.png"
//           alt="Naxxar"
//         />
//         <div className="Password-Reset Forgot-Password ">
//           {" "}
//           <span>Password Reset</span>
//         </div>

//         <div className="Forgot-Password-text ">
//           {resendMessage ? (
//             <span>A new code has been sent to {email}.</span>
//           ) : (
//             <span>We sent a code to {email}. </span>
//           )}
//         </div>

//         <form className="shlk-login-form" onSubmit={handleSubmit}>
//           <div className="shlk-form__container padding_adjustment">
//             <input
//               className="shlk-login-input shlk-login-email"
//               name="code"
//               type="text"
//               placeholder="Enter 6-digit code"
//               value={code}
//               onChange={(e) => setCode(e.target.value)}
//               required
//             />
//           </div>
//           <input
//             className="shlk-login-input shlk-login-btn padding_adjustment"
//             type="submit"
//             value={loading ? "Verifying..." : "Verify"}
//           />
//         </form>
//         {error && <div className="error-message">{error}</div>}
        
//         <div className="action-buttons">
//           <div
//             className="back-to-login padding_adjustment"
//             onClick={handleBackToLogin}
//           >
//             <span>&larr; Back to Login</span>
//           </div>
//           <div className="resend-code">
//             <button
//               className="resend-code-btn"
//               style={{ cursor: isResendDisabled ? "not-allowed" : "pointer" }}
//               onClick={handleResendCode}
//               disabled={isResendDisabled}
//             >
//               {isResendDisabled
//                 ? `Try again in ${timer} seconds`
//                 : "Resend Code"}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default withRouter(EnterResetCode);

import React, { useState, useEffect, FormEvent } from "react";
import "./styles.scss";
import { useHistory, withRouter } from "react-router-dom";
import { send_otp, verify_otp } from "services/login";

const EnterResetCode: React.FC = () => {
  const [code, setCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [resendMessage, setResendMessage] = useState<boolean>(false);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string>("");
  const history = useHistory();

  // Retrieve the email from localStorage when the component mounts
  useEffect(() => {
    const storedEmail = localStorage.getItem("resetEmail");
    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      history.push("/forgot-password");
    }
  }, [history]);

  // Timer countdown for resend button
  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }
  }, [timer]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await verify_otp({ emails: email, otp: code });

      if (response.success === true) {
        localStorage.setItem("resetEmail", email);
        localStorage.setItem("token", response.token);
        history.push("/reset-password");
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError("Failed to send OTP. Please check your email and try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    console.log("Resend button clicked");
    setResendMessage(false);
    setIsResendDisabled(true);
    setTimer(30); // Reset the timer to 30 seconds for each resend

    try {
      console.log("Api Calling");
      await send_otp({ emails: email });
      setResendMessage(true);
      console.log("Api Called");
    } catch (err) {
      console.error("Failed to resend OTP:", err);
      setResendMessage(false);
      setIsResendDisabled(false); // Allow user to attempt resend again
    } finally{
      window.location.reload();
    }
  };

  const handleBackToLogin = () => {
    sessionStorage.setItem('refreshLogin', 'true');
    history.push("/login");
  };

  return (
    <div className="shlk-login">
      <div className="shlk-login-wrapper">
        <img
          className="shlk-login-logo"
          src="/assets/icon/Naxxar_Full.png"
          alt="Naxxar"
        />
        <div className="Password-Reset Forgot-Password ">
          <span>Password Reset</span>
        </div>

        <div className="Forgot-Password-text ">
          {resendMessage ? (
            <span>A new code has been sent to {email}.</span>
          ) : (
            <span>We sent a code to {email}. </span>
          )}
        </div>

        <form className="shlk-login-form" onSubmit={handleSubmit}>
          <div className="shlk-form__container padding_adjustment">
            <input
              className="shlk-login-input shlk-login-email"
              name="code"
              type="text"
              placeholder="Enter 6-digit code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </div>
          <input
            className="shlk-login-input shlk-login-btn padding_adjustment"
            type="submit"
            value={loading ? "Verifying..." : "Verify"}
          />
        </form>
        {error && <div className="error-message">{error}</div>}

        <div className="action-buttons">
          <div
            className="back-to-login padding_adjustment"
            onClick={handleBackToLogin}
          >
            <span>&larr; Back to Login</span>
          </div>
          <div className="resend-code">
            <button
              className="resend-code-btn"
              style={{ cursor: isResendDisabled ? "not-allowed" : "pointer" }}
              onClick={handleResendCode}
              disabled={isResendDisabled}
            >
              {isResendDisabled
                ? `Try again in ${timer} seconds`
                : "Resend Code"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EnterResetCode);
