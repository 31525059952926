// import React, { Component } from 'react';
// import Button from 'components/Inputs/Button';
// import ContentLoader from 'components/Loading/ContentLoader';
// import Table from 'components/Table';
// import { withRouter, RouteComponentProps } from 'react-router-dom';
// import { removeMember } from 'services/tags';
// import { TagsContext } from '../../../context';
// import './styles.scss';

// interface Props extends RouteComponentProps {
//     tag: any;
// }

// interface State {
//     loading: boolean;
// }

// class MembersInfoTablesTag extends Component<Props, State> {
//     constructor(props: Props) {
//         super(props);

//         this.state = {
//             loading: false
//         };
//     }

//     removeMember = (member: any) => {
//         if(window.confirm(`Are you sure you want to remove ${member.user.email} from this tag?`)) {
//             this.setState({loading: true});

//             removeMember({ user: member.user._id, id: this.props.tag._id })
//                 .then(() => {
//                     const tag = this.props.tag;
//                     tag.members = tag.members.filter((m: any) => m !== member);

//                     this.context.updateTags(this.context.tags);
//                     this.setState({ loading: false });
//                 });
//         }
//     }

//     render() {
//         const members = this.props.tag.members;
//         const membersLength = members.length;

//         return <ContentLoader loading={this.state.loading} content={() =>
//             <div className="tags-references tags-ref-members">
//                 <div className='references-title'>
//                     <h3>
//                         {
//                         membersLength ?
//                             `${membersLength} member${membersLength > 1 ? 's' : ''}` :
//                             'No members'
//                         }
//                     </h3>
//                     <Button
//                         className="references-share"
//                         category="Tags"
//                         action="Share"
//                         icon="share"
//                         label={this.props.tag.name}
//                         to={`/tags/${this.props.tag._id}#share`}
//                     >
//                         Share
//                     </Button>
//                 </div>
//                 { membersLength > 0 &&
//                    <Table headings={['Email', 'Roles']} rows={
//                         this.props.tag.members.map((member: any) => ({
//                             id: member.user._id,
//                             onClick: () => this.removeMember(member),
//                             cells: [
//                                 { value: member.user.email },
//                                 { value: member.permissions.roles.join(', ') }
//                             ]
//                         }))
//                     } />
//                 }
//             </div>
//         }/>;
//     }
// }

// MembersInfoTablesTag.contextType = TagsContext;

// export default withRouter(MembersInfoTablesTag);

import React, { Component } from 'react';
import Button from 'components/Inputs/Button';
import ContentLoader from 'components/Loading/ContentLoader';
import Table from 'components/Table';
import ContentPopup from 'components/ContentPopup';
import SharePopup from 'components/Popups/Share';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { removeMember, inviteMember, getMemberRoles } from 'services/tags';
import { TagsContext } from '../../../context';
import './styles.scss';

interface Props extends RouteComponentProps {
    tag: any;
}

interface State {
    loading: boolean;
    showSharePopup: boolean;
}

class MembersInfoTablesTag extends Component<Props, State> {
    static contextType = TagsContext;

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            showSharePopup: false,
        };
    }

    componentDidMount() {
        // Listen to hash changes to handle popup state
        window.addEventListener('hashchange', this.handleHashChange);
        this.handleHashChange(); // Handle the initial hash state
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }

    handleHashChange = () => {
        this.setState({
            showSharePopup: window.location.hash === '#share',
        });
    };

    openSharePopup = () => {
        window.location.hash = '#share'; // Update URL hash to open popup
    };

    closeSharePopup = () => {
        window.history.pushState('', document.title, window.location.pathname + window.location.search); // Remove hash from URL
        this.setState({ showSharePopup: false });
    };

    removeMember = (member: any) => {
        if (window.confirm(`Are you sure you want to remove ${member.user.email} from this tag?`)) {
            this.setState({ loading: true });

            removeMember({ user: member.user._id, id: this.props.tag._id })
                .then(() => {
                    const tag = this.props.tag;
                    tag.members = tag.members.filter((m: any) => m !== member);

                    this.context.updateTags(this.context.tags);
                    this.setState({ loading: false });
                });
        }
    };

    render() {
        const { loading, showSharePopup } = this.state;
        const { tag } = this.props;
        const members = tag.members;
        const membersLength = members.length;

        return (
            <>
                <ContentLoader loading={loading} content={() =>
                    <div className="tags-references tags-ref-members">
                        <div className='references-title'>
                            <h3>
                                {
                                    membersLength ?
                                        `${membersLength} member${membersLength > 1 ? 's' : ''}` :
                                        'No members'
                                }
                            </h3>
                            <Button
                                className="references-share"
                                category="Tags"
                                action="Share"
                                icon="share"
                                label={tag.name}
                                onClick={this.openSharePopup} // Open popup on button click
                            >
                                Share
                            </Button>
                        </div>
                        {membersLength > 0 &&
                            <Table headings={['Email', 'Roles']} rows={
                                tag.members.map((member: any) => ({
                                    id: member.user._id,
                                    onClick: () => this.removeMember(member),
                                    cells: [
                                        { value: member.user.email },
                                        { value: member.permissions.roles.join(', ') }
                                    ]
                                }))
                            } />
                        }
                    </div>
                } />

                {/* Share Popup Component */}
                {showSharePopup &&
                    <ContentPopup hash="share" content={this.closeSharePopup}>
                        <SharePopup
                            type='tag'
                            inviteMember={inviteMember}
                            getRoles={getMemberRoles}
                            document={tag}
                            close={this.closeSharePopup}
                        />
                    </ContentPopup>
                }
            </>
        );
    }
}

export default withRouter(MembersInfoTablesTag);
