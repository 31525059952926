import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReactSVG } from 'react-svg';
import XLSX from 'xlsx/xlsx.mini';
import Table from 'components/Table';
import Button from 'components/Inputs/Button';
import { hasPermission } from 'utils/auth';
import { getNamedSource } from 'utils/measurement';
import { format as formatDate } from 'utils/date';
import './styles.scss';

interface Props extends RouteComponentProps {
  close: any;
  info: { measurements: any[], tracker: any } | null;
}

type State = {
  headings: string[];
};

const DELAY_MILLISECONDS_THRESHOLD = 540000; // 9 minutes
const INFO_HEADINGS = ['Device', 'Starting date', 'Ending date'];

const HEADINGS = {
  index: 'Index',
  date: 'Measurement date',
  latency: 'Latency',
  battery: 'Battery %',
  lat: 'Lat',
  lng: 'Lng',
  city: 'City',
  tech: 'Location source',
  temperature: 'Temp ºC',
  motion: 'Motion',
  light: 'Light',
  tilt: 'Tilt',
  collision: 'Collision'
};

const DEFAULT_HEADINGS = [
  HEADINGS.index,
  HEADINGS.date,
  HEADINGS.latency,
  HEADINGS.lat,
  HEADINGS.lng,
  HEADINGS.city,
  HEADINGS.tech,
  HEADINGS.temperature,
  HEADINGS.motion,
  HEADINGS.light
];

const EXTRA_HEADINGS = [HEADINGS.tilt, HEADINGS.collision];

class Raw extends Component<Props, State> {
  csvFileHref: string | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      headings: DEFAULT_HEADINGS
    };
  }

  componentDidMount() {
    if(this.props.info) {
      const headings = [...DEFAULT_HEADINGS];
      const extraHeadings = [...EXTRA_HEADINGS];
      const headingsDict: any = {
        [HEADINGS.tilt]: 'angle',
        [HEADINGS.collision]: 'collision'
      };

      for(const measurement of this.props.info.measurements) {
        if(!extraHeadings.length) break;
        extraHeadings.forEach((heading, index) => {
          if(measurement[headingsDict[heading]]) {
            headings.push(...extraHeadings.splice(index, 1));
          }
        });
      }

      headings.push(HEADINGS.battery);

      this.setState({ headings });
    }
  }

  generateXLSX(rawInfo: string[][],measurements: any, filename: string) {
    const wb = XLSX.utils.book_new();
    const data = measurements.map(({ data }: { data: any[] }) => data);
    const ws = XLSX.utils.aoa_to_sheet([...rawInfo, [], this.state.headings, ...data]);

    XLSX.utils.book_append_sheet(wb, ws, filename);

    XLSX.writeFile(wb, `${filename}.xlsx`);
  }

  generateCSV(measurements: any[]) {
    const headings = this.state.headings.join(',');
    const body = measurements.map(({ data }: { data: any[] }) =>
        Object.values(data).join(',')
      ).join('\n');

    const csv = [headings, body].join('\n');

    this.cleanWindowCSVHref();
    this.csvFileHref = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));

    return this.csvFileHref;
  }

  cleanWindowCSVHref = () => {
    if(this.csvFileHref) {
      window.URL.revokeObjectURL(this.csvFileHref);
    }
  }

  viewMeasurement = (data: any) => {
    this.props.close(data);
  }

  getTableRowsData = ({ id, data, raw }: { id: string; data: any; raw: string}) => {
    return {
      id,
      onClick: () => {
        const selection = window.getSelection();

        if(!selection || selection.toString().length < 1) {
          this.viewMeasurement(raw);
          hasPermission("APP_DEV") && console.log(raw);
        }
      },
      cells: data.map((value: string) => ({ value }))
    };
  }

  getMeasurementInfo = (measurement: any, index: number) => {
    const delay = (new Date(measurement.createdAt).getTime() - new Date(measurement.time).getTime());
    const data: any = {
      [HEADINGS.index]: index,
      [HEADINGS.date]: formatDate(measurement.time),
      [HEADINGS.latency]: delay > DELAY_MILLISECONDS_THRESHOLD ? formatDate(measurement.createdAt) : '',
      [HEADINGS.lat]: measurement.location.lat.toFixed(4),
      [HEADINGS.lng]: measurement.location.lng.toFixed(4),
      [HEADINGS.city]: measurement.location.city === 'Unknown' ? '' : measurement.location.city,
      [HEADINGS.tech]: getNamedSource(measurement.source),
      [HEADINGS.temperature]: measurement.temperature,
      [HEADINGS.motion]: measurement.movement ? 'Yes' : '',
      [HEADINGS.light]: measurement.light ? 'Yes' : '',
      [HEADINGS.collision]: measurement.collision ? 'Yes' : '',
      [HEADINGS.tilt]: measurement.angle && measurement.angle > 45 ? 'H' : '',
      [HEADINGS.battery]: measurement.battery
    };

    return {
      id: measurement._id,
      raw: measurement,
      data: this.state.headings.map((heading: any) => data[heading])
    };
  }

  renderInfo = () => {
    const info = this.props.info!;
    const measurements = info.measurements;
    const trackerName = info.tracker.name;
    const rawInfo = [INFO_HEADINGS, [trackerName, formatDate(measurements[0].time), formatDate(measurements[measurements.length - 1].time)]];
    const measurementsInfo = info.measurements.map(this.getMeasurementInfo);
    const filename = trackerName.replaceAll(' ', '_');

    return (
      <>
        <div className="shlk-title__container">
          <h2 className="shlk-title">{trackerName}</h2>
        </div>
        <Table headings={[...this.state.headings]} rows={measurementsInfo.map(this.getTableRowsData)}/>
        <div className="shlk-raw-download__container">
          <div className="download-title">
            Download as:
          </div>
          <Button
            className="download-button"
            category="Raw"
            action="Click Download CSV"
            label={trackerName}
            to={this.generateCSV(measurementsInfo)}
            download={filename}
          >
            CSV<ReactSVG className="download-icon" src="/assets/svg/download.svg"></ReactSVG>
          </Button>
          <Button
            className="download-button"
            category="Raw"
            action="Click Download Excel"
            label={trackerName}
            onClick={() => this.generateXLSX(rawInfo, measurementsInfo, filename)}
          >
            Excel<ReactSVG className="download-icon" src="/assets/svg/download.svg"></ReactSVG>
          </Button>
        </div>
      </>
    );
  }

  componentWillUnmount = this.cleanWindowCSVHref;

  render() {
    return (
      <div className="shlk-page-raw">
        {
          this.props.info && this.props.info.measurements.length ?
            this.renderInfo() :
            <h3>There is no data yet</h3>
        }
      </div>
    );
  }
}

export default withRouter(Raw);