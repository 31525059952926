import React, { Component } from 'react';
import { RouteProps } from 'react-router-dom';

export default class NotFound extends Component<RouteProps> {
  render() {
    const error = {
      stack: `Not Found Path: ${this.props.location ? this.props.location.pathname : document.location.pathname}`,
      response: {
        status: 404,
        statusText: 'Not Found'
      }
    };

    throw error;

    return <></>;
  }
}
