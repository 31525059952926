import React from 'react';
import Icon from 'components/Icon';
import { format as formatDate } from 'utils/date';
import Marker, { MeasMarkerProps } from '../AbstractMarker';
import './styles.scss';

export default class DotMarker<T extends MeasMarkerProps> extends Marker<T> {
  name = 'DotMarker';

  getTriggers = (meas: any) => {
    const triggers = [];

    if(meas.light) triggers.push('light');
    if(meas.motion) triggers.push('motion');

    return triggers;
  }

  hasPopup = true;
  renderPopupContent = () => {
    const meas = this.props.measurement;
    const triggers = this.getTriggers(meas);

    return (<div className="marker-popup">
                <p className="marker-popup-field icon"><Icon className="marker-popup-icon" name="adjust" size={12}/><b>LOCATION</b></p>
                <p className="marker-popup-field"><b>Index:</b> {this.props.index}</p>
                <p className="marker-popup-field">{formatDate(meas.time)}</p>
                <p className="marker-popup-field"><b>Temperature:</b> {meas.temperature} ºC</p>
                { meas.battery && <p className="marker-popup-field"><b>Battery:</b> {meas.battery}%</p> }
                { triggers.length > 0 && <p className="marker-popup-field"><b>Triggered:</b> {triggers.join(', ')}</p> }
            </div>);
  }

  renderMarker() {
    const type = this.props.source === "waypoint" ? "dot" : "marker";

    return (<span className={`shlk-marker marker-dot-hitbox marker-type-${type}`}>
      <span className={`marker-dot marker-dot-${this.props.source.replace('/', '')}`}></span>
    </span>);
  }
}