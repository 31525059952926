import React from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import "chart.js/auto";

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor?: string;
    fill?: boolean;
    borderWidth?: any;
    barPercentage?: any;
    barThickness?: any;
    maxBarThickness?: any;
    minBarLength?: any;
  }[];
}

const ChartWrapper: React.FC<{
  type: string;
  data: any;
  options: any;
  plugins: any;
}> = ({ type, data, options, plugins }) => {
  return (
    <>
      {type === "line" && (
        <Line
          data={data} // Pass the data directly
          width={500}
          height={250}
          options={options}
        />
      )}

      {type === "bar" && (
        <Bar
          data={data} // Pass the data directly
          width={200}
          height={450}
          options={options}
        />
      )}
      {type === "doughnut" && (
        <Doughnut
        
          data={data} // Pass the data directly
          options={options}
          plugins={plugins}
        />
      )}
    </>
  );
};

export default ChartWrapper;
