import React, { Component } from 'react';
import Button from 'components/Inputs/Button';
import Input from 'components/Inputs/Input/Default';
import Selectable from 'components/Inputs/Selectable';
import ContentLoader from 'components/Loading/ContentLoader';
import Page from 'components/ControlBars/Page';
import { createCompany } from 'services/company';
import { toggle } from 'utils/functions';
import "./styles.scss";

interface State {
  loading: boolean;
  data: any;
}

export default class AdminCompany extends Component<{}, State> {
  constructor(props: {}){
    super(props);

    this.state = {
      loading : false,
      data: {
        projects: []
      }
    };
  }

  inputChange = (event: any) => {
    const { value, name } = event.target;

    this.setState({
      data: Object.assign({}, this.state.data, {
        [name]: value
      })
    });
  }

  create = () => {
    this.setState({ loading : true });

    createCompany(this.state.data)
      .then(() => window.alert(`Company ${this.state.data.companyName} created`))
      .then(() => this.setState({ loading : false, data : { projects: [] }}))
      .catch(() => {
        window.alert("There was a problem creating the company\nContact the tech team");
        this.setState({ loading : false, data : {}});
      });
  }

  renderContent = () =>
      <>
        <h1>Create Company</h1>

        <hr/>
        <h2>Company</h2>
        <Input name="companyName" type="text" label="Company Name" onChange={this.inputChange}/>

        <hr/>
        <h2>User</h2>
        <Input name="userName" type="text" label="User Name" onChange={this.inputChange}/>
        <Input name="password" type="text" label="Password" onChange={this.inputChange}/>
        <Input name="email" type="email" label="Email" onChange={this.inputChange}/>

        <h4>Projects</h4>
        <Selectable name="eShell"
                  checked={this.state.data.projects.includes('eshell')}
                  onClick={() => this.setState({
                    data: Object.assign(
                      this.state.data,
                      { projects: toggle(this.state.data.projects, 'eshell')}
                    )
                  })}/>
        <hr/>
        <Button category="Admin" action="Create Company" label={this.state.data.companyName} onClick={this.create}>
          Create
        </Button>
      </>

  render() {
    return <Page className="shlk-admin-company">
        <ContentLoader content={this.renderContent}
                        loading={this.state.loading} />
    </Page>;
  }
}
