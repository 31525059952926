import React, { useRef, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import WaypointMarker from '../Markers/WaypointMarker';
import LocationMarker from '../Markers/Location';

interface CustomMarkerOptions {
  origin?: { label: { text: string; color: string } };
  destination?: { label: { text: string; color: string } };
}


const MapComponent = ({ waypoints, destination, origin, sea_route, isDrivable, airports = {}, airport_driving = [], seaport_driving = []}
  : { waypoints: any[], destination: any, origin: any, sea_route: any[], isDrivable: boolean, airports: any, airport_driving: any[], seaport_driving: any[]  }) => {

  const mapRef = useRef<google.maps.Map>(); // Annotate with Google Maps Map type
  const mapsRef = useRef<typeof google.maps>(); // Annotate with Google Maps type

  

  const trackerLineRef = useRef<google.maps.Polyline>(); // Annotate with Google Maps Polyline type
  const seaRouteRef = useRef<google.maps.Polyline>(); // Add a ref for sea_route polyline

  const drivableRouteRef = useRef<google.maps.DirectionsRenderer>(); // Ref for drivable route
  const airportRouteRef = useRef<google.maps.Polyline>();

  const seaportDriveRouteRefOrigin = useRef<google.maps.DirectionsRenderer>(); 
  const seaportDriveRouteRefDestination = useRef<google.maps.DirectionsRenderer>();
  const airportDriveRouteRefOrigin = useRef<google.maps.DirectionsRenderer>(); 
  const airportDriveRouteRefDestination = useRef<google.maps.DirectionsRenderer>();

  const [mapsApiLoaded, setMapsApiLoaded] = useState(false);

  

  const handleApiLoaded = ({ map, maps }: { map: any, maps: any }) => {
    mapRef.current = map;
    mapsRef.current = maps;
    updateMapBounds();
    updatePolyline();
    updateSeaRoute();
    setMapsApiLoaded(true); // Set API loaded state
  };

  const renderSeaportRoute = () => {
    // Implement similar logic as renderDrivableRoute
    // for seaport_driving using seaportRouteRef
    if (seaportDriveRouteRefOrigin.current) {
      seaportDriveRouteRefOrigin.current.setMap(null);
    }

    if (seaportDriveRouteRefDestination.current) {
      seaportDriveRouteRefDestination.current.setMap(null);
    }

    if (mapsRef.current) {
      const directionsService = new mapsRef.current.DirectionsService();

      const request = {
        origin: new mapsRef.current.LatLng(seaport_driving[0].origin.lat, seaport_driving[0].origin.lng),
        destination: new mapsRef.current.LatLng(seaport_driving[0].destination.lat, seaport_driving[0].destination.lng),
        travelMode: mapsRef.current.TravelMode.DRIVING // Ensure drivable route
      };

      directionsService.route(request, (response, status) => {
        if (mapsRef.current && status === mapsRef.current.DirectionsStatus.OK) {
          seaportDriveRouteRefOrigin.current = new mapsRef.current.DirectionsRenderer({
            map: mapRef.current,
            directions: response
          });
          const directionsRenderer = seaportDriveRouteRefOrigin.current; // Get the DirectionsRenderer object
          directionsRenderer.setOptions({
            suppressMarkers: true, // Hide the default markers
            polylineOptions: {
              strokeColor: 'teal' // Customize the drivable route color
            }
          });
        } else {
          console.error('Directions request failed due to:', status);
        }
      });

      const request2 = {
        origin: new mapsRef.current.LatLng(seaport_driving[1].origin.lat, seaport_driving[1].origin.lng),
        destination: new mapsRef.current.LatLng(seaport_driving[1].destination.lat, seaport_driving[1].destination.lng),
        travelMode: mapsRef.current.TravelMode.DRIVING // Ensure drivable route
      };

      directionsService.route(request2, (response, status) => {
        if (mapsRef.current && status === mapsRef.current.DirectionsStatus.OK) {
          seaportDriveRouteRefDestination.current = new mapsRef.current.DirectionsRenderer({
            map: mapRef.current,
            directions: response
          });
          const directionsRenderer = seaportDriveRouteRefDestination.current; // Get the DirectionsRenderer object
          directionsRenderer.setOptions({
            suppressMarkers: true, // Hide the default markers
            polylineOptions: {
              strokeColor: 'teal' // Customize the drivable route color
            }
          });
        } else {
          console.error('Directions request failed due to:', status);
        }
      });
    }

  };

  const renderAirportRoute = () => {
    // Implement similar logic as renderDrivableRoute
    // for airport_driving using airportRouteRef

    console.log("airport_driving:", airport_driving);
       
    if (airportDriveRouteRefOrigin.current) {
      airportDriveRouteRefOrigin.current.setMap(null);
    }

    if (airportDriveRouteRefDestination.current) {
      airportDriveRouteRefDestination.current.setMap(null);
    } 
  

    if (mapsRef.current) {
      console.log("mapsRef.current:", mapsRef.current);
      const directionsService = new mapsRef.current.DirectionsService();
  
      const request = {
        origin: new mapsRef.current.LatLng(airport_driving[0].origin.lat, airport_driving[0].origin.lng),
        destination: new mapsRef.current.LatLng(airport_driving[0].destination.lat, airport_driving[0].destination.lng),
        travelMode: mapsRef.current.TravelMode.DRIVING // Ensure drivable route
      };
  
      directionsService.route(request, (response, status) => {
        console.log("Status:", status);
        console.log("response:", response);
        if (mapsRef.current && status === mapsRef.current.DirectionsStatus.OK) {
          airportDriveRouteRefOrigin.current = new mapsRef.current.DirectionsRenderer({
            map: mapRef.current,
            directions: response
          });
          const directionsRenderer = airportDriveRouteRefOrigin.current; // Get the DirectionsRenderer object
          directionsRenderer.setOptions({
            suppressMarkers: true, // Hide the default markers
            polylineOptions: {
              strokeColor: 'blue' // Customize the drivable route color
            }
          });
        } else {
          console.error('Directions request failed due to:', status);
        }
      });

      const request2 = {
        origin: new mapsRef.current.LatLng(airport_driving[1].origin.lat, airport_driving[1].origin.lng),
        destination: new mapsRef.current.LatLng(airport_driving[1].destination.lat, airport_driving[1].destination.lng),
        travelMode: mapsRef.current.TravelMode.DRIVING // Ensure drivable route
      };

      directionsService.route(request2, (response, status) => {
        if (mapsRef.current && status === mapsRef.current.DirectionsStatus.OK) {
          airportDriveRouteRefDestination.current = new mapsRef.current.DirectionsRenderer({
            map: mapRef.current,
            directions: response
          });
          const directionsRenderer = airportDriveRouteRefDestination.current; // Get the DirectionsRenderer object
          directionsRenderer.setOptions({
            suppressMarkers: true, // Hide the default markers
            polylineOptions: {
              strokeColor: 'blue' // Customize the drivable route color
            }
          });
        } else {
          console.error('Directions request failed due to:', status);
        }
      });
    }
  };

  const renderDrivableRoute = () => {

    if (drivableRouteRef.current) {
      drivableRouteRef.current.setMap(null);
    }

    console.log("Map ref current:", mapsRef.current);

    if (mapsRef.current) {
      const directionsService = new mapsRef.current.DirectionsService();

      const request = {
        origin: new mapsRef.current.LatLng(origin.location.lat, origin.location.lng),
        destination: new mapsRef.current.LatLng(destination.location.lat, destination.location.lng),
        travelMode: mapsRef.current.TravelMode.DRIVING // Ensure drivable route
      };

      directionsService.route(request, (response, status) => {
        if (mapsRef.current && status === mapsRef.current.DirectionsStatus.OK) {
          drivableRouteRef.current = new mapsRef.current.DirectionsRenderer({
            map: mapRef.current,
            directions: response
          });
          const directionsRenderer = drivableRouteRef.current; // Get the DirectionsRenderer object
          directionsRenderer.setOptions({
            suppressMarkers: true, // Hide the default markers
            polylineOptions: {
              strokeColor: 'black' // Customize the drivable route color
            }
          });
        } else {
          console.error('Directions request failed due to:', status);
        }
      });
    }
  };

  const updateSeaRoute = () => {
    if (seaRouteRef.current) {
      seaRouteRef.current.setMap(null);
    }

    const path = sea_route.map((point: { lat: number, lng: number }) => ({ lat: point.lat, lng: point.lng }));
    if (mapsRef.current) {
      seaRouteRef.current = new mapsRef.current.Polyline({
        path,
        geodesic: true, // Ensure accurate rendering across long distances
        strokeColor: 'teal', // Customize color as desired
        strokeOpacity: 0.8,
        strokeWeight: 4
      });
    }

    if (seaRouteRef.current && mapRef.current) {
      seaRouteRef.current.setMap(mapRef.current);
    }
  };

  const updateMapBounds = () => {
    if (mapRef.current && mapsRef.current && origin && destination) {
      const bounds = new mapsRef.current.LatLngBounds();
      bounds.extend(new mapsRef.current.LatLng(origin.location.lat, origin.location.lng));
      bounds.extend(new mapsRef.current.LatLng(destination.location.lat, destination.location.lng));
      mapRef.current.fitBounds(bounds);
    }
  };

  const updatePolyline = () => {
    if (trackerLineRef.current) {
      trackerLineRef.current.setMap(null);
    }

    const path = waypoints.map(point => ({ lat: point.lat, lng: point.lng }));
    if (mapsRef.current) {
      trackerLineRef.current = new mapsRef.current.Polyline({
        path,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      });
    }

    if (trackerLineRef.current && mapRef.current) {
      trackerLineRef.current.setMap(mapRef.current);
    }
  };


  const updateAirportRoute = () => {

    if (airportRouteRef.current) {
      airportRouteRef.current.setMap(null);
    }
  
    if (mapsRef.current && airports && airports.origin && airports.destination) {
      const originLatLng = new mapsRef.current.LatLng(
        airports.origin.lat,
        airports.origin.lng
      );
      const destinationLatLng = new mapsRef.current.LatLng(
        airports.destination.lat,
        airports.destination.lng
      );
  
      airportRouteRef.current = new mapsRef.current.Polyline({
        path: [originLatLng, destinationLatLng],
        geodesic: true,
        strokeColor: 'blue', // Customize color as needed
        strokeOpacity: 0.8,
        strokeWeight: 3
      });
  
      if (mapRef.current) {
        airportRouteRef.current.setMap(mapRef.current);
      }
    }
  };
  
  // Update polyline and map bounds when waypoints, origin or destination change
  useEffect(() => {
    if (mapsApiLoaded) { // Ensure API is loaded before rendering routes
      updatePolyline();
      updateMapBounds();
      updateSeaRoute();

      console.log("isDrivable:", isDrivable);

      if (isDrivable) {
        console.log("Rendering drivable route");
        renderDrivableRoute();
      }

      if (Object.keys(airports).length > 0) {
        updateAirportRoute();
      }

      if (seaport_driving.length > 0) {
        renderSeaportRoute();
      }

      if (airport_driving.length > 0) {
        renderAirportRoute();
      }
    }
  }, [
    waypoints,
    origin,
    destination,
    sea_route,
    isDrivable,
    airports,
    seaport_driving,
    airport_driving,
    mapsApiLoaded // Add mapsApiLoaded as a dependency
  ]);


  return (
    <div style={{ height: '56vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY || '', version: 'weekly', libraries: ['places'] }}
        defaultCenter={{ lat: 0, lng: 0 }} // Placeholder center
        defaultZoom={10} // Placeholder zoom
        onGoogleApiLoaded={handleApiLoaded}
        yesIWantToUseGoogleMapApiInternals
      >
        {waypoints.map((point, index) => (
          <WaypointMarker key={index} lat={point.lat} lng={point.lng} index={index} />
        ))}
        {origin && <LocationMarker type="origin" lat={origin.location.lat} lng={origin.location.lng} />}
        {destination && <LocationMarker type="destination" lat={destination.location.lat} lng={destination.location.lng} />}
      </GoogleMapReact>
    </div>
  );
};

export default MapComponent;
