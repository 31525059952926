// import React, { Component, RefObject, createRef } from "react";
// import GoogleMapReact from "google-map-react";
// import AbstractMap from "components/GoogleMap/Abstract";
// import LocationMarker from "components/GoogleMap/Markers/Location";
// import Icon from "components/Icon";
// import Input from "../Input";

// interface State {
//   loading: boolean;
// }

// interface Props {
//   shipment: any;
//   field: any;
//   onChange: any;
// }

// //Represents the Orgin and Destination field cards in the Shipment form
// export default class ShipmentLocation extends Component<Props, State> {
//   private map: any;
//   private maps: any;
//   private autoCompleteWidget: any;
//   private inputRef: RefObject<HTMLInputElement> = createRef();

//   constructor(props: Props) {
//     super(props);

//     this.state = {
//       loading: true,
//     };
//   }

//   //Updaes location on map
//   updateLocation = () => {
//     const { geometry } = this.autoCompleteWidget.getPlace();

//     console.log("Address", geometry);

//     //Update address
//     this.props.onChange(
//       this.props.field,
//       this.inputRef.current!.value,
//       "address"
//     );

//     if (geometry) {
//       const location = geometry.location.toJSON();
//       console.log("location", location);
//       this.props.onChange(this.props.field, location, "location");
//       this.map.setCenter(location);
//     } else {
//       window.alert("Address not found. Please try a more detailed one.");
//     }

//     this.setState({ loading: false });
//   };

//   //Sets user location
//   setUserLocation = () => {
//     return new Promise((resolve) => {
//       if (navigator.geolocation) {
//         return navigator.geolocation.getCurrentPosition((position) => {
//           this.map.setCenter({
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           });
//           resolve({});
//         }, resolve);
//       } else {
//         resolve({});
//       }
//     });
//   };

//   //When google maps api is loaded, the map is set to the center of the location
//   onApiLoaded = ({ map, maps }: any, location: GoogleMapReact.Coords) => {
//     this.map = map;
//     this.maps = maps;

//     //Creates autocomplete widget
//     this.autoCompleteWidget = new maps.places.Autocomplete(
//       this.inputRef.current
//     );

//     if (location) {
//       map.setCenter(location);
//       this.setState({ loading: false });
//     } else {
//       const setUserLocationWithRetry = () =>
//         this.setUserLocation().finally(() => {
//           clearInterval(interval);
//           this.setState({ loading: false });
//         });

//       const interval = setInterval(setUserLocationWithRetry, 2000);
//       setUserLocationWithRetry();

//       //When user selects a new address, update location
//       this.autoCompleteWidget.addListener("place_changed", this.updateLocation);
//     }
//   };

//   render() {
//     const location = this.props.shipment[this.props.field].location;
//     console.log("location", location);

//     return (
//       <>
//         <div className="new-box-half">
//           <h4 className="new-box-title location">{this.props.field}</h4>

//           <div className="new-address-map">
//             <div className="new-input new-input--address">
//               <Input
//                 shipment={this.props.shipment}
//                 inputRef={this.inputRef}
//                 onChange={this.props.onChange}
//                 label="address"
//                 field={this.props.field}
//                 sublevel="address"
//               />
//               <Input
//                 shipment={this.props.shipment}
//                 onChange={this.props.onChange}
//                 label="label"
//                 field={this.props.field}
//                 sublevel="label"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="new-box-half new-location-map new-map">
//           {this.state.loading && (
//             <span className="map-loading-wrapper">
//               <Icon size={30} name="refresh" className="map-loading" />
//             </span>
//           )}

//           <AbstractMap
//             onGoogleApiLoaded={(api) => this.onApiLoaded(api, location)}
//             defaultCenter={{ lat: 59.412025, lng: 5.268272 }}
//             zoom={location ? 10 : 6}
//           >
//             {!!location && (
//               <LocationMarker
//                 type={this.props.field}
//                 lat={location.lat}
//                 lng={location.lng}
//               />
//             )}
//           </AbstractMap>
//         </div>
//       </>
//     );
//   }
// }

import React, { Component, RefObject, createRef } from "react";
import GoogleMapReact from "google-map-react";
import AbstractMap from "components/GoogleMap/Abstract";
import LocationMarker from "components/GoogleMap/Markers/Location";
import Icon from "components/Icon";
import Input from "../Input";

interface State {
  loading: boolean;
  addressEdited: boolean; // New state property
}

interface Props {
  shipment: any;
  field: any;
  onChange: any;
}

export default class ShipmentLocation extends Component<Props, State> {
  private map: any;
  private maps: any;
  private autoCompleteWidget: any;
  private inputRef: RefObject<HTMLInputElement> = createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      addressEdited: false, // Initialize state
    };
  }

  // Clears coordinates if user starts editing the address field
  handleAddressEdit = () => {
    const { addressEdited } = this.state;
    if (!addressEdited) {
      // Clear coordinates only on the first edit
      this.props.onChange(this.props.field, null, "location");
      this.setState({ addressEdited: true }); // Set flag to prevent further clearing
    }
  };

  // Updates location on map
  updateLocation = () => {
    const { geometry } = this.autoCompleteWidget.getPlace();

    console.log("Address", geometry);

    // Update address
    this.props.onChange(
      this.props.field,
      this.inputRef.current!.value,
      "address"
    );

    if (geometry) {
      const location = geometry.location.toJSON();
      console.log("location", location);
      this.props.onChange(this.props.field, location, "location");
      this.map.setCenter(location);
    } else {
      window.alert("Address not found. Please try a more detailed one.");
    }

    this.setState({ loading: false, addressEdited: false }); // Reset flag
  };

  // Sets user location
  setUserLocation = () => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        return navigator.geolocation.getCurrentPosition((position) => {
          this.map.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          resolve({});
        }, resolve);
      } else {
        resolve({});
      }
    });
  };

  // When google maps api is loaded, the map is set to the center of the location
  onApiLoaded = ({ map, maps }: any, location: GoogleMapReact.Coords) => {
    this.map = map;
    this.maps = maps;

    // Creates autocomplete widget
    this.autoCompleteWidget = new maps.places.Autocomplete(
      this.inputRef.current
    );

    if (location) {
      map.setCenter(location);
      this.setState({ loading: false });
    } else {
      const setUserLocationWithRetry = () =>
        this.setUserLocation().finally(() => {
          clearInterval(interval);
          this.setState({ loading: false });
        });

      const interval = setInterval(setUserLocationWithRetry, 2000);
      setUserLocationWithRetry();

      // When user selects a new address, update location
      this.autoCompleteWidget.addListener("place_changed", this.updateLocation);
    }

    // Add event listener to handle the initial edit
  
    this.autoCompleteWidget.addListener("place_changed", this.updateLocation);
  this.inputRef.current!.addEventListener("input", this.handleAddressEdit);
  };

  render() {
    const location = this.props.shipment[this.props.field].location;
    console.log("location", location);

    return (
      <>
        <div className="new-box-half">
          <h4 className="new-box-title location">{this.props.field}</h4>

          <div className="new-address-map">
            <div className="new-input new-input--address">
              <Input
                shipment={this.props.shipment}
                inputRef={this.inputRef}
                onChange={this.props.onChange}
                label="address"
                field={this.props.field}
                sublevel="address"
              />
              <Input
                shipment={this.props.shipment}
                onChange={this.props.onChange}
                label="label"
                field={this.props.field}
                sublevel="label"
              />
            </div>
          </div>
        </div>

        <div className="new-box-half new-location-map new-map">
          {this.state.loading && (
            <span className="map-loading-wrapper">
              <Icon size={30} name="refresh" className="map-loading" />
            </span>
          )}

          <AbstractMap
            onGoogleApiLoaded={(api) => this.onApiLoaded(api, location)}
            defaultCenter={{ lat: 59.412025, lng: 5.268272 }}
            zoom={location ? 10 : 6}
          >
            {!!location && (
              <LocationMarker
                type={this.props.field}
                lat={location.lat}
                lng={location.lng}
              />
            )}
          </AbstractMap>
        </div>
      </>
    );
  }
}
