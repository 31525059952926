import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import Page from "components/ControlBars/Page";
import ContentLoader from "components/Loading/ContentLoader";
import Shipment from "components/Forms/Shipment";
import { createNewShipment, validateShipment } from "services/shipments";
import { getAvailableTrackers } from "services/trackers";
import { getParam } from "utils/navigation";
import { getTagsList } from "services/tags";
import "./styles.scss";

type State = {
  shipment: any;
  trackers: Array<any>;
  tags: Array<any>;
  selectedTrackers: any;
  loading: boolean;
  validationErrors: any; // Store validation errors here
  create: any;
};

export default class NewShipment extends PureComponent<
  RouteComponentProps,
  State
> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: true,
      shipment: { origin: {}, destination: {} },
      trackers: [],
      tags: [],
      selectedTrackers: null,
      validationErrors: "", // Store validation errors here
      create: false,
    };
  }

  findSelectedTracker = (trackers: any) => {
    const query = getParam(this.props.location.search, "tracker");
    const ids = query ? query.split(",") : [];
    const selected: any[] = [];

    ids.forEach((id: string) => {
      const tracker = trackers.find((tracker: any) => tracker._id === id);
      trackers && selected.push(tracker);
    });

    if (selected.length !== ids.length) {
      window.alert("Some tracker isn't available to you");
    }

    return selected;
  };

  componentDidMount() {
    Promise.all([getAvailableTrackers(), getTagsList()]).then(
      ([trackers, tags]) => {
        this.setState({
          loading: false,
          tags,
          trackers,
          selectedTrackers: this.findSelectedTracker(trackers),
          shipment: {
            status: "ongoing",
            origin: {},
            destination: {},
            tags: [],
          },
        });
      }
    );
  }

  // save = (shipment: any, trackers: any) => {
  //   this.setState({ loading : true });
  //   console.log("NewShipment", shipment, trackers);
  //   createNewShipment(shipment, trackers.map((tracker: any) => tracker._id))
  //           .then(() => window.alert("Shipment created correctly"))
  //           .then(() => this.props.history.push('/'));
  // }

  save = (shipment: any, trackers: any, event: any) => {
    // event.preventDefault(); // Prevent default form submission
    this.setState({ loading: false, validationErrors: "", create: true }); // Clear previous errors

    // Validate shipment data before creating
    validateShipment(
      shipment,
      trackers.map((tracker: any) => tracker._id)
    )
      .then((response: any) => {
        if (!response.valid) {
          // If validation fails, set error messages in state and stop further execution
          const errorMessages = Object.values(response.errors).join("\n");
          this.setState({
            validationErrors: errorMessages,
            loading: false, // Stop loading to allow user to edit the form
          });
          return; // Stop execution
        }

        // If validation is successful, proceed to create shipment
        createNewShipment(
          shipment,
          trackers.map((tracker: any) => tracker._id)
        )
          .then(() => {
            // Shipment created successfully
            window.alert("Shipment created successfully");
            this.setState({ loading: false, create: false }); // Stop loading before navigation
            this.props.history.push("/"); // Redirect to another page
          })
          .catch((error) => {
            // Handle any errors during shipment creation
            console.error("Error creating shipment:", error);
            this.setState({ loading: false }); // Stop loading on error
          });
      })
      .catch((error) => {
        // Handle any unexpected errors during validation
        console.error("Error during validation:", error);
        this.setState({ loading: false }); // Stop loading on error
      });
  };

  renderContent = () => {
    return (
      <>
        <Shipment
          shipment={this.state.shipment}
          trackers={this.state.trackers}
          tags={this.state.tags}
          selectedTrackers={this.state.selectedTrackers}
          callback={this.save}
          validationErrors={this.state.validationErrors}
          create={this.state.create}        />
      </> // Pass validationErrors as a prop/>
    );
  };

  render() {
    return (
      <Page className="shlk-new-shipment">
        <ContentLoader
          loading={this.state.loading}
          content={this.renderContent}
        />
      </Page>
    );
  }
}
