import React, { Component } from 'react';
import QRCode from 'qrcode';
import { ReactSVG } from 'react-svg';

import './styles.scss';

interface Props {
  tracker: any;
}

interface State {
  qr: any;
}

export default class AdminQRTracker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { qr: null };
  }

  componentDidMount() {
    const link = `https://app.naxxar.uk/eshell/${this.props.tracker.identifier}`;

    QRCode.toDataURL(link, { color: { dark: '#19255A', light: '#0000' }})
        .then((url: any) => this.setState({ qr: url }));
  }

  render() {
    const tracker = this.props.tracker;

    return (
      <div className="shlk-free-tracker-qr" style={{ width: '5.4cm', height: '7.0cm' }}>
        <ReactSVG className="admin-qr-logo" src="/assets/svg/logo.svg" />

        <p>eShell<br/>{tracker.identifier}</p>

        <div style={{ textAlign: "center" }}>
                { (this.state.qr) && <img className="admin-qr" alt="public_qr" src={this.state.qr} />}
        </div>

        <div className="admin-qr-imgs">
          <img className="admin-qr-img" src="/assets/img/ce.png" alt="ce-logo"/>
          <img className="admin-qr-img" src="/assets/img/rohs.png" alt="rohs-logo"/>
          <img className="admin-qr-img" src="/assets/img/fcc.png" alt="fcc-logo"/>
        </div>
      </div>
  );}
}
