import React, { PureComponent } from 'react';
import Title from 'components/Title';
import ContentLoader from 'components/Loading/ContentLoader';
import AddPermissions from 'components/Permissions/Add';
import './styles.scss';

interface Props {
  document: any;
  close: any;
  type: string;
  inviteMember: Function;
  getRoles: Function;
}

interface State {
  loading: boolean;
  error: string;
  member: any;
  roles: Array<{
    name: string;
    permissions: Array<string>;
  }>;
}

export default class SharePopup extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      member: null,
      roles: [],
      error: ''
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const data = { id: this.props.document._id };

    this.props.getRoles(data)
      .then((roles: any) => this.setState({ loading: false, roles }));
  }

  sendInvite = (name: string, email: string, roles: Array<string>) => {
    this.setState({ loading: true, error: "" });

    this.props.inviteMember({ name, email, roles, id: this.props.document._id }, { handledException: true })
      .then(({ member }: { member: any}) => {
        window.alert(`The user ${email} has been added to the ${this.props.type}`);

        this.props.document.members.push(member);
        this.props.close(this.props.document);
      })
      .catch((error: any) => {
        this.setState({ error: error.response.data.msg, loading: false });
      });
  }

  render() {
    console.log(this.props.inviteMember, "type");
    return (<div className="shlk-share-shipment">
      <Title text={`Invite user to ${this.props.type}`} icon="people" />

      <ContentLoader loading={this.state.loading} content={() => (
        <AddPermissions roles={this.state.roles}
                          mandatory={["ROLE_SHIPMENT_VIEWER"]}
                          cancel={() => this.props.close()}
                          update={this.sendInvite}/>
      )} />

      {
          (this.state.error) && (
            <div className="add-error">
              <h3>ERROR: {this.state.error}</h3>
            </div>
          )
      }
    </div>
    );
  }
}