import React, { Component } from 'react';
import AbstractMap from 'components/GoogleMap/Abstract';
import LiveVesselMarker from 'components/GoogleMap/Markers/LiveVessel';
import './styles.scss';

export interface MapSettings {
  vessels: any[];
  open?: string | null;
  popup?: Function | null;
}

interface Props extends MapSettings {
  vessels: Array<any>;
  className?: string;
  click?: any;
}

const MAX_BOUNDING_ZOOM = 7;

const isOpen = (key: string, open?: string | null) => {
  return !!open && (key.split("_")[0] === open.split("_")[0]);
};

export default class VesselMap extends Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  static defaultProps = { className: '', click() {}, onRemove() {} };

  private map: any;
  private maps: any;

  componentDidUpdate() {
    (this.map && this.maps) && this.updateMap();
  }

  updateMap = () => {
    const vessels = this.props.vessels.filter(vessel => vessel.lastMeasurement);
    const vessel = vessels.find(vessel => `${this.props.open}` === `${vessel.mmsi}`);

    if(vessel) {
      this.map.setCenter({ lat : vessel.lastMeasurement.location.lat , lng : vessel.lastMeasurement.location.lng });
    } else if(this.props.vessels.length === 0){
      this.map.setZoom(1);
    } else {
      const bounds = new this.maps.LatLngBounds();

      vessels.forEach(({ lastMeasurement }) => {
        bounds.extend({ lat: lastMeasurement.location.lat, lng: lastMeasurement.location.lng });
      });

      this.map.fitBounds(bounds);
      this.map.setZoom(Math.min(this.map.getZoom(), MAX_BOUNDING_ZOOM));
    }
  }

  onApiLoaded = (map: any, maps: any) => {
    if(map && maps) {
      this.map = map;
      this.maps = maps;

      this.updateMap();
    }
  }

  render() {
    const vessels = this.props.vessels.filter(vessel => vessel.lastMeasurement);

    return (<div className={`shlk-map shlk-vessel-map ${this.props.className}`}>
      <AbstractMap
        defaultCenter={{ lat : 0, lng : 0}}
        onGoogleApiLoaded={({ map, maps }) => this.onApiLoaded(map, maps)}
        defaultZoom={1}
      >

      {
          (vessels.map((vessel, index) =>
            <LiveVesselMarker
                  active={vessel.active}
                  key={`VesselMarker_${vessel.mmsi}_${index}`}
                  text={index + 1}
                  open={isOpen(vessel.key, this.props.open)}
                  click={(status: string) => this.props.click(vessel.key, status)}
                  popup={this.props.popup ? this.props.popup.bind(null, vessel) : null}
                  vessel={vessel}
                  lat={vessel.lastMeasurement.location.lat} lng={vessel.lastMeasurement.location.lng}/>))
      }

      </AbstractMap>
    </div>);
  }
}