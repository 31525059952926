import React from 'react';
import { AirMode } from './index'; // replace './index' with the path to your index.tsx file
import Input from '../Input';
import Dropdown from '../Dropdown';
import AirportAutoComplete from '../Input/AirportAutoComplete'; 


interface AirModeProps {
  airModeData: AirMode;
  onChange: (field: string, value: any) => void;
}

const AirModeComponent: React.FC<AirModeProps> = ({ airModeData, onChange }) => {
  return (
    <div>
      <div className="new-box-half">





            <Dropdown
              parentObject={airModeData}
              onChange={onChange}
              field="aircraftType"
              label='Aircraft Type'
              options={['Freighter', 'Belly freight', 'Unknown']}
            />

            <AirportAutoComplete
              shipment={airModeData}
              onChange={onChange}
              field="originAirport"
              label='Origin Airport'
            />

            <AirportAutoComplete
              shipment={airModeData}
              onChange={onChange}
              field="destinationAirport"
                label='Destination Airport'
            />

        </div>
    </div>
  );
};

export default AirModeComponent;