// Define a constant object that maps certain keys to their corresponding values.
// These keys and values represent different types of sources.
const NAMED_SOURCES: { [key: string]: string } = {
    'TRACKER': 'GPS',       // 'TRACKER' maps to 'GPS'
    'TOWER': 'Cell tower',  // 'TOWER' maps to 'Cell tower'
    'WIFI': 'WiFi',         // 'WIFI' maps to 'WiFi'
    'VESSEL': 'Vessel',     // 'VESSEL' maps to 'Vessel'
    'N/A': 'n/a'            // 'N/A' maps to 'n/a'
};

// Export a function that takes a source string as an argument and returns the corresponding value from the NAMED_SOURCES object.
export const getNamedSource = (source: string) => NAMED_SOURCES[source];