import React, { Component } from "react";
import "./styles.scss";

interface Props {
  parentObject: any;
  field: string;
  options: Array<string>; // Array of enum values
  onChange: (field: string, value: any) => void; // onChange function
  label?: string;
  value?: any;
}

export default class Dropdown extends Component<Props> {
  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { field, onChange } = this.props;
    onChange(field, event.target.value);
  };

  render() {
    const { parentObject, field, options, label } = this.props;
    const selectedValue = parentObject[field];
    const containerClassName = `dropdown-container ${field}`;
    return (
      <div className={containerClassName}>
        <label htmlFor={field} className="new-input-label">
          {label || field}
        </label>
        <select
          id={field}
          value={selectedValue}
          onChange={this.handleChange}
          className="new-dropdown-value"
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
