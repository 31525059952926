import React from 'react';
import Icon from 'components/Icon';
import { getEventIcon } from 'utils/events';
import { format as formatDate } from 'utils/date';
import Marker, { MarkerProps } from '../AbstractMarker';
import './styles.scss';

interface EventProps extends MarkerProps {
  event: any;
}

export default class EventMarker extends Marker<EventProps> {
  name = 'EventMarker';

  hasPopup = true;
  renderPopupContent = () => {
    const event = this.props.event;

    return (<div className="marker-popup">
                <p className="marker-popup-field"><b>EVENT</b></p>
                <p className="marker-popup-field">{formatDate(event.measurement.time)}</p>
                <p className="marker-popup-field"><b>Message:</b> { event.message }</p>
            </div>);
  };

  renderMarker() {
    return (
      <Icon className={`marker-event marker-event--${!this.props.event.ack ? "unack" : "ack"}`}
            size={12} name={ getEventIcon(this.props.event.type) }/>
    );
  }
}

