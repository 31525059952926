// // // import React, { Component } from "react";
// // // import { ReactSVG } from "react-svg";
// // // import { Link, RouteComponentProps, withRouter } from "react-router-dom";
// // // import Icon from "components/Icon";
// // // import TrackableElement from "components/Trackable/Element";
// // // import { goBack } from "utils/navigation";
// // // import { isDesktop, isMobile } from "utils/device";
// // // import "./styles.scss";

// // // type Option = {
// // //   type: string;
// // //   icon: string;
// // //   render?: any;
// // //   to?: string;
// // //   notification?: boolean;
// // // };

// // // interface Props extends RouteComponentProps {
// // //   menu: Array<Option>;
// // //   onBoxOpen?: Function;
// // //   title?: string;
// // // }

// // // type State = {
// // //   type: string;
// // //   open: boolean;
// // //   isMobile: any;
// // // };

// // // class Box extends Component<Props, State> {
// // //   constructor(props: Props) {
// // //     super(props);

// // //     this.state = {
// // //       type: props.menu[0].type,
// // //       open: isDesktop(),
// // //       isMobile: window.innerWidth < 768,
// // //     };
// // //   }

// // //   componentDidMount() {
// // //     // Add the event listener to check for resize
// // //     window.addEventListener("resize", this.checkMobile);
// // //     // Initial check in case the component is mounted when already in mobile view
// // //     this.checkMobile();
// // //   }

// // //   componentWillUnmount() {
// // //     window.removeEventListener("resize", this.checkMobile);
// // //   }
// // //   checkMobile = () => {
// // //     const isMobile = window.innerWidth < 768; // Adjust threshold as needed
// // //     this.setState({ isMobile });
// // //   };
// // //   renderMenuOption = (
// // //     { icon, type, notification, to }: Option,
// // //     index: number
// // //   ) => {
// // //     let className = "box-menu-button";
// // //     if (this.state.type === type) className += " box-menu-button--active";
// // //     if (notification) className += " box-menu-button--notification";

// // //     const svg = (
// // //       <ReactSVG
// // //         className="box-menu-icon box-menu-icon-hover"
// // //         src={`/assets/svg/${icon}.svg`}
// // //       />
// // //     );
// // //     const props = { className, key: `BoxOption${index}` };

// // //     return to ? (
// // //       <Link to={to} {...props}>
// // //         {svg}
// // //       </Link>
// // //     ) : (
// // //       <TrackableElement
// // //         {...props}
// // //         category="Box"
// // //         action="Click Menu"
// // //         label={type}
// // //         onClick={() =>
// // //           this.setState((prevState) => ({
// // //             type: prevState.type === type ? "" : type,
// // //           }))
// // //         }
// // //       >
// // //         {svg}
// // //       </TrackableElement>
// // //     );
// // //   };

// // //   render() {
// // //     const isOpen = this.state.open;
// // //     const { isMobile } = this.state;
// // //     console.log(isMobile, "mobile");
// // //     return (
// // //       <div className={`shlk-box `}>
// // //         {isOpen ? (
// // //           <div
// // //             className={`box-content-menu ${isOpen ? "Open-box" : "Close-box"}`}
// // //             style={{
// // //               zIndex: "3",
// // //               width:
// // //                 this.state.type !== "info" &&
// // //                 this.state.type !== "emissions" &&
// // //                 this.state.type !== "scanbox"
// // //                   ? ""
// // //                   : isMobile
// // //                   ? "15%"
// // //                   : "",
// // //             }}
// // //           >
// // //             <TrackableElement
// // //               className="box-button back"
// // //               category="Box"
// // //               action="Back button"
// // //               onClick={() => goBack(this.props.history)}
// // //             >
// // //               <Icon
// // //                 className={`box-button-back  ${
// // //                   isOpen ? "Open-box" : "Close-box"
// // //                 }`}
// // //                 size={26}
// // //                 name="arrow_back"
// // //               />
// // //               <span>Back</span>
// // //             </TrackableElement>
// // //             <TrackableElement
// // //               className="box-button"
// // //               category="Box"
// // //               action={`${isOpen ? "Close" : "Open"} Box`}
// // //               onClick={() =>
// // //                 this.setState({ open: !isOpen }, () => {
// // //                   this.state.open &&
// // //                     this.props.onBoxOpen &&
// // //                     this.props.onBoxOpen();
// // //                   this.setState((prevState) => ({
// // //                     type:
// // //                       prevState.type === this.state.type ? "" : this.state.type,
// // //                   }));
// // //                 })
// // //               }
// // //             >
// // //               <Icon
// // //                 className="box-button-menu"
// // //                 size={28}
// // //                 name={`${isOpen ? "menu_open" : "menu"}`}
// // //               />
// // //             </TrackableElement>

// // //             {this.props.menu.map(this.renderMenuOption)}
// // //           </div>
// // //         ) : (
// // //           <div className="back-button">
// // //             {" "}
// // //             <TrackableElement
// // //               className="box-button back"
// // //               category="Box"
// // //               action="Back button"
// // //               onClick={() => goBack(this.props.history)}
// // //             >
// // //               <Icon
// // //                 className={`box-button-back  ${
// // //                   isOpen ? "Open-box" : "Close-box"
// // //                 }`}
// // //                 size={26}
// // //                 name="arrow_back"
// // //               />
// // //               <span>Back</span>
// // //             </TrackableElement>
// // //             <TrackableElement
// // //               className="box-button"
// // //               category="Box"
// // //               action={`${isOpen ? "Close" : "Open"} Box`}
// // //               onClick={() =>
// // //                 this.setState({ open: !isOpen }, () => {
// // //                   this.state.open &&
// // //                     this.props.onBoxOpen &&
// // //                     this.props.onBoxOpen();
// // //                 })
// // //               }
// // //             >
// // //               <Icon
// // //                 className="box-button-menu"
// // //                 size={28}
// // //                 name={`${isOpen ? "menu_open" : "menu"}`}
// // //               />
// // //             </TrackableElement>
// // //           </div>
// // //         )}

// // //         {this.props.menu
// // //           .filter((option) => option.type === this.state.type)
// // //           .map((option, index) =>
// // //             this.state.type == "location" ? (
// // //               ""
// // //             ) : (
// // //               <div
// // //                 key={`BoxOption${index}`}
// // //                 className={`${
// // //                   this.state.type !== "info" &&
// // //                   this.state.type !== "emissions" &&
// // //                   this.state.type !== "scanbox"
// // //                     ? "box-content box-content__element scroll"
// // //                     : "box-content__element"
// // //                 }`}
// // //                 style={{
// // //                   width:
// // //                     this.state.type !== "info" &&
// // //                     this.state.type !== "emissions" &&
// // //                     this.state.type !== "scanbox"
// // //                       ? ""
// // //                       : isMobile
// // //                       ? "85%"
// // //                       : "",
// // //                 }}
// // //               >
// // //                 <div
// // //                   style={{
// // //                     position: "absolute",
// // //                     marginTop:
// // //                       this.state.type !== "info" &&
// // //                       this.state.type !== "emissions" &&
// // //                       this.state.type !== "scanbox"
// // //                         ? ""
// // //                         : isMobile
// // //                         ? "15px"
// // //                         : "10px",
// // //                     width: "auto",
// // //                     right: isMobile ? "15px" : "13px",
// // //                     cursor: "pointer",
// // //                   }}
// // //                 >
// // //                   <span></span>
// // //                   <span>
// // //                     {" "}
// // //                     <ReactSVG
// // //                       style={{ color: "black" }}
// // //                       src={"/assets/svg/close1.svg"}
// // //                       onClick={() =>
// // //                         this.setState((prevState) => ({
// // //                           type:
// // //                             prevState.type === this.state.type
// // //                               ? ""
// // //                               : this.state.type,
// // //                         }))
// // //                       }
// // //                     />
// // //                   </span>{" "}
// // //                 </div>

// // //                 {this.props.title && (
// // //                   <p
// // //                     className=""
// // //                     style={{
// // //                       margin: "0px",
// // //                       fontSize: "15px",
// // //                       fontWeight: "bold",
// // //                       padding: this.state.type === "info" ? "10px" : "",
// // //                       background: this.state.type === "info" ? "white" : "",
// // //                       boxShadow:
// // //                         this.state.type === "info"
// // //                           ? "0px 0px 5px 0px rgba(0, 0, 0, 0.2)"
// // //                           : "",

// // //                       borderRadius:
// // //                         this.state.type === "info" ? "10px 10px 0px 0px" : "",
// // //                     }}
// // //                   >
// // //                     {this.props.title}
// // //                   </p>
// // //                 )}
// // //                 {option.render()}
// // //               </div>
// // //             )
// // //           )}
// // //       </div>
// // //     );
// // //   }
// // // }

// // // export default withRouter(Box);

// // // import React, { useState, useEffect, useCallback } from "react";
// // // import { ReactSVG } from "react-svg";
// // // import { Link, useHistory } from "react-router-dom";
// // // import Icon from "components/Icon";
// // // import TrackableElement from "components/Trackable/Element";
// // // import { goBack } from "utils/navigation";
// // // import { isDesktop } from "utils/device";
// // // import "./styles.scss";

// // // type Option = {
// // //   type: string;
// // //   icon: string;
// // //   render?: any;
// // //   to?: string;
// // //   notification?: boolean;
// // // };

// // // interface Props {
// // //   menu: Array<Option>;
// // //   onBoxOpen?: Function;
// // //   title?: string;
// // //   type?: any;
// // //   content?: any;
// // // }

// // // const Box: React.FC<Props> = (props) => {
// // //   const [type, setType] = useState(props.menu[0].type || "");
// // //   const [open, setOpen] = useState(isDesktop());
// // //   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
// // //   const history = useHistory();

// // //   useEffect(() => {
// // //     const handleResize = () => {
// // //       setIsMobile(window.innerWidth < 768);
// // //     };

// // //     window.addEventListener("resize", handleResize);
// // //     handleResize();

// // //     return () => {
// // //       window.removeEventListener("resize", handleResize);
// // //     };
// // //   }, []);

// // //   useEffect(() => {
// // //     setType(props.type || type);
// // //   }, [props.type]);

// // //   const openMenuType = useCallback((newType: string) => {
// // //     console.log("Setting type to:", newType);
// // //     setType(newType);
// // //     console.log("State after setting type:", newType);
// // //   }, []);

// // //   const renderMenuOption = (option: Option, index: number) => {
// // //     let className = "box-menu-button";
// // //     if (type === option.type) className += " box-menu-button--active";
// // //     if (option.notification) className += " box-menu-button--notification";

// // //     const svg = (
// // //       <ReactSVG
// // //         className="box-menu-icon box-menu-icon-hover"
// // //         src={`/assets/svg/${option.icon}.svg`}
// // //       />
// // //     );
// // //     const props = { className, key: `BoxOption${index}` };

// // //     return option.to ? (
// // //       <Link to={option.to} {...props}>
// // //         {svg}
// // //       </Link>
// // //     ) : (
// // //       <TrackableElement
// // //         {...props}
// // //         category="Box"
// // //         action="Click Menu"
// // //         label={option.type}
// // //         onClick={() => openMenuType(option.type)}
// // //       >
// // //         {svg}
// // //       </TrackableElement>
// // //     );
// // //   };
// // //   console.log(props.type, "box type");
// // // import React, { useState, useEffect, useCallback } from "react";
// // // import { ReactSVG } from "react-svg";
// // // import { Link, useHistory } from "react-router-dom";
// // // import Icon from "components/Icon";
// // // import TrackableElement from "components/Trackable/Element";
// // // import { goBack } from "utils/navigation";
// // // import { isDesktop } from "utils/device";
// // // import "./styles.scss";

// // // type Option = {
// // //   type: string;
// // //   icon: string;
// // //   render?: any;
// // //   to?: string;
// // //   notification?: boolean;
// // // };

// // // interface Props {
// // //   menu: Array<Option>;
// // //   onBoxOpen?: Function;
// // //   title?: string;
// // //   type?: string;
// // //   content?: any;
// // // }

// // // const Box: React.FC<Props> = (props) => {
// // //   const [type, setType] = useState<string>(props.menu[0].type || "");
// // //   const [open, setOpen] = useState<boolean>(isDesktop());
// // //   const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
// // //   const history = useHistory();

// // //   useEffect(() => {
// // //     const handleResize = () => {
// // //       setIsMobile(window.innerWidth < 768);
// // //     };

// // //     window.addEventListener("resize", handleResize);
// // //     handleResize();

// // //     return () => {
// // //       window.removeEventListener("resize", handleResize);
// // //     };
// // //   }, []);

// // //   useEffect(() => {
// // //     console.log("Props type changed:", props.type);
// // //     setType(props.type || type);
// // //   }, [props.type]);

// // //   const openMenuType = useCallback((newType: string) => {
// // //     console.log("Setting type to:", newType);
// // //     setType(newType);
// // //   }, []);

// // //   const renderMenuOption = (option: Option, index: number) => {
// // //     let className = "box-menu-button";
// // //     if (type === option.type) className += " box-menu-button--active";
// // //     if (option.notification) className += " box-menu-button--notification";

// // //     const svg = (
// // //       <ReactSVG
// // //         className="box-menu-icon box-menu-icon-hover"
// // //         src={`/assets/svg/${option.icon}.svg`}
// // //       />
// // //     );
// // //     const props = { className, key: `BoxOption${index}` };

// // //     return option.to ? (
// // //       <Link to={option.to} {...props}>
// // //         {svg}
// // //       </Link>
// // //     ) : (
// // //       <TrackableElement
// // //         {...props}
// // //         category="Box"
// // //         action="Click Menu"
// // //         label={option.type}
// // //         onClick={() => openMenuType(option.type)}
// // //       >
// // //         {svg}
// // //       </TrackableElement>
// // //     );
// // //   };

// // //   console.log("Current box type:", type);
// // //   return (
// // //     <div className={`shlk-box`}>
// // //       {open ? (
// // //         <div
// // //           className={`box-content-menu ${open ? "Open-box" : "Close-box"}`}
// // //           style={{
// // //             zIndex: "3",
// // //             width:
// // //               type !== "info" && type !== "emissions" && type !== "scanbox"
// // //                 ? ""
// // //                 : isMobile
// // //                 ? "15%"
// // //                 : "",
// // //           }}
// // //         >
// // //           <TrackableElement
// // //             className="box-button back"
// // //             category="Box"
// // //             action="Back button"
// // //             onClick={() => goBack(history)}
// // //           >
// // //             <Icon
// // //               className={`box-button-back ${open ? "Open-box" : "Close-box"}`}
// // //               size={26}
// // //               name="arrow_back"
// // //             />
// // //             <span>Back</span>
// // //           </TrackableElement>
// // //           <TrackableElement
// // //             className="box-button"
// // //             category="Box"
// // //             action={`${open ? "Close" : "Open"} Box`}
// // //             onClick={() => {
// // //               setOpen(!open);
// // //               !open && props.onBoxOpen && props.onBoxOpen();
// // //             }}
// // //           >
// // //             <Icon
// // //               className="box-button-menu"
// // //               size={28}
// // //               name={`${open ? "menu_open" : "menu"}`}
// // //             />
// // //           </TrackableElement>

// // //           {props.menu.map(renderMenuOption)}
// // //         </div>
// // //       ) : (
// // //         <div className="back-button">
// // //           <TrackableElement
// // //             className="box-button back"
// // //             category="Box"
// // //             action="Back button"
// // //             onClick={() => goBack(history)}
// // //           >
// // //             <Icon
// // //               className={`box-button-back ${open ? "Open-box" : "Close-box"}`}
// // //               size={26}
// // //               name="arrow_back"
// // //             />
// // //             <span>Back</span>
// // //           </TrackableElement>
// // //           <TrackableElement
// // //             className="box-button"
// // //             category="Box"
// // //             action={`${open ? "Close" : "Open"} Box`}
// // //             onClick={() => {
// // //               setOpen(!open);
// // //               !open && props.onBoxOpen && props.onBoxOpen();
// // //             }}
// // //           >
// // //             <Icon
// // //               className="box-button-menu"
// // //               size={28}
// // //               name={`${open ? "menu_open" : "menu"}`}
// // //             />
// // //           </TrackableElement>
// // //         </div>
// // //       )}

// // //       {props.menu
// // //         .filter((option) => option.type === type)
// // //         .map((option, index) => (
// // //           <div
// // //             key={`BoxOption${index}`}
// // //             className={`${
// // //               type !== "info" && type !== "emissions" && type !== "scanbox"
// // //                 ? "box-content box-content__element scroll"
// // //                 : "box-content__element"
// // //             }`}
// // //             style={{
// // //               width:
// // //                 type !== "info" && type !== "emissions" && type !== "scanbox"
// // //                   ? ""
// // //                   : isMobile
// // //                   ? "85%"
// // //                   : "",
// // //             }}
// // //           >
// // //             <div
// // //               style={{
// // //                 position: "absolute",
// // //                 marginTop:
// // //                   type !== "info" && type !== "emissions" && type !== "scanbox"
// // //                     ? ""
// // //                     : isMobile
// // //                     ? "15px"
// // //                     : "10px",
// // //                 width: "auto",
// // //                 right: isMobile ? "15px" : "13px",
// // //                 cursor: "pointer",
// // //               }}
// // //             >
// // //               <span></span>
// // //               <span>
// // //                 <ReactSVG
// // //                   style={{ color: "black" }}
// // //                   src={"/assets/svg/close1.svg"}
// // //                   onClick={() =>
// // //                     setType((prevType) => (prevType === type ? "" : type))
// // //                   }
// // //                 />
// // //               </span>
// // //             </div>

// // //             {props.title && (
// // //               <p
// // //                 className=""
// // //                 style={{
// // //                   margin: "0px",
// // //                   fontSize: "15px",
// // //                   fontWeight: "bold",
// // //                   padding: type === "info" ? "10px" : "",
// // //                   background: type === "info" ? "white" : "",
// // //                   boxShadow:
// // //                     type === "info" ? "0px 0px 5px 0px rgba(0, 0, 0, 0.2)" : "",
// // //                   borderRadius: type === "info" ? "10px 10px 0px 0px" : "",
// // //                 }}
// // //               >
// // //                 {props.title}
// // //               </p>
// // //             )}
// // //             {option.render && option.render({ openMenuType })}
// // //             {props.type === "share" && <>HII</>}
// // //           </div>
// // //         ))}
// // //     </div>
// // //   );
// // // };

// // // export default Box;

// // // import React, { useState, useEffect, useCallback } from "react";
// // // import { ReactSVG } from "react-svg";
// // // import { Link, useHistory } from "react-router-dom";
// // // import Icon from "components/Icon";
// // // import TrackableElement from "components/Trackable/Element";
// // // import { goBack } from "utils/navigation";
// // // import { isDesktop } from "utils/device";
// // // import "./styles.scss";

// // // type Option = {
// // //   type: string;
// // //   icon: string;
// // //   render?: any;
// // //   to?: string;
// // //   notification?: boolean;
// // // };

// // // interface Props {
// // //   menu: Array<Option>;
// // //   onBoxOpen?: Function;
// // //   title?: string;
// // //   type?: string;
// // //   content?: any;
// // // }

// // // const Box: React.FC<Props> = (props) => {
// // //   const [type, setType] = useState<string>(props.menu[0].type || "");
// // //   const [open, setOpen] = useState<boolean>(isDesktop());
// // //   const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
// // //   const history = useHistory();

// // //   // Handle window resize
// // //   useEffect(() => {
// // //     const handleResize = () => {
// // //       setIsMobile(window.innerWidth < 768);
// // //     };

// // //     window.addEventListener("resize", handleResize);
// // //     handleResize();

// // //     return () => {
// // //       window.removeEventListener("resize", handleResize);
// // //     };
// // //   }, []);

// // //   // Update internal state when props.type changes
// // //   useEffect(() => {
// // //     console.log("Props type changed:", props.type);
// // //     if (props.type !== type) {
// // //       console.log("Updating internal state to new type:", props.type);
// // //       setType(props.type || "");
// // //     }
// // //   }, [props.type]);

// // //   // Handle menu type click
// // //   const openMenuType = useCallback((newType: string) => {
// // //     console.log("Menu option clicked:", newType);
// // //     if (type !== newType) {
// // //       console.log("Setting type to:", newType);
// // //       setType(newType);
// // //     } else {
// // //       console.log("Type is already:", newType);
// // //     }
// // //   }, [type]);

// // //   // Render each menu option
// // //   const renderMenuOption = (option: Option, index: number) => {
// // //     let className = "box-menu-button";
// // //     if (type === option.type) className += " box-menu-button--active";
// // //     if (option.notification) className += " box-menu-button--notification";

// // //     const svg = (
// // //       <ReactSVG
// // //         className="box-menu-icon box-menu-icon-hover"
// // //         src={`/assets/svg/${option.icon}.svg`}
// // //       />
// // //     );
// // //     const props = { className, key: `BoxOption${index}` };

// // //     return option.to ? (
// // //       <Link to={option.to} {...props}>
// // //         {svg}
// // //       </Link>
// // //     ) : (
// // //       <TrackableElement
// // //         {...props}
// // //         category="Box"
// // //         action="Click Menu"
// // //         label={option.type}
// // //         onClick={() => openMenuType(option.type)}
// // //       >
// // //         {svg}
// // //       </TrackableElement>
// // //     );
// // //   };

// // //   console.log("Current box type:", type);
// // //   console.log("Current props type:", props.type);

// //   return (
// //     <div className={`shlk-box`}>
// //       {open ? (
// //         <div
// //           className={`box-content-menu ${open ? "Open-box" : "Close-box"}`}
// //           style={{
// //             zIndex: "3",
// //             width:
// //               type !== "info" && type !== "emissions" && type !== "scanbox"
// //                 ? ""
// //                 : isMobile
// //                 ? "15%"
// //                 : "",
// //           }}
// //         >
// //           <TrackableElement
// //             className="box-button back"
// //             category="Box"
// //             action="Back button"
// //             onClick={() => goBack(history)}
// //           >
// //             <Icon
// //               className={`box-button-back ${open ? "Open-box" : "Close-box"}`}
// //               size={26}
// //               name="arrow_back"
// //             />
// //             <span>Back</span>
// //           </TrackableElement>
// //           <TrackableElement
// //             className="box-button"
// //             category="Box"
// //             action={`${open ? "Close" : "Open"} Box`}
// //             onClick={() => {
// //               setOpen(!open);
// //               !open && props.onBoxOpen && props.onBoxOpen();
// //             }}
// //           >
// //             <Icon
// //               className="box-button-menu"
// //               size={28}
// //               name={`${open ? "menu_open" : "menu"}`}
// //             />
// //           </TrackableElement>

// //           {props.menu.map(renderMenuOption)}
// //         </div>
// //       ) : (
// //         <div className="back-button">
// //           <TrackableElement
// //             className="box-button back"
// //             category="Box"
// //             action="Back button"
// //             onClick={() => goBack(history)}
// //           >
// //             <Icon
// //               className={`box-button-back ${open ? "Open-box" : "Close-box"}`}
// //               size={26}
// //               name="arrow_back"
// //             />
// //             <span>Back</span>
// //           </TrackableElement>
// //           <TrackableElement
// //             className="box-button"
// //             category="Box"
// //             action={`${open ? "Close" : "Open"} Box`}
// //             onClick={() => {
// //               setOpen(!open);
// //               !open && props.onBoxOpen && props.onBoxOpen();
// //             }}
// //           >
// //             <Icon
// //               className="box-button-menu"
// //               size={28}
// //               name={`${open ? "menu_open" : "menu"}`}
// //             />
// //           </TrackableElement>
// //         </div>
// //       )}

// //       {props.menu
// //         .filter((option) => option.type === type)
// //         .map((option, index) => (
// //           <div
// //             key={`BoxOption${index}`}
// //             className={`${
// //               type !== "info" && type !== "emissions" && type !== "scanbox"
// //                 ? "box-content box-content__element scroll"
// //                 : "box-content__element"
// //             }`}
// //             style={{
// //               width:
// //                 type !== "info" && type !== "emissions" && type !== "scanbox"
// //                   ? ""
// //                   : isMobile
// //                   ? "85%"
// //                   : "",
// //             }}
// //           >
// //             <div
// //               style={{
// //                 position: "absolute",
// //                 marginTop:
// //                   type !== "info" && type !== "emissions" && type !== "scanbox"
// //                     ? ""
// //                     : isMobile
// //                     ? "15px"
// //                     : "10px",
// //                 width: "auto",
// //                 right: isMobile ? "15px" : "13px",
// //                 cursor: "pointer",
// //               }}
// //             >
// //               <span></span>
// //               <span>
// //                 <ReactSVG
// //                   style={{ color: "black" }}
// //                   src={"/assets/svg/close1.svg"}
// //                   onClick={() =>
// //                     setType((prevType) => (prevType === type ? "" : type))
// //                   }
// //                 />
// //               </span>
// //             </div>

// //             {props.title && (
// //               <p
// //                 className=""
// //                 style={{
// //                   margin: "0px",
// //                   fontSize: "15px",
// //                   fontWeight: "bold",
// //                   padding: type === "info" ? "10px" : "",
// //                   background: type === "info" ? "white" : "",
// //                   boxShadow:
// //                     type === "info" ? "0px 0px 5px 0px rgba(0, 0, 0, 0.2)" : "",
// //                   borderRadius: type === "info" ? "10px 10px 0px 0px" : "",
// //                 }}
// //               >
// //                 {props.title}
// //               </p>
// //             )}
// //             {option.render && option.render({ openMenuType })}
// //             {props.type === "share" && <>HII</>}
// //           </div>
// //         ))}
// //     </div>
// //   );
// // };

// // export default Box;

// import React, { useState, useEffect, useCallback } from "react";
// import { ReactSVG } from "react-svg";
// import { Link, useHistory } from "react-router-dom";
// import Icon from "components/Icon";
// import TrackableElement from "components/Trackable/Element";
// import { goBack } from "utils/navigation";
// import { isDesktop } from "utils/device";
// import "./styles.scss";

// type Option = {
//   type: string;
//   icon: string;
//   render?: any;
//   to?: string;
//   notification?: boolean;
// };

// interface Props {
//   menu: Array<Option>;
//   onBoxOpen?: Function;
//   title?: string;
//   type?: string;
//   content?: any;
// }

// const Box: React.FC<Props> = (props) => {
//   const [type, setType] = useState<string>(props.menu[0].type || "");
//   const [open, setOpen] = useState<boolean>(isDesktop());
//   const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
//   const history = useHistory();

//   // Handle window resize
//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 768);
//     };

//     window.addEventListener("resize", handleResize);
//     handleResize();

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   // Update internal state when props.type changes
//   useEffect(() => {
//     if (props.type && props.type !== type) {
//       console.log("Props type changed:", props.type);
//       console.log("Updating internal state to new type:", props.type);
//       setType(props.type);
//     }
//   }, [props.type]);

//   // Handle menu type click
//   const openMenuType = useCallback((newType: string) => {
//     console.log("Menu option clicked:", newType);
//     if (type !== newType) {
//       console.log("Setting type to:", newType);
//       setType(newType);
//     } else {
//       console.log("Type is already:", newType);
//     }
//   }, [type]);

//   // Render each menu option
//   const renderMenuOption = (option: Option, index: number) => {
//     let className = "box-menu-button";
//     if (type === option.type) className += " box-menu-button--active";
//     if (option.notification) className += " box-menu-button--notification";

//     const svg = (
//       <ReactSVG
//         className="box-menu-icon box-menu-icon-hover"
//         src={`/assets/svg/${option.icon}.svg`}
//       />
//     );
//     const props = { className, key: `BoxOption${index}` };

//     return option.to ? (
//       <Link to={option.to} {...props}>
//         {svg}
//       </Link>
//     ) : (
//       <TrackableElement
//         {...props}
//         category="Box"
//         action="Click Menu"
//         label={option.type}
//         onClick={() => openMenuType(option.type)}
//       >
//         {svg}
//       </TrackableElement>
//     );
//   };

//   // Handle props.type reset
//   useEffect(() => {
//     if (type === "share" && props.type !== "share") {
//       console.log("Resetting props.type to default value");
//       setType("info");
//     }
//   }, [type, props.type]);

//   console.log("Current box type:", type);
//   console.log("Current props type:", props.type);

//   return (
//     <div className={`shlk-box`}>
//       {open ? (
//         <div
//           className={`box-content-menu ${open ? "Open-box" : "Close-box"}`}
//           style={{
//             zIndex: "3",
//             width:
//               type !== "info" && type !== "emissions" && type !== "scanbox"
//                 ? ""
//                 : isMobile
//                 ? "15%"
//                 : "",
//           }}
//         >
//           <TrackableElement
//             className="box-button back"
//             category="Box"
//             action="Back button"
//             onClick={() => goBack(history)}
//           >
//             <Icon
//               className={`box-button-back ${open ? "Open-box" : "Close-box"}`}
//               size={26}
//               name="arrow_back"
//             />
//             <span>Back</span>
//           </TrackableElement>
//           <TrackableElement
//             className="box-button"
//             category="Box"
//             action={`${open ? "Close" : "Open"} Box`}
//             onClick={() => {
//               setOpen(!open);
//               !open && props.onBoxOpen && props.onBoxOpen();
//             }}
//           >
//             <Icon
//               className="box-button-menu"
//               size={28}
//               name={`${open ? "menu_open" : "menu"}`}
//             />
//           </TrackableElement>

//           {props.menu.map(renderMenuOption)}
//         </div>
//       ) : (
//         <div className="back-button">
//           <TrackableElement
//             className="box-button back"
//             category="Box"
//             action="Back button"
//             onClick={() => goBack(history)}
//           >
//             <Icon
//               className={`box-button-back ${open ? "Open-box" : "Close-box"}`}
//               size={26}
//               name="arrow_back"
//             />
//             <span>Back</span>
//           </TrackableElement>
//           <TrackableElement
//             className="box-button"
//             category="Box"
//             action={`${open ? "Close" : "Open"} Box`}
//             onClick={() => {
//               setOpen(!open);
//               !open && props.onBoxOpen && props.onBoxOpen();
//             }}
//           >
//             <Icon
//               className="box-button-menu"
//               size={28}
//               name={`${open ? "menu_open" : "menu"}`}
//             />
//           </TrackableElement>
//         </div>
//       )}

//       {props.menu
//         .filter((option) => option.type === type)
//         .map((option, index) => (
//           <div
//             key={`BoxOption${index}`}
//             className={`${
//               type !== "info" && type !== "emissions" && type !== "scanbox"
//                 ? "box-content box-content__element scroll"
//                 : "box-content__element"
//             }`}
//             style={{
//               width:
//                 type !== "info" && type !== "emissions" && type !== "scanbox"
//                   ? ""
//                   : isMobile
//                   ? "85%"
//                   : "",
//             }}
//           >
//             <div
//               style={{
//                 position: "absolute",
//                 marginTop:
//                   type !== "info" && type !== "emissions" && type !== "scanbox"
//                     ? ""
//                     : isMobile
//                     ? "15px"
//                     : "10px",
//                 width: "auto",
//                 right: isMobile ? "15px" : "13px",
//                 cursor: "pointer",
//               }}
//             >
//               <span></span>
//               <span>
//                 <ReactSVG
//                   style={{ color: "black" }}
//                   src={"/assets/svg/close1.svg"}
//                   onClick={() =>
//                     setType((prevType) => (prevType === type ? "" : type))
//                   }
//                 />
//               </span>
//             </div>

//             {props.title && (
//               <p
//                 className=""
//                 style={{
//                   margin: "0px",
//                   fontSize: "15px",
//                   fontWeight: "bold",
//                   padding: type === "info" ? "10px" : "",
//                   background: type === "info" ? "white" : "",
//                   boxShadow:
//                     type === "info" ? "0px 0px 5px 0px rgba(0, 0, 0, 0.2)" : "",
//                   borderRadius: type === "info" ? "10px 10px 0px 0px" : "",
//                 }}
//               >
//                 {props.title}
//               </p>
//             )}
//             {option.render && option.render({ openMenuType })}
//             {props.type === "share" && <>HII</>}
//           </div>
//         ))}
//     </div>
//   );
// };

// export default Box;

import React, { useState, useEffect, useCallback } from "react";
import { ReactSVG } from "react-svg";
import { Link, useHistory } from "react-router-dom";
import Icon from "components/Icon";
import TrackableElement from "components/Trackable/Element";
import { goBack } from "utils/navigation";
import { isDesktop } from "utils/device";
import "./styles.scss";

type Option = {
  type: string;
  icon: string;
  render?: (props: { openMenuType: (type: string) => void }) => JSX.Element;
  to?: string;
  notification?: boolean;
};

interface Props {
  menu: Option[];
  onBoxOpen?: () => void;
  title?: string;
  type?: string;
  content?: any;
}

const Box: React.FC<Props> = ({
  menu,
  onBoxOpen,
  title,
  type: propType,
  content,
}) => {
  const [type, setType] = useState<string>(menu[0].type || "");
  const [open, setOpen] = useState<boolean>(isDesktop());
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const history = useHistory();
  const [initialType, setInitialType] = useState<string>(propType || "");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (propType == "share" || propType == "tags") {
      setType(propType);
    } else {
      setType(type);
    }
  }, [propType]);

  // useEffect(() => {
  //   if (type === "share" && propType !== "share") {
  //     setType("info");
  //     setInitialType("info");
  //   }
  // }, [type, propType]);

  const openMenuType = useCallback(
    (newType: string) => {
      if (type !== newType) {
        setType(newType);
      }
    },
    [type]
  );

  const renderMenuOption = (option: Option, index: number) => {
    const isActive = type === option.type;
    const className = `box-menu-button ${
      isActive ? "box-menu-button--active" : ""
    } ${option.notification ? "box-menu-button--notification" : ""}`;

    const svg = (
      <ReactSVG
        className="box-menu-icon box-menu-icon-hover"
        src={`/assets/svg/${option.icon}.svg`}
      />
    );

    return option.to ? (
      <Link to={option.to} className={className} key={`BoxOption${index}`}>
        {svg}
      </Link>
    ) : (
      <TrackableElement
        className={className}
        key={`BoxOption${index}`}
        category="Box"
        action="Click Menu"
        label={option.type}
        onClick={() => openMenuType(option.type)}
      >
        {svg}
      </TrackableElement>
    );
  };

  console.log(type,  "type, proptype");

  return (
    <div className="shlk-box">
      {open ? (
        <div
          className={`box-content-menu ${open ? "Open-box" : "Close-box"}`}
          style={{
            zIndex: "3",
            width:
              type !== "info" && type !== "emissions" && type !== "scanbox"
                ? ""
                : isMobile
                ? "15%"
                : "",
                minWidth:isMobile? type == "share" || type == "tags" ? "15%" : "" :"" ,
          }}
        >
          <TrackableElement
            className="box-button back"
            category="Box"
            action="Back button"
            onClick={() => goBack(history)}
          >
            <Icon
              className={`box-button-back ${open ? "Open-box" : "Close-box"}`}
              size={26}
              name="arrow_back"
            />
            <span>Back</span>
          </TrackableElement>
          <TrackableElement
            className="box-button"
            category="Box"
            action={`${open ? "Close" : "Open"} Box`}
            onClick={() => {
              setOpen((prev) => {
                const newOpenState = !prev;
                if (!newOpenState && onBoxOpen) onBoxOpen();
                return newOpenState;
              });
            }}
          >
            <Icon
              className="box-button-menu"
              size={28}
              name={open ? "menu_open" : "menu"}
            />
          </TrackableElement>

          {menu.map(renderMenuOption)}
        </div>
      ) : (
        <div className="back-button">
          <TrackableElement
            className="box-button back"
            category="Box"
            action="Back button"
            onClick={() => goBack(history)}
          >
            <Icon
              className={`box-button-back ${open ? "Open-box" : "Close-box"}`}
              size={26}
              name="arrow_back"
            />
            <span>Back</span>
          </TrackableElement>
          <TrackableElement
            className="box-button"
            category="Box"
            action={`${open ? "Close" : "Open"} Box`}
            onClick={() => {
              setOpen((prev) => {
                const newOpenState = !prev;
                if (!newOpenState && onBoxOpen) onBoxOpen();
                return newOpenState;
              });
            }}
          >
            <Icon
              className="box-button-menu"
              size={28}
              name={open ? "menu_open" : "menu"}
            />
          </TrackableElement>
        </div>
      )}

      {menu
        .filter((option) => option.type === type)
        .map((option, index) =>
          type == "location" ? (
            <></>
          ) : (
            <div
              key={`BoxOption${index}`}
              className={`${
                type !== "info" && type !== "emissions" && type !== "scanbox"
                  ? "box-content box-content__element scroll"
                  : "box-content__element"
              }`}
              style={{
                width:
                  type !== "info" && type !== "emissions" && type !== "scanbox"
                    ? ""
                    : isMobile
                    ? "85%"
                    : "",
             
              }}
            >
              <div
                style={{
                  position: "absolute",
                  marginTop:
                    type !== "info" &&
                    type !== "emissions" &&
                    type !== "scanbox"
                      ? ""
                      : isMobile
                      ? "15px"
                      : "10px",
                  width: "auto",
                  right: isMobile ? "15px" : "13px",
                  cursor: "pointer",
                }}
              >
                <span></span>
                <span>
                  <ReactSVG
                    style={{ color: "black" }}
                    src={"/assets/svg/close1.svg"}
                    onClick={() =>
                      setType((prevType) => (prevType === type ? "" : type))
                    }
                  />
                </span>
              </div>

              {title && (
                <p
                  style={{
                    margin: "0px",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: type === "info" ? "10px" : "",
                    background: type === "info" ? "white" : "",
                    boxShadow:
                      type === "info"
                        ? "0px 0px 5px 0px rgba(0, 0, 0, 0.2)"
                        : "",
                    borderRadius: type === "info" ? "10px 10px 0px 0px" : "",
                  }}
                >
                  {title.length > 30 ? title.slice(0, 30) + "..." : title}
                </p>
              )}
              {option.render && option.render({ openMenuType })}
            </div>
          )
        )}
    </div>
  );
};

export default Box;
