import React from 'react';
import NotAllowed from 'pages/NotAllowed';
import { hasPermission } from 'utils/auth';
import PrivateRoute from '../Private';

export default class AdminRoute extends PrivateRoute {
    render() {
        return (hasPermission("ADMIN_PAGES")) ?
                    super.render() :
                    <NotAllowed { ...this.props } />;
    }
}