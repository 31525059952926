import React, { Component } from 'react';
import ContentLoader from 'components/Loading/ContentLoader';
import Icon from 'components/Icon';
import { format as formatDate } from 'utils/date';
import { getVessel } from 'services/vessel';

interface Props {
  index: number;
  vessel: any;
  measurement: any;
}

interface State {
  loading: boolean;
  vessel: any;
}

export default class VesselMarkerPopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { loading: true, vessel: null };
  }

  async componentDidMount() {
    let vessel = this.props.vessel;
    const measurementVessel = this.props.measurement.vessel;

    if(measurementVessel) {
      vessel = await getVessel({ id: measurementVessel });
    }

    this.setState({ loading: false, vessel });
  }

  renderPopup = () => {
    const vessel = this.state.vessel;
    const meas = this.props.measurement;

    return (<div className="marker-popup">
              <p className="marker-popup-field icon"><Icon className="marker-popup-icon" name="directions_boat" size={12}/><b>VESSEL</b></p>
              <p className="marker-popup-field"><b>Index:</b> {this.props.index}</p>
              <p className="marker-popup-field"><b>At:</b> {formatDate(meas.time)}</p>
              {vessel && vessel.name && <p className="marker-popup-field"><b>Name:</b> {vessel.name}</p>}
              {vessel && <p className="marker-popup-field"><b>MMSI:</b> {vessel.mmsi}</p>}
            </div>);
  }
  render() {
    return <ContentLoader loading={this.state.loading} content={this.renderPopup} />;
  }
}