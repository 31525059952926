import React, { Component, ChangeEvent } from "react";
import AirMode from "./AirMode";
import SeaMode from "./SeaMode";
import LandMode from "./LandMode";
import RailMode from "./RailMode";

import ModeSelector from "./ModeSelector";
import { on } from "events";

/**
 * Represents a Mode object
 */
export interface ModeObject {
  modeType: string;
}

/**
 * Extends Mode to include Air mode fields
 */
export interface AirMode extends ModeObject {
  originAirport?: any;
  destinationAirport?: any;
  aircraftType?: string;
}

/**
 * Extends Mode to include Sea mode fields
 */
export interface SeaMode extends ModeObject {
  imo?: string;
  shipType?: string;
  originPort?:any;
  destinationPort?:any;
}

/**
 * Extends Mode to include Land mode fields
 */
export interface LandMode extends ModeObject {
  fuel?: string;

}

/**
 * Extends Mode to include Rail mode fields
 */
export interface RailMode extends ModeObject {
  originStation?: any;
  destinationStation?: any;
  trainTractionType?: string;
}

export type AnyMode = AirMode | SeaMode | LandMode | RailMode;

type State = {
  mode: string;
};

interface Props {

  mode: ModeObject;
  id: number;
  onModeChange: (mode: ModeObject, id: number) => void;
  onModeDelete: (id: number) => void;
  onModeFieldChange: (field: string, value: any, id: number, sublevel? :string) => void;
  update?: any; // Add update to Props
}

export default class ModeForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    console.log("ModeForm", props);
    let modeComponent;
    switch (props.mode.modeType) {
      case "Air":

        modeComponent = <AirMode airModeData={props.mode} onChange={this.handleFieldChange}/>;
        break;
      case "Sea":
        modeComponent = <SeaMode  seaModeData={props.mode} onChange={this.handleFieldChange} update={this.props.update} />;
        break;
      case "Land":
        modeComponent = <LandMode landModeData={props.mode} onChange={this.handleFieldChange}/>;
        break;
      case "Rail":
        modeComponent = <RailMode railModeData={props.mode} onChange={this.handleFieldChange}/>;
        break;
      default:
        modeComponent = <LandMode landModeData={props.mode} onChange={this.handleFieldChange}/>;
    }
    this.state = {
      mode: props.mode.modeType
    };
  }

  handleFieldChange = (field:  string, value: any, sublevel? :string) => {


    this.props.onModeFieldChange(field, value, this.props.id, sublevel);
  };


  handleModeChange = (newModeType: string) => {
    const updatedMode: ModeObject = { modeType: newModeType };
    this.setState({ mode: newModeType });
    this.props.onModeChange(updatedMode, this.props.id);
    if (newModeType === "Air") {
      this.props.onModeFieldChange("originAirport",
      {address:"", location: {lat: 0, lng: 0}}, this.props.id);
      this.props.onModeFieldChange("destinationAirport",
      {address:"", location: {lat: 0, lng: 0}}, this.props.id);
    } else if (newModeType === "Rail") {
      this.props.onModeFieldChange("originStation",
      {address:"", location: {lat: 0, lng: 0}}, this.props.id);
      this.props.onModeFieldChange("destinationStation",
      {address:"", location: {lat: 0, lng: 0}}, this.props.id);
    }else if (newModeType === "Sea") {
      this.props.onModeFieldChange("originPort",
      {address:"", location: {lat: 0, lng: 0}}, this.props.id);
      this.props.onModeFieldChange("destinationPort",
      {address:"", location: {lat: 0, lng: 0}}, this.props.id);
    }



  };

  render() {
    const { mode } = this.state;
    const { mode: modeData } = this.props;

    return (
      <div className="new-box">
        <h4 className="new-box-title">Mode details</h4>
        <label htmlFor="modeSelect">Mode Type</label>
        <div className="new-wrapper__row--left">
        <ModeSelector selectedMode={mode} onModeChange={this.handleModeChange} />
        </div>

        <div style={{ marginTop: '10px' }}>
          {mode === 'Air' && <AirMode airModeData={modeData} onChange={this.handleFieldChange} />}
          {mode === 'Sea' && <SeaMode seaModeData={modeData} onChange={this.handleFieldChange} update={this.props.update}/>}
          {mode === 'Land' && <LandMode landModeData={modeData} onChange={this.handleFieldChange} />}
          {mode === 'Rail' && <RailMode railModeData={modeData} onChange={this.handleFieldChange}/>}
          {/* Render other mode components based on the selected mode */}
        </div>
        <button className="new-delete" onClick={() => this.props.onModeDelete(this.props.id)}>
          Delete
        </button>
      </div>
    );
  }
}
