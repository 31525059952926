import React, { Component, PropsWithChildren } from 'react';
import Icon from 'components/Icon';
import './styles.scss';

type Props = PropsWithChildren<{
  vessel: any;
  index: number;
}>

export default class VesselList extends Component<Props> {
  render() {
    const isPending = !this.props.vessel.name;

    return (
      <div className="fleet-list__element">
        <div className="fleet-info">
          <div className="fleet-info__icon">
            { !isPending ? this.props.index + 1 : (<Icon className="shlk-fleet-loading" name="sensors_off" size={16} />)}
          </div>
          <div className="fleet-info__text">
            <div className="vessel-info__name"><b>{this.props.vessel.name}</b></div>
            <div className="vessel-info__mmsi">MMSI: {this.props.vessel.mmsi}</div>
          </div>
        </div>
        { this.props.children }
      </div>
    );
  }
}