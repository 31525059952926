import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import Page from 'components/ControlBars/Page';
import ContentLoader from 'components/Loading/ContentLoader';
import ShipmentContent from 'components/Shipment';
import { getPublicShipmentInfo } from 'services/shipments';
import { clean } from 'services/api';
import './styles.scss';

interface MatchProps {
  id: string;
}
type Props = RouteComponentProps<MatchProps>

type State = {
  loading: boolean;
  shipment: any;
};


export default class PublicShipment extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      shipment: null
    };
  }

  getShipment= () => {
    this.setState({ loading: true });

    getPublicShipmentInfo(this.props.match.params.id)
      .then((shipment) => this.setState({ loading: false, shipment }));
  }

  componentDidMount() {
    this.getShipment();
  }

  hardRefresh = () => {
    clean();
    this.getShipment();
  }

  renderContent = () => {
    return <ShipmentContent shipment={this.state.shipment}
                            permissions={[
                              "VIEW_SHIPMENT"
                            ]}
                            hardRefresh={this.hardRefresh}
                            isPublic={true}/>;
  }

  render() {
    return (
      <Page className="shlk-public-shipment" type="map">
        <ContentLoader loading={this.state.loading} content={this.renderContent} />
      </Page>
    );
  }
}