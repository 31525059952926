import React, { Component } from 'react';
import AbstractMap from 'components/GoogleMap/Abstract';
import LiveTrackerMarker from 'components/GoogleMap/Markers/LiveTracker';
import LiveVesselMarker from 'components/GoogleMap/Markers/LiveVessel';
import Tracker from 'components/Tracker';
import './styles.scss';

export interface Props {
  trackers: Array<any>;
  className?: string;
  open?: Number;
  click?: any;
  popup?: any;
}

const isOnboard = (tracker: any) => tracker.transport && tracker.transport.status === 'ONBOARD';

export default class TrackersMap extends Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  static defaultProps = { className: '', open() {}, click() {} };
  private map: any;
  private maps: any;
  private trackersLength = 0;

  componentDidUpdate() {
    (this.map && this.maps) && this.updateMap(this.trackersLength !== this.props.trackers.length);
    this.trackersLength = this.props.trackers.length;
  }

  trackerDisclaimer = (tracker: any) => {
    if(isOnboard(tracker)) {
      const vessel = tracker.lastMeasurement.vessel;

      return `${vessel ? `${vessel.name} -`: ''} Tracker onboard`;
    }
  }

  trackerPopup = (tracker: any) => {
    const disclaimer = this.trackerDisclaimer(tracker);

    return (<div className="tracker-popup">
      { disclaimer && <b className="tracker-disclaimer">{disclaimer}</b>}
      <Tracker tracker={tracker} />
    </div>);
  }

  updateMap = (useBounds: boolean) => {
    const trackers = this.props.trackers.filter(tracker => tracker.lastMeasurement),
          open = trackers.find(tracker => tracker.identifier === this.props.open);

    if(open) {
      this.map.setCenter(open.lastMeasurement.location);
    } else if(trackers.length === 0){
      this.map.setZoom(1);
    } else if(useBounds) {
      const bounds = new this.maps.LatLngBounds();

      trackers.forEach(tracker => {
        bounds.extend(tracker.lastMeasurement.location);
      });

      this.map.fitBounds(bounds);
      this.map.setZoom(Math.min(this.map.getZoom(), 7));
    }
  }

  onApiLoaded = (map: any, maps: any) => {
    if(map && maps) {
      this.map = map;
      this.maps = maps;

      this.updateMap(true);
    }
  }

  render() {
    const trackers: any[] = this.props.trackers.filter(tracker => tracker.lastMeasurement);

    return (<div className={`shlk-map ${this.props.className}`}>
      <AbstractMap
        defaultCenter={{ lat : 0, lng : 0}} defaultZoom={1}
        onGoogleApiLoaded={({ map, maps }) => this.onApiLoaded(map, maps)}
      >

      {
        trackers.map((tracker: any, index: number) => {
          const isTrackerOnboard: boolean = isOnboard(tracker);

          if(isTrackerOnboard) {
            tracker.lastMeasurement = tracker.transport.measurements[tracker.transport.measurements.length - 1];
          }

          const props = {
            key: `Marker_${index}`,
            active: !!tracker.transport,
            open: this.props.open === tracker.identifier,
            popup: this.props.popup ? this.props.popup.bind(this, tracker) : this.trackerPopup.bind(this, tracker),
            click: (status: string) => this.props.click(tracker.identifier, status),
            lat: tracker.lastMeasurement.location.lat,
            lng: tracker.lastMeasurement.location.lng
          };

          return isTrackerOnboard ?
            <LiveVesselMarker {...props} vessel={{ lastMeasurement: tracker.lastMeasurement }} /> :
            <LiveTrackerMarker {...props} />;
        })
      }
      </AbstractMap>
    </div>);
  }
}