// import Icon from 'components/Icon';
// import React, { Component, CSSProperties } from 'react';

// import './styles.scss';

// interface Cell {
//     onClick?: Function;
//     className?: string;
//     rowSpan?: number;
//     colSpan?: number;
//     style?: CSSProperties;
//     value?: string;
//     icon?: string;
//     render?: () => JSX.Element;
// }

// interface Row {
//     id?: string;
//     onClick?: Function;
//     className?: string;
//     cells: Cell[];
// }

// interface Props {
//     headings: string[];
//     rows: Row[];
//     className?: string;
// }

// export default class Table extends Component<Props> {
//     renderRow({ onClick, value, icon, className, style, render, rowSpan, colSpan }: Cell, index: number) {
//         const props: any = { key: `${value}_${index}`, className: onClick ? 'clickable' : '' };

//         if(onClick) props.onClick = onClick;
//         if(className) props.className += ` ${className}`;
//         if(style) props.style = style;
//         if(rowSpan) props.rowSpan = rowSpan;
//         if(colSpan) props.colSpan = colSpan;

//         return (
//             <td {...props}>
//                 { render && render() } { value && value } { icon && <Icon className="table-component__icon" name={icon} size={16} />}
//             </td>
//         );
//     }

//     renderRows() {
//         return this.props.rows.map(({ onClick, cells, id, className }: Row, index: number) => (
//             <tr key={`TableBodyRow_${id || index}`}
//                 onClick={() => onClick && onClick(cells)}
//                 className={`${className || ''} ${onClick ? 'clickable' : ''}`}>
//               { cells.map(this.renderRow) }
//             </tr>
//           ));
//     }

//     renderHeadings() {
//         return (<tr>{ this.props.headings.map((heading: string, index: number) => (<th key={`${heading}_${index}`}>{ heading }</th>)) }</tr>);
//     }

//     render() {
//         return (
//             <div className={`table-component ${this.props.className || ''}`}>
//                 <table className="table-component__element">
//                     <thead>
//                         { this.renderHeadings() }
//                     </thead>
//                     <tbody>
//                         { this.renderRows() }
//                     </tbody>
//                 </table>
//             </div>
//         );
//     }
// }

import Icon from 'components/Icon';
import React, { Component, CSSProperties } from 'react';
import './styles.scss';

interface Cell {
    onClick?: Function;
    className?: string;
    rowSpan?: number;
    colSpan?: number;
    style?: CSSProperties;
    value?: string;
    icon?: string;
    render?: () => JSX.Element;
}

interface Row {
    id?: string;
    onClick?: Function;
    className?: string;
    cells: Cell[];
}

interface Props {
    headings: string[];
    rows: Row[];
    className?: string;
}

export default class Table extends Component<Props> {
    renderRow({ onClick, value, icon, className, style, render, rowSpan, colSpan }: Cell, index: number) {
        const props: any = {
            key: `${value}_${index}`,
            className: onClick ? 'clickable' : '',
            'data-label': value // Add data-label attribute for mobile
        };

        if (onClick) props.onClick = onClick;
        if (className) props.className += ` ${className}`;
        if (style) props.style = style;
        if (rowSpan) props.rowSpan = rowSpan;
        if (colSpan) props.colSpan = colSpan;

        return (
            <td {...props}>
                { render && render() }
                { value && value }
                { icon && <Icon className="table-component__icon" name={icon} size={16} /> }
            </td>
        );
    }

    renderRows() {
        return this.props.rows.map(({ onClick, cells, id, className }: Row, index: number) => (
            <tr key={`TableBodyRow_${id || index}`}
                onClick={() => onClick && onClick(cells)}
                className={`${className || ''} ${onClick ? 'clickable' : ''}`}>
              { cells.map(this.renderRow) }
            </tr>
        ));
    }

    renderHeadings() {
        return (
            <tr>{ this.props.headings.map((heading: string, index: number) => (<th key={`${heading}_${index}`}>{ heading }</th>)) }</tr>
        );
    }

    render() {
        return (
            <div className={`table-component ${this.props.className || ''}`}>
                <table className="table-component__element">
                    <thead>
                        { this.renderHeadings() }
                    </thead>
                    <tbody>
                        { this.renderRows() }
                    </tbody>
                </table>
            </div>
        );
    }
}
