import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';

import AdminRoute from 'components/Route/Admin';
import PrivateRoute from 'components/Route/Private';
import ErrorBoundary from 'components/ErrorBoundary';
import TrackableRoute from 'components/Trackable/Route';
import DeviceHandler from 'components/DeviceHandler';
import Analytics from 'utils/analytics';
import ServiceWorkers from 'utils/sw';
import { PAGES } from 'pages/_index';

import './styles.scss';


export default class App extends Component<{}> {
  constructor(props: {}) {
    super(props);

    Analytics.init();
    ServiceWorkers.init();

    console.log("-------------------------");
    console.log("WELCOME TO Naxxar!");
    console.log("-------------------------");
  }

  render() {
    return (
      <Router>
        <ErrorBoundary>
          <DeviceHandler>
            { () => (
              <Switch>
                <TrackableRoute exact title="Login" path="/login" component={PAGES.Login} />
                <TrackableRoute exact title="Login" path="/forgot-password" component={PAGES.ForgotPassword} />
                <TrackableRoute exact title="Login" path="/enter-code" component={PAGES.EnterResetCode} />
                <TrackableRoute exact title="Login" path="/reset-password" component={PAGES.ResetPassword} />
                <TrackableRoute exact title="Naxxar Public" path="/shipments/:id/public"  component={PAGES.Public} />
                <TrackableRoute exact title="eShell Info" path="/eshell/:id/"  component={PAGES.EShell} />

                <PrivateRoute exact title="User" path="/user" component={PAGES.User} />

                <PrivateRoute exact title="eShells Management" path="/trackers" component={PAGES.Trackers} />
                <PrivateRoute exact title="Device Management" path="/trackers/:id" component={PAGES.Tracker} />

                <Redirect exact path="/archives" to="/shipments" />
                <PrivateRoute exact title="Shipments" path="/shipments" component={PAGES.Shipments} />
                <PrivateRoute exact title="Shipment" path="/shipments/:id" component={PAGES.Shipment} />
                <PrivateRoute exact title="Shipment Report" path="/shipments/:id/report" component={PAGES.Report} />

                <PrivateRoute exact title="Tags" path="/tags" component={PAGES.Tags} />
                <PrivateRoute exact title="Tag" path="/tag/:id" component={PAGES.Tag} />

                <PrivateRoute exact title="New Shipment" path="/new" component={PAGES.New} />
                <PrivateRoute exact title="Fleet" path="/fleet" component={PAGES.Fleet} />

                <AdminRoute exact title="Admin" path="/admin" component={PAGES.Admin} />
                <AdminRoute exact title="Admin - Trackers" path="/admin/trackers" component={PAGES.AdminTrackers} />
                <AdminRoute exact title="Admin - Trackers - Free" path="/admin/trackers/free" component={PAGES.AdminTrackersFree} />
                <AdminRoute exact title="Admin - Trackers - All" path="/admin/trackers/all" component={PAGES.AdminTrackersAll} />
                <AdminRoute exact title="Admin - Users" path="/admin/users" component={PAGES.AdminUsers} />
                <AdminRoute exact title="Admin - Company" path="/admin/company" component={PAGES.AdminCompany} />
                <AdminRoute exact title="Admin - Data" path="/admin/data" component={PAGES.AdminData} />
                <AdminRoute exact title="Admin - Data - Shipments" path="/admin/shipments" component={PAGES.AdminDataShipments} />


                <PrivateRoute exact title="Dashboard" path="/" component={PAGES.Home} />

                <TrackableRoute title="Not Found" component={PAGES.NotFound} />
              </Switch>
            )}
          </DeviceHandler>
        </ErrorBoundary>
      </Router>
    );
  }
}