import React, { Component, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import './styles.scss';

type Props = PropsWithChildren<{
  title: string;
  link: string;
}>

export default class Location extends Component<Props> {
  render() {
      return (
        <Link className="shlk-widget shlk-widget-map" to={this.props.link}>
          <div className="shlk-title">
            <div className="shlk-title__link">
              <h2 className="shlk-title__text">{this.props.title}</h2>
              <Icon className="shlk-title__icon" size={30} name="play_circle"/>
            </div>
          </div>
          <div className="shlk-card shlk-widget-location">
            { this.props.children }
          </div>
        </Link>
      );
  }
}