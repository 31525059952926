// import React, { Component } from 'react';
// import BoxTitle from 'components/Box/Title';
// import Dropdown from 'components/Inputs/Dropdown';
// import Selectable from 'components/Inputs/Selectable';
// import { ShipmentContext } from '../context';
// import "./styles.scss";

// interface Props {
//     events: boolean;
//     settings: any;
//     onChange?: Function;
// }

// export default class MapSettings extends Component<Props> {
//     update = (key: any, value: string[] | boolean) => {
//         const settings = Object.assign({}, this.props.settings);
//         settings[key] = value;

//         this.props.onChange ?
//             this.props.onChange(settings) :
//             this.context.updateMapSettings(settings);
//     }

//     updateSources = (key: any, checked: boolean) => {
//         let sources = [...this.props.settings.sources];

//         if(checked) {
//             sources.push(key);
//         } else {
//             sources = sources.filter((source: string) => source !== key);
//         }

//         this.update('sources', sources);
//     }

//     render() {
//         return (
//             <div className="shipment-mapsettings">
//                 <BoxTitle text="Display" />

//                 <div className="mapsettings-option">
//                     <Selectable
//                         name="Routes"
//                         checked={this.props.settings.routes}
//                         info="Only display measurements in the map"
//                         onClick={(value: boolean) => this.update("routes", value)}
//                     />
//                 </div>

//                 {
//                     (this.props.events) && (
//                         <div className="mapsettings-option">
//                             <Selectable
//                                 name="Events"
//                                 checked={this.props.settings.events}
//                                 info="Events will appear in the map, colored red if they haven't been acknoledged yet"
//                                 onClick={(value: boolean) => this.update("events", value)}
//                             />
//                         </div>
//                     )
//                 }

//                 <div className="mapsettings-option">
//                     <Dropdown title={<Selectable
//                         name="Location by color"
//                         checked={this.props.settings.colors}
//                         onClick={(value: boolean) => this.update("colors", value)}
//                     />
//                     }>
//                         <p className="mapsettings-subtitle">
//                             <span className="mapsetting-color"><span className="mapsetting-color-cluster"></span>Cluster</span>
//                             <span className="mapsetting-color"><span className="mapsetting-color-tracker"></span>GPS</span>
//                             <span className="mapsetting-color"><span className="mapsetting-color-wifi"></span>WiFi</span>
//                             <span className="mapsetting-color"><span className="mapsetting-color-tower"></span>Cell tower</span>
//                             <span className="mapsetting-color"><span className="mapsetting-color-vessel"></span>Vessel</span>
//                             <span className="mapsetting-color"><span className="mapsetting-color-na"></span>n/a</span>
//                         </p>
//                     </Dropdown>
//                 </div>

//                 <h4 className="mapsettings-title">Location selector</h4>

//                 <div className="mapsettings-option">
//                     <Selectable
//                         name="GPS"
//                         checked={this.props.settings.sources.includes('TRACKER')}
//                         onClick={(value: boolean) => this.updateSources("TRACKER", value)}
//                     />
//                     <Selectable
//                         name="Cell tower"
//                         checked={this.props.settings.sources.includes('TOWER')}
//                         onClick={(value: boolean) => this.updateSources("TOWER", value)}
//                     />
//                     <Selectable
//                         name="WiFi"
//                         checked={this.props.settings.sources.includes('WIFI')}
//                         onClick={(value: boolean) => this.updateSources("WIFI", value)}
//                     />
//                     <Selectable
//                         name="Vessel"
//                         checked={this.props.settings.sources.includes('VESSEL')}
//                         onClick={(value: boolean) => this.updateSources("VESSEL", value)}
//                     />
//                     <Selectable
//                         name="n/a"
//                         checked={this.props.settings.sources.includes('N/A')}
//                         onClick={(value: boolean) => this.updateSources("N/A", value)}
//                     />
//                 </div>
//             </div>
//         );
//     }
// }

// MapSettings.contextType = ShipmentContext;

import React, { Component } from "react";
import BoxTitle from "components/Box/Title";
import Dropdown from "components/Inputs/Dropdown";
import Selectable from "components/Inputs/Selectable";

import { ShipmentContext } from "../context";
import "./styles.scss";
import { ReactSVG } from "react-svg";

interface Props {
  events: boolean;
  settings: any;
  onChange?: Function;
}

interface State {
  expandedInfo: string | null;
}

export default class MapSettings extends Component<Props, State> {
  state: State = {
    expandedInfo: null, // Keeps track of which info is expanded
  };

  update = (key: any, value: string[] | boolean) => {
    const settings = { ...this.props.settings };
    settings[key] = value;

    this.props.onChange
      ? this.props.onChange(settings)
      : this.context.updateMapSettings(settings);
  };

  updateSources = (key: any, checked: boolean) => {
    let sources = [...this.props.settings.sources];

    if (checked) {
      sources.push(key);
    } else {
      sources = sources.filter((source: string) => source !== key);
    }

    this.update("sources", sources);
  };

  handleIconClick = (name: string) => {
    this.setState((prevState) => ({
      expandedInfo: prevState.expandedInfo === name ? null : name,
    }));
  };

  render() {
    const { expandedInfo } = this.state;

    return (
      <div className="shipment-mapsettings">
        <BoxTitle text="Display" />

        <div className="mapsettings-option" style={{flexDirection:"row"}}>
          <Selectable
            name="Routes"
            checked={this.props.settings.routes}
            onClick={(value: boolean) => this.update("routes", value)}
          />
          <ReactSVG
            src="/assets/svg/info.svg"
            className="info-icon"
            onClick={() => this.handleIconClick("routes")}
            style={{ cursor: "pointer", marginLeft: "9px", marginTop:"3px", color:"black"}}
          />

       
        </div>
        {expandedInfo === "routes" && (
            <div className="info-text" style={{marginBottom:"5px"}}>
              Only display measurements in the map.
            </div>
          )}
        {this.props.events && (
          <div className="mapsettings-option" style={{flexDirection:"row"}}>
            <Selectable
              name="Events"
              checked={this.props.settings.events}
              onClick={(value: boolean) => this.update("events", value)}
            />
            <ReactSVG
              src="/assets/svg/info.svg"
              className="info-icon"
              onClick={() => this.handleIconClick("events")}
              style={{ cursor: "pointer", marginLeft: "9px", marginTop:"3px", color:"black" }}
            />
           
          </div>
        )}
 {expandedInfo === "events" && (
              <div className="info-text">
                Events will appear in the map, colored red if they havent been
                acknowledged yet.
              </div>
            )}
        <div className="mapsettings-option">
          <Dropdown
            title={
              <Selectable
                name="Location by color"
                checked={this.props.settings.colors}
                onClick={(value: boolean) => this.update("colors", value)}
              />
            }
          >
            <p className="mapsettings-subtitle">
              <span className="mapsetting-color">
                <span className="mapsetting-color-cluster"></span>Cluster
              </span>
              <span className="mapsetting-color">
                <span className="mapsetting-color-tracker"></span>GPS
              </span>
              <span className="mapsetting-color">
                <span className="mapsetting-color-wifi"></span>WiFi
              </span>
              <span className="mapsetting-color">
                <span className="mapsetting-color-tower"></span>Cell tower
              </span>
              <span className="mapsetting-color">
                <span className="mapsetting-color-vessel"></span>Vessel
              </span>
              <span className="mapsetting-color">
                <span className="mapsetting-color-na"></span>n/a
              </span>
            </p>
          </Dropdown>
        </div>

        <h4 className="mapsettings-title">Location selector</h4>

        <div className="mapsettings-option">
          <Selectable
            name="GPS"
            checked={this.props.settings.sources.includes("TRACKER")}
            onClick={(value: boolean) => this.updateSources("TRACKER", value)}
          />
          <Selectable
            name="Cell tower"
            checked={this.props.settings.sources.includes("TOWER")}
            onClick={(value: boolean) => this.updateSources("TOWER", value)}
          />
          <Selectable
            name="WiFi"
            checked={this.props.settings.sources.includes("WIFI")}
            onClick={(value: boolean) => this.updateSources("WIFI", value)}
          />
          <Selectable
            name="Vessel"
            checked={this.props.settings.sources.includes("VESSEL")}
            onClick={(value: boolean) => this.updateSources("VESSEL", value)}
          />
          <Selectable
            name="n/a"
            checked={this.props.settings.sources.includes("N/A")}
            onClick={(value: boolean) => this.updateSources("N/A", value)}
          />
        </div>
      </div>
    );
  }
}

MapSettings.contextType = ShipmentContext;
