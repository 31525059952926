import React, { Component } from 'react';
import Input from 'components/Inputs/Input/Default';
import { KEYBOARD_KEYS } from './../constants';
import './styles.scss';

interface Props {
    datalist: { _id: string; name: string }[];
    onSelect: Function;
    name: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    onEnterKey?: boolean;
}

export default class Datalist extends Component<Props> {
    static defaultProps = { placeholder: '', className: '' };

    render() {
        return (
            <div className={`input-datalist ${this.props.className}`}>
                <Input
                    disabled={this.props.disabled}
                    className="input-element"
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    type="text"
                    list={this.props.name}
                    onKeyUp={(event: any) => {
                        const code = event.code || event.which;
                        const onEnterKey = this.props.onEnterKey && code === KEYBOARD_KEYS.enter;

                        if(!event.nativeEvent.code || onEnterKey) {
                            this.props.onSelect(event);
                        }
                    }}/>
                <datalist id={this.props.name}>
                    { this.props.datalist.map((element: any) =>
                        <option key={element._id} value={element.value || element.name }>{element.name}</option>)
                    }
                </datalist>
            </div>
        );
    }
}
