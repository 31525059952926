import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { format as formatDate } from 'utils/date';
import Table from 'components/Table';

interface Props extends RouteComponentProps {
    trackers: any[]
}

class TrackersInfoTablesTag extends Component<Props> {
    render() {
        const trackers = this.props.trackers;
        const trackersLength = trackers.length;

        return (
        <div className="tags-references">
            <h3 className="references-title">{trackersLength ? `${trackersLength} eShells` : 'No eShells'}</h3>
            { trackersLength > 0 &&
                <Table headings={['Name', 'Last Update', 'Battery', 'Temperature', 'View']} rows={
                    this.props.trackers.map((tracker: any) => ({
                        id: tracker._id,
                        onClick: () => this.props.history.push(`/trackers/${tracker.identifier}`),
                        cells: [
                            { value: tracker.name },
                            { value: formatDate(tracker.lastMeasurement.time) },
                            { value: `${tracker.lastMeasurement.battery} %` },
                            { value: `${tracker.lastMeasurement.temperature} ºC` },
                            { icon: 'arrow_forward_ios' }
                        ]
                    }))
                } />
            }
        </div>);
    }
}

export default withRouter(TrackersInfoTablesTag);