//A Company Card component that displays the company name with an 
//onClick handler that calls the handleCompanyClick function with the company id as an argument.


import React from 'react';
import './styles.scss';

interface Props {
  shipment: any;
  onClick: (index: number) => void;
  isSelected: boolean;
  index: number;
}

const ShipmentCard = ({ shipment, index, onClick, isSelected }: Props) => {
  const origin = shipment.origin; 
  const destination = shipment.destination;
  return (
    <div 
      className={`shipment-card ${isSelected ? 'selected' : ''}`} 
      onClick={() => onClick(index)}
    >
      <h3>{shipment.name}</h3>
      <div className="address">
        <br/>
        {origin.address}
        <br/>
        <strong>&darr;</strong>
        <br/>
        {destination.address}
      </div>
    </div>
  );
};

export default ShipmentCard;