import React, { Component } from "react";
import BoxTitle from "components/Box/Title";
import FormSettings from "components/Forms/Settings";
import ContentLoader from "components/Loading/ContentLoader";
import BoxButton from "components/Box/Button";
import { validateSettings } from "components/Forms/Settings/utils";
import { updateSettings } from "services/shipments";
import { ShipmentContext } from "../context";
import "./styles.scss";
import NewFormSettings from "components/Forms/Settings/indexx";

interface State {
  loading: boolean;
  settings: any;
}

export default class Settings extends Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      loading: true,
      settings: null,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
      settings:
        this.context.shipment.settings &&
        JSON.parse(JSON.stringify(this.context.shipment.settings)),
    });
  }

  updateSettings = () => {
    this.setState({ loading: true });

    updateSettings(this.context.shipment._id, this.state.settings)
      .then(
        () =>
          (this.context.shipment.settings = JSON.parse(
            JSON.stringify(this.state.settings)
          ))
      )
      .finally(() => this.setState({ loading: false }));
  };

  update = (newSettings: any) => {
    this.setState({ settings: newSettings });
  };

  isThereAnUpdate = () => {
    return (
      JSON.stringify(this.state.settings) !==
      JSON.stringify(this.context.shipment.settings)
    );
  };

  renderContent = () => {
    const trackerModels = this.context.shipment.transports.map(
        ({ tracker }: any) => tracker.model
      );
    const validation = validateSettings(
      this.state.settings,
      trackerModels[0]
    );

    console.log( trackerModels[0], "shipment settings");
    const showSubmitButton =
      this.context.shipment.status === "ongoing" &&
      this.isThereAnUpdate() &&
      validation.valid;
    const formData = {
      readOnly: this.context.shipment.status === "completed",
      trackers: this.context.shipment.transports.map(
        ({ tracker }: any) => tracker
      ),
      settings: this.state.settings,
      validation,
    };

    return (
      <>
        <BoxTitle text="Settings" />

        <NewFormSettings
          className="bc-stretch"
          data={formData}
          update={this.update}
        />

        <div className="settings-btn-wrapper bc-wrapper">
          <BoxButton
            category="ShipmentSettings"
            action="Click Update Settings"
            label={this.context.shipment._id}
            disabled={!showSubmitButton}
            onClick={this.updateSettings}
            icon="save"
            text="Save"
          />
        </div>
      </>
    );
  };

  render() {
    return (
      <div
        className={
          "shipment-settings bc-wrapper shipment-settings__" +
          this.context.shipment.status
        }
      >
        <ContentLoader
          loading={this.state.loading}
          content={this.renderContent}
        />
      </div>
    );
  }
}

Settings.contextType = ShipmentContext;
