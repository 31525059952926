import React, { Component } from "react";
import { ReactSVG } from "react-svg";

import TrackableElement from "components/Trackable/Element";

import "./styles.scss";

export interface MeasMarkerProps extends MarkerProps {
  index: number;
  measurement: any;
  source: string;
}

export interface MarkerProps {
  lat: number;
  lng: number;
  popup?: Function;
  click?: Function;
  open?: boolean;
}

export default abstract class AbstractMarker<
  T extends MarkerProps
> extends Component<T> {
  abstract readonly name: string;

  hasPopup = !!this.props.popup;
  renderMarker() {
    return <></>;
  }
  renderPopupContent = () => <></>;

  renderPopup() {
    console.log(this.name, "name");
    return (
      <div className="shlk-marker-popup">
        {this.props.popup ? this.props.popup() : this.renderPopupContent()}

        <TrackableElement
          category="Marker"
          action={`${!this.props.click ? "Fail " : ""}Close Marker`}
          label={this.name}
          onClick={() => {
            if (this.props.click) {
              this.props.click("close");
            }
          }}
        >
          <ReactSVG
            className="shlk-marker-close"
            src={`/assets/svg/close.svg`}
          />
        </TrackableElement>

        <ReactSVG
          className="shlk-marker-triangle"
          src={`/assets/svg/triangle.svg`}
        />
      </div>
    );
  }

  render() {
    const onClickHandler = this.props.click
      ? () => this.props.click!("open")
      : null;
    return (
      <div
        className={
          "shlk-marker-wrapper " + (this.props.open ? "shlk-marker__open" : "")
        }
        style={{zIndex : this.name === "LiveTrackerMarker" ? "" :"1"}}
      >
        {this.props.open && this.hasPopup && this.renderPopup()}

        <TrackableElement
          className="shlk-marker "
          category="Marker"
          action={`${!this.props.click ? "Fail " : ""}Open Marker`}
          label={this.name}
          onClick={onClickHandler}
        >
          {this.renderMarker()}
        </TrackableElement>
      </div>
    );
  }
}
