// // import React, { useState, FormEvent } from 'react';
// // import './styles.scss';
// // import { useHistory } from 'react-router-dom';

// // const ResetPassword: React.FC = () => {
// //   const [password, setPassword] = useState<string>('');
// //   const [confirmPassword, setConfirmPassword] = useState<string>('');
// //   const history = useHistory();

// //   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
// //     e.preventDefault();
// //     if (password !== confirmPassword) {
// //       console.log("Passwords do not match.");
// //     } else {
// //       console.log("Password reset successfully.");
// //     }
// //   };

// //   const handleBackToLogin = () => {
// //     history.push('/login');  // Navigates back to the login page
// //   };

// //   return (
// //     <div className="shlk-login">
// //       <div className="shlk-login-wrapper">
// //         <img className="shlk-login-logo"
// //              src="/assets/icon/Naxxar_Full.png"
// //              alt="Naxxar" />
// //               <div className='Forgot-Password'> <span>Set New Password</span></div>
// //         <div className='Forgot-Password-text'><span>must be atleast 8 characters</span></div>
// //         <form className="shlk-login-form" onSubmit={handleSubmit}>
// //           <div className="shlk-form__container">
// //             <input className="shlk-login-input shlk-login-pwd"
// //                    name="password"
// //                    type="password"
// //                    placeholder="New Password"
// //                    value={password}
// //                    onChange={(e) => setPassword(e.target.value)}
// //                    required />
// //             <input className="shlk-login-input shlk-login-pwd"
// //                    name="confirmPassword"
// //                    type="password"
// //                    placeholder="Confirm Password"
// //                    value={confirmPassword}
// //                    onChange={(e) => setConfirmPassword(e.target.value)}
// //                    required />
// //           </div>
// //           <input className="shlk-login-input shlk-login-btn"
// //                  type="submit"
// //                  value="Save" />
// //                     <div className="back-to-login" onClick={handleBackToLogin}>
// //           <span>&larr; Back to Login</span>
// //         </div>
// //         </form>
// //       </div>
// //     </div>
// //   );
// // };

// // export default ResetPassword;

// import React, { useState, useEffect, FormEvent } from 'react';
// import './styles.scss';
// import { useHistory } from 'react-router-dom';

// const ResetPassword: React.FC = () => {
//   const [password, setPassword] = useState<string>('');
//   const [confirmPassword, setConfirmPassword] = useState<string>('');
//   const [email, setEmail] = useState<string>('');  // Store email here
//   const history = useHistory();

//   // Retrieve email from localStorage when the component mounts
//   useEffect(() => {
//     const storedEmail = localStorage.getItem('resetEmail');  // Or sessionStorage
//     if (storedEmail) {
//       setEmail(storedEmail);
//     } else {
//       // If no email is found, redirect to forgot-password page
//       history.push('/forgot-password');
//     }
//   }, [history]);

//   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     if (password !== confirmPassword) {
//       console.log("Passwords do not match.");
//     } else {
//       // You can pass the email along with the password reset API call here
//       console.log(`Password reset for email: ${email}`);
//       console.log("Password reset successfully.");

//       // Optionally, navigate to the login page or any other page
//       history.push('/login');
//     }
//   };

//   const handleBackToLogin = () => {
//     history.push('/login');  // Navigates back to the login page
//   };

//   return (
//     <div className="shlk-login">
//       <div className="shlk-login-wrapper">
//         <img className="shlk-login-logo"
//              src="/assets/icon/Naxxar_Full.png"
//              alt="Naxxar" />
//         <div className='Forgot-Password'><span>Set New Password</span></div>
//         <div className='Forgot-Password-text'><span>must be at least 8 characters</span></div>
//         <form className="shlk-login-form" onSubmit={handleSubmit}>
//           <div className="shlk-form__container padding_adjustment">
//             <input className="shlk-login-input shlk-login-pwd"
//                    name="password"
//                    type="password"
//                    placeholder="New Password"
//                    value={password}
//                    onChange={(e) => setPassword(e.target.value)}
//                    required />
//             <input className="shlk-login-input shlk-login-pwd"
//                    name="confirmPassword"
//                    type="password"
//                    placeholder="Confirm Password"
//                    value={confirmPassword}
//                    onChange={(e) => setConfirmPassword(e.target.value)}
//                    required />
//           </div>
//           <input className="shlk-login-input shlk-login-btn padding_adjustment"
//                  type="submit"
//                  value="Save" />
//           <div className="back-to-login padding_adjustment" onClick={handleBackToLogin}>
//             <span>&larr; Back to Login</span>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ResetPassword;

import React, { useState, useEffect, FormEvent } from "react";
import "./styles.scss";
import { useHistory, withRouter } from "react-router-dom";
import { updateUser } from "services/users"; // Import your update user service
import { reset_password } from "services/login";
import { ReactSVG } from "react-svg";
import Icon from "components/Icon";

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [email, setEmail] = useState<string>(""); // Store email here
  const [loading, setLoading] = useState<boolean>(false); // Loading state for API call
  const [token, setToken] = useState<String>("");
  const [error, setError] = useState<String>("");
  // State for toggling password visibility
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const history = useHistory();

  // Retrieve email from localStorage when the component mounts
  useEffect(() => {
    const storedEmail = localStorage.getItem("resetEmail"); // Or sessionStorage
    const storedToken = localStorage.getItem("token"); // Or sessionStorage
    console.log(storedToken);
    storedToken && setToken(storedToken);
    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      // If no email is found, redirect to forgot-password page
      history.push("/forgot-password");
    }
  }, [history]);
  // Check if password and confirmPassword match

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    // Check if password meets criteria
    if (!passwordRegex.test(password)) {
      setLoading(false);
      setError(
        "Password must be at least 8 characters long, include a number, a special character, and at least one uppercase letter."
      );
      return;
    }
    if (password !== confirmPassword) {
      setLoading(false);
      setError("Passwords do not match. Please try again.");
      return;
    }
    try {
      // Trigger the send_otp API
      const response = await reset_password({
        emails: email,
        newPassword: password,
        token: token,
      });
      if (response.success == true) {
        // Store email in localStorage
        localStorage.setItem("resetEmail", email);
        sessionStorage.setItem("refreshLogin", "true");
        // Navigate to the next page without email in the URL
        history.push("/login");
      } else {
        setError(response.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      alert("Your password has been changed successfully!");
    }
  };
  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };
  const handleBackToLogin = () => {
    sessionStorage.setItem("refreshLogin", "true");
    history.push("/login"); // Navigates back to the login page
  };

  return (
    <div className="shlk-login">
      <div className="shlk-login-wrapper">
        <img
          className="shlk-login-logo"
          src="/assets/icon/Naxxar_Full.png"
          alt="Naxxar"
        />
        <div className="Forgot-Password">
          <span>Set New Password</span>
          <span
            style={{ marginLeft: "5px" }}
            title="Password must be at least 8 characters long, 
          include a number, a special character, 
          and at least one uppercase letter."
          >
            <Icon className="info" size={15} name={"info"} />
          </span>
        </div>

        <form className="shlk-login-form" onSubmit={handleSubmit}>
          <div className="shlk-form__container padding_adjustment">
            <div className="wrap-password" style={{ border: "none" }}>
              <input
                className="shlk-login-input shlk-login-pwd"
                name="password"
                type={showPassword ? "text" : "password"} // Toggle input type
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div
                className="show-hide-btn"
                onClick={togglePasswordVisibility}
                style={{ display: password ? "" : "none" }}
              >
                {showPassword ? (
                  <Icon className="info" size={19} name="visibility_off" />
                ) : (
                  <Icon className="info" size={19} name="visibility" />
                )}
              </div>
            </div>
            <div className="wrap-password ">
              <input
                className="shlk-login-input shlk-login-pwd"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"} // Toggle input type
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <div
                className="show-hide-btn"
                onClick={toggleConfirmPasswordVisibility}
                style={{ display: confirmPassword ? "" : "none" }}
              >
                {showConfirmPassword ? (
                  <Icon className="info" size={19} name="visibility_off" />
                ) : (
                  <Icon className="info" size={19} name="visibility" />
                )}
              </div>
            </div>
          </div>
          <input
            className="shlk-login-input shlk-login-btn padding_adjustment"
            type="submit"
            value={loading ? "Saving..." : "Save"}
            disabled={loading} // Disable button while loading
          />

          {error && <div className="error-message">{error}</div>}
          <div
            className="back-to-login padding_adjustment"
            onClick={handleBackToLogin}
          >
            <span>&larr; Back to Login</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
