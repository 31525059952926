import ShipmentCardV1 from "components/Cards/Shipment";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { completeShipment, deleteShipment } from "services/shipments";
import { ShipmentContext } from "../context";
import "./styles.scss";

interface MatchProps {
  id: string;
}

interface Props extends RouteComponentProps<MatchProps> {
  actions: boolean;
  isPublic?:any;
  openTagTab: () => void; // New prop for opening the Share tab
}

interface State {
  loading: boolean;
}

class Info extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { loading: false };
  }

  updateShipment = (serviceFunction: any, status: string) => {
    this.setState({ loading: true });

    serviceFunction(this.context.shipment._id).then(() => {
      const shipment = this.context.shipment;
      shipment.status = status;
      this.context.updateShipment(shipment);
      this.setState({ loading: false });
    });
  };

  complete = () => {
    if (
      window.confirm(
        "Are you sure you want to complete the shipment? \nThis action is not reversible"
      )
    ) {
      this.updateShipment(completeShipment, "completed");
    }
  };

  delete = () => {
    if (
      window.confirm(
        "Are you sure you want to cancel the shipment? \nThis action is not reversible"
      )
    ) {
      this.updateShipment(deleteShipment, "canceled");
    }
  };

  render() {
    const shipment = this.context.shipment;

    return (
      <>
        <ShipmentCardV1
          shipment={shipment}
          lines={[]}
          showBoxButton={true}
          actions={true}
          isPublic ={this.props.isPublic}
          openTagTab = {this.props.openTagTab}
        />
      </>
    );
  }
}

Info.contextType = ShipmentContext;

export default withRouter(Info);
