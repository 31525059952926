import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import Calendar from "components/Inputs/Calendar";
import Input from "components/Inputs/Input/Default";
import BoxButton from "components/Box/Button";
import SelectableList from "components/SelectableList";
import ContentLoader from "components/Loading/ContentLoader";
import { getNamedEshell } from "utils/tracker";
import { calculateLastUpdated } from "utils/date";
import { updateTracker } from "services/trackers";
import { getTagsList } from "services/tags";
import { hasPermission } from "utils/auth";
import { TrackerContext } from "../context";
import "./styles.scss";

interface Props extends RouteComponentProps {
  onHistoryUpdate: ({ start, end }: { start?: string; end?: string }) => void;
}

interface State {
  update: any;
  loading: boolean;
  history: {
    start: Date | null;
    end: Date | null;
  };
  tags: any[];
}

class TrackerInfo extends Component<Props, State> {
  constructor(props: Props, { measurements }: any) {
    super(props);

    const start = measurements[0] ? new Date(measurements[0].time) : null;
    const end = measurements[measurements.length - 1]
      ? new Date(measurements[measurements.length - 1].time)
      : null;

    this.state = {
      update: {},
      loading: true,
      history: { start, end },
      tags: [],
    };
  }

  componentDidMount() {
    getTagsList().then((tags) => this.setState({ tags, loading: false }));
  }

  isSaveAllowed = () => {
    if (Object.keys(this.state.update).length > 0) return true;

    return (
      this.state.update.tags &&
      this.state.update.tags.some((tag: any) =>
        this.context.tracker.tags.some(({ name }: any) => tag.name !== name)
      )
    );
  };

  onSave = () => {
    const tracker = Object.assign({}, this.context.tracker, this.state.update);

    this.setState({ loading: true });

    updateTracker({ id: tracker._id, update: this.state.update }).then(() => {
      this.context.updateTracker(tracker);
      this.setState({ loading: false, update: {} });
    });
  };

  onFieldUpdate = ({ target }: { target: any }) => {
    const update = {
      ...this.state.update,
      [target.name]: target.value,
    };

    if (!target.value && !this.context.tracker[target.name]) {
      delete update[target.name];
    }

    this.setState({ update });
  };

  onTagsUpdate = (tags: string[]) => {
    this.setState({
      update: {
        ...this.state.update,
        tags: this.state.tags.filter(({ name }: any) => tags.includes(name)),
      },
    });
  };

  updateMeasurements = () => {
    const query: { start?: string; end?: string } = {};
    const { start, end } = this.state.history;

    start && (query.start = start.toISOString());
    end && (query.end = end.toISOString());

    this.props.onHistoryUpdate(query);
  };

  renderTrackerInfo = () => {
    const tracker = Object.assign({}, this.context.tracker, this.state.update);
    const lastMeas = tracker.lastMeasurement;

    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Input
            className="tracker-field mobile-name"
            label="Name:"
            type="text"
            name="name"
            value={tracker.name}
            onChange={this.onFieldUpdate}
          />
          <Input
            className="tracker-field mobile-asset"
            label="Asset id:"
            type="text"
            name="container"
            placeholder="Container or asset ref"
            value={tracker.container}
            onChange={this.onFieldUpdate}
          />
        </div>
        <div className="info-fields">
          <span className="tracker-field">
            <b>Model:</b>
            {getNamedEshell(tracker.model)}
          </span>

          <span className="tracker-field">
            <b>Identifier:</b>
            {tracker.identifier}
          </span>

          {this.context.events && (
            <span className="tracker-field">
              <b>Events:</b>
              {this.context.events.length}
            </span>
          )}

          {lastMeas && (
            <>
              {!!lastMeas.battery && (
                <span className="tracker-field">
                  <b>Battery:</b>
                  {lastMeas.battery}%
                </span>
              )}
              {!!lastMeas.temperature && (
                <span className="tracker-field">
                  <b>Temp:</b>
                  {lastMeas.temperature} ºC
                </span>
              )}
              <span className="tracker-field">
                <b>Updated:</b>
                {calculateLastUpdated(lastMeas.time)}
              </span>
            </>
          )}
          <span className="tracker-field">
            <b>Tags:</b>
            <SelectableList
              name="tags"
              selected={tracker.tags.map(({ name }: any) => name)}
              list={this.state.tags.map(({ name }: any) => name)}
              disabled={!hasPermission("ACTIONS_SHIPMENT")}
              onChange={this.onTagsUpdate}
            />
          </span>
        </div>
      </>
    );
  };

  renderInfoDisplay = () => {
    const { measurements } = this.context;

    const min = measurements[0] ? new Date(measurements[0].time) : null;
    const max = measurements[measurements.length - 1]
      ? new Date(measurements[measurements.length - 1].time)
      : null;

    return (
      <div className="info-display">
        <h2 className="display-title">Display</h2>
        <div className="display-pickers">
          <div className="datepicker">
            <Calendar
              name="start"
              max={this.state.history.end || max}
              value={this.state.history.start}
              onChange={(date: Date) =>
                this.setState(
                  { history: { ...this.state.history, start: date } },
                  () => this.updateMeasurements()
                )
              }
            />
          </div>

          <div className="datepicker">
            <Calendar
              name="end"
              min={this.state.history.start || min}
              value={this.state.history.end}
              onChange={(date: Date) =>
                this.setState(
                  { history: { ...this.state.history, end: date } },
                  () => this.updateMeasurements()
                )
              }
            />
          </div>
        </div>
        <BoxButton
          category="Tracker"
          action="Click Save"
          label={this.context.tracker._id}
          onClick={(event: any) => {
            event.stopPropagation();
            this.props.history.push(
              `/trackers/${this.context.tracker.identifier}#raw`
            );
          }}
          icon="table_view"
          text="View data"
        />
      </div>
    );
  };

  render() {
    const tracker = this.context.tracker;

    return (
      <ContentLoader
        loading={this.state.loading}
        content={() => (
          <div
            className="trackers-info bc-wrapper"
            style={{
              background: "white",
              padding: "10px",
              // boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
              borderRadius: "0px 0px 10px 10px",
              minHeight: "0%",
            }}
          >
            <div className="info-wrapper bc-stretch">
              <div>{this.renderTrackerInfo()}</div>
              <div className="info-buttons">
                <BoxButton
                  category="Tracker"
                  action="Click Save"
                  label={tracker._id}
                  onClick={this.onSave}
                  disabled={!this.isSaveAllowed()}
                  icon="save"
                  text="Save"
                />
                <BoxButton
                  category="Tracker"
                  action="Click Cancel"
                  label={tracker._id}
                  onClick={() => this.setState({ update: {} })}
                  disabled={!this.isSaveAllowed()}
                  icon="close"
                  text="Cancel"
                />
              </div>
            </div>

            {this.renderInfoDisplay()}
          </div>
        )}
      />
    );
  }
}

TrackerInfo.contextType = TrackerContext;

export default withRouter(TrackerInfo);
