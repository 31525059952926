import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Page from 'components/ControlBars/Page';
import ContentLoader from 'components/Loading/ContentLoader';
import Button from 'components/Inputs/Button';
import Input from 'components/Inputs/Input/Default';
import Table from 'components/Table';
import { getTagsInfoList, createTag } from 'services/tags';
import { hasPermission } from 'utils/auth';
import { TagsContext } from './context';
import './styles.scss';

interface State {
    loading: boolean;
    tagName: string;
    tagColor: string;
    info: Array<{
        tag: any;
        shipments: number;
        trackers: number;
    }>
}

class Tags extends Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            loading: true,
            tagName: '',
            tagColor: '#3D5FA0',
            info: []
        };
    }

    updateInfo = () => {
        this.setState({ loading: true });

        return getTagsInfoList().then(info => {
            this.context.updateTags(info.map((i: any) => i.tag));
            this.setState({ loading: false, info });
        });
    }

    createTag = () => {
        this.setState({ loading: true });
        createTag({ name: this.state.tagName, color: this.state.tagColor })
            .then(this.updateInfo)
            .catch(error => {
                window.alert(error.response.data.msg);
                this.setState({ loading: false });
            });
    }

    componentDidMount() {
        this.updateInfo();
    }

    // renderCreateInput = () =>
    //     <Input type="text"
    //             name="tagName"
    //             value={this.state.tagName}
    //             onChange={(event: any) =>  this.setState({ tagName: event.target.value }) }/>
      renderCreateInput = () => 
        <Input 
            type="text"
            name="tagName"
            placeholder="Enter tag name"
            value={this.state.tagName}
            onChange={(event: any) => this.setState({ tagName: event.target.value })} 
        />

    renderCreateColor = () =>
        <Input type="color"
                name="tag-color"
                value={this.state.tagColor}
                onInput={(event: any) => { this.setState({ tagColor: event.target.value }); }}/>

    renderCreateBtn = () =>
        <Button
            category="Tags"
            action="Create"
            disabled={!this.state.tagName}
            icon="add"
            iconSize={20}
            onClick={() => this.createTag() }/>

    renderContent = () => {
        const info = this.state.info;

        const data: any = info.map(({ tag, shipments, trackers }: any) => ({
            id: tag._id,
            onClick: () => this.props.history.push(`/tag/${tag._id}`),
            cells: [
                { value: tag.name },
                { style: { backgroundColor : tag.styles.color } },
                { value: shipments },
                { value: trackers },
                { icon: 'arrow_forward_ios' }
            ]
        }));

        hasPermission('CONFIG_TAGS') && data.push({
            id: 'TagsCreate',
            cells: [
                { className: 'tags-create-input', render: this.renderCreateInput },
                { className: 'tags-create-color', render: this.renderCreateColor },
                { value: "-" },
                { value: "-" },
                { className: 'tags-create-btn', render: this.renderCreateBtn }
            ]
        });

        return <Table className='tags-table'
                        headings={['Name', 'Color', 'Shipments', 'Trackers', 'View']}
                        rows={data} />;
    }

    render() {
        return (<Page className="shlk-tags">
            <div className="tags-header">
                <h1 className="tags-title">Tags</h1>
            </div>
            <ContentLoader loading={this.state.loading} content={this.renderContent}/>
        </Page>);
    }
}

Tags.contextType = TagsContext;

export default withRouter(Tags);