import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { format as formatDate } from 'utils/date';
import Table from 'components/Table';

interface Props extends RouteComponentProps {
    shipments: any[]
}

class ShipmentInfoTablesTag extends Component<Props> {
    render() {
        const shipments = this.props.shipments;
        const shipmentsLength = shipments.length;

        return (
            <div className="tags-references">
                <h3 className="references-title">{shipmentsLength ? `${shipmentsLength} Shipments` : 'No Shipments'}</h3>
                { shipmentsLength > 0 &&
                    <Table headings={['Name', 'Status', 'Created', 'Origin', 'Destination', 'View']} rows={
                        this.props.shipments.map((shipment: any) => ({
                            id: shipment._id,
                            onClick: () => this.props.history.push(`/shipments/${shipment._id}`),
                            cells: [
                                { value: shipment.name },
                                { value: shipment.status.toUpperCase(), className: shipment.status },
                                { value: formatDate(shipment.createdAt) },
                                { value: shipment.origin.label },
                                { value: shipment.destination.label },
                                { icon: 'arrow_forward_ios' }
                            ]
                        }))
                    } />
                }
            </div>);
    }
}

export default withRouter(ShipmentInfoTablesTag);