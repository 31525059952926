import React from 'react';
import { ReactSVG } from 'react-svg';
import Marker, { MarkerProps } from '../AbstractMarker';
import './styles.scss';

interface TextProps extends MarkerProps {
  text: string;
}

export default class TextMarker extends Marker<TextProps> {
  name = 'TextMarker';

  renderMarker() {
    return (
      <div className="marker-text shlk-marker">
        <h1 className="marker-text-title">{this.props.text}</h1>
        <ReactSVG className="marker-text-pin" src="/assets/svg/pin.svg"/>
      </div>
    );
  }
}