import React from 'react';
import { RailMode } from './index'; // replace './index' with the path to your index.tsx file

import Dropdown from '../Dropdown';
import RailwayStationAutoComplete from '../Input/RailwayStationAutoComplete';

interface RailModeProps {
  railModeData: RailMode;
  onChange: (field: string, value: any) => void;
}

const RailModeComponent: React.FC<RailModeProps> = ({ railModeData, onChange }) => {
  return (
    <div>
      <div className="new-box-half">

            <RailwayStationAutoComplete
              shipment={railModeData}
              onChange={onChange}
              field="originStation"
              label='Origin Station'
            />

            <RailwayStationAutoComplete
              shipment={railModeData}
              onChange={onChange}
              field="destinationStation"
              label='Destination Station'
            />

            <Dropdown
              parentObject={railModeData}
              onChange={onChange}
              field="trainTractionType"
              label='Train Traction Type'
              options={['Diesel', 'Electric', 'Unknown']}
            />
        </div>
    </div>
  );
};

export default RailModeComponent;