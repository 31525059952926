// import React, { Component } from "react";
// import Icon from "components/Icon";
// import Button from "components/Inputs/Button";
// import { format as formatDate } from "utils/date";
// import "./styles.scss";

// interface Props {
//   shipment: any;

// }

// export default class ReportHeader extends Component<Props> {
//   render() {
//     return (
//       <thead className="report-header">
//         <tr>
//           <td
//             className="report-header-content"
//             style={{ alignItems: "flex-end" }}
//           >
//             <Button
//               className="report-hide report-header-icon"
//               category="Report"
//               action="Open Settigns"
//               to="#settings"
//             >
//               <Icon name="tune" size={22} />
//             </Button>

//             <div className="report-header-info" style={{ textAlign: "left" , marginLeft:"10px"}}>
//               <h2 className="report-title" style={{ fontSize: "23px" }}>
//                 Shipment Report: <span>{this.props.shipment.name}</span>
//               </h2>
//               <h3 className="report-date">
//                 {formatDate(this.props.shipment.createdAt)} -{" "}
//                 {formatDate(this.props.shipment.updatedAt)}
//               </h3>
//             </div>

//             <Button
//               className="report-hide report-header-icon"
//               category="Report"
//               action="print"
//               onClick={() => window.print()}
//             >
//               <Icon name="print" size={22} />
//             </Button>

//             <Button
//               className="report-hide report-header-icon"
//               category="Report"
//               action="print"
//               onClick={() => window.print()}
//             >
//               <Icon name="share" size={22} />
//             </Button>
//           </td>
//         </tr>
//       </thead>
//     );
//   }
// }

import React, { Component } from "react";
import Icon from "components/Icon";
import Button from "components/Inputs/Button";
import { format as formatDate } from "utils/date";
import "./styles.scss";

interface Shipment {
  name: string;
  createdAt: string;
  updatedAt: string;
}

interface Props {
  shipment: Shipment;
  onShareButtonClick: () => void; // Prop to handle share button click
  title: any;
  isnotmaintitle: boolean;
}

export default class ReportHeader extends Component<Props> {
  render() {
    const { shipment, onShareButtonClick, title, isnotmaintitle } = this.props;

    return (
      <thead className="report-header">
        <tr>
          <td
            className="report-header-content"
            style={{ alignItems: "flex-end" }}
          >
            <div
              className="report-header-info"
              style={{ textAlign: "left", marginLeft: "10px" }}
            >
              <h2 className="report-title" style={{ fontSize: "23px" }}>
                {title}: <span>{isnotmaintitle ? shipment.name : null}</span>
              </h2>
              <h3
                className="report-date"
                style={{ display: isnotmaintitle ? "" : "none" }}
              >
                {formatDate(shipment.createdAt)} -{" "}
                {formatDate(shipment.updatedAt)}
              </h3>
            </div>
            {isnotmaintitle && (
              <>
                {" "}
                <Button
                  className="report-hide report-header-icon"
                  category="Report"
                  action="Print"
                  onClick={() => window.print()}
                  aria-label="Print Report"
                >
                  <Icon name="print" size={22} />
                </Button>
                <Button
                  className="report-hide report-header-icon"
                  category="Report"
                  action="Share"
                  onClick={onShareButtonClick} // Use the handler from props
                  aria-label="Share Report"
                >
                  <Icon name="share" size={22} />
                </Button>
              </>
            )}
          </td>
        </tr>
      </thead>
    );
  }
}
