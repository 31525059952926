import React, { Component } from 'react';
import Datalist from 'components/Inputs/Datalist';
import TagsList, { Tag } from 'components/Tags/List';
import { addTagHandler } from 'utils/tags';
import { hasPermission } from 'utils/auth';
import { reducer } from 'utils/functions';

interface Props {
  shipment: any;
  tags: any;
  onChange: any;
}

export default class ShipmentTags extends Component<Props> {
  updateTags = (tags: any[]) => {
    this.props.onChange('tags', tags);
  }

  removeTag = (tag: any, tagsInUse: any[]) => {
    const tags = tagsInUse.filter(({ _id }: { _id: string }) => _id !== tag._id);
    this.props.onChange('tags', tags);
  }

  render() {
    const { shipment, tags } = this.props;
    const [tagsInUse, tagsToUse] = reducer(tags, (tag: Tag) => shipment.tags.some(({ _id }: Tag) => _id === tag._id));

    return (<>
      <div className="tags-header">
        <h4 className="new-box-title">
          Tags
        </h4>
        {
          hasPermission('ACTIONS_SHIPMENT') &&
          (<Datalist datalist={tagsToUse}
            disabled={!tagsToUse.length}
            name="tags"
            placeholder={tagsToUse.length ? 'Select tag' : 'No available options'}
            onSelect={(event: any) => addTagHandler(event, tagsToUse, this.props.shipment.tags, this.updateTags)} />)
        }
      </div>
      <div className="tags-output">
        {
          tagsInUse.length > 0 &&
          <TagsList tags={tagsInUse} onRemove={
            hasPermission('ACTIONS_SHIPMENT') ? (tag: any) => this.removeTag(tag, tagsInUse) : null
          } />
        }
      </div>
    </>);
  }
}