import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import BoxTitle from 'components/Box/Title';
import Vessel from 'components/Vessel';
import Button from 'components/Inputs/Button';
import VesselPopup from 'components/GoogleMap/Popups/Fleet';
import ContentLoader from 'components/Loading/ContentLoader';
import TrackableElement from 'components/Trackable/Element';
import { MapSettings } from 'components/GoogleMap/VesselMap';
import { getOnboardShipments } from 'services/shipments';
import './styles.scss';

interface Props {
    updateMapSettings: Function;
    mapSettings: MapSettings;
}

type State = {
  loading: boolean;
  shipments: any[];
};

const isActive = (shipment: any) => {
  return shipment.transports.some(({ status }: { status: string}) => status === 'ONBOARD');
};

const processVessels = (shipments: any) => {
  const vessels: any = {};

  shipments.forEach((shipment: any) => {
    const vessel = shipment.vessel,
          mmsi = vessel.mmsi,
          active = isActive(shipment);

    if(!vessels[mmsi]) {
      vessel.shipments = [];
      vessel.key = `${mmsi}`;
      vessels[mmsi] = vessel;
    }

    vessels[mmsi].shipments.push(shipment);
    vessels[mmsi].active = vessels[mmsi].active || active;
  });

  return Object.values(vessels);
};

export default class LoadedVessels extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading : true,
      shipments: []
    };
  }

  async componentDidMount() {
    getOnboardShipments()
      .then((shipments: any) => {
        this.props.updateMapSettings({
          ...this.props.mapSettings,
          vessels : processVessels(shipments),
          popup: this.vesselPopup
        });
        this.setState({ loading: false, shipments });
      });
  }

  openHandler = (vessel: any, token: string) => {
    vessel.name && this.props.updateMapSettings({
      ...this.props.mapSettings,
      open: token
    });
  }

  renderShipmentVessel = (shipment: any, vessel: any) => {
    const token = `${vessel.key}_${shipment._id}`;
    const isPending = !vessel.name;
    const isVesselOpen = this.props.mapSettings.open && this.props.mapSettings.open.indexOf(token) === 0;
    const trackProps = {
      className: "fleet-loaded-shipment",
      category: "Loaded Vessels",
      action: isPending ? 'Pending' : 'Open',
      label: vessel.mmsi,
      key: token,
      onClick : () => this.openHandler(vessel, token)
    };

    return <TrackableElement {...trackProps}>
      <div className="loaded-info">
        <p><b>Shipment:</b></p>
        <p>{ shipment.name }</p>
      </div>
      <span className={`fleet-action ${isVesselOpen ? 'active' : ''}`}>
          { !isPending && <ReactSVG className="fleet-action__icon" src={`/assets/svg/arrow-forward.svg`} /> }
      </span>
    </TrackableElement>;
  }

  renderVesselList = (vessel: any, index: number) => {
    return (
      <Vessel vessel={vessel} index={index} key={vessel.key}>
          {
            vessel.shipments.map((shipment: any) =>
                this.renderShipmentVessel(shipment, vessel))
          }
        </Vessel>
      );
  }

  vesselPopup = (vessel: any) => {
    const opened = this.props.mapSettings.open && this.props.mapSettings.open.split("_")[1];
    const shipment = vessel.shipments.find((shipment: any) => shipment._id === opened) || vessel.shipments[0];
    vessel.active = isActive(shipment);

    return shipment ? (<VesselPopup vessel={vessel}>
      <div className="vessel-popup__button" >
        <div className="popup-shipment">
          <p><b>Shipment :</b></p>
          <p>{ shipment.name }</p>
        </div>
        <Button
          category="Loaded Vessels"
          action="Open Active Vessel"
          label={vessel.mmsi}
          styles="solid"
          to={`/shipments/${shipment._id}`}
          >
          Open shipment
        </Button>
      </div>
    </VesselPopup>) : <></>;
  }

  renderContent = () => {
    const [locatedVessels, pendingVessels] = this.props.mapSettings.vessels
      .reduce(([located, pending], vessel) =>
        vessel.name ? [[...located, vessel], pending] : [located, [...pending, vessel]]
      , [[], []]);

      return (<>
          <h5 className="fleet-subtitle"><b>{ this.props.mapSettings.vessels.length }</b> Shipment Vessels</h5>
          { pendingVessels.length > 0 &&
            <p className="fleet-list__disclaimer">We are retrieving the information for some vessels. This may take time.</p>
          }
          <div className="fleet-list__container">
            { locatedVessels.map(this.renderVesselList) }
            { pendingVessels.map(this.renderVesselList) }
          </div>
        </>
      );
  }

  render() {
    return (
      <div className="shlk-fleet shlk-fleet-loaded">
        <div className="fleet-box__container">
          <BoxTitle text="Active Shipments"/>
          <ContentLoader loading={this.state.loading} content={this.renderContent}/>
        </div>
      </div>
    );
  }
}
