// Import necessary modules and components
import React, { PureComponent } from 'react';
import ContentLoader from 'components/Loading/ContentLoader';
import Shipment from 'components/Forms/Shipment';
import { getAvailableTrackers } from 'services/trackers';
import { updateShipment } from 'services/shipments';
import { getTagsList } from 'services/tags';
import './styles.scss';

// Define the fields that can be edited
const EDITABLE_FIELDS = ['name', 'description', 'booking', 'vessel', 'origin', 'destination', 'settings', 'tags'];

// Define the types for the props and state
interface Props {
  shipment: any;
  close: any;
}

type State = {
  selectedTrackers: Array<any>;
  trackers: Array<any>;
  tags: Array<any>;
  loading: boolean;
};

// Define the EditShipment component
export default class EditShipment extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    // Initialize the state
    this.state = {
      loading: true,
      selectedTrackers: [],
      trackers : [],
      tags: []
    };
  }

  // Fetch the available trackers and tags when the component mounts
  componentDidMount() {
    Promise.all([
      getAvailableTrackers(),
      getTagsList()
    ])
      .then(([trackers, tags]) =>
        this.setState({
          loading: false,
          selectedTrackers: this.getSelectedTrackers(),
          trackers, tags
        }));
  }

  // Define the method to edit the shipment
  edit = (shipment: any, trackers: any, cancel: boolean) => {
    if(cancel) this.props.close();
    else {
      // Filter the shipment object to only include the editable fields
      const update = Object.keys(shipment)
        .filter(key => EDITABLE_FIELDS.includes(key))
        .reduce((obj: any, key: string) => {
          obj[key] = shipment[key];

          return obj;
        }, {});

      // Update the shipment and close the form
      updateShipment({ id: shipment._id, update, trackers: trackers.map((tracker: any) => tracker._id) })
            .then(({ shipment }) => this.props.close(shipment));
    }
  }

  // Define the method to get the selected trackers
  getSelectedTrackers = () => {
    return this.props.shipment.transports ?
      this.props.shipment.transports.map(({ tracker }: any) => tracker) :
      [];
  }

  // Define the method to render the content
  renderContent = () => {
    return <Shipment shipment={this.props.shipment}
                      trackers={[...this.state.selectedTrackers, ...this.state.trackers]}
                      tags={this.state.tags}
                      selectedTrackers={this.state.selectedTrackers}
                      edit={true}
                      callback={ this.edit }/>;
  }

  // Define the render method
  render() {
    return (
      <div className="shlk-edit-shipment">
        {/*Use the ContentLoader component to show a loading spinner while the data is being fetched */}
        <ContentLoader loading={this.state.loading} content={this.renderContent} />
      </div>
    );
  }
}