import Icon from "components/Icon";
import IsLogged from "components/Permissions/Logged";
import TrackableElement from "components/Trackable/Element";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { logOut } from "services/logout";
import "./styles.scss";
import HasPermissions from "components/Permissions/Has";

interface State {
  isMobile: boolean;
  activeLink: string;
  isMenuOpen: any;
}

class Header extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isMobile: false,
      activeLink: this.getActiveLink(props.location.pathname),
      isMenuOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        activeLink: this.getActiveLink(this.props.location.pathname),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 768; // Adjust threshold as needed
    this.setState({ isMobile });
  };

  getActiveLink = (path: string) => {
    if (path === "/") return "dashboard";
    if (path.includes("/shipments")) return "shipments";
    if (path === "/trackers") return "devices";
    if (path.includes("/admin")) return "admin";
    if (path.includes("/user")) return "user";
    return "";
  };

  handleLinkClick = (linkName: string) => {
    this.setState({ activeLink: linkName });
  };
  toggleMenu = () => {
    this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));
  };

  logoutClick = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      logOut();
    }
  };

  renderNavigation = () => {
    return (
      <div className="header-nav">
        <TrackableElement
          category="Header"
          action="Click Logo"
          className="header-logo"
        >
          <Link className="header-logo-link" key="header_dashboard" to="/">
            <ReactSVG
              className="header-logo-image"
              src="/assets/svg/logo.svg"
            />
          </Link>
        </TrackableElement>
      </div>
    );
  };

  renderUserActions = () => {
    const { isMobile, activeLink, isMenuOpen } = this.state;
    const path = this.props.location.pathname;
    return (
      <div className="header-user">

        {!isMobile && (
          <IsLogged>
                    {path.includes("/public") && (
          <TrackableElement
            category="Header"
            action="Click Full Version"
            className="header-wrapper"
          >
            <Link to={path.replace("/public", "")}>
              <span className="header-caption">Full version</span>
            </Link>
          </TrackableElement>
        )}
            <TrackableElement
              category="Header"
              action="Click User"
              className={`header-wrapper ${
                activeLink === "dashboard" ? "active" : ""
              }`}
            >
              <Link
                className="header-wrapper"
                to="/"
                onClick={() => this.handleLinkClick("dashboard")}
              >
                <>Dashboard</>
              </Link>
            </TrackableElement>
            {/* <HasPermissions permission="ADMIN_PAGES"> */}
            <TrackableElement
              category="Header"
              action="Click User"
              className={`header-wrapper ${
                activeLink === "shipments" ? "active" : ""
              }`}
            >
              <Link
                className="header-wrapper"
                to="/shipments"
                onClick={() => this.handleLinkClick("shipments")}
              >
                <>Shipments</>
              </Link>
            </TrackableElement>
            <TrackableElement
              category="Header"
              action="Click User"
              className={`header-wrapper ${
                activeLink === "devices" ? "active" : ""
              }`}
            >
              <Link
                className="header-wrapper"
                to="/trackers"
                onClick={() => this.handleLinkClick("devices")}
              >
                <>Devices</>
              </Link>
            </TrackableElement>
            <HasPermissions permission="ADMIN_PAGES">
              <TrackableElement
                category="Header"
                action="Click User"
                className={`header-wrapper ${
                  activeLink === "admin" ? "active" : ""
                }`}
              >
                <Link
                  className="header-wrapper"
                  to="/admin"
                  onClick={() => this.handleLinkClick("admin")}
                >
                  <>Admin</>
                </Link>
              </TrackableElement>
            </HasPermissions>
            <IsLogged>
              <TrackableElement
                category="Header"
                action="Click User"
                className={`header-wrapper ${
                  activeLink === "user" ? "active" : ""
                }`}
              >
                <Link
                  className="header-wrapper"
                  to="/user"
                  onClick={() => this.handleLinkClick("user")}
                >
                  <Icon className="header-icon" size={40} name="person" />
                </Link>
              </TrackableElement>
            </IsLogged>
            {/* </HasPermissions> */}
            <IsLogged>
              <TrackableElement
                category="Header"
                action="Click LogOut"
                className="header-wrapper"
                onClick={this.logoutClick}
              >
                <ReactSVG
                  className="box-menu-icon"
                  src={`/assets/svg/logout.svg`}
                />
              </TrackableElement>
            </IsLogged>
          </IsLogged>
        )}
        {isMobile && (
          <>
            <button className="hamburger-menu-button" onClick={this.toggleMenu}>
              <Icon size={28} name={`${isMenuOpen ? "close" : "menu"}`} />
            </button>
            {isMenuOpen && (
              <div className="hamburger-menu">
                <IsLogged>
                {path.includes("/public") && (
          <TrackableElement
            category="Header"
            action="Click Full Version"
            className="header-wrapper"
          >
            <Link to={path.replace("/public", "")}>
             Full version
            </Link>
          </TrackableElement>
        )}
                  <TrackableElement
                    category="Header"
                    action="Click User"
                    className={`header-wrapper ${
                      activeLink === "dashboard" ? "active" : ""
                    }`}
                  >
                    <Link
                      className="header-wrapper"
                      to="/"
                      onClick={() => this.handleLinkClick("dashboard")}
                    >
                      <>Dashboard</>
                    </Link>
                  </TrackableElement>
                  <TrackableElement
                    category="Header"
                    action="Click User"
                    className={`header-wrapper ${
                      activeLink === "shipments" ? "active" : ""
                    }`}
                  >
                    <Link
                      className="header-wrapper"
                      to="/shipments"
                      onClick={() => this.handleLinkClick("shipments")}
                    >
                      <>Shipments</>
                    </Link>
                  </TrackableElement>
                  <TrackableElement
                    category="Header"
                    action="Click User"
                    className={`header-wrapper ${
                      activeLink === "devices" ? "active" : ""
                    }`}
                  >
                    <Link
                      className="header-wrapper"
                      to="/trackers"
                      onClick={() => this.handleLinkClick("devices")}
                    >
                      <>Devices</>
                    </Link>
                  </TrackableElement>
                  <HasPermissions permission="ADMIN_PAGES">
                  <TrackableElement
                    category="Header"
                    action="Click User"
                    className={`header-wrapper ${
                      activeLink === "admin" ? "active" : ""
                    }`}
                  >
                    <Link
                      className="header-wrapper"
                      to="/admin"
                      onClick={() => this.handleLinkClick("admin")}
                    >
                      <>Admin</>
                    </Link>
                  </TrackableElement>
                  </HasPermissions>
                  <TrackableElement
                    category="Header"
                    action="Click User"
                    className={`header-wrapper ${
                      activeLink === "user" ? "active" : ""
                    }`}
                  >
                    <Link
                      className="header-wrapper"
                      to="/user"
                      onClick={() => this.handleLinkClick("user")}
                    >
                      <Icon className="header-icon" size={40} name="person" />
                    </Link>
                  </TrackableElement>
                  <TrackableElement
                    category="Header"
                    action="Click LogOut"
                    className="header-wrapper"
                    onClick={this.logoutClick}
                  >
                    <ReactSVG
                      className="box-menu-icon"
                      src={`/assets/svg/logout.svg`}
                    />
                  </TrackableElement>
                </IsLogged>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="shlk-header shlk-ui">
        {this.renderNavigation()}
        {this.renderUserActions()}
      </div>
    );
  }
}

export default withRouter(Header);
