import React, { Component } from 'react';
import Welcome from 'components/Welcome';
import Page from 'components/ControlBars/Page';

export default class Home extends Component<{}> {
  render() {
    return (
      <Page className="shlk-home-public">
          <Welcome />
      </Page>
    );
  }
}