import React, { Component } from 'react';

import Button from 'components/Inputs/Button';
import Icon from 'components/Icon';

interface Props {
  shipment: any;
  trackers: any;
  validation: any;
  edit: boolean;
  callback: any;
  create? :any;
}


export default class ShipmentActions extends Component<Props> {
  canComplete = () => {
    const shipment = this.props.shipment;

    return shipment.name &&
      (!shipment.vessel || `${shipment.vessel}`.length === 9) &&
      shipment.origin && shipment.origin.address && shipment.origin.location &&
      shipment.destination && shipment.destination.address && shipment.destination.location &&
      this.props.trackers.length > 0;
  }

  userConfirmation = () => {
    if(this.props.shipment.transports) {
      const selectedTrackers = this.props.trackers.map(({ _id }: { _id: string }) => _id);
      const removedTrackers = this.props.shipment.transports.filter((transport: any) => !selectedTrackers.includes(transport.tracker._id));

      return !removedTrackers.length || window.confirm(`You are removing the next tracker(s) from the shipment:\n${removedTrackers.map(({ tracker }: any) => tracker.name).join(', ')}\n\nThis will remove all the data related. Are you sure?`);
    }

    return true;
  }

  complete = (cancel: boolean) => {
    this.props.callback(this.props.shipment, this.props.trackers, cancel);
  }

  render() {
    const showCompleteButton = this.canComplete() && this.props.validation.valid;
    console.log(this.props, "validation");

    return (<>
      <div className="new-buttons">
        {(this.props.edit) &&
          <Button
            category="ShipmentForm"
            action="Click Cancel Form"
            label="Edit"
            onClick={() => this.complete(true)}
            styles='solid'
            className="new-button new-button-cancel"
          >
            <Icon size={22} name="close" />
            Cancel
          </Button>
        }

        <Button
          category="ShipmentForm"
          action="Click Complete Form"
          label={this.props.edit ? 'Edit' : 'New'}
          onClick={() => this.userConfirmation() && this.complete(false)}
          styles='solid'
          disabled={!showCompleteButton || this.props.create}
          className="new-button"
        >
          <Icon size={22} name={this.props.edit ? 'save' : 'add_circle'} />
          {this.props.edit ? 'Save' : 'Create'}
        </Button>
      </div>
    </>);
  }
}