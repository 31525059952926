/**
 * Represents a trackable route component that tracks page views and updates the document title.
 */
import React, { Component } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Dimensions, RouteParams, trackPageview } from 'utils/analytics';
import { initializeNavigationStack, setNavigationStack, getFullPathname } from 'utils/navigation';

export interface TrackableRouteProps extends RouteProps {
    title: string;
    component: any;
}

// Define the TrackableRoute component, which extends the Component class from React
export default class TrackableRoute<T extends TrackableRouteProps = TrackableRouteProps, S = {}> extends Component<T, S> {
    /**
     * This method tracks the page view by sending analytics data and updates the document title.
     */
    trackPageview() {
        // Initialize an empty dimensions object
        let dimensions: Dimensions = {};

        // Define the route parameters for the page view
        const routeParams: RouteParams = {
            page_title: this.props.title, // The title of the page
            page_path: getFullPathname(window.location), // The full path of the page
            page_location: window.location.href // The full URL of the page
        };

        // If the location prop is defined, update the route parameters and dimensions
        if(this.props.location) {
            // Update the page path and page location with the pathname, search, and hash from the location prop
            routeParams.page_path = `${this.props.location.pathname}${this.props.location.search}${this.props.location.hash}`;
            routeParams.page_location = `${window.location.origin}${routeParams.page_path}`;

            // Update the dimensions with the state from the location prop
            dimensions = this.props.location.state as Dimensions || {};
        }

        // Track the page view with the route parameters and dimensions
        trackPageview(routeParams, dimensions);
    }

    // When the component mounts, initialize the navigation stack
    componentDidMount() {
        initializeNavigationStack();
    }

    // When the component updates, update the navigation stack with the previous and current location
    componentDidUpdate(prevProps: Readonly<TrackableRouteProps>) {
        setNavigationStack(prevProps.location, this.props.location);
    }

    // Render the Route component with the props of the TrackableRoute component
    render() {
        // Update the document title with the title prop
        document.title = this.props.title;

        // Track the page view
        this.trackPageview();

        // Render the Route component with the props of the TrackableRoute component
        return <Route { ...this.props } />;
    }
}