export const lineChartOptions = {
  plugins: {
    legend: {
      display: true,
      // position: "bottom",

      align: "center",
      title: {
        display: true,
        color: "#19575c",
        text: "In transit emissions",
        position:"center",
        font: {
          size: "15px",
          weight:"bold",
          family: "Roboto Condensed"
        },
      },
      labels: {
        usePointStyle: true,
        pointStyle: "rect",
        
      },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Timestamp",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Emissions (kgCO2e)",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
    },
  },
};
export const lineChartOptionsMobile = {
  maintainAspectRatio: true,
  aspectRatio: 1,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      align: "start",
      title: {
        color: "red",
      },
      labels: {
        usePointStyle: true,
        pointStyle: "rect",
      },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Timestamp",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Emissions (kgCO2e)",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
    },
  },
};
export const barChartOptionsTonne = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Dynamic | Static",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "g CO2e/tkm (Emissions per transport activity)",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
  },
};
export const barChartOptionsTeu = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Dynamic | Static",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "kg CO2e/TEU (Container Emission Intensity)",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
  },
};
export const barChartOptionsKg = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Dynamic | Static",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "kg CO2e/kg (Emissions per weight)",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
  },
};
export const barChartOptionsTonneMobile = {
  maintainAspectRatio: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: "kg CO2e/tkm (Emissions per transport activity)",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
  },
};
export const barChartOptionsTeuMobile = {
  maintainAspectRatio: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: "kg CO2e/TEU (Container Emission Intensity)",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
  },
};
export const barChartOptionsKgMobile = {
  maintainAspectRatio: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: "kg CO2e/kg (Emissions per weight)",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "",
        color: "#19575C",
        font: {
          size: "13px",
        },
      },
      display: true,
      stacked: true,
    },
  },
};

export const donughnutChartOptions = {
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      align: "start",
      title: {
        color: "red",
      },
      labels: {
        usePointStyle: true,
        pointStyle: "rect",
      },
    },
  },
};

export const donughnutChartOptions1 = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      align: "center",
      title: {
        color: "#19575C",
      },
      labels: {
        usePointStyle: true,
        pointStyle: "rect",
      },
    },
  },
};
export const donughnutChartOptions2 = {
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      align: "start",
      title: {
        color: "#19575C",
      },
      labels: {
        usePointStyle: true,
        pointStyle: "rect",
      },
    },
  },
};
