// // // // // import React, { CSSProperties, Component, PropsWithChildren } from 'react';
// // // // // import Header from '../Header';
// // // // // import './styles.scss';
// // // // // import { getUser } from 'services/users';

// // // // // type PageProps = PropsWithChildren<{
// // // // //   className: string;
// // // // //   type?: 'error' | 'map';
// // // // // }>

// // // // // export default class Page extends Component<PageProps> {
// // // // //   componentDidMount() {
// // // // //     getUser()
// // // // //       .then((response) => {
// // // // //         console.log('User data:', response, response.acceptedTerms); // Log the response here
// // // // //       })
// // // // //       .catch((error) => {
// // // // //         console.error('Error fetching user data:', error); // Handle and log errors
// // // // //       });
// // // // //   }

// // // // //   render() {
// // // // //     let className = 'shlk-page';
// // // // //     const style: CSSProperties = {};

// // // // //     if(this.props.type) className += ` shlk-page--${this.props.type}`;
// // // // //     if(this.props.className) className += ` ${this.props.className}`;
// // // // //     if(this.props.type === "error") style.backgroundImage = "url(assets/img/background_logo.png)";

// // // // //     return (
// // // // //       <div className={className} style={style}>
// // // // //         <Header />

// // // // //         <div className='shlk-page-content'>
// // // // //           {this.props.children}
// // // // //         </div>
// // // // //       </div>
// // // // //     );
// // // // //   }
// // // // // }

// // // // import React, { CSSProperties, Component, PropsWithChildren } from 'react';
// // // // import Header from '../Header';
// // // // import './styles.scss';
// // // // import { getUser } from 'services/users';

// // // // type PageProps = PropsWithChildren<{
// // // //   className: string;
// // // //   type?: 'error' | 'map';
// // // // }>;

// // // // type PageState = {
// // // //   showPopup: boolean;
// // // // };

// // // // export default class Page extends Component<PageProps, PageState> {
// // // //   constructor(props: PageProps) {
// // // //     super(props);
// // // //     this.state = {
// // // //       showPopup: false, // Initialize state for controlling popup visibility
// // // //     };
// // // //   }

// // // //   componentDidMount() {
// // // //     getUser()
// // // //       .then((response) => {
// // // //         console.log('User data:', response, response.acceptedTerms);
// // // //         if (!response.acceptedTerms) {
// // // //           this.setState({ showPopup: true }); // Show popup if terms are not accepted
// // // //         }
// // // //       })
// // // //       .catch((error) => {
// // // //         console.error('Error fetching user data:', error);
// // // //       });
// // // //   }

// // // //   closePopup = () => {
// // // //     this.setState({ showPopup: false }); // Hide popup when close button is clicked
// // // //   };

// // // //   renderPopup() {
// // // //     if (!this.state.showPopup) return null;

// // // //     return (
// // // //       <div className="popup-overlay">
// // // //         <div className="popup-modal">
// // // //           <button className="popup-close" onClick={this.closePopup}>
// // // //             &times;
// // // //           </button>
// // // //           <div className="popup-content">
// // // //             <h2>Terms and Conditions</h2>
// // // //             <p>You need to accept the terms and conditions to continue.</p>
// // // //             {/* Add more content if needed */}
// // // //           </div>
// // // //         </div>
// // // //       </div>
// // // //     );
// // // //   }

// // // //   render() {
// // // //     let className = 'shlk-page';
// // // //     const style: CSSProperties = {};

// // // //     if (this.props.type) className += ` shlk-page--${this.props.type}`;
// // // //     if (this.props.className) className += ` ${this.props.className}`;
// // // //     if (this.props.type === 'error') style.backgroundImage = 'url(assets/img/background_logo.png)';

// // // //     return (
// // // //       <div className={className} style={style}>
// // // //         <Header />

// // // //         <div className="shlk-page-content">{this.props.children}</div>

// // // //         {this.renderPopup()} {/* Conditionally render the popup */}
// // // //       </div>
// // // //     );
// // // //   }
// // // // }

// // // import React, { CSSProperties, Component, PropsWithChildren } from 'react';
// // // import Header from '../Header';
// // // import './styles.scss';
// // // import { getUser} from 'services/users'; // Assuming getTerms API is in the same service
// // // import { getTerms } from 'services/documents';

// // // type PageProps = PropsWithChildren<{
// // //   className: string;
// // //   type?: 'error' | 'map';
// // // }>;

// // // type PageState = {
// // //   showPopup: boolean;
// // //   termsContent: string; // State to hold the terms HTML content
// // // };

// // // export default class Page extends Component<PageProps, PageState> {
// // //   constructor(props: PageProps) {
// // //     super(props);
// // //     this.state = {
// // //       showPopup: false, // Initialize state for controlling popup visibility
// // //       termsContent: '', // Initialize state for holding the HTML content
// // //     };
// // //   }

// // //   componentDidMount() {
// // //     getUser()
// // //       .then((response) => {
// // //         console.log('User data:', response, response.acceptedTerms);
// // //         if (!response.acceptedTerms) {
// // //           this.setState({ showPopup: true }); // Show popup if terms are not accepted
// // //           this.loadTerms(); // Call loadTerms to get the HTML content
// // //         }
// // //       })
// // //       .catch((error) => {
// // //         console.error('Error fetching user data:', error);
// // //       });
// // //   }

// // //   loadTerms() {
// // //     getTerms()
// // //       .then((termsResponse:any) => {
// // //         this.setState({ termsContent: termsResponse }); // Store the HTML content in the state
// // //       })
// // //       .catch((error:any) => {
// // //         console.error('Error fetching terms and conditions:', error);
// // //       });
// // //   }

// // //   closePopup = () => {
// // //     this.setState({ showPopup: false }); // Hide popup when close button is clicked
// // //   };

// // //   renderPopup() {
// // //     if (!this.state.showPopup) return null;

// // //     return (
// // //       <div className="popup-overlay">
// // //         <div className="popup-modal">
// // //           <button className="popup-close" onClick={this.closePopup}>
// // //             &times;
// // //           </button>
// // //           <div className="popup-content">
// // //             <h2>Terms and Conditions</h2>
// // //             {/* Render HTML content using dangerouslySetInnerHTML */}
// // //             <div dangerouslySetInnerHTML={{ __html: this.state.termsContent }} />
// // //           </div>
// // //         </div>
// // //       </div>
// // //     );
// // //   }

// // //   render() {
// // //     let className = 'shlk-page';
// // //     const style: CSSProperties = {};

// // //     if (this.props.type) className += ` shlk-page--${this.props.type}`;
// // //     if (this.props.className) className += ` ${this.props.className}`;
// // //     if (this.props.type === 'error') style.backgroundImage = 'url(assets/img/background_logo.png)';

// // //     return (
// // //       <div className={className} style={style}>
// // //         <Header />

// // //         <div className="shlk-page-content">{this.props.children}</div>

// // //         {this.renderPopup()} {/* Conditionally render the popup */}
// // //       </div>
// // //     );
// // //   }
// // // }

// // import React, { CSSProperties, Component, PropsWithChildren } from 'react';
// // import Header from '../Header';
// // import './styles.scss';
// // import { getUser } from 'services/users'; // Assuming getTerms API is a GET request
// // import { getTerms } from 'services/documents';

// // type PageProps = PropsWithChildren<{
// //   className: string;
// //   type?: 'error' | 'map';
// // }>;

// // type PageState = {
// //   showPopup: boolean;
// //   termsContent: string; // State to hold the terms HTML content
// // };

// // export default class Page extends Component<PageProps, PageState> {
// //   constructor(props: PageProps) {
// //     super(props);
// //     this.state = {
// //       showPopup: false, // Initialize state for controlling popup visibility
// //       termsContent: '', // Initialize state for holding the HTML content
// //     };
// //   }

// //   componentDidMount() {
// //     getUser()
// //       .then((response) => {
// //         console.log('User data:', response, response.acceptedTerms);
// //         if (!response.acceptedTerms) {
// //           this.setState({ showPopup: true }); // Show popup if terms are not accepted
// //           this.loadTerms(); // Call loadTerms to get the HTML content
// //         }
// //       })
// //       .catch((error) => {
// //         console.error('Error fetching user data:', error);
// //       });
// //   }

// //   loadTerms() {
// //     getTerms()
// //       .then((termsResponse:any) => {
// //         this.setState({ termsContent: termsResponse }); // Store the HTML content in the state
// //       })
// //       .catch((error:any) => {
// //         console.error('Error fetching terms and conditions:', error);
// //       });
// //   }

// //   closePopup = () => {
// //     this.setState({ showPopup: false }); // Hide popup when close button is clicked
// //   };

// //   renderPopup() {
// //     if (!this.state.showPopup) return null;

// //     return (
// //       <div className="popup-overlay">
// //         <div className="popup-modal">
// //           <button className="popup-close" onClick={this.closePopup}>
// //             &times;
// //           </button>
// //           <div className="popup-content">
// //             <h2>Terms and Conditions</h2>
// //             {/* Render HTML content using dangerouslySetInnerHTML */}
// //             <div dangerouslySetInnerHTML={{ __html: this.state.termsContent }} />
// //           </div>
// //         </div>
// //       </div>
// //     );
// //   }

// //   render() {
// //     let className = 'shlk-page';
// //     const style: CSSProperties = {};

// //     if (this.props.type) className += ` shlk-page--${this.props.type}`;
// //     if (this.props.className) className += ` ${this.props.className}`;
// //     if (this.props.type === 'error') style.backgroundImage = 'url(assets/img/background_logo.png)';

// //     return (
// //       <div className={className} style={style}>
// //         <Header />

// //         <div className="shlk-page-content">{this.props.children}</div>

// //         {this.renderPopup()} {/* Conditionally render the popup */}
// //       </div>
// //     );
// //   }
// // }

// import React, { CSSProperties, Component, PropsWithChildren } from 'react';
// import Header from '../Header';
// import './styles.scss';
// import { getUser } from 'services/users';
// import { getTerms } from 'services/documents';

// type PageProps = PropsWithChildren<{
//   className: string;
//   type?: 'error' | 'map';
// }>;

// type PageState = {
//   showPopup: boolean;
//   termsContent: string;
// };

// export default class Page extends Component<PageProps, PageState> {
//   constructor(props: PageProps) {
//     super(props);
//     this.state = {
//       showPopup: false,
//       termsContent: '',
//     };
//   }

//   componentDidMount() {
//     getUser()
//       .then((response) => {
//         console.log('User data:', response, response.acceptedTerms);
//         if (!response.acceptedTerms) {
//           this.setState({ showPopup: true });
//           this.loadTerms();
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching user data:', error);
//       });
//   }

//   loadTerms() {
//     getTerms()
//       .then((termsResponse: any) => {
//         this.setState({ termsContent: termsResponse });
//       })
//       .catch((error: any) => {
//         console.error('Error fetching terms and conditions:', error);
//       });
//   }

//   closePopup = () => {
//     this.setState({ showPopup: false });
//     document.body.classList.remove('no-scroll'); // Remove scroll lock on popup close
//   };

//   componentDidUpdate(prevProps: PageProps, prevState: PageState) {
//     if (this.state.showPopup && !prevState.showPopup) {
//       document.body.classList.add('no-scroll'); // Add scroll lock on popup open
//     }
//   }

//   renderPopup() {
//     if (!this.state.showPopup) return null;

//     return (
//       <div className="popup-overlay">
//         <div className="popup-modal">
//           <button className="popup-close" onClick={this.closePopup}>
//             &times;
//           </button>
//           <div className="popup-content">
//             <h2>Terms and Conditions</h2>
//             <div dangerouslySetInnerHTML={{ __html: this.state.termsContent }} />
//           </div>
//         </div>
//       </div>
//     );
//   }

//   render() {
//     let className = 'shlk-page';
//     const style: CSSProperties = {};

//     if (this.props.type) className += ` shlk-page--${this.props.type}`;
//     if (this.props.className) className += ` ${this.props.className}`;
//     if (this.props.type === 'error') style.backgroundImage = 'url(assets/img/background_logo.png)';

//     return (
//       <div className={className} style={style}>
//         <Header />
//         <div className="shlk-page-content">{this.props.children}</div>
//         {this.renderPopup()}
//       </div>
//     );
//   }
// }

import React, { CSSProperties, Component, PropsWithChildren } from "react";
import Header from "../Header";
import "./styles.scss";
import { getUser, acceptTerms } from "services/users"; // Assuming acceptTerms is the API for accepting terms
import { getTerms } from "services/documents";

type PageProps = PropsWithChildren<{
  className: string;
  type?: "error" | "map";
}>;

type PageState = {
  showPopup: boolean;
  termsContent: string;
  userId: string | null; // Store user ID
  termsPath: any;
};

export default class Page extends Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      showPopup: false,
      termsContent: "",
      userId: null, // Initialize user ID as null
      termsPath: "",
    };
  }

  componentDidMount() {
    const currentPath = window.location.pathname;

    // Only proceed with getUser() if the path is not /login or /forgot-password
    if (
      currentPath !== "/login" &&
      currentPath !== "/forgot-password" &&
      currentPath !== "/reset-password" &&
      currentPath !== "/enter-code"
    ) {
      getUser()
        .then((response) => {
          console.log("User data:", response);
          // Store the user ID and check if terms were already accepted
          this.setState({ userId: response._id });
          if (!response.acceptedTerms) {
            this.setState({ showPopup: true });
            this.loadTerms();
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }

  loadTerms() {
    getTerms()
      .then((termsResponse: any) => {
        this.setState({
          termsContent: termsResponse.html,
          termsPath: termsResponse.path,
        });
      })
      .catch((error: any) => {
        console.error("Error fetching terms and conditions:", error);
      });
  }

  handleAcceptTerms = () => {
    // Call the acceptTerms API and pass userId and acceptedTerms: true
    const { userId } = this.state;

    if (userId) {
      const today = new Date();
      const formattedDate = today.toLocaleDateString("en-GB"); // Formats date as "DD/MM/YYYY"
      acceptTerms({
        _id: userId,
        acceptedTerms: true,
        acceptedDetails: { date: formattedDate, version: this.state.termsPath },
      }) // Pass _id and acceptedTerms: true
        .then(() => {
          this.setState({ showPopup: false });
          document.body.classList.remove("no-scroll"); // Remove scroll lock after accepting
          window.location.reload();
          // Refresh the page to reflect changes
        })
        .catch((error: any) => {
          console.error("Error accepting terms and conditions:", error);
        });
    }
  };

  componentDidUpdate(prevProps: PageProps, prevState: PageState) {
    if (this.state.showPopup && !prevState.showPopup) {
      document.body.classList.add("no-scroll"); // Add scroll lock on popup open
    }
  }

  renderPopup() {
    if (!this.state.showPopup) return null;

    return (
      <div className="popup-overlay">
        <div className="popup-modal">
          {/* Removed the close button */}

          <div className="popup-content">
            <h2>Terms and Conditions</h2>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.termsContent }}
            />
            <button
              className="accept-button"
              onClick={this.handleAcceptTerms}
              style={{
                background: "#19575c",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #4DB6AC",
              }}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let className = "shlk-page";
    const style: CSSProperties = {};

    if (this.props.type) className += ` shlk-page--${this.props.type}`;
    if (this.props.className) className += ` ${this.props.className}`;
    if (this.props.type === "error")
      style.backgroundImage = "url(assets/img/background_logo.png)";

    return (
      <div className={className} style={style}>
        <Header />
        <div className="shlk-page-content">{this.props.children}</div>
        {this.renderPopup()}
      </div>
    );
  }
}
