import React, { Component } from 'react';
import ContentLoader from 'components/Loading/ContentLoader';
import Title from 'components/Title';
import { getUser } from 'services/users';
import View from './View';
import Add from './Add';
import { CompanyContext } from './context';
import './styles.scss';

interface State {
  loading: boolean;
  company: any;
  layout: string;
}

export default class Company extends Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      loading: true,
      company: null,
      layout: "VIEW"
    };
  }

  getInfo = () => {
    this.setState({ loading: true });

    getUser()
      .then((user: any) => this.setState({ loading: false, company: user.company }));
  }

  componentDidMount() {
    this.getInfo();
  }

  updateCompany = (company: any) => {
    this.setState({ company });
  };

  changeLayout = (layout: string) => {
    this.getInfo();
    this.setState({ layout });
  };

  render() {
    this.context.company = this.state.company;
    this.context.updateCompany = this.updateCompany;
    this.context.changeLayout = this.changeLayout;

    return <>
        { !this.context.company && <Title text="Company" icon="people" /> }
        <ContentLoader loading={this.state.loading} content={() =>
              this.state.layout === "VIEW" ? <View/> : <Add/>
        }/>
      </>;
  }
}

Company.contextType = CompanyContext;