import axios from 'axios';
import { handleException } from 'utils/errors';
import { getCookieData } from 'utils/data/cookies';

export interface Options {
  method?: 'POST' | 'GET';
  responseType?: 'blob';
  forceUpdate?: boolean;
  handledException?: boolean;
}

let CACHE: any = {};

const getKey = (path: string, method: string, data: object) => {
  return `${path}||${method}||${JSON.stringify(data)}`;
};

export const clean = (data?: any) => {
  CACHE = {};

  return Promise.resolve(data);
};

/**
 * Makes an API request to the specified path with the given data and options.
 * @param path The API endpoint path.
 * @param data The data to send with the request.
 * @param credentials Indicates whether to include credentials in the request.
 * @param options Additional options for the request.
 * @returns A Promise that resolves to the API response data.
 */
// This function is an asynchronous function named shlkAPI. It takes four parameters: path, data, credentials, and options.
export const shlkAPI = async (path: string, data: any = {}, credentials = true, options: Options = {}) => {

  // It sets the method to the method provided in options, or 'POST' if no method is provided.
  const method = options.method || 'POST',

  // It retrieves the authToken from the cookies.
  authToken: string | null = getCookieData('authToken'),

  // It generates a key based on the path, method, and data.
  key = getKey(path, method, data);

  // It logs the function name, path, data, and options to the console.
  console.log('shlkAPI', path, data, options);

  // It checks if there's no cached response for the key, or if forceUpdate is true.
  if(!CACHE[key] || !!options.forceUpdate) {

    // It sets up the options for the axios request.
    const aixosOptions: any = {
      method,
      url: process.env.REACT_APP_ENDPOINT_API + path,
      headers: {},
      withCredentials: credentials
    };

    // It logs the axios options to the console.
    console.log('shlkAPI', aixosOptions);

    // If the method is 'GET', it adds the data as params. Otherwise, it adds the data as the request body.
    if(method === 'GET') {
      aixosOptions.params = data;
    } else {
      aixosOptions.data = data;
    }

    // If there's an authToken, it adds it to the headers.
    if(authToken) aixosOptions.headers['Authorization'] = authToken;

    // If a responseType is provided, it sets it in the axios options.
    if(options.responseType) aixosOptions.responseType = options.responseType;

    // It makes the axios request, stores the promise in the cache, and returns the data when the promise resolves.
    CACHE[key] = axios(aixosOptions)
      .then((res: any) => res.data)
      .catch((error: any) => {
        // If an error occurs, it handles the exception, removes the promise from the cache, and rethrows the error.
        handleException(error, options.handledException);

        delete CACHE[key];
        throw error;
      });
  }

  // It returns the promise from the cache.
  return CACHE[key];
};