import React, { Component } from "react";
import { debounce } from "utils/functions";
import { trackEvent } from "utils/analytics";
import "./styles.scss";

interface Props {
  min: string;
  max: string;
  label: string;
  value: string;
  update: any;
  units?: string;
}

interface State {
  value: string;
}

export default class Input extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { value: props.value };
  }

  onChange = () => {
    const { value } = this.state;

    trackEvent("RangeForm", this.props.label, value);
    this.props.update(value);
  };

  debounceFilterUpdate = debounce(this.onChange, 200);

  render() {
    return (
      <div className="form-range">
        <label className="form-range-label">{this.props.label}</label>

        <div
          className="form-range-wrapper"
          style={{ marginTop: "10px", marginLeft: "-2px" }}
        >
          <input
            className="form-range-line"
            type="range"
            name={this.props.label}
            onChange={(event) => {
              this.setState({ value: event.currentTarget.value });
              this.debounceFilterUpdate();
            }}
            value={this.state.value}
            min={this.props.min}
            max={this.props.max}
          />

          <p className="form-range-value">
            {this.state.value}
            {this.props.units || ""}
          </p>
        </div>
      </div>
    );
  }
}
