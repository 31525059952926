/**
 * Represents a private route component that requires authentication.
 * It extends the TrackableRoute component and handles the logic for redirecting
 * unauthorized users to the login page.
 */
import React from 'react';
import { Redirect } from 'react-router-dom';
import TrackableRoute, { TrackableRouteProps } from 'components/Trackable/Route';
import Loading from 'pages/Loading';
import { getCookieData } from 'utils/data/cookies';

/**
 * Represents the state of the Private component.
 */
interface State {
    valid: boolean;
    loading: boolean;
}

/**
 * Represents a private route component that requires authentication.
 * Extends the TrackableRoute component.
 */
// Define the PrivateRoute component, which extends the TrackableRoute component
export default class PrivateRoute extends TrackableRoute<TrackableRouteProps, State> {
    // Define the constructor for the PrivateRoute component
    constructor(props: TrackableRouteProps) {
        // Call the constructor of the parent TrackableRoute component
        super(props);

        // Initialize the state of the PrivateRoute component
        this.state = {
            loading: true, // The component is initially loading
            valid: false // The current user is initially not valid
        };
    }

    // Define what happens when the component mounts
    componentDidMount() {
        // Get the current user from the cookies
        const currentUser: string | null = getCookieData('authToken') && getCookieData('userId');

        // Update the state with the validity of the current user and set loading to false
        this.setState({ valid: !!currentUser, loading : false });
    }

    // Define what the component renders
    render() {
        // Get the location from the props
        const location = this.props.location;

        // Define the redirect path and search parameters
        const redirectPath = encodeURIComponent(location ? location.pathname : "/");
        const search = (location && location.search) ? `&${location.search.replace("?", "")}` : "";

        // If the component is loading, render the Loading component
        if(this.state.loading) {
            return (<Loading />);
        } 
        // If the current user is not valid, redirect to the login page
        else if(!this.state.valid) {
            return (<Redirect to={`/login?from=${redirectPath}${search}`} />);
        }

        // If the current user is valid, render the TrackableRoute component with the props of the PrivateRoute component
        return  <TrackableRoute { ...this.props } />;
    }
}