export const EXTERNAL_ESHELLS = {
    site: 'https://antisink.naxxar.uk/login',
    identifiers: [
        '862110768000003',
        '862110768000012',
        '862110768000020',
        '862110768000021',
        '862110768000029',
        '862110768000056',
        '862110768000066',
        '862110768000082',
        '862110768000083',
        '862110768000088',
        '862110768000093',
        '862110768000094',
        '862110768000099',
        '862203898000002',
        '862203898000008',
        '862203898000012',
        '862211898000031',
        '862211898000032',
        '862211898000033',
        '862211898000034',
        '862211898000035',
        '862211898000036',
        '862211898000037',
        '862211898000038',
        '862211898000039',
        '862211898000040',
        '862211898000041',
        '862211898000042',
        '862211898000043',
        '862211898000044',
        '862211898000045',
        '862211898000046',
        '862211898000047',
        '862211898000048',
        '862211898000049',
        '862211898000050',
        '862211898000051',
        '862211898000052',
        '862211898000053',
        '862211898000054',
        '862211898000055',
        '862211898000056',
        '862211898000057',
        '862211898000058',
        '862211898000059',
        '862211898000060',
        '862211898000061',
        '862211898000062',
        '862211898000063',
        '862211898000064',
        '862211898000065',
        '862211898000066',
        '862211898000067',
        '862211898000068',
        '862211898000069',
        '862211898000070',
        '862211898000071',
        '862211898000072',
        '862211898000073',
        '862211898000074',
        '862211898000075',
        '862211898000076',
        '862211898000077',
        '862211898000078',
        '862211898000079',
        '862211898000080',
        '862211898000081',
        '862211898000082',
        '862211898000083',
        '862211898000084',
        '862211898000085',
        '862211898000086',
        '862211898000087',
        '862211898000088',
        '862211898000089',
        '862211898000092'
    ]
};
