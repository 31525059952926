import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import HasPermission from 'components/Permissions/Has';
import ContentLoader from 'components/Loading/ContentLoader';
import { removeUser } from 'services/company';
import { format } from 'utils/roles';
import { getCookieData } from 'utils/data/cookies';
import { CompanyContext } from '../../context';
import './styles.scss';

interface Props {
  member: any;
}

interface State {
  loading: boolean;
}

export default class Member extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  remove = () => {
    const user = this.props.member.user;

    if(window.confirm(`Are you sure you want to remove ${user.email} from ${this.context.company.name}?`)) {
      this.setState({ loading : true });

      removeUser(user._id)
        .then((company) => this.context.updateCompany(company));
    }
  }

  render() {
    const member = this.props.member;

    return (
      <div className="shlk-member">
        <ContentLoader loading={this.state.loading} content={() => {
          return (<>
            <ReactSVG className="member-icon" src="/assets/svg/person.svg" />
            <div>
              <p><b>Name: </b>{member.user.fullName}</p>
              <p><b>Email: </b>{member.user.email}</p>
              <p><b>Roles: </b>
                {member.permissions.roles.map((role: string, index: number) => {
                  return `${index > 0 ? ', ' : ''}${format(role)}`;
                })}
              </p>
            </div>

            {
              (member.user.email !== getCookieData("email")) &&
              <HasPermission permission='INVITE_MEMBERS'>
                <div className='memeber-remove' onClick={this.remove}>-</div>
              </HasPermission>
            }
          </>);
         }}/>
      </div>
      );
    }
  }

  Member.contextType = CompanyContext;