//A Company Card component that displays the company name with an 
//onClick handler that calls the handleCompanyClick function with the company id as an argument.


import React from 'react';
import './styles.scss';

interface Props {
  company: any;
  onClick: (id: string) => void;
  isSelected: boolean;
}

const CompanyCard = ({ company, onClick, isSelected }: Props) => {
  return (
    <div 
      className={`company-card ${isSelected ? 'selected' : ''}`} 
      onClick={() => onClick(company.id)}
    >
      <h3>{company.name}</h3>
    </div>
  );
};

export default CompanyCard;