
import React, { PureComponent } from "react";

import { RouteComponentProps } from "react-router";
import Page from "components/ControlBars/Page";
import ContentLoader from "components/Loading/ContentLoader";
import RoutesMap from "components/GoogleMap/RoutesMap";
import ContentPopup from "components/ContentPopup";
import {
  getEmissionsStats,
  getEmissionsTimeLine,
  getMemberRoles,
  getShipmentReportData,
  inviteMember,
  sendReport,
} from "services/shipments";
import ReportHistory from "./History";
import ReportStatus from "./Status";
import ReportEvents from "./Events";
import ReportQR from "./QR";
import PieTerrainsTime from "./Charts/Pie/TerrainTime";
import BarTerrainTime from "./Charts/Bar/TerrainTime";
import BarTerrainDistance from "./Charts/Bar/TerrainDistance";
import ReportHeader from "./Header";
import ReportFooter from "./Footer";
import ReportFakeTable from "./FakeTable";
import ReportSettingsPopup from "./SettingsPopup";
import "./styles.scss";
import SharePopup from "components/Popups/Share";
import { ReactSVG } from "react-svg";
import Input from "components/Inputs/Input/Default";
import Button from "components/Inputs/Button";
import TrackableElement from "components/Trackable/Element";
import Icon from "components/Icon";
import ChartWrapper from "components/Shipment/Emissions/Charts/Chart";
import {
  barChartOptionsKg,
  barChartOptionsTeu,
  barChartOptionsTonne,
  donughnutChartOptions,
  lineChartOptions,
} from "components/Shipment/Emissions/Charts/data";
import html2canvas from "html2canvas";
import { PDFDocument, rgb } from "pdf-lib";
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

export type ReportConfig = {
  group: "country" | "city";
  identifier: string;
};

interface MatchProps {
  id: string;
}

interface State {
  loading: boolean;
  cursorloading: boolean;
  shipment: any;
  locations: any[];
  terrains: any;
  emissionsTimeLine: any;
  emissionStats: any;
  config: ReportConfig;
  triggers: boolean;
  selectedOption: string;
  showPopup: boolean; // New state for controlling popup visibility
  email: any;
  name: any;
  report: any;
}

export default class Report extends PureComponent<
  RouteComponentProps<MatchProps>,
  State
> {
  state: State = {
    loading: true,
    cursorloading: false,
    locations: [],
    shipment: null,
    terrains: null,
    emissionsTimeLine: null,
    emissionStats: null,
    config: {
      group: "country",
      identifier: "",
    },
    triggers: false,
    selectedOption: "Select",
    showPopup: false, // Initialize popup visibility state
    email: "",
    name: null,
    report: null,
  };

  // Arrow function to handle changes in input fields
  handleInputChange = (event: any) => {
    event.preventDefault(); // Prevent default form submission behavior
    const { name, value } = event.target;
    this.setState({ [name]: value } as Pick<State, keyof State>);
  };
  // Convert PDF to Base64
  convertPdfToBase64 = (pdfBlob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(",")[1]); // Remove the data URL prefix
      };
      reader.onerror = reject;
      reader.readAsDataURL(pdfBlob);
    });
  };

  handleSendReport = async (event: any) => {
    event.preventDefault();
    console.log("Report generation initiated.");

    const { email, name } = this.state;

    // Get the HTML content from the element with class 'report-table'
    const element = document.querySelector(".report-table") as HTMLElement;

    if (!element) {
      console.error("Content element not found.");
      return;
    }

    console.log("Content element found.");
    this.setState({ cursorloading: true });
    // Optionally, add a delay to ensure all content is fully loaded
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay as needed

    try {
      console.log("Capturing content...");

      // Convert HTML content to a canvas
      const canvas = await html2canvas(element, {
        useCORS: true, // Use CORS if cross-origin images are involved
        logging: true, // Enable logging to see debug output in console
      });

      const imageData = canvas.toDataURL("image/png");

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();

      // Define the margin size
      const margin = 40;

      // Adjust the page size to include margins
      const pageWidth = canvas.width + margin * 2;
      const pageHeight = canvas.height + margin * 2;

      const page = pdfDoc.addPage([pageWidth, pageHeight]);

      // Embed the image into the PDF
      const image = await pdfDoc.embedPng(imageData);
      const imageDims = image.scale(1);

      // Draw the image with margins
      page.drawImage(image, {
        x: margin,
        y: pageHeight - imageDims.height - margin,
        width: imageDims.width,
        height: imageDims.height,
      });

      // Serialize the PDF document to bytes
      const pdfBytes = await pdfDoc.save();

      // Convert the PDF bytes to a Base64 string
      const base64Pdf = Buffer.from(pdfBytes).toString("base64");

      // Prepare data for the API request
      const data = {
        emails: [email],
        report: base64Pdf,
        name: name,
        shipment: this.state.shipment.name,
        company: this.state.shipment.company.name,
      };

      // Call the sendReport API function
      const response = await sendReport(data);
      console.log("Report sent successfully:", response);

      // Notify the user
      window.alert(`The report has been sent to ${email}`);
    } catch (error) {
      console.error("Failed to send report:", error);
      window.alert("Failed to send report");
    } finally {
      // Optionally, you can reset loading state here
      this.setState({ cursorloading: false });
    }
  };

  refreshInfo = async () => {
    const id = this.props.match.params.id;
    this.setState({ loading: true });

    try {
      // Fetch shipment report data
      const { shipment, locations, terrains, identifier } =
        await getShipmentReportData({
          id,
          config: this.state.config,
        });

      // Fetch emissions timeline data
      const emissionsTimeLineData = await getEmissionsTimeLine({ id });

      // Fetch emissions stats data
      const emissionStatsData = await getEmissionsStats({ id });

      // Update state with all the fetched data
      this.setState({
        loading: false,
        shipment,
        locations,
        terrains,
        config: { ...this.state.config, identifier },
        emissionsTimeLine: emissionsTimeLineData,
        emissionStats: emissionStatsData,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ loading: false }); // Stop loading even if there's an error
    }
  };

  componentDidMount = this.refreshInfo;

  configurationChange = (config: ReportConfig) => {
    this.setState({ config }, this.refreshInfo);
  };

  handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    this.setState({ selectedOption });
  };

  togglePopup = () => {
    this.setState((prevState) => ({
      showPopup: !prevState.showPopup,
    }));
  };

  renderContent = () => {
    const { shipment, locations, terrains, config, selectedOption } =
      this.state;
    const { emissionsTimeLine, emissionStats } = this.state;

    const dynamicEmissions = emissionsTimeLine.dynamicEmissions;
    // Map dynamicEmissions to arrays of emissions and createdAt
    const emissionsStaticArray = dynamicEmissions
      ? dynamicEmissions.map((item: any) => item.static / 1000)
      : "";
    const emissionsDynamicArray = dynamicEmissions
      ? dynamicEmissions.map((item: any) => item.dynamic)
      : "";
    const formatTime = (seconds: any) => {
      const totalMinutes = Math.floor(seconds / 60);
      const hours = (Math.floor(totalMinutes / 60) % 24)
        .toString()
        .padStart(2, "0");
      const minutes = (totalMinutes % 60).toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    const createdAtArray = dynamicEmissions
      ? dynamicEmissions.map((item: any) => formatTime(item.cumulativeDistance))
      : "";
    console.log(
      shipment,

      "emissionsTimeLine"
    );

    const lineChartData = {
      labels: createdAtArray,
      datasets: [
        {
          label: "GLEC DYNAMIC",
          data: emissionsDynamicArray,
          borderColor: "#59f9dc",
          backgroundColor: "#59f9dc",
          fill: false,
        },
        {
          label: "GLEC STATIC",
          data: emissionsStaticArray,
          borderColor: "#4C3BCF",
          backgroundColor: "#4C3BCF",
          fill: false,
        },
      ],
    };

    const doughnutChartData = {
      labels: ["Air", "Water", "Land", "Rail"],
      datasets: [
        {
          label: "Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerMode.Air
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerMode.Sea
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerMode.Land
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerMode.Rail
              : "",
          ],
          backgroundColor: ["#B563F7", "#8AB4F8", "#FAC21B", "#FA7B57"],
          borderColor: ["#B563F7", "#8AB4F8", "#FAC21B", "#FA7B57"],
        },
        {
          label: "Static ",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerMode.Air
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerMode.Sea
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerMode.Land
              : "",
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerMode.Rail
              : "",
          ],
          backgroundColor: ["#DCBAF7", "#ADC9F5", "#F6D87E", "#FAC9AF"],
          borderColor: ["#DCBAF7", "#ADC9F5", "#F6D87E", "#FAC9AF"],
        },
      ],
    };
    const totalDynamic = emissionStats.emissions
      ? emissionStats.emissions.totalDynamic
      : "";
    const totalStatic = emissionStats.emissions
      ? emissionStats.emissions.totalStatic
      : "";

    const difference =
      totalDynamic && totalStatic && totalDynamic - totalStatic;

    const doughtnutLabel = {
      id: "doughnutLabel",
      afterDatasetsDraw(chart: any, args: any, plugins: any) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.font = "bold 30px sans-serif";
        ctx.fillStyle = "#19575C"; // Default color for positive differences
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        if (totalDynamic > totalStatic) {
          ctx.fillStyle = "#C11C1C"; // Change color to red for positive differences
          ctx.fillText("+" + Math.round(difference) + "kg", centerX, centerY);
        } else if (totalDynamic < totalStatic) {
          ctx.fillStyle = "#06D001"; // Change color to green for negative differences
          ctx.fillText(
            "-" + Math.round(-difference) + "kg", // Display absolute value with minus sign
            centerX,
            centerY
          );
        } else {
          // Difference is 0 or not valid
          ctx.fillText("0 kg", centerX, centerY);
        }
        ctx.font = "bold 9px sans-serif";
        ctx.fillStyle = "#19575C"; // Default color for positive differences
        ctx.fillText("of CO2e compared ", centerX, centerY + 30); // Adjust the y position as needed
        ctx.font = "bold 9px sans-serif";
        ctx.fillText("to GLEC default ", centerX, centerY + 40); // Adjust the y position as needed
      },
    };

    const barChartDataTonne = {
      labels: [""],

      datasets: [
        {
          label: "Air - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTKM.Air
              : "0",
          ],
          backgroundColor: "#B563F7",
          stack: "kg",
        },
        {
          label: "Water - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTKM.Sea
              : "0",
          ],
          backgroundColor: "#8AB4F8",
          stack: "kg",
        },
        {
          label: "Air - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTKM.Air
              : "0",
          ],
          backgroundColor: "#DCBAF7",
          stack: "tonne",
        },
        {
          label: "Land - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTKM.Land
              : "0",
          ],
          backgroundColor: "#FAC21B",
          stack: "kg",
        },
        {
          label: "Water - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTKM.Sea
              : "0",
          ],
          backgroundColor: "#ADC9F5",
          stack: "tonne",
        },
        {
          label: "Rail - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTKM.Rail
              : "0",
          ],
          backgroundColor: "#FA7B57",
          stack: "kg",
        },
        {
          label: "Land - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTKM.Land
              : "0",
          ],
          backgroundColor: "#F6D87E",
          stack: "tonne",
        },
        {
          label: "Rail - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTKM.Rail
              : "0",
          ],
          backgroundColor: "#FAC9AF",
          stack: "tonne",
        },
      ],
    };
    const barChartDataTeu = {
      labels: [""],

      datasets: [
        {
          label: "Air - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTEU.Air
              : "0",
          ],
          backgroundColor: "#B563F7",
          stack: "kg",
        },
        {
          label: "Water - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTEU.Sea
              : "0",
          ],
          backgroundColor: "#8AB4F8",
          stack: "kg",
        },
        {
          label: "Air - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTEU.Air
              : "0",
          ],
          backgroundColor: "#DCBAF7",
          stack: "tonne",
        },
        {
          label: "Land - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTEU.Land
              : "0",
          ],
          backgroundColor: "#FAC21B",
          stack: "kg",
        },
        {
          label: "Water - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTEU.Sea
              : "0",
          ],
          backgroundColor: "#ADC9F5",
          stack: "tonne",
        },
        {
          label: "Rail - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerTEU.Rail
              : "0",
          ],
          backgroundColor: "#FA7B57",
          stack: "kg",
        },
        {
          label: "Land - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTEU.Land
              : "0",
          ],
          backgroundColor: "#F6D87E",
          stack: "tonne",
        },
        {
          label: "Rail - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerTEU.Rail
              : "0",
          ],
          backgroundColor: "#FAC9AF",
          stack: "tonne",
        },
      ],
    };
    const barChartDataKg = {
      labels: [""],

      datasets: [
        {
          label: "Air - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerKg.Air
              : "0",
          ],
          backgroundColor: "#B563F7",
          stack: "kg",
        },
        {
          label: "Water - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerKg.Sea
              : "0",
          ],
          backgroundColor: "#8AB4F8",
          stack: "kg",
        },
        {
          label: "Air - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerKg.Air
              : "0",
          ],
          backgroundColor: "#DCBAF7",
          stack: "tonne",
        },
        {
          label: "Land - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerKg.Land
              : "0",
          ],
          backgroundColor: "#FAC21B",
          stack: "kg",
        },
        {
          label: "Water - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerKg.Sea
              : "0",
          ],
          backgroundColor: "#ADC9F5",
          stack: "tonne",
        },
        {
          label: "Rail - Dynamic",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.dynamicEmissionsPerModePerKg.Rail
              : "0",
          ],
          backgroundColor: "#FA7B57",
          stack: "kg",
        },
        {
          label: "Land - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerKg.Land
              : "0",
          ],
          backgroundColor: "#F6D87E",
          stack: "tonne",
        },
        {
          label: "Rail - Static",
          data: [
            emissionStats.emissions
              ? emissionStats.emissions.staticEmissionsPerModePerKg.Rail
              : "0",
          ],
          backgroundColor: "#FAC9AF",
          stack: "tonne",
        },
      ],
    };
    return (
      <div className="report-div">
        <table
          className="report-table"
          style={{
            margin: "0 auto",
            width: "1200px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <ReportHeader
            shipment={shipment}
            onShareButtonClick={this.togglePopup} // Pass handler to child
            title="Shipment Report"
            isnotmaintitle={true}
          />
          <select
            value={this.state.selectedOption}
            onChange={this.handleSelectChange}
            className="dropdown-select"
            style={{
              // marginRight: "10px",
              marginTop: "5px",
              right: "0",
              position: "absolute",
            }}
          >
            <option value="Select" disabled>
              Select
            </option>
            <option value="Shipment only">Shipment only</option>
            <option value="Emissions only">Emissions only</option>
            <option value="Both">Both</option>
          </select>

          {/* Render the rest of the content here */}
          <ReportFakeTable>
            <div
              className="report-content report-content-1"
              style={{
                display:
                  this.state.selectedOption === "Emissions only" ? "none" : "",
              }}
            >
              <div className="report-content-left report-content-left--border">
                <ReportStatus shipment={shipment} terrains={terrains} />
                <ReportQR shipment={shipment} />
              </div>
              <div className="report-content-right">
                <RoutesMap
                  settings={{
                    trackers: [config.identifier],
                    sources: ["TRACKER", "VESSEL", "TOWER", "WIFI", "N/A"],
                    colors: false,
                    routes: true,
                    events: true,
                  }}
                  routes={this.state.shipment.transports.map(
                    (transport: any) => ({
                      measurements: transport.measurements,
                      events: transport.events,
                      tracker: transport.tracker,
                      shipment: this.state.shipment,
                      transport: transport,
                      vessel: this.state.shipment.vessel,
                    })
                  )}
                />
              </div>
            </div>
            <div
              style={{
                display:
                  this.state.selectedOption === "Emissions only" ? "none" : "",
              }}
            >
              <ReportHistory shipment={shipment} locations={locations} />
              <ReportEvents shipment={shipment} config={config} />
            </div>
            <div
              className="report-unbreakable"
              style={{
                display:
                  this.state.selectedOption === "Shipment only" ? "none" : "",
              }}
            >
              {/* <h3 className="report-content-header">Time use (h)</h3> */}
              <ReportHeader
                shipment={shipment}
                onShareButtonClick={this.togglePopup} // Pass handler to child
                title="Emissions Report"
                isnotmaintitle={false}
              />
              <div className="report-content report-content-2">
                <div
                  className="report-content-left"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* <BarTerrainTime terrains={terrains} /> */}

                  <div
                    style={{
                      width: "300px",
                      marginRight: "20px",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div>
                      <ChartWrapper
                        type={"doughnut"}
                        data={doughnutChartData}
                        options={donughnutChartOptions}
                        plugins={[doughtnutLabel]}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "190px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#3D9AA1",
                          fontWeight: "bold",
                        }}
                      >
                        GLEC DYNAMIC
                      </span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#FAC21B",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>LAND</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#8AB4F8",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>SEA</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#B563F7",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>AIR</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#FA7B57",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>RAIL</span>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#8B8B8B",
                          fontWeight: "bold",
                        }}
                      >
                        GLEC STATIC
                      </span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#F6D87E",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>LAND</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#ADC9F5",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>SEA</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#DCBAF7",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>AIR</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#FAC9AF",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>RAIL</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="report-content-right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* <PieTerrainsTime terrains={terrains} /> */}
                  <div
                    className=" expanded-line-chart shipment-card__bar full_width"
                    style={{
                      // backgroundColor: "#e8eeef",
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "space-evenly",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        // backgroundColor: "#e8eeef",
                        marginRight: "20px",
                      }}
                    >
                      {" "}
                      <ChartWrapper
                        type={"bar"}
                        data={barChartDataTonne}
                        options={barChartOptionsTonne}
                        plugins={undefined}
                      />
                    </div>
                    <div
                      style={{
                        // backgroundColor: "#e8eeef",
                        marginRight: "20px",
                      }}
                    >
                      {" "}
                      <ChartWrapper
                        type={"bar"}
                        data={barChartDataTeu}
                        options={barChartOptionsTeu}
                        plugins={undefined}
                      />
                    </div>
                    <div
                      style={{
                        // backgroundColor: "#e8eeef",
                        marginRight: "10px",
                      }}
                    >
                      {" "}
                      <ChartWrapper
                        type={"bar"}
                        data={barChartDataKg}
                        options={barChartOptionsKg}
                        plugins={undefined}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      marginTop: "50px",
                      marginLeft: "50px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#3D9AA1",
                          fontWeight: "bold",
                        }}
                      >
                        GLEC DYNAMIC
                      </span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#FAC21B",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>LAND</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#8AB4F8",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>SEA</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#B563F7",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>AIR</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#FA7B57",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>RAIL</span>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#8B8B8B",
                          fontWeight: "bold",
                        }}
                      >
                        GLEC STATIC
                      </span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#F6D87E",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>LAND</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#ADC9F5",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>SEA</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#DCBAF7",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>AIR</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: "#FAC9AF",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                              marginLeft: "5px", // Adjust as needed for spacing
                            }}
                          ></div>
                          <span>RAIL</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="report-unbreakable"
              style={{
                display:
                  this.state.selectedOption === "Shipment only"
                    ? "none"
                    : "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <div
                style={{
                  width: "39%",
                  padding: "20px",
                  textAlign: "justify",
                  background: "#e8eeef",
                  display: "flex",
                  alignItems: "center",
                  height: "240px",
                  borderRadius: "10px",
                  // backgroundColor: "white",
                  // boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px",
                }}
              >
                This analysis uses the Global Logistics Emissions Council (GLEC)
                framework to evaluate carbon emissions for the {shipment.name}{" "}
                shipment. It compares emissions calculated with default GLEC v3
                values (Static GLEC) against dynamic calculations using carbon
                emission models based on real-time data from various
                transportation modes (Dynamic GLEC), highlighting how these
                factors impact the overall carbon footprint.
              </div>

              <div
                style={{
                  // backgroundColor: "#e8eeef",
                  display: "flex",
                  width: "61%",
                  height: "100%",
                  justifyContent: "space-evenly",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                {" "}
                <ChartWrapper
                  type={"line"}
                  data={lineChartData}
                  options={lineChartOptions}
                  plugins={undefined}
                />
              </div>
            </div>
          </ReportFakeTable>

          <ReportFooter shipment={shipment} />
        </table>
      </div>
    );
  };

  render() {
    return (
      <Page className="shlk-shipment-report">
        <ContentLoader
          loading={this.state.loading}
          content={this.renderContent}
        />
        {this.state.showPopup && (
          <>
            <div
              className="popup-container shlk-cp-card            "
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "70%",
                backgroundColor: "var(--shlk-transparent)",
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                boxSizing: "border-box",
                transition: "transform 0.3s ease-in-out",
                transform: this.state.showPopup
                  ? "translateY(0)"
                  : "translateY(100%)",
                cursor: this.state.cursorloading ? "progress" : "default",
              }}
            >
              <div className="shlk-cp-header">
                <TrackableElement
                  className="shlk-cp-close-wrapper "
                  category="Card"
                  action="Close"
                  onClick={this.togglePopup}
                >
                  <Icon
                    className="shlk-cp-close"
                    size={40}
                    name="expand_more"
                  />
                </TrackableElement>
              </div>
              <div className="cc-header">
                <ReactSVG
                  className="cc-header-icon"
                  src={`/assets/svg/people.svg`}
                />
                <h1>Send this Shipment Report</h1>
                {this.props.children}
              </div>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="add-inputs" style={{ width: "100%" }}>
                    <Input
                      type="text"
                      name="name"
                      label="Name: "
                      onChange={this.handleInputChange}
                    />
                    <Input
                      type="email"
                      name="email"
                      label="Email: "
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div
                  className="add-buttons"
                  style={{
                    cursor: this.state.cursorloading ? "progress" : "default",
                  }}
                >
                  <Button category="Invite" action="Cancel">
                    Cancel
                  </Button>

                  <Button
                    category="Invite"
                    action="Send"
                    onClick={this.handleSendReport}
                    disabled={!this.state.email}
                  >
                    {this.state.cursorloading ? "Sending..." : "Send Email"}
                  </Button>
                </div>
              </form>
            </div>
          </>
        )}
      </Page>
    );
  }
}
