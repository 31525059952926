import React from 'react';
import './styles.scss';

interface MultimodalMetricProps {
  values: number | { air: number; sea: number; driving: number };
  units: string[]; // Array of units
  threshold: number; // Threshold value
  title: string;
}

const MultimodalMetric: React.FC<MultimodalMetricProps> = ({ values, units, threshold, title }) => {
  const icons = {
    air: 'airplanemode_active',
    sea: 'directions_boat',
    land: 'local_shipping'
  };

 
  const formattedValues =
  typeof values === 'object'
    ? {
      air: values.air && values.air !== -1 ? values.air.toFixed(3) : "NA",
      sea: values.sea && values.sea !== -1 ? values.sea.toFixed(3) : "NA",
      driving: values.driving && values.driving !== -1 ? values.driving.toFixed(3) : "NA"
      }
    : { 

      air: "NA",
      sea: "NA",
      driving: values.toFixed(3)
    };

  return (
    <div className="multimodal-metric">
      <h2>{title}</h2>
      <div className="metric-section">
        {formattedValues.air && (
          <span className="metric-item">
            <span className="material-icons">{icons.air}</span>
            <span>{formattedValues.air}</span>
            {formattedValues.air !== "NA" && <span>{Number(formattedValues.air) > threshold ? units[1] : units[0]}</span>}
          </span>
        )}
        {formattedValues.sea && (
          <span className="metric-item">
            <span className="material-icons">{icons.sea}</span>
            <span>{formattedValues.sea}</span>
            {formattedValues.sea !== "NA" && <span>{Number(formattedValues.sea) > threshold ? units[1] : units[0]}</span>}
          </span>
        )}
        {formattedValues.driving && (
          <span className="metric-item">
            <span className="material-icons">{icons.land}</span>
            <span>{formattedValues.driving}</span>
            {formattedValues.driving !== "NA" && <span>{Number(formattedValues.driving) > threshold ? units[1] : units[0]}</span>}
          </span>
        )}
      </div>
    </div>
  );
};

export default MultimodalMetric;