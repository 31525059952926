import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import './styles.scss';

export type Tag = {
    _id?: string;
    name: string;
    styles: { color: string };
    className?: string;
}

type Props = {
    tags: Tag[];
    noLink?: boolean;
    onRemove?: Function | null;
}

export default class TagsList extends Component<Props> {
    renderTag(tag: Tag) {
        const key = tag._id || tag.name;

        return (
            <div
                key={key}
                className={`tags-tag ${tag.className || ''}`}
                style={{ backgroundColor: tag.styles.color , marginLeft:"0px"}}
            >
            { this.props.onRemove &&
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <Icon className="tag-icon" name="close" size={20} onClick={(event: any) => this.props.onRemove!(tag, event)} />
            }
            { this.props.noLink ? tag.name : <Link key={key} to={`/tag/${tag._id}`}>{tag.name}</Link> }
        </div>);
    }
    render() {
        return this.props.tags.length ? <div className="tags-container">{ this.props.tags.map((tag: Tag) => this.renderTag(tag)) }</div> : null;
    }
}
