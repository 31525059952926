export const setCookieData = (name: string, value: string, expires?: number) => {
    let expiration = '';

    if(expires && Number.isInteger(expires)) {
        const date = new Date();

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        date.setTime(date.getTime() + expires!);
        expiration = `expires=${date.toUTCString()};`;
    }

    document.cookie = `${name}=${value};${expiration}`;
};

export const getCookieData = (name: string) => {
    const cookie = document.cookie.split('; ').find(cookie => cookie.startsWith(`${name}=`));

    return cookie ? cookie.split('=')[1] : null;
};

export const removeCookieData = (name: string) => {
    setCookieData(name, '', 0);
};