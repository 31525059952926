import React, { Component } from 'react';
import Title from 'components/Title';
import ContentLoader from 'components/Loading/ContentLoader';
import AddPermissions from 'components/Permissions/Add';
import { getRoles } from 'services/users';
import { inviteUser } from 'services/company';
import { CompanyContext } from '../context';
import './styles.scss';

interface State {
  loading: boolean;
  error: string;
  roles: Array<{
    name: string;
    permissions: Array<string>;
  }>;
}

export default class Add extends Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      loading: false,
      roles: [],
      error: ""
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    getRoles()
      .then((roles) => this.setState({ loading: false, roles }));
  }

  sendInvite = (name: string, email: string, roles: Array<string>) => {
    this.setState({ loading: true, error: "" });

    console.log('Trying to invite user: ' + name + ' with email: ' + email + ' and roles: ' + roles.join(', ') + '.');

    inviteUser({ name, email, roles }, { handledException: true })
      .then(() => {
        window.alert(`The user ${email} has been added to ${this.context.company.name}`);
        this.setState({ loading: false });
        this.context.changeLayout("VIEW");
      })
      .catch((error: any) => {
        console.log("Error creating user: " + error);
        this.setState({ error: error.response.data, loading: false});
      });
  }

  render() {
    return (<div className='company-add'>
      <Title text={"Invite user to " + this.context.company.name} icon="people" />

      <ContentLoader loading={this.state.loading} content={() => (
        <AddPermissions roles={this.state.roles}
                          cancel={() => this.context.changeLayout("VIEW")}
                          update={this.sendInvite}/>
      )} />

      {
          (this.state.error) && (
            <div className='add-error'>
              <h3>ERROR: {this.state.error}</h3>
            </div>
          )
      }
    </div>
    );
  }
}

Add.contextType = CompanyContext;