import React, { Component } from 'react';
import { round } from 'utils/math';
import './styles.scss';

interface Props {
  shipment: any;
  terrains: any;
}

export default class ReportStatus extends Component<Props> {
  render() {
    const { maritime, road, idle } = this.props.terrains;

    return (
      <div className='report-status'>
        <div>
          <h4>Status Summary</h4>
          <p>Tracked: { round(maritime.time + road.time + idle.time)} hours</p>
          <p>Maritime: { round(maritime.time) } hours</p>
          <p>Road: { round(road.time) } hours</p>
          <p>Idle: { round(idle.time) } hours</p>
        </div>
        <div>
          <h4>Tracked Distance</h4>
          <p>Total: { round(maritime.km + road.km)} km</p>
          <p>Maritime: { round(maritime.km) } km</p>
          <p>Road: { round(road.km) } km</p>
        </div>
      </div>
    );
  }
}