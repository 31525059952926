import React, { Component } from 'react';
import Button from 'components/Inputs/Button';

import './styles.scss';

interface Props {
    category: string;
    action: string;
    label?: string;
    onClick?: any;
    to?: string;
    icon?: string;
    text: string;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

export default class BoxButton extends Component<Props> {
    render() {
        return <Button { ...this.props }
                    iconSize={20}
                    className="box-btn">

            <span className="box-btn-txt">
                {this.props.text}
            </span>

        </Button>;
    }
}
