import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import ContentPopup from 'components/ContentPopup';
import MapSettings from 'components/Shipment/MapSettings';
import Raw from 'components/Raw';
import Page from 'components/ControlBars/Page';
import ContentLoader from 'components/Loading/ContentLoader';
import Box from 'components/Box';
import RoutesMap from 'components/GoogleMap/RoutesMap';
import { getTracker, getTrackerHistory } from 'services/trackers';
import Info from './Info';
import Shipments from './Shipments';
import Settings from './Settings';
import { TrackerContext } from './context';
import RoutesMap3 from 'components/Trackable/Route3';

const HISTORY_LIMIT = 27;

interface MatchProps {
  id: string;
}
type Props = RouteComponentProps<MatchProps>

type State = {
  loading: boolean;
  updating: boolean;
  tracker: any;
  events: Array<any>;
  measurements: Array<any>;
  updateMeasurements: any;
  updateEvents: any;
  updateTracker: any;
  mapSettings: any;
};

export default class Tracker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      updating: false,
      tracker: null,
      events: [],
      updateEvents: (events: Array<any>) => {
        this.setState({ events });
      },
      measurements: [],
      updateMeasurements: (measurements: Array<any>) => {
        this.setState({ measurements });
      },
      updateTracker: (tracker: any) => {
        this.setState({ tracker });
      },
      mapSettings: {
        trackers: [],
        tower: false,
        routes: true,
        events: true,
        sources: ['TRACKER', 'VESSEL', 'TOWER', 'WIFI', 'N/A'],
        popup: {
          open: null,
          click: this.openPopup.bind(this)
        }
      }
    };
  }

  async componentDidMount() {
    const identifier = this.props.match.params.id;

    Promise.all([
        getTracker({ identifier }),
        getTrackerHistory({ identifier, limit: HISTORY_LIMIT })
      ])
      .then(([{ tracker }, { measurements, events }]: any) =>
        this.setState({
          tracker,
          events,
          measurements: measurements,
          loading: false,
          mapSettings: Object.assign(this.state.mapSettings, { trackers: [tracker.identifier] })
        })
      );
  }

  onHistoryUpdate = ({ start, end }: { start?: string, end?: string }) => {
    this.setState({ updating: true });

    getTrackerHistory({ identifier: this.props.match.params.id, start, end })
    .then(({ measurements, events }: any) => this.setState({ measurements, events, updating: false }));
  }

  onMapSettingsUpdate = (mapSettings: any) => this.setState({ mapSettings })

  openPopup = (state: string, element: any, type: string, center?: any) => {
    const mapSettings = Object.assign({}, this.state.mapSettings);

    if(state === "open") {
      mapSettings.popup.open = { type, id: element._id || element, center };
    } else {
      mapSettings.popup.open = null;
    }

    this.setState({ mapSettings });
  }

  getOpen = (value: string) => {
    const measurement: any = this.state.measurements.find((meas: any) => meas._id === value);

    if(measurement) {
      this.openPopup("open", value, measurement.source, measurement.location);
    }
  }

  render() {
    return (
      <Page className="shlk-trackers" type="map">
        <ContentLoader loading={this.state.loading} content={() =>
          <TrackerContext.Provider value={this.state}>
            <Box
              title={
                this.state.tracker.name.length > 20
                  ? `${this.state.tracker.name.substring(0, 20)}...`
                  : this.state.tracker.name
              }
              menu={[{
                type: "info",
                render: () => <Info onHistoryUpdate={this.onHistoryUpdate}/>,
                icon: "info-outline"
              },{
                type: "settings",
                render: () => <Settings />,
                icon: "settings"
              },{
                type: "Shipments",
                render: () => <Shipments />,
                icon: "route"
              }, {
                type: "MapSettings",
                render: () => <MapSettings events settings={this.state.mapSettings} onChange={this.onMapSettingsUpdate} />,
                icon: "map"
              }]
            }
            />

            <RoutesMap3
              updating={this.state.updating}
              settings={this.state.mapSettings}
              routes={[{
                tracker: this.state.tracker,
                measurements: this.state.measurements,
                events: this.state.events
              }]}
            />

            <ContentPopup hash="raw" content={(close: any) =>
              <Raw info={{ measurements: this.state.measurements, tracker: this.state.tracker }}
                close={(meas: any) => {
                  meas && this.getOpen(meas._id);
                  close();
                }
              }/>
            }/>
          </TrackerContext.Provider>
        } />
      </Page>
    );
  }
}

Tracker.contextType = TrackerContext;
