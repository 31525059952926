const intersects = (a: number, b: number, c: number, d: number, p: number, q: number, r: number, s: number) => {
  let gamma, lambda;
  const det = (c - a) * (s - q) - (r - p) * (d - b);
  if (det === 0) {
    return false;
  } else {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
    return (0 <= lambda && lambda <= 1) && (0 <= gamma && gamma <= 1);
  }
};

const intersectsWithBounds = (origin: any, dest: any, southWest: any, northEast: any) => {
  return isInMap(origin, southWest, northEast) ||
          isInMap(dest, southWest, northEast) ||
          intersects(origin.lat, origin.lng, dest.lat, dest.lng, southWest.lat(), southWest.lng(), southWest.lat(), northEast.lng()) ||
          intersects(origin.lat, origin.lng, dest.lat, dest.lng, southWest.lat(), southWest.lng(), northEast.lat(), southWest.lng()) ||
          intersects(origin.lat, origin.lng, dest.lat, dest.lng, northEast.lat(), northEast.lng(), southWest.lat(), northEast.lng()) ||
          intersects(origin.lat, origin.lng, dest.lat, dest.lng, northEast.lat(), northEast.lng(), northEast.lat(), southWest.lng());
};

const isInMap = (location: any, southWest: any, northEast: any) => {
  return location.lat >= southWest.lat() && location.lat <= northEast.lat() &&
          location.lng >= southWest.lng() && location.lng <= northEast.lng();
};

export function metersToPixels(meters: number, lat: number, zoom: number) {
  const metersPerPx =  1 / (156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom + 1));
  return meters * metersPerPx;
}

export function intersectsWithMap(map: any, origin: any, dest: any) {
  const bounds = map.getBounds();
  const southWest = bounds.getSouthWest();
  const northEast = bounds.getNorthEast();

  if(!origin && !dest) return false;
  else if(!origin) return isInMap(dest.location, southWest, northEast);
  else if(!dest) return isInMap(origin.location, southWest, northEast);
  else return intersectsWithBounds(origin.location, dest.location, southWest, northEast);
}