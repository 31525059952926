import React, { Component } from 'react';

import './styles.scss';

interface Props {
  children: React.ReactElement<any>[];
}

export default class ReportFakeTable extends Component<Props> {
  render() {
    return (
      <tbody className='fake-tbody' style={{marginTop:"20px"}}>
        { this.props.children.map((child: any, index: number) => {
          return <tr className='fake-tr' key={`FakeTableRow${index}`}>
            <td className='fake-td'>
              { child }
            </td>
          </tr>;
        }) }
      </tbody>
    );
  }
}