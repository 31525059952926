import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AuthHome from './Auth';
import PublicHome from './Public';

import { hasPermission } from 'utils/auth';

interface State {
  loading: boolean;
}

export default class Home extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = { loading: false };
  }

  render() {
    return hasPermission("VIEW_HOME") ? <AuthHome /> : <PublicHome />;
  }
}