import React, { Component } from "react";
import GoogleMapReact, { Coords } from "google-map-react";
import LocationMarker from "../Markers/Location";

interface AbstractMapProps extends GoogleMapReact.Props {
  origin?: any; // Define origin prop
  destination?: any; // Define destination prop
}

export default class AbstractMap extends Component<AbstractMapProps> {
  render() {
    const { children, origin, destination, ...restProps } = this.props;
    console.log(origin? origin.location.lat:"", destination?destination.location.lat:"", "od");
    return (
      <GoogleMapReact
        {...restProps}
        bootstrapURLKeys={{
          key: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
          id: "__googleMapsScriptId",
          version: "weekly",
          libraries: ["places"],
        }}
        defaultCenter={{ lat: 59.95, lng: 30.33 }} // Provide a default center if needed
        defaultZoom={5} // Provide a default zoom level if needed
      >
        {origin && <LocationMarker type="origin" lat={origin? origin.location.lat:""} lng={origin? origin.location.lng:""} />}
        {destination && (
          <LocationMarker type="destination" lat={destination?destination.location.lat:""} lng={destination?destination.location.lng:""} />
        )}

        {children}
      </GoogleMapReact>
    );
  }
}
