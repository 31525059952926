import React, { Component } from 'react';
import TagsList from 'components/Tags/List';
import BoxButton from 'components/Box/Button';
import Datalist from 'components/Inputs/Datalist';
import { addTagHandler } from 'utils/tags';

import './styles.scss';

interface Props {
    actions: boolean;
    all: any[];
    selected: any[];
    onUpdate: Function;
}

interface State {
    current: any[];
}

export default class TagsBox extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { current: [...this.props.selected] };
    }

    addTag = (tags: any) => this.setState({ current: tags })

    removeTag = (tag: any) => {
        const tags = this.state.current.filter(({ _id }: { _id: string }) => _id !== tag._id);

        this.setState({ current: tags });
    }

    isThereAnUpdate = () => {
        return JSON.stringify(this.state.current) !== JSON.stringify(this.props.selected);
    }

    render() {
        const tagsToUse = this.props.all.filter((tag: any) => !this.state.current.some(({ _id }: { _id: string }) => tag._id === _id));
        const removeTag = this.props.actions ? this.removeTag : null;

        return (<div className="shipment-tags bc-wrapper">
            <div className="shipment-tags__title">
                <h2 className="box-title">Tags</h2>
            </div>
            <div className="shipment-tags__data bc-stretch">
                {
                    this.state.current.length ?
                        <TagsList tags={this.state.current} onRemove={removeTag}/> :
                        <p className='shipment-tags-empty'><i>No tags selected</i></p>
                }
            </div>
            { this.props.actions && (
                <div className="shipment-tags__action">
                    <Datalist
                        disabled={!tagsToUse.length}
                        datalist={tagsToUse}
                        name="tags"
                        placeholder={tagsToUse.length ? 'Select tag' : 'No available options'}
                        onSelect={(event: any) => addTagHandler(event, tagsToUse, this.state.current, this.addTag)
                    }/>

                    <BoxButton
                        category="ShipmentTags"
                        action="Click Update Tags"
                        disabled={!this.isThereAnUpdate()}
                        onClick={() => this.props.onUpdate(this.state.current)}
                        icon="save"
                        text="Save"
                    />
                </div>
                )
            }
        </div>);
    }
}