export const format = (date: string) => {
    const formated = date.indexOf(":") >= 0 ? new Date(date) : new Date(parseInt(date));

    return formated.toLocaleTimeString() + " " + formated.toLocaleDateString();
};

export const calculateLastUpdated = (time: any) => {
    const date: any = new Date(time);
    const now: any = new Date();

    const diffTime = Math.abs(date - now);

    const diffMonths = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));
    if(diffMonths > 0) {
        return date.toLocaleDateString();
    }

    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    if(diffDays > 0) {
        return `${diffDays} days ago`;
    }

    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    if(diffHours > 0) {
        return `${diffHours} hours ago`;
    }

    const diffMinutes = Math.floor(diffTime / (1000 * 60));
    if(diffMinutes > 0) {
        return `${diffMinutes} minutes ago`;
    }

    const diffSecs = Math.floor(diffTime / 1000);
    return `${diffSecs} seconds ago`;
};

export const getDatetimeLocal = (date : Date) => {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * -60 * 1000);

    return newDate.toISOString().slice(0, 19);
};

export const getUTCFormat = (date: Date) => {
    return date.toISOString().substring(0,19);
};