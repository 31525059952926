import React, { Component } from 'react';
import Spinner from './../Spinner';

type Props = {
    loading: boolean;
    content: any;
}

export default class ContentLoader extends Component<Props> {
    render() {
        if (this.props.loading) {
            return <Spinner />;
        }

        return this.props.content();
    }
}