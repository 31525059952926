import React from 'react';
import VesselMarkerPopup from './Popup';
import { MeasMarkerProps } from '../AbstractMarker';
import DotMarker from '../Dot';

interface VesselProps extends MeasMarkerProps {
  vessel: any;
}

export default class VesselMarker extends DotMarker<VesselProps> {
  name = 'VesselMarker';

  renderPopupContent = () => {
    return <VesselMarkerPopup measurement={this.props.measurement} vessel={this.props.vessel} index={this.props.index}/>;
  }
}