import React, { Component, ReactNode } from 'react';
import { calculateDevice } from 'utils/device';

type State = {
  device: string;
}

type Props = {
  children: () => ReactNode;
}

export default class DeviceHandler extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
        device: calculateDevice()
    };
  }

  updateDevice = () => {
    const device = calculateDevice();

    if(device !== this.state.device) {
        this.setState({ device });
    }
  }

  componentDidMount = () => {
    window.onresize = this.updateDevice;
  }

  render() {
    return <div className={`shlk-${this.state.device}`}>{ this.props.children() }</div>;
  }
}