import { shlkAPI, Options } from 'services/api';

export const login = async (data: { email: string; password: string }, options: Options) => {
  return shlkAPI('login', data, false, options);
};



export const send_otp = async (data: { emails: string;  }) => {
  return shlkAPI('send_otp', data, true,);
};

export const verify_otp = async (data: { emails: string; otp:string }) => {
  return shlkAPI('verify_otp', data, true,);
};

export const reset_password = async (data: { emails: string; newPassword:string , token:any}) => {
  return shlkAPI('reset_password', data, true,);
};