import React from 'react';
import './DrivingCard.scss';

interface DrivingCardProps {
  origin: string; // Origin location
  destination: string; // Destination location
  distance: number; // Distance between origin and destination
  emissions: number; // Emissions produced by the journey
}

const DrivingCard: React.FC<DrivingCardProps> = ({ origin, destination, distance, emissions }) => {
  console.log('DrivingCardProps:', { origin, destination, distance, emissions });
  return (
    <div className="driving-card">
        <h2>Driving Journey</h2>
      <div className="node-row bold-text">Origin: {origin}</div>
      <div className="distance-row">Driving distance: <span className="bold-text">{distance.toFixed(2)} km, {emissions.toFixed(2)} kg CO<sub>2</sub></span> </div>
      <div className="node-row bold-text">Destination: {destination}</div>
    </div>
  );
};

export default DrivingCard;
