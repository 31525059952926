/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export const ShipmentContext: any = React.createContext({
    permissions: [],

    shipment: null,
    updateShipment: () => {},

    tags: [],
    updateTags(tags: never[]) {
        this.tags = tags;
    },

    mapSettings: {},
    updateMapSettings: () => {},

    hardRefresh: () => {}
});

