import React, { Component } from 'react';
import './styles.scss';

interface Props {
  size: number;
  name: string;
  onClick?: Function;
  className?: string;
  type?: string;
  title?: string;
}

export default class Icon extends Component<Props> {
  render() {
    let className = 'material-icons';
    if(this.props.className) className += ` ${this.props.className}`;
    if(this.props.type) className += ` material-icons-${this.props.type}`;
    if(this.props.onClick) className += ' interactable';

    return (
      <span
        className={className}
        title={this.props.title}
        style={{
          fontSize: this.props.size
        }}
        onClick={(event: any) => this.props.onClick && this.props.onClick(event)}
      >
          { this.props.name }
      </span>
    );
  }
}