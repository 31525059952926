import { sub } from "date-fns";
import React, { Component, RefObject } from "react";

/**
 * Props for the ShipmentInput component.
 */
interface Props {
  shipment: any;
  field: string;
  label?: string;
  sublevel?: string;
  sub_sub_level?: string; // New property
  type?: "textarea" | "text";
  onChange: any;
  validator?: Function;
  inputRef?: RefObject<HTMLInputElement>;
  disabled?: any;
}

/**
 * Represents the text input component.
 */
export default class ShipmentInput extends Component<Props> {
  render() {
    const {
      shipment,
      field,
      label,
      sublevel,
      sub_sub_level,
      type,
      validator,
      inputRef,
      disabled,
    } = this.props;

    // Updated value fetching logic
    let value = shipment[field] || "";
    if (sublevel) {
      value = shipment[field][sublevel] || "";
      if (sub_sub_level) {
        value = shipment[field][sublevel][sub_sub_level] || "";
      }
    }

    let validation = { isValid: true, error: null };
    if (validator) {
      validation = validator(value);
    }

    // Updated ID generation
    const inputId = `input-${field}-${sublevel || "main"}-${
      sub_sub_level || ""
    }`;

    return (
      <div
        className={`new-input new-input--${field} ${sublevel || ""} ${
          sub_sub_level || ""
        } ${validation.isValid ? "" : "error"}`}
      >
        <label className="new-input-label" htmlFor={inputId}>
          {label || field}
        </label>
        {type === "textarea" ? (
          <textarea
            id={inputId}
            name="textarea"
            className="new-input-value"
            value={value}
            onChange={(event) => {
              this.props.onChange(
                field,
                event.currentTarget.value,
                sublevel,
                sub_sub_level
              );
            }}
          ></textarea>
        ) : (
          <input
            id={inputId}
            ref={inputRef}
            className="new-input-value"
            disabled={disabled}
            style={{ cursor: disabled ? "not-allowed" : "" }}
            type="text"
            value={value}
            onChange={(event) => {
              this.props.onChange(
                field,
                event.currentTarget.value,
                sublevel,
                sub_sub_level
              );
            }}
          />
        )}
        {validation.error && (
          <div className="new-input-validation">{validation.error}</div>
        )}
      </div>
    );
  }
}
