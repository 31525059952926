import React, { Component } from 'react';
import BoxTitle from 'components/Box/Title';
import Vessel from 'components/Vessel';
import FormInfo from 'components/Inputs/Info';
import Button from 'components/Inputs/Button';
import VesselPopup from 'components/GoogleMap/Popups/Fleet';
import Input from 'components/Inputs/Input/Default';
import ContentLoader from 'components/Loading/ContentLoader';
import TrackableElement from 'components/Trackable/Element';
import { MapSettings } from 'components/GoogleMap/VesselMap';
import BoxButton from 'components/Box/Button';
import BoxOption from 'components/Box/Option';
import { getFleet, updateFleet } from 'services/fleet/';
import './styles.scss';

const MAX_VESSELS = 15;

interface Props {
    updateMapSettings: Function;
    mapSettings: MapSettings;
}

type State = {
  loading: boolean;
  inputVessel: string;
};

export default class AllFleet extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading : true,
      inputVessel: ''
    };
  }

  async componentDidMount() {
    getFleet()
      .then((fleet: any) => {
        const vessels = fleet ? fleet.vessels : [];
        vessels.forEach((vessel: any) => (vessel.key = `${vessel.mmsi}`));
        this.props.updateMapSettings({ ...this.props.mapSettings, vessels, popup: this.vesselPopup });
        this.setState({ loading: false });
      });
  }

  update = (ids: any) => {
    this.setState({ loading: true });

    updateFleet(ids)
        .then((fleet: any) => {
            const vessels = fleet ? fleet.vessels : [];
            vessels.forEach((vessel: any) => (vessel.key = `${vessel.mmsi}`));
            this.props.updateMapSettings({...this.props.mapSettings, vessels });
            this.setState({ loading: false });
        });
  }

  addVesselHandler = (event: any) => {
    event.preventDefault();

    const mmsi = parseInt(this.state.inputVessel, 10);
    const ids = this.props.mapSettings.vessels.map(vessel => vessel.mmsi);

    if (!ids.includes(mmsi)) {
      ids.push(mmsi);
      this.update(ids);
    }

    this.setState({ inputVessel: '' });
  }

  removeVesselHandler = (vessel: any) => {
    if(window.confirm('Do you want to remove the vessel?')) {
      const ids = this.props.mapSettings.vessels.filter(v => v !== vessel).map(v => v.mmsi);

      this.update(ids);
    }
  }

  openVesselHandler = (vessel: any) => {
    this.props.updateMapSettings({
      ...this.props.mapSettings,
      open: vessel.key
    });
  }

  renderVesselList = (vessel: any, index: number) => {
    const isPending = !vessel.name;
    const onClickCallback = isPending ? this.removeVesselHandler : this.openVesselHandler;
    const isVesselOpen = this.props.mapSettings.open === vessel.key;

    return (
        <TrackableElement
          key={vessel.key}
          category="Fleet"
          action={`${isPending ? 'Remove Pending' : 'Open'} Vessel`}
          label={vessel.mmsi}
          onClick={() => onClickCallback(vessel)}
        >
          <BoxOption
              className={isPending ? 'loading' : ''}
              selected={isVesselOpen}
              icon={isPending ? 'close' : ''}
            >
              <Vessel vessel={vessel} index={index}>
            </Vessel>
          </BoxOption>
        </TrackableElement>);
  }

  renderAddForm() {
    return (
      <div className="fleet-form__container">
        <form className="fleet-form__element" onSubmit={ this.addVesselHandler }>
          <Input
            name="mmsi"
            type="number"
            placeholder="MMSI of Vessel"
            value={this.state.inputVessel}
            onChange={({ target }: { target: EventTarget}) => { this.setState({ inputVessel: (target as HTMLInputElement).value }); }}
            icon={
              <FormInfo
                name="mmsi"
                text={<>You can find the <i>mmsi</i> of your vessel
                in &nbsp;<a rel='noopener noreferrer' target='_blank' href='https://www.marinetraffic.com/'>MarineTraffic</a></>}
              ></FormInfo>
            }/>

          <BoxButton
            category="FleetForm"
            action="Add vessel"
            label={this.state.inputVessel}
            type="submit"
            disabled={!this.state.inputVessel}
            text="Add"
          />
        </form>
      </div>
    );
  }

  vesselPopup = (vessel: any) => {
    return (
      <VesselPopup vessel={vessel}>
        <div className="vessel-popup__button" >
          <Button
            category="Fleet"
            action="Click Remove Vessel"
            label={vessel.mmsi}
            styles="alert"
            onClick={this.removeVesselHandler}
            >
            Remove
          </Button>
        </div>
      </VesselPopup>);
  }

  renderContent = () => {
    const [locatedVessels, pendingVessels] = this.props.mapSettings.vessels
      .reduce(([located, pending], vessel) =>
        vessel.name ? [[...located, vessel], pending] : [located, [...pending, vessel]]
      , [[], []]);

      return (<>
          <h5 className="fleet-subtitle"><b>{ this.props.mapSettings.vessels.length }</b> Vessels in your fleet</h5>
          { pendingVessels.length > 0 &&
            <p className="fleet-list__disclaimer">We are retrieving the information for some vessels. This may take time.</p>
          }
          <div className="fleet-list__container bc-stretch">
            { locatedVessels.map(this.renderVesselList) }
            { pendingVessels.map(this.renderVesselList) }
          </div>
          { locatedVessels.length + pendingVessels.length >= MAX_VESSELS ?
            <p className="fleet-form__disclaimer">
              You have achieved the maximum vessels to track. Please contact with <a href="mailto:support@naxxar.uk">support@naxxar.uk</a>
            </p> :
            this.renderAddForm()
          }
        </>);
  }

  render() {
    return (
      <div className="shlk-fleet shlk-fleet-all bc-wrapper">
        <BoxTitle text="Fleet"/>
        <ContentLoader loading={this.state.loading} content={this.renderContent}/>
      </div>
    );
  }
}
