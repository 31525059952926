import React from 'react';
import Icon from 'components/Icon';
import Marker, { MeasMarkerProps } from '../AbstractMarker';
import './styles.scss';

export default class ClusterMarker extends Marker<MeasMarkerProps> {
  name = 'ClusterMarker';

  hasPopup = true;
  renderPopupContent = () => {
    const cluster = this.props.measurement.cluster;

    return (<div className="marker-popup">
                <p className="marker-popup-field icon"><Icon className="marker-popup-icon" name="workspaces" size={12}/><b>GROUP</b></p>
                <p className="marker-popup-field"><b>Amount</b> {cluster.amount}</p>
            </div>);
  }

  renderMarker() {
    return (<span className="shlk-marker marker-cluster"></span>);
  }
}