import React, { useState, useEffect } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import { getTruckFuels, getTruckMake, getTruckModels } from "services/Trucks";

interface LandModeProps {
  landModeData: any; // Adjust this type according to your actual data structure
  onChange: (field: string, value: any) => void;
}

const axleConfigurationEncodingMap: Record<string, number> = {
  "4x2": 1,
  "6x2": 2,
  "6x4": 3,
  "8x4": 4,
};

const LandModeComponent: React.FC<LandModeProps> = ({
  landModeData,
  onChange,
}) => {
  const [fuelOptions, setFuelOptions] = useState<string[]>(["Unknown"]);
  const [makeOptions, setMakeOptions] = useState<string[]>(["Unknown"]);
  const [modelOptions, setModelOptions] = useState<string[]>(["Unknown"]);
  const [selectedMake, setSelectedMake] = useState<string | null>(null);
  const [selectedFuel, setSelectedFuel] = useState<string | null>(null);
  const [selectedAxle, setSelectedAxle] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);

  // useEffect(() => {
  //   console.log("Running land", landModeData);
  //   const fetchFuelOptions = async () => {
  //     try {
  //       const response = await getTruckFuels();
  //       setFuelOptions(["Unknown", ...response.fuels]);
  //     } catch (error) {
  //       console.error("Error fetching fuel options:", error);
  //       setError("Error fetching fuel options.");
  //     }
  //   };

  //   const fetchMakeOptions = async () => {
  //     try {
  //       const response = await getTruckMake();
  //       setMakeOptions(["Unknown", ...response.makes]);
  //     } catch (error) {
  //       console.error("Error fetching make options:", error);
  //       setError("Error fetching make options.");
  //     }
  //   };

  //   fetchFuelOptions();
  //   fetchMakeOptions();

  //   if (
  //     landModeData.AxleConfiguration  &&
  //     landModeData.fuel &&
  //     landModeData.make !== "Unknown"
  //   ) {
  //   }
  // }, []);
  useEffect(() => {
  
    // Fetch fuel options
    const fetchFuelOptions = async () => {
      try {
        const response = await getTruckFuels();
        setFuelOptions(["Unknown", ...response.fuels]);
      } catch (error) {
        console.error("Error fetching fuel options:", error);
        setError("Error fetching fuel options.");
      }
    };
  
    // Fetch make options
    const fetchMakeOptions = async () => {
      try {
        const response = await getTruckMake();
        setMakeOptions(["Unknown", ...response.makes]);
      } catch (error) {
        console.error("Error fetching make options:", error);
        setError("Error fetching make options.");
      }
    };
  
    // Call the functions to fetch initial options
    fetchFuelOptions();
    fetchMakeOptions();
  
    // Fetch models when AxleConfiguration, fuel, and make are available and not "Unknown"
    const fetchModelOptions = async () => {
      if (
        landModeData.AxleConfiguration !== "Unknown" &&
        landModeData.fuel !== "Unknown" &&
        landModeData.make !== "Unknown"
      ) {
        try {
          const response = await getTruckModels({
            make: landModeData.make,
            fuel: landModeData.fuel,
            AxleConfiguration: landModeData.AxleConfiguration,
          });
          setModelOptions(["Unknown", ...response]);
        } catch (error) {
          console.error("Error fetching model options:", error);
          setError("Error fetching model options.");
        }
      }
    };
  
    // Check conditions to fetch models
    if (
      landModeData.AxleConfiguration &&
      landModeData.fuel &&
      landModeData.make !== "Unknown"
    ) {
      fetchModelOptions();
    }
  }, [landModeData]); // Re-run the effect when landModeData changes
  
  useEffect(() => {
    console.log(selectedAxle, selectedMake, selectedFuel, "field values");
    const fetchModelOptions = async () => {
      if (
        selectedMake === "Unknown" ||
        selectedFuel === "Unknown" ||
        selectedAxle === "Unknown"
      ) {
        setModelOptions(["Unknown"]);
      } else if (selectedMake && selectedFuel && selectedAxle !== null) {
        try {
          const response = await getTruckModels({
            make: selectedMake,
            fuel: selectedFuel,
            AxleConfiguration: selectedAxle,
          });
          setModelOptions(["Unknown", ...response]);
        } catch (error) {
          console.error("Error fetching model options:", error);
          setError("Error fetching model options.");
        }
      }
    };

    fetchModelOptions();
  }, [selectedMake, selectedFuel, selectedAxle]);

  const handleMakeChange = (field: string, value: any) => {
    setSelectedMake(value);
    if (value === "Unknown") {
      setModelOptions(["Unknown"]);
    }
    onChange(field, value);
  };

  const handleFuelChange = (field: string, value: any) => {
    setSelectedFuel(value);
    if (value === "Unknown") {
      setModelOptions(["Unknown"]);
    }
    onChange(field, value);
  };

  const handleAxleChange = (field: string, value: any) => {
    const encodedValue = axleConfigurationEncodingMap[value] || "Unknown";
    setSelectedAxle(encodedValue);
    if (encodedValue === "Unknown") {
      setModelOptions(["Unknown"]);
    }
    onChange(field, value);
  };

  return (
    <div>
      {error && <div className="error">{error}</div>}
      <div className="new-box-half">
        <Dropdown
          parentObject={landModeData}
          field="fuel"
          options={fuelOptions ? fuelOptions : []}
          onChange={handleFuelChange}
          label="Fuel"
        />
      </div>
      <div className="new-box-half">
        <Dropdown
          parentObject={landModeData}
          field="make"
          options={makeOptions ? makeOptions : []}
          onChange={handleMakeChange}
          label="Make"
        />
      </div>
      <div className="new-box-half">
        <Dropdown
          parentObject={landModeData}
          field="AxleConfiguration"
          options={[
            "Unknown",
            ...Object.keys(
              axleConfigurationEncodingMap
                ? axleConfigurationEncodingMap
                : "Unknown"
            ),
          ]}
          onChange={handleAxleChange}
          label="Axle Configuration"
        />
      </div>
      <div className="new-box-half">
        <Dropdown
          parentObject={landModeData}
          field="model"
          options={modelOptions ? modelOptions : []}
          onChange={onChange}
          label="Model"
        />
      </div>
    </div>
  );
};

export default LandModeComponent;
